import { useEffect, useState } from "react";

// const getOnlineStatus = () =>
//     typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true;

export function useNavigatorOnline () {
    const [status, setStatus] = useState(true);

    const setOnline = () => setStatus(true);
    const setOffline = () => setStatus(false);

    function refresh(){
        setStatus(undefined)
        setTimeout(() => {
            setStatus(true)
        }, 500);
    }

    useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return [status, refresh]
};
