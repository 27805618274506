import { Content } from "components/Content/Content";
import styles from "./Discovery.module.css";
import { InputButton } from "components/InputButton/InputButton";
import { useRef, useState } from "react";
import { OnchainFilters } from "components/OnchainFilters/OnchainFilters";
import { DISCOVERY_TEMPLATES, SIMPLE_STRUCTURE } from "Constants";
import { Dropdown } from "components/Dropdown/Dropdown";
import { AccountBalanceRounded, CloudRounded } from "@mui/icons-material";

export function Discovery() {

    const metricEl = useRef(null);
    const [metricOpen, setMetricOpen] = useState(false);
    const [metric, setMetric] = useState(null);
    const [filters, setFilters] = useState([])

    function templateClicked(code) {
        if (code === "RECENT") {
            setMetric("transactions")
        }

        if (code === "TOP_USERS") {
            setMetric("transactions")
            setFilters([
                {
                    filterStructure: SIMPLE_STRUCTURE,
                    id: {
                        filterResourceType: "template",
                        filterObject: null,
                        filterKey: "contract_address",
                        filterDatatype: "string"
                    },
                    filterResourceType: "template",
                    filterObject: null,
                    filterDatatype: "string",
                    filterKey: "contract_address",
                    staticOptions: null,
                    hasAsyncOptions: true,
                    name: "Contract address",
                    icon: {
                        "type": {}, "compare": null
                    },
                    supertype: null,
                    section: "template",
                    filterValue: [{ "id": "0x7a250d5630b4cf539739df2c5dacb4c659f2488d", "name": "Uniswap Router" }], "filterOperator": "eq"
                }
            ])
        }

        if (code === "TOP_TOKEN_HOLDERS") {
            setMetric("token_balance")
            setFilters([
                {
                    filterStructure: "SIMPLE_STRUCTURE",
                    id: {
                        filterResourceType: "template",
                        filterObject: null,
                        filterKey: "token_address",
                        filterDatatype: "string"
                    },
                    filterResourceType: "template",
                    filterObject: null,
                    filterDatatype: "string",
                    filterKey: "token_address",
                    staticOptions: null,
                    hasAsyncOptions: true,
                    name: "Token address",
                    icon: {
                        type: {},
                        compare: null
                    },
                    supertype: null,
                    section: "template",
                    filterValue: [
                        {
                            id: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
                            name: "USD Coin"
                        }
                    ],
                    filterOperator: "eq"
                }
            ])
        }

        if (code === "METHOD") {
            setMetric("transactions")
            setFilters([
                {
                    filterStructure: "SIMPLE_STRUCTURE",
                    id: {
                        filterResourceType: "template",
                        filterObject: null,
                        filterKey: "contract_method",
                        filterDatatype: "string"
                    },
                    filterResourceType: "template",
                    filterObject: null,
                    filterDatatype: "string",
                    filterKey: "contract_method",
                    staticOptions: null,
                    hasAsyncOptions: true,
                    name: "Method",
                    icon: {
                        type: {},
                        compare: null
                    },
                    supertype: null,
                    section: "template",
                    filterValue: [
                        {
                            id: "0xd0e30db0",
                            name: "deposit()"
                        }
                    ],
                    filterOperator: "eq"
                }
            ])
        }
    }

    const metricButtonText = metric == null ? "Select metric..." : METRIC_OPTIONS.find((x) => x.id === metric).name

    return <Content>
        <div className={styles.templatesWrap}>
            <button className={styles.template} onClick={() => templateClicked("RECENT")}>
                <div>💳 Most recent transactions</div>
            </button>
            <button className={styles.template} onClick={() => templateClicked("TOP_USERS")}>
                <div>👥 Top users of Uniswap</div>
            </button>
            <button className={styles.template} onClick={() => templateClicked("TOP_TOKEN_HOLDERS")}>
                <div>💵 Top token holders of USDC</div>
            </button>
            <button className={styles.template} onClick={() => templateClicked("METHOD")}>
                <div>💰 Users who have deposited</div>
            </button>
        </div>
        <div className={styles.queryBox}>
            <Dropdown
                anchorEl={metricEl}
                isOpen={metricOpen}
                onClose={() => setMetricOpen(false)}
                options={METRIC_OPTIONS}
                onChange={(obj) => {
                    const { id } = obj
                    setMetric(id)
                }}
            />
            <InputButton
                pref={metricEl}
                onOpen={() => setMetricOpen(true)}
                isOpen={metricOpen}
                empty={metric == null}
                buttonText={metricButtonText}
                className={styles.mainInput}
            />
            <div className={styles.filterWrap}>
                <div className={styles.filterSubtitle}>Filters</div>
                <div>
                    <OnchainFilters
                        filters={filters}
                        setFilters={setFilters}
                        templates={DISCOVERY_TEMPLATES}
                    // excludeTemplates={["wallet_address", "transaction_role", "chain"]}
                    // templates={EVENT_CONDITION_OPTIONS_V2.filter((x) => ["wallet_address", "transaction_role", "event_type", "contract_address", "chain"].includes(x.id))}
                    />
                </div>
            </div>
        </div>
    </Content>
}

const METRIC_OPTIONS = [
    {
        id: "transactions",
        name: "Transactions",
        icon: CloudRounded
    },
    {
        id: "token_balance",
        name: "Balance",
        icon: AccountBalanceRounded
    }
]