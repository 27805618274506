import { AutoAwesome, ElectricBoltRounded, FunctionsRounded, LayersRounded, MonetizationOn, PeopleAltRounded, Person2Outlined, SvgIconComponent, TextFieldsRounded, ToggleOnRounded } from "@mui/icons-material"
import { EVENT_STRUCTURE, LABEL_STRUCTURE, PROPERTY_STRUCTURE } from "Constants"
import { getRequestCohort, getRequestSubgroup } from "utils/cohort"
import { isDateValid } from "utils/date"
import { FilterDate } from "./date"

abstract class BaseCohortFilter {
    abstract toOption(): object
    abstract toRequest(): object
    abstract toStore(): object
    abstract isValid(): boolean
}

export class CohortFilter extends BaseCohortFilter {

    id: string
    structure: string
    name: string
    custom: boolean | undefined
    optionIcon: SvgIconComponent | undefined
    optionPretext: string | undefined
    activeIcon: SvgIconComponent | undefined
    activePretext: string | undefined

    constructor({ id, structure, name, custom }: { id: string, structure: string, name: string, custom?: boolean }) {
        super()
        this.id = id
        this.structure = structure
        this.name = name
        this.custom = custom
    }

    getIconPreset(icon: string | undefined): SvgIconComponent | undefined {
        if (icon !== undefined) {
            if (icon === "MONEY") return MonetizationOn
            if (icon === "AUTO") return AutoAwesome
        }
    }

    toRequest(): object {
        console.warn("using default request structure")
        return this
    }

    toStore(): object {
        console.warn("using default store structure")
        return {... this}
    }

    isValid(): boolean {
        return false
    }

    toOption(): object {
        return {
            structure: this.structure,
            id: this.id,
            name: this.name,
            icon: this.optionIcon,
            supertype: this.optionPretext,
            activeIcon: this.activeIcon,
            activePretext: this.activePretext,
        }
    }

}

type CohortLabel = {
    id: string
}

export class CohortLabelFilter extends CohortFilter {

    label: CohortLabel

    constructor({ id, name, label, custom }: { id: string, name: string, label: CohortLabel, custom?: boolean }) {
        super({ id, structure: LABEL_STRUCTURE, name, custom })
        this.label = label
        this.optionIcon = Person2Outlined
        this.optionPretext = "In label"
        this.activeIcon = PeopleAltRounded
        this.activePretext = "User in"
    }

    toRequest() {
        return {
            custom: this.custom,
            structure: this.structure,
            label: this.label
        }
    }

    toStore() {
        return {
            custom: this.custom,
            structure: this.structure,
            name: this.name,
            label: this.label
        }
    }

    isValid(): boolean {
        return this.label.id !== undefined
    }

}

type CohortProperty = {
    type?: string
    key?: string
    datatype?: string
    object?: string
    operator?: string
    value?: string | number | boolean
}

export class CohortPropertyFilter extends CohortFilter {

    property: CohortProperty

    getOptionIcon(): SvgIconComponent | undefined {
        const { type, datatype } = this.property
        if (type === "chain") return ElectricBoltRounded
        if (datatype === "string") return TextFieldsRounded
        if (datatype === "numeric") return FunctionsRounded
        if (datatype === "bool") return ToggleOnRounded
    }

    constructor({ id, name, property, icon, custom }: { id: string, name: string, type: string, icon: string | undefined, property: CohortProperty, custom?: boolean }) {
        super({ id, structure: PROPERTY_STRUCTURE, name, custom })
        this.property = property
        this.optionIcon = icon ? this.getIconPreset(icon) : this.getOptionIcon()
        if (this.property.operator == null) this.property.operator = "eq"
    }

    toRequest() {
        return {
            custom: this.custom,
            structure: this.structure,
            property: this.property
        }
    }

    toStore() {
        return {
            custom: this.custom,
            structure: this.structure,
            name: this.name,
            property: this.property
        }
    }

    isValid(): boolean {
        const { value } = this.property || {}
        return value != null && value !== ""
    }

}

type CohortEvent = {
    key?: string
    operator?: string
    date?: FilterDate
    behavior?: string
    value?: number;
}


export class CohortEventFilter extends CohortFilter {

    event: CohortEvent
    subgroup: CohortFilter[] | undefined
    subfilterOptions: CohortPropertyFilter[] | undefined

    constructor({ id, name, custom, event, icon, subfilterOptions }: { id: string, name: string, custom?: boolean, type: string, event: CohortEvent, icon: string | undefined, subfilterOptions: any[] | undefined }) {
        super({ id, structure: EVENT_STRUCTURE, name, custom })
        this.event = event || {}
        this.subfilterOptions = subfilterOptions?.map(option => new CohortPropertyFilter(option))
        this.optionIcon = icon ? this.getIconPreset(icon) : LayersRounded
        this.event.behavior = "EVENT_DID"
        this.event.operator = "gt"
        this.event.date = new FilterDate({
            mode: "last",
            value: 30,
            units: "day",
        })
    }

    toRequest() {
        return {
            custom: this.custom,
            structure: this.structure,
            event: this.event,
            subgroup: getRequestSubgroup(this.subgroup),
        }
    }

    toStore() {
        return {
            custom: this.custom,
            structure: this.structure,
            name: this.name,
            event: this.event
        }
    }

    isValid(): boolean {
        const { value, date, behavior } = this.event || {}
        if (behavior === "EVENT_DID") return value != null && isDateValid(date)
        if (behavior === "EVENT_DID_NOT") return isDateValid(date)
        return false    
    }

}