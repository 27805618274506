import { Content } from "components/Content/Content"
import styles from "./User.module.css"
import { CardV2 } from "components/CardV2/CardV2"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useInfiniteEndpoint, usePostEndpoint } from "ApiConnector"
import Blockies from 'react-blockies';
import PageLoading from "components/PageLoading/PageLoading"
import moment from "moment"
import { formatNumber, getExplorerLink, leftTrim, smartTrim, timeDifferencePastISO, timeDifferencePastMoment } from "helpers"
import { ArrowBackIosNewRounded, LayersRounded } from "@mui/icons-material"
import { cloneElement, useEffect, useRef, useState } from "react"
import { PLATFORMS } from "Constants"
import { CircularProgress, LinearProgress } from "@mui/material"
import styled from "@emotion/styled"
import { CustomLoadingButtonV2 } from "components/CustomLoadingV2/CustomLoadingButtonV2"
import { SelectV2 } from "components/SelectV2/SelectV2"
import { set } from "react-hook-form"

function getUserText({ userAliases, userAddresses, userDistinctIds }) {
    if (userAliases != null && userAliases.length > 0) {
        return userAliases[0]
    }
    if (userAddresses != null && userAddresses.length > 0) {
        return smartTrim(userAddresses[0], 10)
    }
    if (userDistinctIds != null && userDistinctIds.length > 0) {
        return leftTrim(userDistinctIds[0], 10)
    }
}

function getShouldShowProperty(user) {
    if (user == null) return false
    const { properties, context } = user || {}
    if (context == null && properties == null) return false
    const contextExists = context.length > 0
    const propsExist = properties.length > 0
    return contextExists || propsExist
}

export function User() {

    const { user, mode } = useParams()
    const { data, error } = usePostEndpoint({ endpoint: "user/info", body: { user } })
    const [maxObj, setMaxObj] = useState({
        time: moment(),
        ttl: 30
    })

    if (data == null) {
        return <PageLoading />
    }

    const { userId, firstSeen, location, source, userAliases, userAddresses, userDistinctIds } = data
    const userText = getUserText({ userAliases, userAddresses, userDistinctIds })
    const showPropertyTab = getShouldShowProperty(data)

    return <Content style={{ display: "flex", gap: "32px", minHeight: "100%" }}>
        <div style={{ display: "flex", flexDirection: "column", minWidth: "33%", maxWidth: "33%", width: "33%" }}>
            <div className={styles.card}>
                <div className={styles.topUserHeader}>
                    <div className={styles.iconContainer}>
                        <Blockies
                            seed={userId}
                            size={10}
                            scale={10}
                            bgColor={"transparent"}
                            className={styles.identicon}
                        />
                    </div>
                    <div className={styles.topUserHeaderText}>
                        {userText}
                    </div>
                </div>
                <div className={styles.userBlock}>
                    <div className={styles.userBlockTitle}>User ID</div>
                    <div className={styles.userBlockValue}>{userId}</div>
                </div>
                <div className={styles.userBlock}>
                    <div className={styles.userBlockTitle}>First Seen</div>
                    <div className={styles.userBlockValue}>{moment(firstSeen).format("MMM D, YYYY [at] h:mm:ss A")}</div>
                </div>
                <LocationBlock location={location} />
                {/* <SourceBlock source={source} /> */}
                {source && <div className={styles.userBlock}>
                    <div className={styles.userBlockTitle}>Source</div>
                    <div className={styles.userBlockValue}>{source}</div>
                </div>}
                <Addresses addresses={userAddresses} />
                <Aliases aliases={userAliases} />
                <OtherIDs ids={userDistinctIds} />
            </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", minWidth: "67%", maxWidth: "67%", width: "67%" }}>
            <div className={styles.topLinks}>
                <Link className={`${styles.filterButton} ${mode == null ? styles.active : ""}`} to={`/users/${user}`}>Activity</Link>
                {showPropertyTab && <Link className={`${styles.filterButton} ${mode === "properties" ? styles.active : ""}`} to={`/users/${user}/properties`}>Properties</Link>}
            </div>
            {mode == null && <Activity id={userId} maxObj={maxObj} setMaxObj={setMaxObj} />}
            {mode === "properties" && <UserProperties user={data} />}
        </div>
    </Content>
}

function UserProperties({ user }) {

    const { user: userDistinctId } = useParams()
    const { properties, context } = user || {}

    const contextExists = context.length > 0
    const propsExist = properties.length > 0

    const [setting, setSetting] = useState(propsExist > 0 ? "properties" : "context")

    const navigate = useNavigate()

    useEffect(() => {
        if(!contextExists && !propsExist){
            navigate(`/users/${userDistinctId}`)
        }
    }, [contextExists, propsExist])

    if (!contextExists && !propsExist) {
        return
    }

    const showButtons = contextExists && propsExist
    const propertyLs = setting === "properties" ? properties : context

    return <>
        {showButtons && <div style={{ display: "flex", marginBottom: 16 }}>
            <button className={`${styles.eventBodyButton} ${styles.large} ${setting === "properties" ? styles.active : ""}`} onClick={() => setSetting("properties")}>Your Properties ({properties.length})</button>
            <button className={`${styles.eventBodyButton} ${styles.large} ${setting === "context" ? styles.active : ""}`} onClick={() => setSetting("context")}>Multibase Properties ({context.length})</button>
        </div>}
        <div className={styles.card}>
            <PropertySection
                properties={propertyLs}
                columns={3}
            />
        </div>
    </>
}

function PropertySection({ properties, columns }) {
    if (properties == null || properties.length === 0) return
    const indexGreaterThanForLastRow = properties.length % columns === 0 ? properties.length - columns : properties.length - properties.length % columns
    return <div className={styles.collapsedSection}>
        <div className={styles.propertiesInner}>
            {properties.map((property, i) => {
                return <PropertyCell
                    key={i}
                    index={i}
                    columns={columns}
                    property={property}
                    indexGreaterThanForLastRow={indexGreaterThanForLastRow}
                />
            })}
        </div>
    </div>
}

function PropertyCell({ property, columns, index, indexGreaterThanForLastRow }) {
    const [copied, setCopied] = useState(false)

    const isLastCol = (columns + 1) % 3 === 0

    function copyToClipboard() {
        navigator.clipboard.writeText(property.value)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1000)
    }

    return <button key={index} className={`${styles.propertiesCell} ${index >= indexGreaterThanForLastRow ? styles.lastRow : ""} ${isLastCol ? styles.lastCol : ""}`} onClick={copyToClipboard}>
        <div className={styles.propertiesTitle}>{property.name}</div>
        <div className={styles.propertiesText}>{property.value}</div>
        <div className={styles.copyIcon}>
            {!copied && <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7.708 2.292.706-.706A2 2 0 0 1 9.828 1h6.239A.97.97 0 0 1 17 2v12a.97.97 0 0 1-.933 1H15M6 5v4a1 1 0 0 1-1 1H1m11-4v12a.97.97 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V9.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 5h5.239A.97.97 0 0 1 12 6Z" />
            </svg>}
            {copied && <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
            </svg>}
        </div>
    </button>
}

// function UserArchive() {

//     const { user, mode } = useParams()
//     const { data: generalUserInfo, error } = usePostEndpoint({ endpoint: "user/info", body: { user } })

//     if (error != null) {
//         return <div className={styles.errorWrap}>
//             <CardV2 className={styles.errorCard}>
//                 <div className={styles.errorTitle}>User not found</div>
//                 <div className={styles.errorSubtitle}>It looks like this user hasn't been imported into this workspace. For help on how to import users, check out the <a href="https://docs.multibase.co/importing/contract" target="_blank" rel="noreferrer" className={styles.errorLink}>documentation</a>.</div>
//                 <Link to="/" className={styles.errorButton}>
//                     <div className={styles.errorButtonIcon}>
//                         <ArrowBackIosNewRounded />
//                     </div>
//                     <div>
//                         Go back home
//                     </div>
//                 </Link>
//             </CardV2>
//         </div>
//     }

//     if (generalUserInfo == null) {
//         return <PageLoading />
//     }

//     const { userId, firstSeen, userAliases, userAddresses, userDistinctIds } = generalUserInfo
//     if (firstSeen == null) {
//         return <div className={styles.errorWrap}>
//             <CardV2 className={styles.errorCard}>
//                 <div className={styles.errorTitle}>User not found</div>
//                 <div className={styles.errorSubtitle}>It looks like this user hasn't logged any activity yet. This could be because the user hasn't been imported correctly, or because the user hasn't logged any activity yet. For help on how to import users, check out the <a href="https://docs.multibase.co/importing/contract" target="_blank" rel="noreferrer" className={styles.errorLink}>documentation</a>.</div>
//                 <Link to="/" className={styles.errorButton}>
//                     <div className={styles.errorButtonIcon}>
//                         <ArrowBackIosNewRounded />
//                     </div>
//                     <div>
//                         Go back home
//                     </div>
//                 </Link>
//             </CardV2>
//         </div>
//     }

//     const firstSeenFmt = timeDifferencePastISO(firstSeen)
//     const highlightBar = <div className={styles.highlightBar} />

//     return <>
//         <Content style={{ display: "flex", flexDirection: "column", height: "100%" }}>
//             <div className={styles.top}>
//                 <div className={styles.topHeader}>
//                     <div className={styles.iconContainer}>
//                         <Blockies
//                             seed={user}
//                             size={10}
//                             scale={10}
//                             bgColor={"transparent"}
//                             className={styles.identicon}
//                         />
//                     </div>
//                     <div className={styles.topHeaderText}>
//                         {/* <div className={styles.title}>{userAlias || user}</div> */}
//                         <div className={styles.subtitle}>First seen {firstSeenFmt} ago</div>
//                     </div>
//                 </div>
//                 <div className={styles.topLinks}>
//                     <Link className={`${styles.filterButton} ${mode == null ? styles.active : ""}`} to={`/users/${user}`}>Activity</Link>
//                     <Link className={`${styles.filterButton} ${mode === "properties" ? styles.active : ""}`} to={`/users/${user}/properties`}>Properties</Link>
//                 </div>
//             </div>
//             <div className={styles.userBody}>
//                 {mode == null && <Activity user={user} />}
//                 {mode === "properties" && <Properties user={user} />}
//             </div>
//         </Content>
//     </>
// }

function getLocationString(location) {
    const [country, region, city] = location
    // denver, co usa
    if (country !== '' && region !== '' && city !== '') {
        return `${city}, ${region} ${country}`
    }

    if (country !== '' && region !== '') {
        return `${region} ${country}`
    }

    if (country !== '') {
        return `${country}`
    }

    return ''
}

function LocationBlock({ location }) {
    if (location == null) return;
    const locationNonEmpty = location.filter((x) => x != null && x !== "").length > 0
    if (!locationNonEmpty) return;

    const locationText = getLocationString(location)

    return <div className={styles.userBlock}>
        <div className={styles.userBlockTitle}>Location</div>
        <div className={styles.userBlockValue}>{locationText}</div>
    </div>
}

function Addresses({ addresses }) {

    const [expanded, setExpanded] = useState(false)
    
    if (addresses == null || addresses.length === 0) return
    return <div className={styles.userBlock}>
        <div className={styles.userBlockTitle}>Addresses</div>
        <div className={styles.userBlockValueWrap}>
            {addresses.slice(0, 5).map((address, i) => {
                return <a
                    key={i}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.userBlockItemTag}
                    href={getExplorerLink("eth", "address", address)}
                >
                    <div className={styles.userBlockItemTagText}>{smartTrim(address, 15)}</div>
                </a>
            })}
            {expanded && addresses.slice(5).map((address, i) => {
                return <a
                    key={i}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.userBlockItemTag}
                    href={getExplorerLink("eth", "address", address)}
                >
                    <div className={styles.userBlockItemTagText}>{smartTrim(address, 15)}</div>
                </a>
            })}
        </div>
        {(addresses.length > 5 && !expanded) && <button className={styles.userBlockShowMore} onClick={() => setExpanded((prev) => !prev)}>
            <div className={styles.userBlockItemTagText}>{addresses.length - 5} more</div>
            <svg className={styles.userBlockItemShowMoreIcon}
                style={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }}
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m1 1 4 4 4-4" />
            </svg>
        </button>}
        {/* <div className={styles.userBlockValueWrap}>
            {addresses.map((address, i) => {
                return <a
                    key={i}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.userBlockItemTag}
                    href={getExplorerLink("eth", "address", address)}
                >
                    <div className={styles.userBlockItemTagText}>{smartTrim(address, 15)}</div>
                </a>
            })}
        </div> */}
    </div>
}

function Aliases({ aliases }) {

    const [expanded, setExpanded] = useState(false)

    if (aliases == null || aliases.length === 0) return
    return <div className={styles.userBlock}>
        <div className={styles.userBlockTitle}>Aliases</div>
        <div className={styles.userBlockValueWrap}>
            {aliases.slice(0, 5).map((alias, i) => {
                return <a key={i} className={styles.userBlockItemTag} href={`https://etherscan.io/name-lookup-search?id=${alias}`} target="_blank" rel="noreferrer">
                    <div className={styles.userBlockItemTagText}>{alias}</div>
                </a>
            })}
            {expanded && aliases.slice(5).map((alias, i) => {
                return <a key={i} className={styles.userBlockItemTag} href={`https://etherscan.io/name-lookup-search?id=${alias}`} target="_blank" rel="noreferrer">
                    <div className={styles.userBlockItemTagText}>{alias}</div>
                </a>
            })}
        </div>
        {(aliases.length > 5 && !expanded) && <button className={styles.userBlockShowMore} onClick={() => setExpanded((prev) => !prev)}>
            <div className={styles.userBlockItemTagText}>{aliases.length - 5} more</div>
            <svg className={styles.userBlockItemShowMoreIcon}
                style={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }}
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m1 1 4 4 4-4" />
            </svg>
        </button>}
    </div>
}

function OtherIDs({ ids }) {

    const [expanded, setExpanded] = useState(false)

    if (ids == null || ids.length === 0) return

    return <div className={styles.userBlock}>
        <div className={styles.userBlockTitle}>Other IDs</div>
        <div className={styles.userBlockValueWrap}>
            {ids.slice(0, 5).map((id, i) => {
                return <div key={i} className={styles.userBlockItemTag}>
                    <div className={styles.userBlockItemTagText}>{id}</div>
                </div>
            })}
            {ids.slice(5).map((id, i) => {
                return <div key={i} className={styles.userBlockItemTag} style={{ display: expanded ? "flex" : "none" }}>
                    <div className={styles.userBlockItemTagText}>{id}</div>
                </div>
            })}
        </div>
        {(ids.length > 5 && !expanded) && <button className={styles.userBlockShowMore} onClick={() => setExpanded((prev) => !prev)}>
            <div className={styles.userBlockItemTagText}>{ids.length - 5} more</div>
            <svg className={styles.userBlockItemShowMoreIcon}
                style={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }}
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m1 1 4 4 4-4" />
            </svg>
        </button>}
    </div>
}

// function getStartOfXMinutes(minutes) {
//     return moment().startOf('minute').add(minutes - moment().minute() % minutes, 'minutes').toISOString()
// }

function getMaxTime(maxObj, setMaxObj) {
    const { time, ttl } = maxObj;
    const now = moment()
    if (time.isAfter(moment().add(ttl, "seconds"))) {
        setMaxObj({ time: now, ttl })
        return now.toISOString()
    }
    return time.toISOString()
}

function Activity({ id, maxObj, setMaxObj }) {

    const [selected, setSelected] = useState([])
    const [activityItems, setActivityItems] = useState(undefined)
    const [hasMore, setHasMore] = useState(true)
    const [offset, setOffset] = useState(0)
    const maxTime = getMaxTime(maxObj, setMaxObj)
    const { data, error } = usePostEndpoint({ endpoint: "user/activity", body: { user: id, offset, max: maxTime } })
    const [loading, setLoading] = useState(true);
    const [contractFilter, setContractFilter] = useState("my")

    function fetchNext() {
        if (!hasMore) return
        setLoading(true);
        setOffset(offset + 100)
    }

    useEffect(() => {
        if (data == null) return
        if (activityItems == null) {
            setActivityItems(data)
            setLoading(false);
            const hm = data.length === 100
            setHasMore(hm)
            return
        }
        const existingIds = activityItems.map((x) => x.id)
        const newDataExists = data.some((x) => !existingIds.includes(x.id))
        if (!newDataExists) {
            setLoading(false);
            setHasMore(false)
            return
        }
        setActivityItems([...activityItems, ...data])
        setLoading(false);
        const hm = data.length === 100
        setHasMore(hm)
    }, [data])

    const top = null
    // const top = <div className={styles.topActivity}>
    //     <SelectV2
    //         className={styles.unitInput}
    //         option={contractFilter}
    //         options={[
    //             { id: "my", name: "My contracts" },
    //             { id: "all", name: "All contracts" },
    //         ]}
    //         onChange={(val) => {
    //             setContractFilter(val)
    //             // onDraftChange({ units: val })
    //         }}
    //     />
    // </div>

    if ((data == null || activityItems == null) && offset === 0) {
        return <>
            {top}
            <BodyLoading />
        </>
    }

    if (error) {
        return <>
            {top}
            <div className={styles.errorCard}>
                <div style={{ maxWidth: 350, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className={styles.errorTitle}>Error loading activity</div>
                    <div className={styles.errorSubtitle}>There was an error loading this user's activity. Please try again later.</div>
                    <Link to="/" className={styles.errorButton}>
                        Go back home
                    </Link>
                </div>
            </div>
        </>
    }

    if (activityItems.length === 0) {
        return <>
            {top}
            <div className={styles.errorCard}>
                <div style={{ maxWidth: 350, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className={styles.errorTitle}>Activity not found</div>
                    <div className={styles.errorSubtitle}>It looks like this user hasn't logged any activity or made any transactions. For help on how to log activity, check out the <a href="https://docs.multibase.co/importing/contract" target="_blank" rel="noreferrer" className={styles.errorLink}>documentation</a>.</div>
                    <Link to="/" className={styles.errorButton}>
                        Go back home
                    </Link>
                </div>
            </div>
        </>
    }

    const lastTime = activityItems[activityItems.length - 1].ts

    return <div className={styles.activityContainer}>
        {/* <div className={styles.leftActivity}
            ref={outerRef}
        > */}
        {top}
        <Timeline
            data={activityItems}
            selected={selected}
            onSelect={(index) => {
                const selectedIndex = selected.findIndex((x) => x === index)
                if (selectedIndex !== -1) {
                    const newSelected = [...selected]
                    newSelected.splice(selectedIndex, 1)
                    setSelected(newSelected)
                    return
                }
                setSelected([...selected, index])
            }}
        />
        {hasMore && <div className={styles.eventBottom}>
            <div className={styles.eventBottomInner}>
                <div>Showing {activityItems.length} results through {moment(lastTime).format("YYYY-MM-DD HH:mm:ss")}</div>
                <CustomLoadingButtonV2 className={styles.loadBtn} light={true} loading={loading} onClick={fetchNext}>
                    Load more
                </CustomLoadingButtonV2>
            </div>
        </div>}
    </div>
    {/* <div className={styles.rightActivity}>
            <EventData data={selected} />
        </div> */}
    // </div>

    // return <div className={styles.activityContainer}>
    //     <CardV2 className={styles.leftActivity} noPadding passedRef={scrollRef}>
    //         {loading && <div className={styles.linearLoadingWrap}>
    //             <MultibaseProgress />
    //         </div>}
    //         <Timeline 
    //             data={data} 
    //             selected={selected} 
    //             onSelect={(obj) => setSelected(obj)}   
    //         />
    //     </CardV2>
    //     <CardV2 className={styles.rightActivity} noPadding>
    //         <EventData data={selected} />
    //     </CardV2>
    // </div>
}

function Timeline({ data, selected, onSelect }) {

    const days = {}
    data.forEach((x, idx) => {
        x.index = idx
        const day = moment(x.ts).format("YYYY-MM-DD")
        if (days[day] == null) days[day] = {
            day: moment(x.ts).startOf("day"),
            data: []
        }
        days[day].data.push(x)
    })

    return <div className={styles.card}>
        <div className={styles.timelineColumn}>
            {Object.keys(days).map((dayIndex, index) => {
                const dayObj = days[dayIndex]
                const { day, data: dayData } = dayObj
                const { type } = dayData
                return <div className={styles.timelineItem} key={index}>
                    <div className={styles.timelineHeader}>
                        <div className={styles.timelineItemDate}>
                            {day.format("MMM D, YYYY")}
                        </div>
                    </div>
                    <SubTimeline data={dayData} selected={selected} onClick={onSelect} />
                </div>
            })}
        </div>
    </div>
}

const SESSION_DEFINITION = 3600

function SubTimeline({ data, selected, onClick }) {

    let lastTime = null

    return <div className={styles.subTimeline}>
        {data.map((x, idx) => {
            const isSelected = selected?.id === x.id
            const { type, index } = x
            let sessionBreak = null
            if (lastTime != null) {
                const diff = moment(lastTime).diff(x.ts, "seconds")
                if (diff > SESSION_DEFINITION) {
                    sessionBreak = <div className={styles.sessionBreak} key={idx}>
                        <div className={styles.sessionLine} />
                    </div>
                }
            }
            lastTime = moment(x.ts)
            return <div key={idx}>
                {sessionBreak}
                <div>
                    {type === "onchain" && <OnchainEvent data={x} isSelected={isSelected} onClick={() => onClick(index)} />}
                    {type === "custom" && <OffchainEvent data={x} isSelected={isSelected} onClick={() => onClick(index)} />}
                    {selected.includes(index) && <EventData data={x} />}
                </div>
            </div>
        })}
    </div>
}

function OnchainEvent({ data, isSelected, onClick }) {
    const { ts, event } = data
    const formattedTime = moment(ts).format("h:mm:ss a")
    const platform = PLATFORMS.find((x) => x.id === data.metadata.chain)
    return <button className={`${styles.eventRow} ${isSelected ? styles.selected : ""}`} onClick={onClick}>
        <div className={styles.iconCol}>
            {/* <ElectricBoltRounded /> */}
            {cloneElement(platform.svg, { className: styles.icon })}
        </div>
        <div className={styles.timeCol}>{formattedTime}</div>
        <div className={styles.textCol}>{event || "[no method]"}</div>
    </button>
}

function OffchainEvent({ data, isSelected, onClick }) {
    const { ts, event } = data
    const formattedTime = moment(ts).format("h:mm:ss a")
    return <button className={`${styles.eventRow} ${isSelected ? styles.selected : ""}`} onClick={onClick}>
        <div className={styles.iconCol}><LayersRounded style={{ width: 16, height: 16 }} /></div>
        <div className={styles.timeCol}>{formattedTime}</div>
        <div className={styles.textCol}>{event || "-"}</div>
    </button>

}

function Properties({ user }) {

    const { data } = usePostEndpoint({ endpoint: "user/properties", body: { user } })

    if (!data) {
        return <BodyLoading />
    }

    if (data?.properties?.length === 0 && data?.context?.length === 0) {
        // props not found
        return <div className={styles.errorWrap}>
            <CardV2 className={styles.errorCard}>
                <div className={styles.errorTitle}>Properties not found</div>
                <div className={styles.errorSubtitle}>It looks like this user hasn't been assigned any properties. For help on how to assign properties, check out the <a href="https://docs.multibase.co/importing/contract" target="_blank" rel="noreferrer" className={styles.errorLink}>documentation</a>.</div>
                <Link to="/" className={styles.errorButton}>
                    <div className={styles.errorButtonIcon}>
                        <ArrowBackIosNewRounded />
                    </div>
                    <div>
                        Go back home
                    </div>
                </Link>
            </CardV2>
        </div>
    }

    if (data?.properties == null && data?.context == null) {
        // props not found
        return <div className={styles.errorWrap}>
            <CardV2 className={styles.errorCard}>
                <div className={styles.errorTitle}>Properties not found</div>
                <div className={styles.errorSubtitle}>It looks like this user hasn't been assigned any properties. For help on how to assign properties, check out the <a href="https://docs.multibase.co/importing/contract" target="_blank" rel="noreferrer" className={styles.errorLink}>documentation</a>.</div>
                <Link to="/" className={styles.errorButton}>
                    <div className={styles.errorButtonIcon}>
                        <ArrowBackIosNewRounded />
                    </div>
                    <div>
                        Go back home
                    </div>
                </Link>
            </CardV2>
        </div>
    }

    return <CardV2 className={styles.scrollAuto}>
        <PropertiesBreakdown data={data} />
    </CardV2>
}

function PropertiesBreakdown({ data }) {

    const { user, mode } = useParams()
    const { properties, context } = data || {}
    const indexGreaterThanForLastRow = context.length % 3 === 0 ? context.length - 3 : context.length - context.length % 3

    return <>
        <div className={styles.collapsedSection}>
            <div className={styles.collapsedTopTitle}>Context</div>
            <div className={styles.propertiesInner}>
                {context.map((property, i) => {
                    const isLastCol = (i + 1) % 3 === 0
                    return <div key={i} className={`${styles.propertiesCell} ${i >= indexGreaterThanForLastRow ? styles.lastRow : ""} ${isLastCol ? styles.lastCol : ""}`}>
                        <div className={styles.propertiesTitle}>{property.name}</div>
                        <div className={styles.propertiesText}>{property.value}</div>
                    </div>
                })}
            </div>
        </div>
        <div className={styles.collapsedSection}>
            <div className={styles.collapsedTopTitle}>Custom properties</div>
            <div className={styles.contextInner}>
                {properties.map((property, i) => {
                    return <div key={i} className={styles.propertiesCell}>
                        <div className={styles.propertiesTitle}>{property.name}</div>
                        <div className={styles.propertiesText}>{property.value}</div>
                    </div>
                })}
            </div>
        </div>
    </>
}

function EventData({ data }) {
    if (data == null) return <></>
    const { type } = data
    // if(type === "onchain"){
    //     return <OnchainEventData data={data} />
    // }
    // if(type === "custom"){
    //     return <OffchainEventData data={data} />
    // }
    return <div className={styles.eventData}>
        {type === "onchain" && <OnchainEventData data={data} />}
        {type === "custom" && <OffchainEventData data={data} />}
    </div>
}

function OnchainEventData({ data }) {
    const { ts, event, metadata } = data

    return <div>
        {/* <div className={styles.eventHeader}>
            <div>{moment(ts).format("MMM D, YYYY")}</div>
            <div>{moment(ts).format("h:mm:ss a")}</div>
        </div> */}
        {/* <div className={styles.eventTitleWrap}>
            <div className={styles.eventTitle}>{event || "[no method]"}</div>
        </div> */}
        <div className={styles.eventBody}>
            <OnchainBody metadata={metadata} />
        </div>
    </div>
}

function OnchainBody({ metadata }) {
    const { chain, from_address, to_address, hash, value } = metadata
    const platformObj = PLATFORMS.find((x) => x.id === chain)

    const properties = [
        {
            name: "Chain",
            value: <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <img src={platformObj.imgURL} className={styles.chainLogo} />
                <div>{platformObj.name}</div>
            </div>
        }, {
            name: "From",
            value: <a href={getExplorerLink(chain, "address", from_address)} target="_blank" rel="noreferrer" className={styles.collapseLinkInline}>{smartTrim(from_address, 15)}</a>
        },
        {
            name: "To",
            value: <a href={getExplorerLink(chain, "address", to_address)} target="_blank" rel="noreferrer" className={styles.collapseLinkInline}>{smartTrim(to_address, 15)}</a>
        },
        {
            name: "Hash",
            value: <a href={getExplorerLink(chain, "tx", hash)} target="_blank" rel="noreferrer" className={styles.collapseLinkInline}>{smartTrim(hash, 25)}</a>
        },
        {
            name: "Value",
            value: <>{formatNumber({ number: value, decimals: 2 })} {platformObj?.token}</>
        }
    ]

    return <>
        <div className={styles.collapsedSection}>
            {/* <div className={styles.collapsedTopTitle}>Transaction data</div> */}
            <div className={styles.collapsedInner}>
                {properties.map((property, i) => {
                    return <div key={i} className={styles.collapsedCell}>
                        <div className={styles.collapsedTitle}>{property.name}</div>
                        <div className={styles.collapsedText}>{property.value}</div>
                    </div>
                })}
            </div>
        </div>
    </>
}

function OffchainEventData({ data }) {
    const { ts, event, properties, context } = data

    return <div>
        {/* <div className={styles.eventHeader}>
            <div>{moment(ts).format("MMM D, YYYY")}</div>
            <div>{moment(ts).format("h:mm:ss a")}</div>
        </div> */}
        {/* <div className={styles.eventTitleWrap}>
            <div className={styles.eventTitle}>{event}</div>
        </div> */}
        <div className={styles.eventBody}>
            <OffchainEventBody properties={properties} context={context} />
        </div>
    </div>
}

function OffchainEventBody({ properties, context }) {

    const contextExists = context.length > 0
    const propsExist = properties.length > 0

    const [expanded, setExpanded] = useState(null)
    const [setting, setSetting] = useState(properties.length > 0 ? "properties" : "context")

    function copyValue(value) {
        navigator.clipboard.writeText(value)
    }

    if (!contextExists && !propsExist) {
        return <div className={styles.collapsedSection}>
            <div className={styles.collapsedInner}>
                <div className={styles.collapsedCell}>
                    <div className={styles.collapsedTitle}>No data found</div>
                </div>
            </div>
        </div>
    }

    const showButtons = contextExists && propsExist
    const propertyLs = setting === "properties" ? properties : context

    return <>
        {showButtons && <div style={{ display: "flex", marginBottom: 8 }}>
            <button className={`${styles.eventBodyButton} ${setting === "properties" ? styles.active : ""}`} onClick={() => setSetting("properties")}>Your Properties ({properties.length})</button>
            <button className={`${styles.eventBodyButton} ${setting === "context" ? styles.active : ""}`} onClick={() => setSetting("context")}>Multibase Properties ({context.length})</button>
        </div>}
        <div className={styles.collapsedSection}>
            <div className={styles.collapsedInner}>
                {propertyLs.map((property, i) => {
                    return <div key={i} className={styles.collapsedCell}>
                        <div className={styles.collapsedTitle}>{property.name}</div>
                        <div className={styles.collapsedCellValueWrap}>
                            {property.value}
                        </div>
                    </div>
                })}
            </div>

        </div>
    </>
}

function BodyLoading() {
    return <div className={styles.bodyLoadingWrap}>
        <CircularProgress sx={{ color: "var(--alpha-text)" }} />
    </div>
}