import styled from "@emotion/styled"
import { LinearProgress } from "@mui/material"

export function Progress({size, className}){
    return <MultibaseProgress height={size} className={className} />
}

const MultibaseProgress = styled(LinearProgress)(({height}) => ({
    height,
    backgroundColor: "rgb(var(--primary) / 20%)",
    '& .MuiLinearProgress-bar': {
        borderRadius: 4,
        backgroundColor: "rgb(var(--primary))"
    }
}))