import styled from "@emotion/styled";
import { MenuItem, Select as MuiSelect, InputBase } from "@mui/material";
import { ReactComponent as Triangle } from "assets/icons/Triangle.svg"
import { Tooltip } from "components/Tooltip/Tooltip";

export function Select({ value, onChange, options, classes, placeholder, defaultValue, locked = false, lockText = null, sx, ...rest }) {

    const selectEl = <CustomSelect
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => {
            onChange(e)
        }}
        className={classes?.wrap}
        placeholder={placeholder}
        disabled={locked}
        classes={{
            select: classes?.select
        }}
        input={<CustomInput />}
        IconComponent={(x) => {
            const open = x.className.includes("iconOpen")
            return <IconWrap open={open}>
                <Triangle />
            </IconWrap>
        }}
        MenuProps={{
            PaperProps: {
                sx: {
                    background: "rgb(var(--background))",
                    transition: "none"
                }
            },
            MenuListProps: {
                sx: {
                    background: "var(--ndp01)",
                }
            },
            transitionDuration: 0,
            classes: {
                paper: classes?.menuWrap
            }
        }}
        {...rest}
    >
        {options.map((x, idx) => {
            return <CustomMenuItem
                key={idx}
                value={x.id}
                disableRipple={true}
            >{x.name}</CustomMenuItem>
        })}
    </CustomSelect>

    if (locked) {
        return <Tooltip text={lockText}>
            <div>
                {selectEl}
            </div>
        </Tooltip>
    }

    return selectEl
}

const CustomInput = styled(InputBase)(({ theme }) => {
    return {
        // 'label + &': {
        //     marginTop: theme.spacing(3),
        // },
        '& .MuiInputBase-input': {
            borderRadius: 5,
            position: 'relative',
            border: '1px solid var(--border-01)',
            fontSize: 13,
            lineHeight: "1em",
            padding: "8px",
            // paddingLeft: "8px",
            display: "flex",
            alignItems: "center",
            boxSizing: 'border-box',
            // backgroundColor: backgroundColor ?? "var(--ndp02)",
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            background: "transparent",
            '&:focus': {
            },
        },
        // if disabled, color is white
        "& .MuiInputBase-input.Mui-disabled": {
            color: "white",
            WebkitTextFillColor: "white",
        },
        zIndex: 0,
        icon: {
            fill: "var(--light)",
        },
    }
})

const CustomSelect = styled(MuiSelect)(({ theme }) => {
    return {
        ':disabled': {
            cursor: "not-allowed",
            color: "white"
        }
        // transitionDuration: "0s !important",
        // boxSizing: 'border-box',
    }
})


const CustomMenuItem = styled(MenuItem)(({ theme }) => {
    return {
        fontSize: 13,
        fontFamily: "Inter",
        ":hover": {
            background: "var(--ndp02)"
        },
        ":focus": {
            background: "var(--ndp02)"
        },
        "&.Mui-selected": {
            background: "var(--ndp02)"
        },
        "&.Mui-selected:focus": {
            background: "var(--ndp02)"
        }
    }
})

const IconWrap = styled.div(({ open }) => {
    return {
        display: "flex",
        alignItems: "center",
        width: "8px",
        height: "8px",
        position: "absolute",
        top: "50%",
        right: "12px",
        transform: `translateY(-50%) rotate(${open ? "0deg" : "180deg"})`,
        transition: "transform 200ms",
        color: "var(--light)",
        zIndex: -1
    }
})