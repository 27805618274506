import styled from "@emotion/styled";
import { Button as MuiButton } from "@mui/material";
import { cloneElement } from "react";
import { Link } from "react-router-dom";

const ClassicButton = styled(MuiButton, {
    shouldForwardProp: (prop) => !['colorVariant'].includes(prop),
})(({ theme, colorVariant }) => {
    let color = "rgb(var(--primary))"
    let highlightColor = "rgb(var(--primary) / 80%)"
    let border = undefined;
    let textColor = "white"
    let disabledColor = "rgb(var(--primary) / 30%)"

    if (colorVariant === "outline") {
        color = "transparent"
        highlightColor = "transparent"
        border = "1px solid var(--border-00)"
    }

    if (colorVariant === "destructive") {
        color = "rgb(var(--alpha-red) / 10%)"
        textColor = "rgb(var(--alpha-red))"
        highlightColor = "rgb(var(--alpha-red) / 8%)"
        border = "1px solid rgb(var(--alpha-red))"
    }

    return {
        // width: "100%",
        textTransform: "none",
        background: color,
        minWidth: "unset",
        border: border,
        padding: "4px 12px",
        fontSize: "13px",
        color: "white",
        boxShadow: "none",
        fontWeight: "400",
        height: "unset",
        color: textColor,
        transitionProperty: "opacity, background",
        transitionDuration: "200ms",
        ':hover': {
            background: highlightColor,
            boxShadow: "none",
            opacity: colorVariant === "outline" ? 0.8 : 1,
        },
        ':disabled': {
            background: disabledColor,
            color: "rgb(255 255 255 / 50%)",
            boxShadow: "none",
        },
    }
})

const TextButton = styled(MuiButton)(({ theme }) => ({
    // width: "/100%",
    textTransform: "none",
    background: "transparent",
    minWidth: "unset",
    justifyContent: "flex-start",
    boxShadow: "none",
    padding: "4px 12px",
    fontSize: "13px",
    color: "white",
    height: "unset",
    ':hover': {
        boxShadow: "none",
    }
}))

const ButtonOpacity = styled(MuiButton)(({ theme }) => ({
    // width: "100%",
    textTransform: "none",
    background: "rgb(var(--primary) / 10%)",
    minWidth: "unset",
    padding: "4px 12px",
    fontSize: "13px",
    color: "rgb(var(--primary))",
    height: "unset",
    boxShadow: "none",
    ':hover': {
        background: "rgb(var(--primary) / 10%)",
        boxShadow: "none",
    }
}))

export function Button({ variant = "classic", colorVariant = "classic", href, state, buttonRef, children, startIcon, className, onClick, iconSize = 16, disabled = false }) {

    let extraProps = {}
    if (href != null) {
        extraProps = {
            component: Link,
            to: href,
            state: state
        }
    }

    if (variant === "text") {
        return <TextButton
            onClick={onClick}
            variant={"text"}
            ref={buttonRef}
            disableRipple={true}
            className={className}
            disabled={disabled}
            {...extraProps}
        >
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                {startIcon && <div style={{ display: "flex", alignItems: "center", width: iconSize, height: iconSize }}>
                    {cloneElement(startIcon, { style: { width: iconSize, height: iconSize } })}
                </div>}
                <div>
                    {children}
                </div>
            </div>
        </TextButton>
    }

    if (variant === "opacity") {
        return <ButtonOpacity
            onClick={onClick}
            ref={buttonRef}
            variant={"contained"}
            disableRipple={true}
            className={className}
            disabled={disabled}
            {...extraProps}
        >
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                {startIcon && <div style={{ display: "flex", alignItems: "center", width: "16px", height: "16px" }}>
                    {cloneElement(startIcon, { style: { width: "16px", height: "16px" } })}
                </div>}
                <div>
                    {children}
                </div>
            </div>
        </ButtonOpacity>
    }

    return <ClassicButton
        variant={"contained"}
        colorVariant={colorVariant}
        disableRipple={true}
        ref={buttonRef}
        className={className}
        onClick={onClick}
        disabled={disabled}
        {...extraProps}
    // LinkComponent={Link}
    // href={href}
    >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {startIcon && <div style={{ display: "flex", alignItems: "center", width: "16px", height: "16px" }}>
                {cloneElement(startIcon, { style: { width: "16px", height: "16px" } })}
            </div>}
            <div>
                {children}
            </div>
        </div>
    </ClassicButton>
}