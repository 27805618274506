import styled from "@emotion/styled"

export function CardV2({ children, noPadding, className, passedRef, style = {} }) {
    return <CardDiv className={className} noPadding={noPadding} style={style} ref={passedRef}>
        {children}
    </CardDiv>
}

const CardDiv = styled.div(({theme, noPadding})=>{
    const mode = theme.palette.mode
    return {
        background: "var(--card)",
        border: "1px solid var(--border-00)",
        width: "100%",
        // boxShadow: mode === "light" && "rgb(149 157 165 / 18%) 0px 0px 10px",
        borderRadius: "4px",
        // height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: noPadding? "0px" : "16px"
    }
})
// const cardStyles = {
//     background: "var(--ndp02)",
//     width: "100%",
//     boxShadow: "rgb(149 157 165 / 18%) 0px 0px 10px",
//     borderRadius: "4px",
//     height: "100%",
//     display: "flex",
//     flexDirection: "column",
//     border: "2px solid var(--border-00)",
//     padding: "24px 32px"
// }