import { Content } from "components/Content/Content";
import { TopBar } from "components/TopBar/TopBar";
import styles from "./Sources.module.css";
import { useEffect, useRef, useState } from "react";
import { AddOutlined, ArrowBackIosNewRounded, ContentCopyRounded, DeleteOutlineRounded, SearchRounded } from "@mui/icons-material";
import { Modal } from "components/Modal/Modal";
import { UilJavaScript, UilLink } from '@iconscout/react-unicons'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MultiDropdown } from "components/MultiDropdown/MultiDropdown";
import { compareArrayRegardlessOfOrder, getValueString } from "helpers";
import { CircularProgress, Collapse } from "@mui/material";
import { toast } from "react-hot-toast";
import { getEndpoint, postEndpoint, useGetEndpoint, usePostEndpoint, useQueryFunctions } from "ApiConnector";
import { DeleteModal } from "components/DeleteModal/DeleteModal";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { CardV2 } from "components/CardV2/CardV2";
import { capitalize } from "utils/text";
import { isAddress } from "ethers";
import { InputButton } from "components/InputButton/InputButton";
import { PLATFORMS } from "Constants";

export function Sources() {

    const [filter, setFilter] = useState('all')
    const [searchActive, setSearchActive] = useState(false)
    const [addSourceModal, setAddSourceModal] = useState(false)
    const [query, setQuery] = useState("")
    const { source, sourceType } = useParams();
    const location = useLocation();
    const isOverview = location.pathname.includes("/sources/overview")
    const isCreate = location.pathname.includes("/sources/create")
    const x = useGetEndpoint("source/list", undefined, isOverview || isCreate)
    const { data: sourceData, isStale } = x;

    if (isOverview) {
        return <OverviewSource sourceId={source} sourceType={sourceType} />
    }

    if (isCreate && sourceType === 'contract') return <SmartContractCreate />
    if (isCreate && sourceType === 'javascript') return <JavascriptCreate />

    return <>
        <Modal
            isOpen={addSourceModal}
            onClose={() => setAddSourceModal(false)}
            title={"Create new"}
            width={700}
        >
            <div className={styles.newPopoverWrap}>
                <div className={styles.popperItemWrap}>
                    <CreateItem
                        icon={<UilLink />}
                        href={'/sources/create/contract'}
                        title={"Smart Contract"}
                        description={"Ingest users who interact with a smart contract"}
                        onClose={() => setAddSourceModal(false)}
                    />
                    <CreateItem
                        icon={<UilJavaScript />}
                        href={'/sources/create/javascript'}
                        title={"JavaScript"}
                        description={"Ingest events and identifies from the Multibase JS SDK"}
                        onClose={() => setAddSourceModal(false)}
                    />
                </div>
            </div>
        </Modal>
        <Content>
            <div className={styles.pageTitle}>My sources</div>
            <div className={styles.topBar}>
                <div className={styles.topBarLeft}>
                    <div className={`${styles.filterButton} ${filter === 'all' ? styles.active : ''}`} onClick={() => setFilter('all')}>All</div>
                    <div className={`${styles.filterButton} ${filter === 'contract' ? styles.active : ''}`} onClick={() => setFilter('contract')}>Contracts</div>
                    <div className={`${styles.filterButton} ${filter === 'javascript' ? styles.active : ''}`} onClick={() => setFilter('javascript')}>Event streams</div>
                </div>
                <div className={styles.topBarRight}>
                    <div className={`${styles.searchBar} ${searchActive ? styles.active : ''}`}>
                        <input
                            type="text"
                            className={styles.searchInput}
                            placeholder="Search for source by name..."
                            onFocus={() => setSearchActive(true)}
                            onBlur={() => setSearchActive(false)}
                            onChange={(e) => setQuery(e.target.value)}
                            value={query}
                        />
                        <div className={styles.searchIcon}>
                            <SearchRounded />
                        </div>
                    </div>
                    <button className={styles.addSourceButton} onClick={() => setAddSourceModal(true)}>
                        <div className={styles.addSourceIcon}>
                            <AddOutlined />
                        </div>
                        <div>Add source</div>
                    </button>
                </div>
            </div>
            <TableWrap
                data={sourceData}
                filter={filter}
                query={query}
            />
        </Content>
    </>
}

// in-page

function SmartContractCreate() {

    const { register, handleSubmit, formState: { errors, isValid }, setError, clearErrors, setValue } = useForm({ mode: 'onChange', delayError: 2000 });
    const { data: labelData } = usePostEndpoint({ endpoint: "labels" })

    const [chainInput, setChainInput] = useState({ el: null, isActive: false });
    const [labelInput, setLabelInput] = useState({ el: null, isActive: false });
    const [activeInput, setActiveInput] = useState(null);
    const [draftChains, setDraftChains] = useState(undefined);
    const [draftLabels, setDraftLabels] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { invalidate } = useQueryFunctions()
    const labelRef = useRef(null);
    const chainRef = useRef(null);

    const isErrorStyle = (name) => {
        return errors[name] != null ? styles.error : ""
    }

    function onSubmit(data) {
        let { name, address } = data;
        const isAddressValid = isAddress(address)
        if (!isAddressValid) {
            setError("address", { type: "manual", message: "Invalid address" })
            return
        }

        if (draftChains == null || draftChains.length === 0) {
            setError("chains", { type: "manual", message: "Chains are required" })
            return
        }

        const promise = new Promise(async (resolve, reject) => {
            setLoading(true);
            const chainsReq = draftChains.map((x) => x.id)
            const { data: dataFromServer } = await postEndpoint("source/create/contract", {
                id: data.id,
                name,
                address,
                chains: chainsReq,
                labels: draftLabels
            });


            if (dataFromServer == null) {
                reject();
                return
            }

            if (draftLabels != null && draftLabels.length > 0) {
                invalidate("general/home")
                invalidate("filters/keys")
                invalidate("cohort/keys")
                invalidate("labels")
            }

            invalidate("source/list")
            const { id: idFromServer } = dataFromServer;
            navigate(`/sources/overview/contract/${idFromServer}`)
            setLoading(false);
            resolve();
        });

        toast.promise(promise, {
            loading: "Saving data source...",
            success: "Data source saved",
            error: "Error saving data source",
        });
    }

    return <>
        {/* <DeleteModal
            isOpen={deleteModal}
            onClose={() => setDeleteModal(false)}
            onDelete={deleteSource}
            subject="source"
        /> */}
        <MultiDropdown
            anchorEl={chainInput.el}
            value={draftChains}
            isOpen={chainInput.isActive}
            onClose={() => setChainInput({ el: null, isActive: false })}
            options={PLATFORMS}
            onChange={(val) => {
                if (errors.chains != null && val != null && val.length > 0) {
                    clearErrors("chains")
                }
                if (val == null) {
                    setDraftChains(undefined)
                    return
                }
                setDraftChains([...val])
            }}
        />
        <MultiDropdown
            anchorEl={labelInput.el}
            value={draftLabels}
            isLoadingAsyncOptions={labelData == null}
            isOpen={labelInput.isActive}
            onClose={() => setLabelInput({ el: null, isActive: false })}
            options={labelData}
            settings={{ freeform: true }}
            onChange={(val) => {
                if (val == null) {
                    setDraftLabels(undefined)
                    return
                }
                setDraftLabels([...val])
            }}
        />
        <Content maxWidth={800}>
            <div className={styles.inPageWrap}>
                <div className={styles.createPageTitle}>Connect a Smart Contract</div>
                <div className={styles.createPageDescription}>Import users who interact with a smart contract</div>
                <form className={styles.formWrap} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.inputTitle}>Enter a name for your source</div>
                    <input
                        autoComplete="off"
                        className={`${styles.createInput} ${isErrorStyle('name')} ${activeInput === 'name' ? styles.active : ''}`}
                        placeholder="Smart Contract"
                        onFocus={() => setActiveInput('name')}
                        {...register('name', {
                            required: {
                                value: true,
                                message: "Source name is required"
                            },
                            onBlur: () => {
                                setActiveInput(null)
                            }
                        })}
                    />
                    <FormError errors={errors} field={"name"} />
                    <div className={styles.inputTitle}>Enter the contract address of the smart contract</div>
                    <input
                        autoComplete="off"
                        className={`${styles.createInput} ${isErrorStyle('address')} ${activeInput === 'address' ? styles.active : ''}`}
                        placeholder="0x7a...88d"
                        onFocus={() => setActiveInput('address')}
                        {...register('address', {
                            required: {
                                value: true,
                                message: "Source contract is required"
                            },
                            onBlur: () => {
                                setActiveInput(null)
                            },
                            validate: (val) => {
                                return isAddress(val) || "Invalid address"
                            }
                        })}
                    />
                    <FormError errors={errors} field={"address"} />
                    <div className={styles.inputTitle}>Enter the chain(s) for the smart contract</div>
                    <button
                        className={`${styles.createInputButton} ${isErrorStyle('chains')} ${chainInput.isActive ? styles.active : ''}`}
                        type="button"
                        ref={chainRef}
                        onClick={(e) => {
                            setChainInput({ el: chainRef, isActive: true })
                        }}
                    >
                        {(draftChains == null || draftChains.length === 0) && <div className={styles.buttonTextEmpty}>Select chains</div>}
                        {draftChains != null && draftChains.length > 0 && <div className={styles.buttonLabelWrap}>
                            {getValueString(draftChains.map((x) => x.name), 40)}
                        </div>}
                    </button>
                    <FormError errors={errors} field={"chains"} />
                    <div className={styles.inputTitle}>Enter the label for the users</div>
                    <button
                        className={`${styles.createInputButton} ${isErrorStyle('label')} ${labelInput.isActive ? styles.active : ''}`}
                        type="button"
                        ref={labelRef}
                        onClick={(e) => {
                            setLabelInput({ el: labelRef, isActive: true })
                        }}
                    >
                        {(draftLabels == null || draftLabels.length === 0) && <div className={styles.buttonTextEmpty}>Select label</div>}
                        {draftLabels != null && draftLabels.length > 0 && <div className={styles.buttonLabelWrap}>
                            {getValueString(draftLabels.map((x) => x.name), 40)}
                        </div>}
                    </button>
                    <CustomLoadingButtonV2
                        className={styles.createButton}
                        type="submit"
                        disabled={!isValid}
                        loading={loading}
                    >
                        Create source
                    </CustomLoadingButtonV2>
                </form>
            </div>
        </Content>
    </>
}

function JavascriptCreate() {
    const { register, handleSubmit, formState: { errors, isValid }, setError, setValue } = useForm({ mode: 'onChange', delayError: 2000 });
    const { data: labelData } = usePostEndpoint({ endpoint: "labels" })

    const [labelInput, setLabelInput] = useState({ el: null, isActive: false });
    const [activeInput, setActiveInput] = useState(null);
    const [draftLabels, setDraftLabels] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { invalidate } = useQueryFunctions()
    const labelRef = useRef(null);

    const isErrorStyle = (name) => {
        return errors[name] != null ? styles.error : ""
    }

    function onSubmit(data) {
        let { name } = data

        const promise = new Promise(async (resolve, reject) => {
            setLoading(true);
            const { data: dataFromServer } = await postEndpoint("source/create/javascript", {
                name,
                labels: draftLabels
            });
            if (dataFromServer == null) {
                reject();
                return
            }

            if (draftLabels != null && draftLabels.length > 0) {
                invalidate("general/home")
                invalidate("filters/keys")
                invalidate("cohort/keys")
                invalidate("labels")
            }

            invalidate("source/list")
            const { id: idFromServer } = dataFromServer;
            navigate(`/sources/overview/javascript/${idFromServer}`)
            setLoading(false);
            resolve();
        });

        toast.promise(promise, {
            loading: "Saving data source...",
            success: "Data source saved",
            error: "Error saving data source",
        });
    }


    return <>
        {/* <DeleteModal
            isOpen={deleteModal}
            onClose={() => setDeleteModal(false)}
            onDelete={deleteSource}
            subject="source"
        /> */}
        <MultiDropdown
            anchorEl={labelInput.el}
            value={draftLabels}
            isLoadingAsyncOptions={labelData == null}
            isOpen={labelInput.isActive}
            onClose={() => setLabelInput({ el: null, isActive: false })}
            options={labelData}
            settings={{ freeform: true }}
            onChange={(val) => {
                if (val == null) {
                    setDraftLabels(undefined)
                    return
                }
                setDraftLabels([...val])
            }}
        />
        <Content maxWidth={800}>
            <div className={styles.inPageWrap}>
                <div className={styles.createPageTitle}>Connect with JavaScript</div>
                <div className={styles.createPageDescription}>Import users and events directly from your app</div>
                <form className={styles.formWrap} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.inputTitle}>Enter a name for your source</div>
                    <input
                        autoComplete="off"
                        className={`${styles.createInput} ${isErrorStyle('name')} ${activeInput === 'name' ? styles.active : ''}`}
                        placeholder="JavaScript"
                        onFocus={() => setActiveInput('name')}
                        {...register('name', {
                            required: {
                                value: true,
                                message: "Source name is required"
                            },
                            onBlur: () => {
                                setActiveInput(null)
                            }
                        })}
                    />
                    <FormError errors={errors} field={"name"} />
                    <div className={styles.inputTitle}>Enter the label for the users</div>
                    <button
                        className={`${styles.createInputButton} ${isErrorStyle('label')} ${labelInput.isActive ? styles.active : ''}`}
                        type="button"
                        ref={labelRef}
                        onClick={(e) => {
                            setLabelInput({ el: labelRef, isActive: true })
                        }}
                    >
                        {(draftLabels == null || draftLabels.length === 0) && <div className={styles.buttonTextEmpty}>Select label</div>}
                        {draftLabels != null && draftLabels.length > 0 && <div className={styles.buttonLabelWrap}>
                            {getValueString(draftLabels.map((x) => x.name), 40)}
                        </div>}
                    </button>
                    <CustomLoadingButtonV2
                        className={styles.createButton}
                        type="submit"
                        disabled={!isValid}
                        loading={loading}
                    >
                        Create source
                    </CustomLoadingButtonV2>
                </form>
            </div>
        </Content>
    </>
}

function Step({ index, title, children, className }) {
    return <div className={`${styles.stepWrap} ${className}`}>
        <div className={styles.stepIndex}>{index}</div>
        <div className={styles.stepContent}>
            <div className={styles.stepTitle}>{title}</div>
            <div className={styles.stepContent}>
                {children}
            </div>
        </div>
    </div>
}

function CreateItem({ icon, title, description, href, onClick, onClose, isNew }) {
    return <Link className={styles.createItemWrap} to={href} onClick={(e) => {
        onClose()
    }}>
        <div className={styles.createItemIcon}>
            {icon}
        </div>
        <div className={styles.createItemTextWrap}>
            <div className={styles.createItemTitle}>
                {title}
                {isNew && <div className={styles.itemTagNew}>
                    New
                </div>}
            </div>
            <div className={styles.createItemDescription}>
                {description}
            </div>
        </div>
    </Link>
}


function FormError({ errors, field }) {
    return <Collapse in={Boolean(errors[field])} timeout={300}><div className={styles.errorTxt}>{errors[field]?.message}</div></Collapse>
}

function CustomLoadingButtonV2({ loading, className, onClick, light, children }) {
    if (loading) {
        return <div className={`${className} ${styles.loading}`}>
            <CircularProgress size={16} sx={{ color: "white" }} />
            <div>{children}</div>
        </div>
    }

    return <button className={className} onClick={onClick}>
        {children}
    </button>
}

const defaultRender = ({ value }) => value
const columns = [
    // { key: "created", title: "Date Created", renderCell: ({ value: ts }) => moment(ts).format("YYYY-MM-DD HH:mm:ss"), maxWidth: 200 },
    { key: "name", title: "Name", flex: 1 },
    {
        key: "status",
        title: "Status",
        renderCell: ({ value: status }) => {
            if (status === "active") {
                return <div className={styles.statusTag}>
                    <div className={`${styles.statusDot} ${styles.green}`} />
                    <div className={styles.statusText}>Active</div>
                </div>
            }

            return <div className={styles.statusTag}>
                <div className={styles.statusDot} />
                <div className={styles.statusText}>{capitalize(status)}</div>
            </div>
        },
        minWidth: 200
    },
    {
        key: "type",
        title: "Source Type",
        renderCell: ({ value: rowType }) => {
            if (rowType == null) return <div>–</div>
            if (rowType === "contract") return <div>Contract</div>
            if (rowType === "javascript") return <div>JavaScript</div>
        },
        minWidth: 200
    }
]

function TableHeaders() {
    return <div className={styles.tableHeaders}>
        {columns.map((col) => {
            const { title, minWidth, flex } = col
            return <div className={styles.tableHeader} style={{ minWidth, flex }}>
                {title}
            </div>
        })}
    </div>
}

function filterData(data, filter, query) {
    if (filter === "all") {
        return data.filter((x) => x.name.toLowerCase().includes(query.toLowerCase()))
    }
    return data.filter((x) => x.type === filter && x.name.toLowerCase().includes(query.toLowerCase()))
}

function TableWrap({ data, filter, query }) {
    if (data == null) return <div className={styles.tableWrap}>
        <TableHeaders />
        <div className={styles.tableLoadingWrap}>
            <CircularProgress sx={{ color: "white" }} />
        </div>
    </div>

    const dataFiltered = filterData(data, filter, query)

    if (dataFiltered?.length === 0) return <div className={styles.tableWrap}>
        <TableHeaders />
        <div className={styles.noSourcesWrap}>
            <div className={styles.noSourcesTitle}>No sources</div>
            <div className={styles.noSourcesDescription}>Adjust your filters or create a new source</div>
        </div>
    </div>

    return <div className={styles.tableWrap}>
        <TableHeaders />
        {dataFiltered.map((x, idx) => {
            const { id, type } = x
            return <Link className={styles.tableRow} to={`/sources/overview/${type}/${id}`}>
                {columns.map((col) => {
                    const { key, renderCell = defaultRender, minWidth, flex } = col
                    const value = x[key]
                    return <div className={styles.tableCell} style={{ minWidth, flex }}>
                        {renderCell({ value, data: x })}
                    </div>
                })}
            </Link>
        })}
    </div>
}

function OverviewSource({ sourceType, sourceId }) {

    const [data, setData] = useState(undefined)

    useEffect(() => {
        if (sourceId == null) return
        const getData = async () => {
            const response = await getEndpoint("source", { id: sourceId })
            const { data } = response
            setData(data)
        }
        getData()
    }, [sourceId])

    if (data === undefined) return <Content topBar={false}>
        <div className={styles.pageLoadingWrap}>
            <CircularProgress sx={{ color: "white" }} />
        </div>
    </Content>

    if (data === null) return <Content topBar={false}>
        <div className={styles.pageLoadingWrap}>
            <CardV2 className={styles.errorCard}>
                <div className={styles.errorTitle}>Source not found</div>
                <div className={styles.errorSubtitle}>We weren't able to find the source you're looking for. Check the URL or try again. Contact <a href="mailto:support@multibase.co" className={styles.errorLink}>support@multibase.co for support</a>.</div>
                <Link to="/sources" className={styles.errorButton}>
                    <div className={styles.errorButtonIcon}>
                        <ArrowBackIosNewRounded />
                    </div>
                    <div>
                        Go back to sources
                    </div>
                </Link>
            </CardV2>
        </div>
    </Content>

    if (sourceType === "javascript") return <JavascriptOverview data={data} setData={setData} />
    if (sourceType === "contract") return <SmartContractOverview data={data} setData={setData} />
}

function SmartContractOverview({ data, setData }) {
    const { data: labelData } = usePostEndpoint({ endpoint: "labels" })
    const { register, handleSubmit, formState: { errors, isValid }, setError, clearErrors, setValue } = useForm({ mode: 'onChange', delayError: 2000, defaultValues: { ...data } });
    const [chainInput, setChainInput] = useState({ el: null, isActive: false });
    const [labelInput, setLabelInput] = useState({ el: null, isActive: false });
    const [activeInput, setActiveInput] = useState(null);
    const [draftChains, setDraftChains] = useState(undefined);
    const [originalDraftLabels, setOriginalDraftLabels] = useState(undefined);
    const [draftLabels, setDraftLabels] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const navigate = useNavigate();
    const { invalidate } = useQueryFunctions()
    const chainRef = useRef(null);
    const labelRef = useRef(null);

    useEffect(() => {
        if (data.id == null || data.chains == null) return

        const chains = data.chains.map((x) => {
            const obj = PLATFORMS.find((y) => y.id === x)
            if (obj == null) return null
            return obj
        })

        setDraftChains(chains)
    }, [data])

    useEffect(() => {
        if (data.id == null || data.labels == null) return
        if (labelData == null) return
        if (draftLabels != null) return
        // Note: this isn't a great way to do this, but it works for now
        const labels = data.labels.map((x) => {
            const f = labelData.find((y) => y.id === x);
            if (f == null) return null
            return f
        }).filter((x) => x != null)

        setOriginalDraftLabels(labels)
        setDraftLabels(labels)
    }, [data, labelData])

    const isErrorStyle = (name) => {
        return errors[name] != null ? styles.error : ""
    }

    function deleteSource() {
        if (data.id == null) return
        const promise = new Promise(async (resolve, reject) => {
            setLoading(true);
            await postEndpoint("source/delete", {
                id: data.id,
            });

            invalidate("source/list")
            navigate("/sources")
            setLoading(false);
            resolve();
        })

        toast.promise(promise, {
            loading: "Deleting source...",
            success: "Source deleted",
            error: "Error deleting source",
        });
    }

    function onSubmit(data) {
        let { name, address } = data;

        const isAddressValid = isAddress(address)
        if (!isAddressValid) {
            setError("address", { type: "manual", message: "Invalid address" })
            return
        }

        if (draftChains == null || draftChains.length === 0) {
            setError("chains", { type: "manual", message: "At least one chain is required" })
            return
        }

        const promise = new Promise(async (resolve, reject) => {
            setLoading(true);

            const chainsReq = draftChains.map((x) => x.id)
            const { data: dataFromServer } = await postEndpoint("source/update/contract", {
                id: data.id,
                name,
                address,
                chains: chainsReq,
                labels: draftLabels
            });

            if (dataFromServer == null) {
                reject();
                setLoading(false);
                return
            }

            if (!compareArrayRegardlessOfOrder(originalDraftLabels, draftLabels)) {
                invalidate("general/home")
                invalidate("filters/keys")
                invalidate("cohort/keys")
                invalidate("labels")
            }

            invalidate("source/list")
            setData({
                ...data,
                id: data.id,
                name,
                chains: chainsReq,
                labels: draftLabels?.map((x) => x.id)
            })
            setLoading(false);
            resolve();
        });

        toast.promise(promise, {
            loading: "Saving data source...",
            success: "Data source saved",
            error: "Error saving data source",
        });
    }

    const { name, id, type } = data

    return <>
        <DeleteModal
            isOpen={deleteModal}
            onClose={() => setDeleteModal(false)}
            onDelete={deleteSource}
            subject="source"
        />
        <MultiDropdown
            anchorEl={chainInput.el}
            value={draftChains}
            isOpen={chainInput.isActive}
            onClose={() => setChainInput({ el: null, isActive: false })}
            options={PLATFORMS}
            onChange={(val) => {
                if (errors.chains != null && val != null && val.length > 0) {
                    clearErrors("chains")
                }
                if (val == null) {
                    setDraftChains(undefined)
                    return
                }
                setDraftChains([...val])
            }}
        />
        <MultiDropdown
            anchorEl={labelInput.el}
            value={draftLabels}
            isLoadingAsyncOptions={labelData == null}
            isOpen={labelInput.isActive}
            onClose={() => setLabelInput({ el: null, isActive: false })}
            options={labelData}
            settings={{ freeform: true }}
            onChange={(val) => {
                if (val == null) {
                    setDraftLabels(undefined)
                    return
                }
                setDraftLabels([...val])
            }}
        />
        <Content topBar={false} noPadding>
            <div className={styles.topBarOverview} style={{ padding: "16px 24px" }}>
                <div className={styles.topBarTopSection}>
                    <div className={styles.topBarLeft}>
                        <Link className={styles.breadcrumbBold} to={'/sources'}>Sources</Link>
                        <div className={styles.topBarDivider}>/</div>
                        <div className={styles.topBarTitle}>{name}</div>
                    </div>
                </div>
            </div>
            <div className={styles.overviewPageWrap}>
                <form className={`${styles.formWrap} ${styles.settingsForm}`} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.inputTitle} style={{ marginTop: 0 }}>Name</div>
                    <div className={styles.inputDescription}>Enter a name to identify this source on Multibase</div>
                    <input
                        autoComplete="off"
                        className={`${styles.createInput} ${isErrorStyle('name')} ${activeInput === 'name' ? styles.active : ''}`}
                        placeholder="Smart Contract"
                        onFocus={() => setActiveInput('name')}
                        {...register('name', {
                            required: {
                                value: true,
                                message: "Source name is required"
                            },
                            onBlur: () => {
                                setActiveInput(null)
                            }
                        })}
                    />
                    <FormError errors={errors} field={"name"} />
                    <div className={styles.inputTitle}>Enter the contract address of the smart contract</div>
                    <input
                        autoComplete="off"
                        className={`${styles.createInput} ${isErrorStyle('address')} ${activeInput === 'address' ? styles.active : ''}`}
                        placeholder="0x7a...88d"
                        onFocus={() => setActiveInput('address')}
                        {...register('address', {
                            required: {
                                value: true,
                                message: "Source contract is required"
                            },
                            onBlur: () => {
                                setActiveInput(null)
                            },
                            validate: (val) => {
                                return isAddress(val) || "Invalid address"
                            }
                        })}
                    />
                    <FormError errors={errors} field={"address"} />
                    <div className={styles.inputTitle}>Chains for smart contract</div>
                    <button
                        className={`${styles.createInputButton} ${isErrorStyle('chains')} ${chainInput.isActive ? styles.active : ''}`}
                        type="button"
                        ref={chainRef}
                        onClick={(e) => {
                            setChainInput({ el: chainRef, isActive: true })
                        }}
                    >
                        {(draftChains == null || draftChains.length === 0) && <div className={styles.buttonTextEmpty}>Select chains</div>}
                        {draftChains != null && draftChains.length > 0 && <div className={styles.buttonLabelWrap}>
                            {getValueString(draftChains.map((x) => x.name), 40)}
                        </div>}
                    </button>
                    <FormError errors={errors} field={"chains"} />
                    <div className={styles.inputTitle}>Enter the label for the users</div>
                    <button
                        className={`${styles.createInputButton} ${isErrorStyle('label')} ${labelInput.isActive ? styles.active : ''}`}
                        type="button"
                        ref={labelRef}
                        onClick={(e) => {
                            setLabelInput({ el: labelRef, isActive: true })
                        }}
                    >
                        {(draftLabels == null || draftLabels.length === 0) && <div className={styles.buttonTextEmpty}>Select label</div>}
                        {draftLabels != null && draftLabels.length > 0 && <div className={styles.buttonLabelWrap}>
                            {getValueString(draftLabels.map((x) => x.name), 40)}
                        </div>}
                    </button>
                    <div style={{ display: "flex", gap: 8, marginTop: 16 }}>
                        <CustomLoadingButtonV2
                            className={styles.saveButton}
                            type="submit"
                            disabled={!isValid}
                            loading={loading}
                        >
                            Save changes
                        </CustomLoadingButtonV2>
                        <button type="button" className={styles.deleteButton} onClick={() => setDeleteModal(true)}>
                            <div className={styles.deleteIcon}>
                                <DeleteOutlineRounded />
                            </div>
                            <div>Delete source</div>
                        </button>
                    </div>
                </form>
            </div>
        </Content>
    </>
}

function JavascriptOverview({ data, setData }) {

    const location = useLocation()

    const pathArr = location.pathname.split("/").filter((x) => x !== "")
    const isBasicOverview = pathArr.length === 4
    const isSettings = pathArr.length === 6 && pathArr[4] === "settings"

    const { name, id, type, labels, key } = data

    const top = <div className={styles.topBarOverview}>
        <div className={styles.topBarTopSection}>
            <div className={styles.topBarLeft}>
                <Link className={styles.breadcrumbBold} to={'/sources'}>Sources</Link>
                <div className={styles.topBarDivider}>/</div>
                <div className={styles.topBarTitle}>{name}</div>
            </div>
        </div>
        <div className={styles.topBarBottomSection}>
            <Link className={`${styles.topBarOption} ${isBasicOverview ? styles.active : ''}`} to={`/sources/overview/${type}/${id}`}>
                Overview
                {isBasicOverview && <div className={styles.highlightBar} />}
            </Link>
            <Link className={`${styles.topBarOption} ${isSettings ? styles.active : ''}`} to={`/sources/overview/${type}/${id}/settings/info`}>
                Settings
                {isSettings && <div className={styles.highlightBar} />}
            </Link>
        </div>
    </div>

    if (isSettings) return <Content topBar={false} noPadding>
        {top}
        <JavaScriptOverviewSettings
            data={data}
            setData={setData}
        />
    </Content>

    if (isBasicOverview) return <Content topBar={false} noPadding>
        {top}
        <div className={styles.overviewPageWrap}>
            <div className={styles.overviewTop}>
                <div className={styles.overviewIcon}><UilJavaScript /></div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className={styles.overviewTitle}>{name}</div>
                    <div className={styles.overviewDescription}>JavaScript source</div>
                </div>
            </div>
            <div className={styles.overviewSection}>
                {/* test */}
                <Step
                    index={1}
                    title={"Install the Multibase JS SDK"}
                    className={styles.overviewStep}
                >
                    <CodeBlock
                        language="bash"
                        code={`# npm \nnpm install @multibase/js\n\n# yarn\nyarn add @multibase/js`}
                        copyable={false}
                    />
                </Step>
                <Step
                    index={2}
                    title={"Initialize the SDK"}
                    className={styles.overviewStep}
                >
                    <CodeBlock
                        language="javascript"
                        code={`import { init } from '@multibase/js'\ninit('${key}')`}
                        copyable={true}
                    />
                </Step>
                <Step
                    index={3}
                    title={"Identify users"}
                    className={styles.overviewStep}
                >
                    <CodeBlock
                        language="javascript"
                        code={`import { identify } from "@multibase/js"\n\n// Basic identifying call\nidentify({\n     address: "0xd8da6bf26964af9d7eed9e03e53415d37aa96045"\n})`}
                        copyable={false}
                    />
                </Step>
                <Step
                    index={4}
                    title={"Track events"}
                    className={styles.overviewStep}
                >
                    <CodeBlock
                        language="javascript"
                        code={`import { track } from "@multibase/js"\n\n// Basic tracking call\ntrack("Link Click")`}
                        copyable={false}
                    />
                </Step>
            </div>
        </div>
    </Content>

}

function JavaScriptOverviewSettings({ data, setData }) {

    const location = useLocation()

    const pathArr = location.pathname.split("/").filter((x) => x !== "")
    let settingsPage = pathArr[5]

    const navigate = useNavigate()
    useEffect(() => {
        if (settingsPage == null) {
            navigate(`/sources/overview/${data.type}/${data.id}/settings/info`)
            return
        }
        if (settingsPage === "info" || settingsPage === "keys") return
        navigate(`/sources/overview/${data.type}/${data.id}/settings/info`)
    }, [settingsPage])

    let el = null

    if (settingsPage === "info") el = <JavaScriptInfo data={data} setData={setData} />
    if (settingsPage === "keys") el = <JavaScriptKeys data={data} />

    return <>
        <div className={styles.settingsWrap}>
            <div className={styles.settingsSidebar}>
                <Link className={`${styles.settingsSidebarItem} ${settingsPage === "info" ? styles.active : ''}`} to={`/sources/overview/${data.type}/${data.id}/settings/info`}>
                    {/* <div className={styles.settingsSidebarIcon}><UilSlidersV /></div> */}
                    <div className={styles.settingsSidebarText}>Info</div>
                </Link>
                <Link className={`${styles.settingsSidebarItem} ${settingsPage === "keys" ? styles.active : ''}`} to={`/sources/overview/${data.type}/${data.id}/settings/keys`}>
                    {/* <div className={styles.settingsSidebarIcon}><UilTagsAlt /></div> */}
                    <div className={styles.settingsSidebarText}>Keys</div>
                </Link>
            </div>
            {el}
        </div>

    </>

}

function JavaScriptInfo({ data, setData }) {
    const { data: labelData } = usePostEndpoint({ endpoint: "labels" })
    const { register, handleSubmit, formState: { errors, isValid }, setError, setValue } = useForm({ mode: 'onChange', delayError: 2000, defaultValues: { ...data } });
    const [labelInput, setLabelInput] = useState({ el: null, isActive: false });
    const [activeInput, setActiveInput] = useState(null);
    const [originalDraftLabels, setOriginalDraftLabels] = useState(undefined);
    const [draftLabels, setDraftLabels] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const navigate = useNavigate();
    const { invalidate } = useQueryFunctions()
    const labelRef = useRef(null);

    useEffect(() => {
        if (data.id == null || data.labels == null) return
        if (labelData == null) return
        if (draftLabels != null) return
        // Note: this isn't a great way to do this, but it works for now
        const labels = data.labels.map((x) => {
            const f = labelData.find((y) => y.id === x);
            if (f == null) return null
            return f
        }).filter((x) => x != null)

        setOriginalDraftLabels(labels)
        setDraftLabels(labels)
    }, [data, labelData])

    const isErrorStyle = (name) => {
        return errors[name] != null ? styles.error : ""
    }

    function deleteSource() {
        if (data.id == null) return
        const promise = new Promise(async (resolve, reject) => {
            setLoading(true);
            await postEndpoint("source/delete", {
                id: data.id,
            });

            invalidate("source/list")
            navigate("/sources")
            setLoading(false);
            resolve();
        })

        toast.promise(promise, {
            loading: "Deleting source...",
            success: "Source deleted",
            error: "Error deleting source",
        });
    }

    function onSubmit(data) {
        let { name } = data

        const promise = new Promise(async (resolve, reject) => {
            setLoading(true);
            const { data: dataFromServer } = await postEndpoint("source/update/javascript", {
                id: data.id,
                name,
                labels: draftLabels
            });
            if (dataFromServer == null) {
                reject();
                setLoading(false);
                return
            }

            if (!compareArrayRegardlessOfOrder(originalDraftLabels, draftLabels)) {
                invalidate("general/home")
                invalidate("filters/keys")
                invalidate("cohort/keys")
                invalidate("labels")
            }

            invalidate("source/list")
            setData({
                ...data,
                id: data.id,
                name,
                labels: draftLabels?.map((x) => x.id)
            })
            setLoading(false);
            resolve();
        });

        toast.promise(promise, {
            loading: "Saving data source...",
            success: "Data source saved",
            error: "Error saving data source",
        });
    }


    return <>
        <DeleteModal
            isOpen={deleteModal}
            onClose={() => setDeleteModal(false)}
            onDelete={deleteSource}
            subject="source"
        />
        <MultiDropdown
            anchorEl={labelInput.el}
            value={draftLabels}
            isLoadingAsyncOptions={labelData == null}
            isOpen={labelInput.isActive}
            onClose={() => setLabelInput({ el: null, isActive: false })}
            options={labelData}
            settings={{ freeform: true }}
            onChange={(val) => {
                if (val == null) {
                    setDraftLabels(undefined)
                    return
                }
                setDraftLabels([...val])
            }}
        />

        <form className={`${styles.formWrap} ${styles.settingsForm}`} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputTitle}>Name</div>
            <div className={styles.inputDescription}>Enter a name to identify this source on Multibase</div>
            <input
                autoComplete="off"
                className={`${styles.createInput} ${isErrorStyle('name')} ${activeInput === 'name' ? styles.active : ''}`}
                placeholder="JavaScript"
                onFocus={() => setActiveInput('name')}
                {...register('name', {
                    required: {
                        value: true,
                        message: "Source name is required"
                    },
                    onBlur: () => {
                        setActiveInput(null)
                    }
                })}
            />
            <FormError errors={errors} field={"name"} />
            <div className={styles.inputTitle}>Labels</div>
            <div className={styles.inputDescription}>Labels are used to organize your sources. You can add multiple labels to a source.</div>
            <button
                className={`${styles.createInputButton} ${isErrorStyle('label')} ${labelInput.isActive ? styles.active : ''}`}
                type="button"
                ref={labelRef}
                onClick={(e) => {
                    setLabelInput({ el: labelRef, isActive: true })
                }}
            >
                {(draftLabels == null || draftLabels.length === 0) && <div className={styles.buttonTextEmpty}>Select label</div>}
                {draftLabels != null && draftLabels.length > 0 && <div className={styles.buttonLabelWrap}>
                    {getValueString(draftLabels.map((x) => x.name), 40)}
                </div>}
            </button>
            <div style={{ display: "flex", gap: 8, marginTop: 16 }}>
                <CustomLoadingButtonV2
                    className={styles.saveButton}
                    type="submit"
                    disabled={!isValid}
                    loading={loading}
                >
                    Save changes
                </CustomLoadingButtonV2>
                <button type="button" className={styles.deleteButton} onClick={() => setDeleteModal(true)}>
                    <div className={styles.deleteIcon}>
                        <DeleteOutlineRounded />
                    </div>
                    <div>Delete source</div>
                </button>
            </div>
        </form>
    </>
}

function JavaScriptKeys({ data }) {
    return <div className={`${styles.formWrap} ${styles.settingsForm}`}>
        <div className={styles.inputTitle}>Source Write Key</div>
        <div className={styles.inputDescription}>This key is used to identify users and track events. It is not recommended to use this key in client-side code.</div>
        <div style={{ display: "flex", alignItems: "center", gap: 8, marginTop: 16 }}>
            <input
                className={styles.createInput}
                value={data.key}
                style={{ marginTop: 0 }}
                readOnly
                onClick={(e) => {
                    // highlight all
                    e.target.select()
                }}
            />
            <button
                className={styles.outline}
            >
                <div
                    className={styles.copyIcon}
                ><ContentCopyRounded /></div>
                <div>Copy</div>
            </button>
        </div>
    </div>
}


function CodeBlock({ language, code, copyable }) {

    function onCopy() {
        navigator.clipboard.writeText(code)
    }

    return <div className={styles.codeBlockWrap}>
        {/* <button className={styles.copyButton} onClick={onCopy}>
            <ContentCopyRounded />
        </button> */}
        <SyntaxHighlighter language={language} style={codeStyle}>
            {code}
        </SyntaxHighlighter>
        {copyable && <button
            className={styles.copyButtonVerbose}
            onClick={onCopy}
        >
            <div className={styles.copyIcon}>
                <ContentCopyRounded />
            </div>
            <div className={styles.copyText}>Copy snippet</div>
        </button>}
    </div>

}

const codeStyle = {
    "hljs": {
        "display": "block",
        "background": "rgb(255 255 255 / 0.05)",
        "color": "white",
        "overflowX": "auto",
        "padding": "1em",
        "borderRadius": "4px",
        "lineHeight": "1.5em",
        "fontFamily": "var(--code-font)",
    },
    "hljs-doctag": {
        "color": "#ff7b72"
    },
    "hljs-keyword": {
        "color": "#ff7b72"
    },
    "hljs-meta": {
        "color": "#ff7b72"
    },
    "hljs-template-tag": {
        "color": "#ff7b72"
    },
    "hljs-template-variable": {
        "color": "#ff7b72"
    },
    "hljs-type": {
        "color": "#ff7b72"
    },
    "hljs-title": {
        "color": "#d2a8ff"
    },
    "hljs-attr": {
        "color": "#79c0ff"
    },
    "hljs-attribute": {
        "color": "#79c0ff"
    },
    "hljs-literal": {
        "color": "#79c0ff"
    },
    "hljs-meta": {
        "color": "#79c0ff"
    },
    "hljs-number": {
        "color": "#79c0ff"
    },
    "hljs-operator": {
        "color": "#79c0ff"
    },
    "hljs-variable": {
        "color": "#79c0ff"
    },
    "hljs-selector-attr": {
        "color": "#79c0ff"
    },
    "hljs-selector-class": {
        "color": "#79c0ff"
    },
    "hljs-selector-id": {
        "color": "#79c0ff"
    },
    "hljs-comment": {
        "color": "#8b949e"
    },
    "hljs-string": {
        "color": "#a5d6ff"
    },
    "hljs-function": {
        "color": "#ffa657"
    },
}

/*!
  Theme: GitHub Dark
  Description: Dark theme as seen on github.com
  Author: github.com
  Maintainer: @Hirse
  Updated: 2021-05-15

  Outdated base version: https://github.com/primer/github-syntax-dark
  Current colors taken from GitHub's CSS
*/

// .hljs {
//     color: #c9d1d9;
//     background: #0d1117;
// }

// .hljs-doctag,
// .hljs-keyword,
// .hljs-meta .hljs-keyword,
// .hljs-template-tag,
// .hljs-template-variable,
// .hljs-type,
// .hljs-variable.language_ {
//     /* prettylights-syntax-keyword */
//     color: #ff7b72;
// }

// .hljs-title,
// .hljs-title.class_,
// .hljs-title.class_.inherited__,
// .hljs-title.function_ {
//     /* prettylights-syntax-entity */
//     color: #d2a8ff;
// }

// .hljs-attr,
// .hljs-attribute,
// .hljs-literal,
// .hljs-meta,
// .hljs-number,
// .hljs-operator,
// .hljs-variable,
// .hljs-selector-attr,
// .hljs-selector-class,
// .hljs-selector-id {
//     /* prettylights-syntax-constant */
//     color: #79c0ff;
// }

// .hljs-regexp,
// .hljs-string,
// .hljs-meta .hljs-string {
//     /* prettylights-syntax-string */
//     color: #a5d6ff;
// }

// .hljs-built_in,
// .hljs-symbol {
//     /* prettylights-syntax-variable */
//     color: #ffa657;
// }

// .hljs-comment,
// .hljs-code,
// .hljs-formula {
//     /* prettylights-syntax-comment */
//     color: #8b949e;
// }

// .hljs-name,
// .hljs-quote,
// .hljs-selector-tag,
// .hljs-selector-pseudo {
//     /* prettylights-syntax-entity-tag */
//     color: #7ee787;
// }

// .hljs-subst {
//     /* prettylights-syntax-storage-modifier-import */
//     color: #c9d1d9;
// }

// .hljs-section {
//     /* prettylights-syntax-markup-heading */
//     color: #1f6feb;
//     font-weight: bold;
// }

// .hljs-bullet {
//     /* prettylights-syntax-markup-list */
//     color: #f2cc60;
// }

// .hljs-emphasis {
//     /* prettylights-syntax-markup-italic */
//     color: #c9d1d9;
//     font-style: italic;
// }

// .hljs-strong {
//     /* prettylights-syntax-markup-bold */
//     color: #c9d1d9;
//     font-weight: bold;
// }

// .hljs-addition {
//     /* prettylights-syntax-markup-inserted */
//     color: #aff5b4;
//     background-color: #033a16;
// }

// .hljs-deletion {
//     /* prettylights-syntax-markup-deleted */
//     color: #ffdcd7;
//     background-color: #67060c;
// }

// .hljs-char.escape_,
// .hljs-link,
// .hljs-params,
// .hljs-property,
// .hljs-punctuation,
// .hljs-tag {
//     /* purposely ignored */
// }