import { Link } from "react-router-dom";
import styles from "./NavLimited.module.css"
import DarkModeIcon from '@mui/icons-material/DarkMode';
import flw from "assets/branding/flw.png";
import flb from "assets/branding/flb.png";
import { useGlobal } from "hooks/global";

export default function NavLimited({ tight }) {

    const { user, theme, toggleTheme } = useGlobal()


    const { stripeRole } = user || {}
    const showLogin = user == null
    const showPremium = user != null && stripeRole !== "premium"

    return <>
        <div className={`${styles.navbar} ${theme === "light" && styles.light}`}>
            <div className={`${styles.navbarInner} ${tight ? styles.tight : ""}`}>
                <div className={styles.left}>
                    <div className={styles.logoWrap}>
                        <Link to="/login" className={`${styles.ghostLinkLogo}`}>
                            <img alt="Alphablue Logo" src={flw} className={styles.logo} />
                        </Link>
                    </div>
                </div>
                <div className={styles.right}>
                </div>
            </div>
        </div>
    </>
}