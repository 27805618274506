import { getColor, smartTrim } from "helpers"
import moment from "moment";
// react component svgs
// import {ReactComponent as ReactLogo} from './logo.svg';
import { ReactComponent as ArbLogo } from 'assets/chains/objects/arb.svg';
import { ReactComponent as ArbNovaLogo } from 'assets/chains/objects/arb-nova.svg';
import { ReactComponent as AvaxLogo } from 'assets/chains/objects/avax.svg';
import { ReactComponent as BaseLogo } from 'assets/chains/objects/base.svg';
import { ReactComponent as BscLogo } from 'assets/chains/objects/bsc.svg';
import { ReactComponent as EthLogo } from 'assets/chains/objects/eth.svg';
import { ReactComponent as FtmLogo } from 'assets/chains/objects/fantom.svg';
import { ReactComponent as GnoLogo } from 'assets/chains/objects/gnosis.svg';
import { ReactComponent as MaticLogo } from 'assets/chains/objects/matic.svg';
import { ReactComponent as PolygonZkEVMLogo } from 'assets/chains/objects/polygon-zkevm.svg';
import { ReactComponent as MoonbeamLogo } from 'assets/chains/objects/moonbeam.svg';
import { ReactComponent as OpLogo } from 'assets/chains/objects/op.svg';
import { ReactComponent as ZkSyncLogo } from 'assets/chains/objects/zksync.svg';
import { ReactComponent as LineaLogo } from 'assets/chains/objects/linea.svg';
import { AutoAwesome, ElectricBoltRounded, FunctionsRounded, MonetizationOn, PeopleAltRounded, Person2Outlined, TextFieldsRounded, ToggleOnRounded } from "@mui/icons-material";

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

export const SINGLE_STRUCTURE = "SINGLE_STRUCTURE"
export const LABEL_STRUCTURE = "LABEL_STRUCTURE"
export const EVENT_STRUCTURE = "EVENT_STRUCTURE"
export const SIMPLE_STRUCTURE = "SIMPLE_STRUCTURE"
export const PROPERTY_STRUCTURE = "PROPERTY_STRUCTURE"

const chains = importAll(require.context('assets/chains', false, /\.(png|jpe?g|svg)$/))

export const SAMPLE_PROJECTS = [
    {
        id: "uniswap",
        name: "Uniswap",
    },
    {
        id: "azuki",
        name: "Azuki"
    },
    {
        id: "opensea",
        name: "OpenSea",
    },
    {
        id: "polygon",
        name: "Polygon"
    }
]

export let DIVERGENT_COLORS = []
document.addEventListener("DOMContentLoaded", function () {
    DIVERGENT_COLORS = [getColor("graph-color-library-divergent-one"), getColor("graph-color-library-divergent-two"), getColor("graph-color-library-divergent-three"), getColor("graph-color-library-divergent-four"), getColor("graph-color-library-divergent-five"), getColor("graph-color-library-divergent-six"), getColor("graph-color-library-divergent-seven"), getColor("graph-color-library-divergent-eight"), getColor("graph-color-library-divergent-nine")]
});
// export const DIVERGENT_COLORS = [getColor("graph-color-library-divergent-one"), getColor("graph-color-library-divergent-two"), getColor("graph-color-library-divergent-three"), getColor("graph-color-library-divergent-four"), getColor("graph-color-library-divergent-five"), getColor("graph-color-library-divergent-six"), getColor("graph-color-library-divergent-seven"), getColor("graph-color-library-divergent-eight"), getColor("graph-color-library-divergent-nine")]

export const BLANK_FIELD = { id: 0, field: "" }
export const ALL_CODE = "all"

export const PLATFORMS = [
    { id: "arb", name: "Arbitrum", token: "ETH", family: "eth", imgURL: chains["arbitrum.png"], svg: <ArbLogo />, mainnet: true },
    { id: "arb-nova", name: "Arbitrum Nova", token: "ETH", family: "eth", imgURL: chains["arbitrum-nova.png"], svg: <ArbNovaLogo />, mainnet: true },
    { id: "avax", name: "Avalanche", token: "AVAX", family: "eth", imgURL: chains["avalanche.png"], svg: <AvaxLogo />, mainnet: true },
    { id: "base", name: "Base", token: "ETH", family: "eth", imgURL: chains["base.png"], svg: <BaseLogo />, mainnet: true },
    { id: "bsc", name: "Binance", token: "BNB", family: "eth", imgURL: chains["binance.png"], svg: <BscLogo />, mainnet: true },
    { id: "eth", name: "Ethereum", token: "ETH", family: "eth", imgURL: chains["ethereum.png"], svg: <EthLogo />, mainnet: true },
    { id: "ftm", name: "Fantom", token: "FTM", family: "eth", imgURL: chains["fantom.png"], svg: <FtmLogo />, mainnet: true },
    { id: "gno", name: "Gnosis", token: "ETH", family: "eth", imgURL: chains["gnosis.png"], svg: <GnoLogo />, mainnet: true },
    { id: "linea", name: "Linea", token: "ETH", family: "eth", imgURL: chains["linea.png"], svg: <LineaLogo />, mainnet: true },
    { id: "matic", name: "Polygon", token: "MATIC", family: "eth", imgURL: chains["polygon.png"], svg: <MaticLogo />, mainnet: true },
    { id: "moonbeam", name: "Moonbeam", token: "ETH", family: "eth", imgURL: chains["moonbeam.png"], svg: <MoonbeamLogo />, mainnet: true },
    { id: "op", name: "Optimism", token: "ETH", family: "eth", imgURL: chains["optimism.png"], svg: <OpLogo />, mainnet: true },
    { id: "polygon-zkevm", name: "Polygon zkEVM", token: "MATIC", family: "eth", imgURL: chains["polygon-zkevm.png"], svg: <PolygonZkEVMLogo />, mainnet: true },
    { id: "zksync", name: "zkSync", token: "ETH", family: "eth", imgURL: chains["zksync.png"], svg: <ZkSyncLogo />, mainnet: true },
]

export const CONDITION_OPTIONS = [
    {
        id: "wallet_address",
        name: "Wallet address",
        preloadedDataCode: "wallets",
        defaultOperator: "eq",
        defaultValue: null,
        validOperators: ["eq", "ne"],
    },
    {
        id: "token_address",
        name: "Token address",
        preloadedDataCode: "assets",
        defaultOperator: "eq",
        defaultValue: null,
        validOperators: ["eq", "ne"],
    },
    {
        id: "collection",
        name: "NFT collection",
        preloadedDataCode: "collections",
        defaultOperator: "eq",
        defaultValue: null,
        validOperators: ["eq", "ne"],
    },
    {
        id: "contract_address",
        name: "Contract address",
        preloadedDataCode: "contracts",
        defaultOperator: "eq",
        defaultValue: null,
        validOperators: ["eq", "ne"],
    },
    {
        id: "event_type",
        name: "Event type",
        defaultOperator: "eq",
        defaultValue: null,
        freeformAllowed: false,
        validOperators: ["eq", "ne"],
        staticOptions: [
            simpleToComplexVal({ id: "mint", displayText: "Mint" }),
            simpleToComplexVal({ id: "burn", displayText: "Burn" }),
            simpleToComplexVal({ id: "transfer", displayText: "Transfer" }),
            simpleToComplexVal({ id: "approve", displayText: "Approve" }),
            simpleToComplexVal({ id: "deposit", displayText: "Deposit" }),
            simpleToComplexVal({ id: "withdraw", displayText: "Withdraw" }),
            simpleToComplexVal({ id: "stake", displayText: "Stake" }),
            simpleToComplexVal({ id: "unstake", displayText: "Unstake" }),
            simpleToComplexVal({ id: "claim", displayText: "Claim" }),
            simpleToComplexVal({ id: "redeem", displayText: "Redeem" }),
            simpleToComplexVal({ id: "purchase", displayText: "Purchase" }),
            simpleToComplexVal({ id: "sell", displayText: "Sell" }),
            simpleToComplexVal({ id: "swap", displayText: "Swap" }),
            simpleToComplexVal({ id: "addLiquidity", displayText: "Add Liquidity" }),
            simpleToComplexVal({ id: "removeLiquidity", displayText: "Remove Liquidity" }),
            simpleToComplexVal({ id: "borrow", displayText: "Borrow" }),
            simpleToComplexVal({ id: "lend", displayText: "Lend" }),
        ],
    },
    {
        id: "contract_method",
        name: "Method",
        preloadedDataCode: "methods",
        defaultOperator: "eq",
        defaultValue: null,
        validOperators: ["eq", "ne"],
    },
    {
        id: "chain",
        name: "Chain",
        defaultOperator: "eq",
        defaultValue: null,
        validOperators: ["eq", "ne"],
        freeformAllowed: false,
        staticOptions: PLATFORMS.map((x) => simpleToComplexVal({ id: x.id, displayText: x.name }))
    },
    {
        id: "transaction_role",
        name: "Transaction role",
        defaultOperator: "eq",
        defaultValue: [
            simpleToComplexVal({ id: "sender", displayText: "Sender" }),
            simpleToComplexVal({ id: "receiver", displayText: "Receiver" }),
        ],
        validOperators: ["eq", "ne"],
        freeformAllowed: false,
        staticOptions: [
            simpleToComplexVal({ id: "sender", displayText: "Sender" }),
            simpleToComplexVal({ id: "receiver", displayText: "Receiver" }),
        ]
    },
    {
        id: "label",
        name: "Label",
        preloadedDataCode: "labels",
        defaultOperator: "eq",
        defaultValue: null,
        validOperators: ["eq", "ne"],
    },
]

const formatAddressWithAlias = ({ id, name }) => {
    if (name == null) return { id, name: smartTrim(id, 10) }
    return { id, name, subtext: smartTrim(id, 20) }
}

const classicFormat = ({ id, name }) => ({ id, name })

export const EVENT_CONDITION_OPTIONS_V2 = [
    {
        id: "wallet_address",
        name: "Wallet address",
        families: ["eth", "sol"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: formatAddressWithAlias,
        hasAsyncOptions: true,
        staticOptions: null,
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "token_address",
        name: "Token address",
        families: ["eth", "sol"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: formatAddressWithAlias,
        hasAsyncOptions: true,
        staticOptions: null,
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "collection",
        name: "NFT collection",
        families: ["eth", "sol"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: formatAddressWithAlias,
        hasAsyncOptions: true,
        staticOptions: null,
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "contract_address",
        name: "Contract address",
        families: ["eth", "sol"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: formatAddressWithAlias,
        hasAsyncOptions: true,
        staticOptions: null,
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "event_type",
        name: "Event type",
        families: ["eth"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: classicFormat,
        hasAsyncOptions: false,
        staticOptions: [
            { id: "mint", name: "Mint" },
            { id: "burn", name: "Burn" },
            { id: "transfer", name: "Transfer" },
            { id: "approve", name: "Approve" },
            { id: "deposit", name: "Deposit" },
            { id: "withdraw", name: "Withdraw" },
            { id: "stake", name: "Stake" },
            { id: "unstake", name: "Unstake" },
            { id: "claim", name: "Claim" },
            { id: "redeem", name: "Redeem" },
            { id: "purchase", name: "Purchase" },
            { id: "sell", name: "Sell" },
            { id: "swap", name: "Swap" },
            { id: "addLiquidity", name: "Add Liquidity" },
            { id: "removeLiquidity", name: "Remove Liquidity" },
            { id: "borrow", name: "Borrow" },
            { id: "lend", name: "Lend" },
        ],
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "contract_method",
        name: "Method",
        families: ["eth"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: classicFormat,
        hasAsyncOptions: true,
        staticOptions: null,
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "transaction_role",
        name: "Transaction role",
        families: ["eth", "sol"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: classicFormat,
        hasAsyncOptions: false,
        staticOptions: [
            { id: "sender", name: "Sender" },
            { id: "receiver", name: "Receiver" },
        ],
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "chain",
        name: "Chain",
        families: ["eth", "sol"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: classicFormat,
        hasAsyncOptions: false,
        staticOptions: PLATFORMS.map((x) => ({ id: x.id, name: x.name })),
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    }
]
 
export function getOperatorsBasic(operators) {
    return ALL_CONDITIONALS.filter((x) => operators.includes(x.id))
}

export const ALL_CONDITIONALS = [
    { id: "eq", name: "=" },
    { id: "ne", name: "!=" },
    { id: "gt", name: ">" },
    { id: "lt", name: "<" },
    { id: "gte", name: ">=" },
    { id: "lte", name: "<=" },
]

export function getConditionals(list = ["eq", "ne"]) {
    return ALL_CONDITIONALS.filter((x) => list.includes(x.id))
}

export function getOperators(operators, multiple) {
    let a = []
    operators?.forEach((x) => {
        if (!multiple && x === "ne") { return }
        a.push(ALL_CONDITIONALS.find((y) => y.id === x))
    })
    return a
}

// export const NUMERICAL_CONDITIONALS = ALL_CONDITIONALS.filter((x)=> ["eq", "ne", "gt", "lt", "gte"x.id  ))



export const DELETE_FIELD = "DELETE_FIELD"
export const ADD_FIELD = "ADD_FIELD"

export function simpleToComplexVal({ id, displayText, metadata = {}, isRequired = false, suboptions }) {

    let complexVal = {
        display: {
            chip: displayText,
            text: displayText,
        },
        value: id,
    }

    if (metadata != null) {
        complexVal.metadata = metadata
    }

    if (isRequired != null) {
        complexVal.isRequired = isRequired
    }

    if (suboptions != null) {
        complexVal.suboptions = suboptions?.map((x) => simpleToComplexVal({ id: x.id, displayText: x.name }))
    }

    return complexVal
}

// export function simpleToComplexValSuboptions(id, displayText, suboptions) {
//     return {
//         display: {
//             chip: displayText,
//             text: displayText,
//         },
//         value: id,
//         suboptions: suboptions?.map((x) => simpleToComplexVal({id: x.id, displayText: x.name}))
//     }
// }

export const QUERY_TYPES = [
    {
        id: "time",
        name: "Time chart",
        supertype: "broad",
        deprecated: true
    },
    {
        id: "event",
        name: "Events",
        supertype: "broad"
    },
    {
        id: "composition",
        name: "Composition",
        supertype: "broad"
    },
    {
        id: "retention",
        name: "Retention",
        supertype: "broad"
    },
    {
        id: "funnel",
        name: "Funnel",
        supertype: "broad"
    },
    {
        id: "pathfinder",
        name: "Pathfinder",
        supertype: "broad"
    }
]

export const VALID_DATE_TYPES = [
    {
        id: "since",
        name: "since",
    },
    {
        id: "before",
        name: "before",
    },
    {
        id: "last",
        name: "in the last",
    },
    {
        id: "between",
        name: "between",
    }
]

export function getBlankLabelDefinition() {
    return {
        metric: "",
        value: "",
        conditions: [
            {
                index: 0,
                condition: "",
                value: []
            }
        ],
        date: {
            scope: "any",
            type: "since",
            value: getDefaultMinDate()
        },
    }
}


function getDefaultMinDate() {
    // return Math.floor(Date.now() / 1000) - 604800
    return moment().subtract(7, "days").toISOString()
}

export const ABS_MAX = 315360000

export const validGranularities = [
    {
        id: "h",
        name: "Hourly",
        title: "Hour",
        min: 1209600,
        shortCode: "h",
        formatDate: "MMM D, h:mm a",
        periods: [
            { id: "1d", name: "1D", days: 1 },
            { id: "3d", name: "3D", days: 3 },
            { id: "7d", name: "7D", days: 7 },
            { id: "14d", name: "14D", days: 14 },
        ]
    },
    {
        id: "d",
        name: "Daily",
        title: "Day",
        min: 31536000,
        shortCode: "d",
        formatDate: "MMM D YYYY",
        periods: [
            { id: "30d", name: "30D", days: 30 },
            { id: "90d", name: "90D", days: 90 },
            { id: "6mo", name: "6M", days: 180 },
            { id: "1y", name: "1Y", days: 365 },
        ],
    },
    {
        id: "w",
        name: "Weekly",
        title: "Week",
        min: ABS_MAX,
        shortCode: "w",
        formatDate: "MMM D YYYY",
        periods: [
            { id: "6mo", name: "6M", days: 180 },
            { id: "1y", name: "1Y", days: 365 },
            { id: "2y", name: "2Y", days: 730 },
            { id: "5y", name: "5Y", days: 1825 },
        ]
    },
    {
        id: "mo",
        name: "Monthly",
        title: "Month",
        min: ABS_MAX,
        shortCode: "mo",
        formatDate: "MMM YYYY",
        periods: [
            { id: "1y", name: "1Y", days: 365 },
            { id: "2y", name: "2Y", days: 730 },
            { id: "3y", name: "3Y", days: 1095 },
            { id: "5y", name: "5Y", days: 1825 },
        ]
    },
]

export const standardSections = [
    { id: ALL_CODE, name: "All Events", inline: "all events" },
    { id: "custom", name: "Custom Events", inline: "custom events" },
    { id: "template", name: "On-chain Events", inline: "on-chain events" },
]

export function standardOptionFn({ filterDatatype, filterResourceType }) {
    let [icon, supertype] = [null, null]
    if (filterDatatype === "string") icon = TextFieldsRounded
    if (filterDatatype === "numeric") icon = FunctionsRounded
    if (filterDatatype === "bool") icon = ToggleOnRounded
    if (filterResourceType === "template") icon = ElectricBoltRounded
    if (filterResourceType === "label") {
        supertype = "In Label"
        icon = Person2Outlined
    }
    if (filterResourceType === "user") supertype = "User"
    return { icon, supertype }
}

export function userOptionFn({ filterDatatype, filterResourceType }) {
    let [icon, supertype] = [null, null]
    if (filterDatatype === "string") icon = TextFieldsRounded
    if (filterDatatype === "numeric") icon = FunctionsRounded
    if (filterDatatype === "bool") icon = ToggleOnRounded
    if (filterResourceType === "template") icon = ElectricBoltRounded
    if (filterResourceType === "label") {
        supertype = "In Label"
        icon = Person2Outlined
    }
    if (filterResourceType === "event") supertype = "Event"
    return { icon, supertype }
}

export const FILTER_OPTIONS = [
    {
        id: "label",
        pretext: "User in",
        icon: PeopleAltRounded,
    }
]


export const LABEL_COLORS = [
    { name: 'red', value: '219 40 40' },
    { name: 'orange', value: '242 113 28' },
    { name: 'yellow', value: '251 189 8' },
    { name: 'olive', value: '181 204 24' },
    { name: 'green', value: '33 186 69' },
    { name: 'teal', value: '0 181 173' },
    { name: 'blue', value: '33 133 208' },
    { name: 'violet', value: '100 53 201' },
    { name: 'purple', value: '163 51 200' },
    { name: 'pink', value: '224 57 151' },
    { name: 'brown', value: '165 103 63' },
    { name: 'grey', value: '118 118 118' },
    { name: 'black', value: '27 28 29' }
]


export const DISCOVERY_TEMPLATES = [
    {
        id: "contract_address",
        name: "Contract address",
        families: ["eth", "sol"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: formatAddressWithAlias,
        hasAsyncOptions: true,
        staticOptions: null,
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "token_address",
        name: "Token address",
        families: ["eth", "sol"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: formatAddressWithAlias,
        hasAsyncOptions: true,
        staticOptions: null,
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "collection",
        name: "NFT collection",
        families: ["eth", "sol"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: formatAddressWithAlias,
        hasAsyncOptions: true,
        staticOptions: null,
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "contract_method",
        name: "Method",
        families: ["eth"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: classicFormat,
        hasAsyncOptions: true,
        staticOptions: null,
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "transaction_role",
        name: "Transaction role",
        families: ["eth", "sol"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: classicFormat,
        hasAsyncOptions: false,
        staticOptions: [
            { id: "sender", name: "Sender" },
            { id: "receiver", name: "Receiver" },
        ],
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    },
    {
        id: "chain",
        name: "Chain",
        families: ["eth", "sol"],
        defaultOperator: "eq",
        datatype: "string",
        formatOption: classicFormat,
        hasAsyncOptions: false,
        staticOptions: PLATFORMS.map((x) => ({ id: x.id, name: x.name })),
        structure: SIMPLE_STRUCTURE,
        icon: ElectricBoltRounded
    }
]