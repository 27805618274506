import { Modal, ModalInput, ModalInputLabel, ModalTextArea, ModalTitle } from "components/Modal/Modal";
import { useForm } from "react-hook-form";
import styles from "./ClassicSaveModal.module.css"
import { useEffect } from "react";

export function ClassicSaveModal({ title, isOpen, onClose, onSave, existing }) {

    const { register, handleSubmit, reset } = useForm({ mode: 'onChange', delayError: 2000, defaultValues: existing });

    // useEffect(() => {
    //     if(existing == null) return
    //     setValue("name", existing.name)
    //     setValue("description", existing.description)
    // }, [existing])

    function onSubmit(data) {
        const { name, description = "" } = data
        onSave({ name, description })
        reset()
    }


    return <Modal onClose={onClose} isOpen={isOpen}>
        <div>
            <ModalTitle>{title}</ModalTitle>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <ModalInputLabel>Name</ModalInputLabel> 
                <ModalInput register={register("name", { required: true })} placeholder="Enter name..." autoComplete="off" />
                <ModalInputLabel optional>Description</ModalInputLabel>
                <ModalTextArea register={register("description")} placeholder={"Enter description..."} autoComplete="off" />
                <div className={styles.actions}>
                    <button type="button" className={styles.outline} onClick={onClose}>Cancel</button>
                    <button type="submit" className={styles.fill}>Save</button>
                </div>
            </form>
        </div>
    </Modal>
}