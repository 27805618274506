import { Content } from "components/Content/Content";
import { useEffect, useState } from "react";
import styles from "./Users.module.css"
import { compareObjectsRegardlessOfKeyOrder, smartTrim } from "helpers";
import { postEndpoint, usePostEndpoint, useQueryFunctions } from "ApiConnector";
import moment from "moment";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { ClassicSaveModal } from "components/ClassicSave/ClassicSaveModal";
import { LABEL_STRUCTURE, PLATFORMS, userOptionFn } from "Constants";
import { Link, useLocation } from "react-router-dom";
import PageLoading from "components/PageLoading/PageLoading";
import numeral from "numeral";
import { useGlobal } from "hooks/global";
import { TableV2 } from "components/TableV2/TableV2";
import Blockies from 'react-blockies';
import { SyncRounded } from "@mui/icons-material";
import { Cohorts } from "components/Cohorts/Cohorts";
import { BLANK_GROUP, cohortModified, getRequestCohort, getStoredCohort, isEmptyCohort, useCohortKeyOptions } from "utils/cohort";
import { CohortLabelFilter } from "models/cohort";
import { isPending } from "q";

export function Users() {
    const { invalidate } = useQueryFunctions()
    const [query, setQuery] = useState("");
    const [searchTimer, setSearchTimer] = useState(null)
    const [searching, setSearching] = useState(false)
    const [offset, setOffset] = useState(0)
    const [saveModal, setSaveModal] = useState(false)
    const [pendingLabel, setPendingLabel] = useState(undefined)
    const [cohort, setCohort] = useState({ groups: [{ ...BLANK_GROUP }] })
    const [sortModel, setSortModel] = useState({ key: "ts", direction: "desc" });
    const location = useLocation()
    const [loadingLabel, setLoadingLabel] = useState(false)
    const [users, setUsers] = useState(undefined)
    const [pendingReset, setPendingReset] = useState(false)
    const { workspace } = useGlobal()
    const { configuration } = workspace || {}

    const { options: filterOptions } = useCohortKeyOptions()
    const requestOptions = getRequestOptions(cohort, offset, sortModel, query)
    const { data: addressData, status } = usePostEndpoint({ endpoint: "users", body: requestOptions, skip: configuration == null })
    const { data: count, status: countStatus } = usePostEndpoint({ endpoint: "users/count", body: requestOptions, skip: configuration == null })
    const modified = cohortModified(cohort)
    const isEmpty = isEmptyCohort(cohort)

    function onSortModelChange(model) {
        setSortModel(model)
        setOffset(0)
        setPendingReset(true)
    }

    // useEffect(() => {
    //     if(users == null && offset === 0) return
    //     if(cohort == null) return
    //     if(savedValidCohort == null) {
    //         setSavedValidCohort(getRequestCohort(cohort))
    //         return
    //     }
    //     const validCohort = getRequestCohort(cohort)
    //     const equal = compareObjectsRegardlessOfKeyOrder(validCohort, savedValidCohort)
    //     if (equal) return
    //     setSavedValidCohort(validCohort)
    //     setOffset(0)
    //     setUsers(undefined)
    // }, [cohort])

    // useEffect(() => {
    //     if(users == null && offset === 0) return
    //     if(savedQuery == null) {
    //         setSavedQuery(query)
    //         return
    //     }
    //     if(savedQuery === query) return
    //     setSavedQuery(query)
    //     setOffset(0)
    //     setUsers(undefined)
    // }, [query])

    useEffect(() => {
        const { state } = location || {}
        if (state == null) return
        const { id, name, type } = state
        if (type !== "label") return
        const labelFilter = new CohortLabelFilter({
            id: null,
            name,
            label: {
                id,
            }
        })
        setCohort({ groups: [{ ...BLANK_GROUP, filters: [labelFilter] }] })
        setPendingLabel(id)
        location.state = undefined
    }, [location])

    useEffect(() => {
        if (filterOptions == null) return
        if (pendingLabel == null) return
        if (loadingLabel) return

        const labelToLoad = pendingLabel
        setPendingLabel(undefined)
        setLoadingLabel(true)

        async function f() {
            const labelFilters = filterOptions.filter((x) => x.structure === LABEL_STRUCTURE)
            const { id: optionId, name, label: labelObj } = labelFilters.find((x) => x.label.id === labelToLoad) || {}
            if (optionId == null) {
                setLoadingLabel(false)
                toast.error("Error loading label")
                return
            }
            const labelFilter = new CohortLabelFilter({
                id: optionId,
                name,
                label: labelObj
            })

            setLoadingLabel(false)
            setCohort({ groups: [{ ...BLANK_GROUP, filters: [labelFilter] }] })
        }
        f()
    }, [filterOptions, pendingLabel])

    useEffect(() => {
        if(users == null && offset === 0) return
        setPendingReset(false)
    }, [users])

    useEffect(() => {
        if (addressData == null) {
            return
        }
        if (offset === 0) {
            setUsers(addressData)
            return
        }
        if (users == null) {
            setUsers(addressData)
            return
        }

        const existingAddresses = users.map((x) => x.address)
        const newAddresses = addressData.map((x) => x.address)

        const anyNewUsers = newAddresses.some((x) => !existingAddresses.includes(x))

        if (!anyNewUsers) return
        setUsers([...users, ...addressData])
    }, [addressData, users])

    function onSearchChanged(e) {
        const { value } = e.target
        setSearching(true)
        if (searchTimer) clearTimeout(searchTimer)
        const timer = setTimeout(() => {
            setQuery(value)
            setOffset(0)
            setSearching(false)
        }, 400)
        setSearchTimer(timer)
    }

    const openLabelModal = () => {
        if (isEmpty) return
        setSaveModal(true)
    }

    function reset() {
        if (!modified) return
        setQuery("")
        setCohort({ groups: [{ ...BLANK_GROUP }] })
    }

    async function save(data) {
        const { name, description } = data;
        setSaveModal(false)

        const promise = new Promise(async (resolve, reject) => {
            try {
                const { data } = await postEndpoint("users/label", {
                    storeCohort: getStoredCohort(cohort),
                    requestCohort: getRequestCohort(cohort),
                    name,
                    description
                })
                const { id } = data || {}
                if (id == null) {
                    console.error("Error saving label")
                    reject("Error saving label")
                    return
                }

                setPendingLabel(id)

                invalidate("general/home")
                invalidate("filters/keys")
                invalidate("cohort/keys")
                invalidate("labels")

                const labelFilter = new CohortLabelFilter({
                    id: null,
                    name,
                    label: {
                        id,
                    }
                })
                setCohort({ groups: [{ ...BLANK_GROUP, filters: [labelFilter] }] })

                resolve()
            } catch (e) {
                console.error(e)
                reject(e)
            }
        })

        toast.promise(promise, {
            loading: "Saving label...",
            success: "Label saved!",
            error: "Error saving label",
        })
    }

    if (configuration == null) return <PageLoading />

    return <>
        <ClassicSaveModal
            title={"Create Label"}
            isOpen={saveModal}
            onClose={() => setSaveModal(false)}
            onSave={save}
        />
        <Content>
            <TopBar
                userCount={count}
            />
            <Cohorts
                cohort={cohort}
                setCohort={setCohort}
                optionFn={userOptionFn}
                excludeResources={["event"]}
                style={{ marginBottom: 12 }}
            />
            <div className={styles.barAboveTable}>
                <div />
                <div className={styles.barAboveTableRight}>
                    <button className={`${styles.outline} ${!modified ? styles.disabled : ""}`} onClick={reset}>Reset all</button>
                    <CustomLoadingButtonV2 className={`${styles.filled} ${isEmpty ? styles.disabled : ""}`} onClick={openLabelModal} light={true}>Create label</CustomLoadingButtonV2>
                </div>
            </div>
            <ResultsTable
                data={users}
                sortModel={sortModel}
                setSortModel={onSortModelChange}
                softLoading={status === "loading" && users?.length < offset + 100}
                broadLoading={status === "loading" || pendingReset}
                offset={offset}
                setOffset={setOffset}
                query={query}
                searching={searching}
                cohort={cohort}
                onCreateLabelOpen={openLabelModal}
                onSearchChanged={onSearchChanged}
            />
        </Content>
    </>
}


function getRequestOptions(cohort, offset, sort, search) {
    return {
        cohort: getRequestCohort(cohort),
        offset,
        sort,
        search: search === "" ? null : search
    }
}

const columnLibraryOffchain = [
    {
        key: "address",
        title: "Wallet",
        minWidth: 300,
        maxWidth: 300,
        renderCell: ({ value, data }) => {
            const { user_alias } = data || {}
            if (user_alias == null) return <Link className={styles.accountWrap} to={`/users/${value}`}>
                <div className={styles.accountIconWrap}>
                    <Blockies
                        seed={value}
                        size={10}
                        scale={10}
                        bgColor={"transparent"}
                        className={styles.accountIcon}
                    />
                </div>
                <div className={styles.accountText}>{smartTrim(value, 15)}</div>
            </Link>
            return <Link className={styles.accountWrap} to={`/users/${value}`}>
                <div className={styles.accountIconWrap}>
                    <Blockies
                        seed={value}
                        size={10}
                        scale={10}
                        bgColor={"transparent"}
                        className={styles.accountIcon}
                    />
                </div>
                <div className={styles.accountText}>{user_alias}</div>
            </Link>
        }
    },
    {
        key: "chains",
        title: "Chains",
        minWidth: 225,
        renderCell: ({ value, data }) => {
            if (value == null) return <div>–</div>
            const { chains, queue } = data
            if (queue) {
                return <div className={styles.syncingTag}>
                    <div className={styles.syncingTagIcon}>
                        <SyncRounded />
                    </div>
                    <div className={styles.syncingTagText}>
                        Syncing
                    </div>
                </div>
            }
            return <div className={styles.chains}>
                {chains.map((chain, idx) => {
                    const pObj = PLATFORMS.find((x) => x.id === chain)
                    if (pObj == null) return <span key={idx} className={`normalCell`}>–</span>
                    return <img key={idx} src={pObj?.imgURL} className={styles.platformIcon} />
                })}
            </div>
        }
    },
    {
        key: "balance_usd",
        title: "Balance",
        sortable: true,
        minWidth: 125,
        renderCell: ({ value }) => {
            if (value == null) return <div>&lt;$1k</div>
            return <div>${numeral(value).format("0,0.00")}</div>
        }
    },
    { key: "country", title: "Country", minWidth: 150 },
    { key: "region", title: "Region", minWidth: 150 },
    { key: "city", title: "City", minWidth: 150 },
    {
        key: "ts",
        title: "First Seen",
        sortable: true,
        // minWidth: 250,
        renderCell: ({ value }) => {
            if (value == null) return <div>–</div>
            const momentObj = moment(value)
            return <div>{momentObj.format("MMM D, YYYY h:mm A")}</div>
        }
    },
    // {
    //     key: "last_active", 
    //     title: "Last Active", 
    //     // minWidth: 250,
    //     sortable: true, 
    //     renderCell: ({ value }) => {
    //         if (value == null) return <div>–</div>
    //         const momentObj = moment(value)
    //         return <div>{momentObj.format("MMM D, YYYY h:mm A")}</div>
    //     }
    // }
]

const columnLibraryOnchain = [
    {
        key: "address",
        title: "Wallet",
        minWidth: 300,
        maxWidth: 300,
        flex: 1,
        renderCell: ({ value, data }) => {
            const { user_alias } = data || {}
            if (user_alias == null) return <Link className={styles.accountWrap} to={`/users/${value}`}>
                <div className={styles.accountIconWrap}>
                    <Blockies
                        seed={value}
                        size={10}
                        scale={10}
                        bgColor={"transparent"}
                        className={styles.accountIcon}
                    />
                </div>
                <div className={styles.accountText}>{smartTrim(value, 15)}</div>
            </Link>
            return <Link className={styles.accountWrap} to={`/users/${value}`}>
                <div className={styles.accountIconWrap}>
                    <Blockies
                        seed={value}
                        size={10}
                        scale={10}
                        bgColor={"transparent"}
                        className={styles.accountIcon}
                    />
                </div>
                <div className={styles.accountText}>{user_alias}</div>
            </Link>
        }
    },
    {
        key: "chains",
        title: "Chains",
        minWidth: 250,
        renderCell: ({ value, data }) => {
            if (value == null) return <div>–</div>
            const { chains } = data
            return <div className={styles.chains}>
                {chains.map((chain, idx) => {
                    const pObj = PLATFORMS.find((x) => x.id === chain)
                    if (pObj == null) return <span key={idx} className={`normalCell`}>–</span>
                    return <img key={idx} src={pObj?.imgURL} className={styles.platformIcon} />
                })}
            </div>
        }
    },
    {
        key: "balance_usd",
        title: "Balance",
        sortable: true,
        minWidth: 200,
        renderCell: ({ value }) => {
            if (value == null) return <div>&lt;$1k</div>
            return <div>${numeral(value).format("0,0.00")}</div>
        }
    },
    {
        key: "ts",
        title: "Imported",
        minWidth: 250,
        sortable: true,
        renderCell: ({ value }) => {
            if (value == null) return <div>–</div>
            const momentObj = moment(value)
            return <div>{momentObj.format("MMM D, YYYY h:mm A")}</div>
        }
    },

]

function getColumns(dataOrigins) {
    let columns = dataOrigins.offchain ? [...columnLibraryOffchain] : [...columnLibraryOnchain]
    return columns
}

function ResultsTable({ data, setOffset, sortModel, setSortModel, onCreateLabelOpen, softLoading, broadLoading, offset, query, searching, onSearchChanged, cohort }) {

    const [resultsQuery, setResultsQuery] = useState(query);
    const [selectedRows, setSelectedRows] = useState([]);
    const { workspace } = useGlobal()
    const { configuration } = workspace || {}
    const { dataOrigins = {} } = configuration || {}

    const columns = getColumns(dataOrigins)

    function topTableSearchChange(e) {
        const { value } = e.target
        setResultsQuery(value)
        onSearchChanged(e)
    }

    useEffect(() => {
        setSelectedRows([])
    }, [data])

    return <>
        <TableV2
            data={data}
            columns={columns}
            loading={broadLoading || searching}
            noData={{
                title: "No users",
                description: "Adjust your filters or import new users",
            }}
            sortModel={sortModel}
            rowHeight={55}
            headerHeight={32}
            onSortModelChange={setSortModel}
            top={<TableTop
                resultsQuery={resultsQuery}
                cohort={cohort}
                users={data}
                selectedRows={selectedRows}
                onCreateLabelOpen={onCreateLabelOpen}
                onSearchChanged={topTableSearchChange}
            />}
            selected={selectedRows}
            // checkboxes={true}
            // onCheckboxClick={({ item, selected }) => {
            //     if (selected) {
            //         setSelectedRows([...selectedRows, item])
            //     } else {
            //         setSelectedRows(selectedRows.filter(x => x.id !== item.id))
            //     }
            // }}
            classes={{
                wrap: styles.tableWrap,
                table: styles.table,
                thead: styles.tableHeaders,
                theadCell: styles.tableHeaderCell,
                tbody: styles.tableBody,
                tbodyRow: styles.tableRow,
                tbodyCell: styles.tableCell,
            }}
        />
        {data != null && <TableBottom
            length={data.length}
            lastTime={data[data.length - 1]?.ts}
            loading={softLoading}
            onClick={() => {
                setOffset(offset + 100)
            }}
        />}
    </>
}

function TableBottom({ length, lastTime, loading, onClick }) {

    return <div className={styles.eventBottom}>
        <div className={styles.eventBottomInner}>
            <div>Showing {length} results through {moment(lastTime).format("YYYY-MM-DD HH:mm:ss")}</div>
            <CustomLoadingButtonV2 className={styles.loadBtn} light={true} loading={loading} onClick={onClick}>
                Load more
            </CustomLoadingButtonV2>
        </div>
    </div>
}

function CustomLoadingButtonV2({ loading, className, onClick, light, children }) {
    if (loading) {
        const color = light ? "rgb(255,255,255)" : "rgb(0,0,0)"
        return <div className={`${className} ${styles.stockLoadingButton}`}>
            <CircularProgress size={12} sx={{ color }} />
            {children}
        </div>
    }

    return <button className={className} onClick={onClick}>
        {children}
    </button>
}

function TopBar({ userCount }) {

    return <div className={styles.topBar}>
        <div className={styles.left}>
            <div>
                {userCount == null && <>Loading...</>}
                {userCount != null && <>{numeral(userCount).format("0,0")} users</>}
            </div>
        </div>
        <div className={styles.right}>
        </div>
    </div>
}

function TableTop({ resultsQuery, users, cohort, onCreateLabelOpen, onSearchChanged }) {

    // const validFilters = getRequestFilters(cohort)
    // const cohortValid = isCohortValid(cohort)

    if (users == null) return

    return <div className={styles.tableTop}>
        <div className={styles.tableTopLeft}>
            <div className={styles.searchWrap}>
                <div className={styles.searchIconWrap}>
                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                </div>
                <input
                    className={styles.searchInput}
                    placeholder={"Search"}
                    value={resultsQuery}
                    onChange={onSearchChanged}
                />
            </div>
            {/* {selectedRows.length > 0 && <button className={styles.tableTopButton}>
                <div className={styles.tableTopButtonIconWrap}>
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                        <path d="M12.356 5.435 1.938 16.384a.5.5 0 0 0 .018.707l2.9 2.757a.5.5 0 0 0 .706-.018L15.978 8.882l-3.622-3.447Zm7.681-.819a.5.5 0 0 0-.018-.706l-2.9-2.757a.5.5 0 0 0-.707.017l-2.68 2.817 3.622 3.446 2.683-2.817Zm-2.89 12.233-1 .025-.024-1a1 1 0 1 0-2 .05l.025 1-1 .024a1 1 0 0 0 .05 2l1-.025.025 1a1 1 0 1 0 2-.05l-.025-1 1-.024a1 1 0 1 0-.05-2h-.001ZM2.953 9.2l.025 1a1 1 0 1 0 2-.05l-.025-1 1-.025a1 1 0 1 0-.05-2l-1 .025-.025-1a1 1 0 0 0-2 .049l.025 1-1 .025a1 1 0 0 0 .05 2l1-.024Zm15.07 2.626-2 .05.05 1.999 2-.05-.05-1.999ZM11.752.978l-2 .05.05 2 2-.05-.05-2Zm-2.95 2.075-2 .05.05 1.999 2-.05-.05-2ZM5.753 1.127l-1.999.05.05 2 1.999-.05-.05-2Zm15.194 7.625-2 .05.05 2 2-.05-.05-2Zm.125 4.998-2 .05.05 2 2-.05-.05-2Z" />
                    </svg>
                </div>
                <div>
                    Enrich {selectedRows.length} users
                </div>
            </button>} */}

        </div>
        {/* <div className={styles.tableTopRight}>
            {(cohortValid && users.length !== 0) && <button className={styles.tableTopButton} onClick={onCreateLabelOpen}>
                Create label from filters
            </button>}
        </div> */}
    </div>
}