import { Popover } from "@mui/material";
import styles from "./DatePickerV2.module.css"
import { useState } from "react";
import { MultiDatePickerInline, SingleDatePickerInline } from "components/DatePicker/DatePicker";
import { InputV2 } from "components/InputV2/inputV2";
import { SelectV2 } from "components/SelectV2/SelectV2";
import moment from "moment";

export function DatePickerDropdown({ anchorEl, value, isOpen, onClose, onChange }) {

    const [dateDraft, setDateDraft] = useState(value || {mode: "since"})

    function onDraftChange(val) {
        if(val.mode != null ){
            if(val.mode === "since") val = { mode: "since", min: moment().subtract(14, "day").toISOString() }
            if(val.mode === "last") val = { mode: "last", value: 14, units: "day" }
            if(val.mode === "between") val = { mode: "between", min: moment().subtract(14, "day").toISOString(), max: moment().toISOString() }
            setDateDraft(val)
            return
        }
        setDateDraft({ ...dateDraft, ...val })
    }

    function onApply(){
        onChange(dateDraft)
        onClose()
    }

    let date_el = <></>

    const { mode = "since" } = dateDraft || {}
    if (mode === "since") {
        const dateMin = dateDraft?.min || undefined
        date_el = <SingleDatePickerInline
            date={dateMin}
            onChange={(val) => onDraftChange({ min: val })}
        />
    }

    if (mode === "last") {
        const { value, units } = dateDraft

        date_el = <div className={styles.lastWrap}>
            <InputV2
                type={"numeric"}
                value={value}
                onChange={(val) => {
                    onDraftChange({ value: val })
                }}
                className={styles.numberInput}
                saveOnChange
            />
            <SelectV2
                className={styles.unitInput}
                option={units}
                options={[
                    { id: "day", name: "days" },
                    { id: "week", name: "weeks" },
                    { id: "month", name: "months" },
                ]}
                onChange={(val) => {
                    onDraftChange({ units: val })
                }}
            />
        </div>
    }

    if (mode === "between") {
        date_el = <MultiDatePickerInline
            date={dateDraft}
            onChange={(val) => onDraftChange(val)}
        />
    }

    return <Popover
        open={isOpen}
        onClose={onClose}
        transitionDuration={0}
        anchorEl={anchorEl?.current}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
    >
        <div className={styles.selectorWrap}>
            <div className={styles.selectorHeader}>
                <button className={`${styles.selectorHeaderButton} ${mode === "since" && styles.selected}`} onClick={() => onDraftChange({ mode: "since" })}>Since</button>
                <button className={`${styles.selectorHeaderButton} ${mode === "last" && styles.selected}`} onClick={() => onDraftChange({ mode: "last" })}>In the last</button>
                <button className={`${styles.selectorHeaderButton} ${mode === "between" && styles.selected}`} onClick={() => onDraftChange({ mode: "between" })}>Between</button>
            </div>
            <div className={styles.selectorBody}>
                {date_el}
            </div>
            <div className={styles.bottomWrap}>
                <button className={styles.outlineButton} onClick={onClose}>Cancel</button>
                <button className={styles.applyButton} onClick={onApply}>Apply</button>
            </div>
        </div>
    </Popover>
}