import { Button } from "components/Button/Button"
import { Link, useNavigate } from "react-router-dom"
import styles from "./PageError.module.css"

export function PageError({
    title = <>There was an error loading this page</>,
    subtitle = <>Something went wrong on our end. Please email <a href="mailto:support@multibase.co">support@multibase.co</a> if this error continues.</>,
    buttonText = "Return to Home",
    buttonPath = "/",
    style = {}
}) {

    return (
        <>
            {/* <Nav /> */}
            <div className={styles.pageErrorWrap} style={style}>
                <div className={`${styles.errorWrap}`}>
                    <div className={styles.errorTitleWrap}>
                        {/* <div className={styles.icon}><ErrorRoundedIcon /></div> */}
                        <div className={styles.title}>{title}</div>
                    </div>
                    <div className={styles.description}>{subtitle}</div>
                    <Link to={buttonPath} className={styles.filled}>{buttonText}</Link>
                </div>
            </div>
        </>
    )
}


export function PageErrorOffline({
    title = <>There was an error loading this page</>,
    message = <>Something went wrong on our end. Please email <a href="mailto:support@multibase.co">support@multibase.co</a> if this error continues.</>,
    buttonText = "Return to Home",
    onAction = null,
    style = {}
}) {

    return (
        <>
            {/* <Nav /> */}
            <div className={styles.pageErrorWrap} style={style}>
                <div className={`${styles.errorWrap}`}>
                    <div className={styles.errorTitleWrap}>
                        {/* <div className={styles.icon}><ErrorRoundedIcon /></div> */}
                        <div className={styles.title}>{title}</div>
                    </div>
                    <div className={styles.description}>{message}</div>
                    <Button className={styles.filled} onClick={onAction}>{buttonText}</Button>
                    {/* <Link to={buttonPath} className={styles.filled}>{buttonText}</Link> */}
                </div>
            </div>
        </>
    )
}
