import styled from "@emotion/styled"

export function CardTitle({ children, className }){
    return <CardDiv className={className}>
        {children}
    </CardDiv>
}

const CardDiv = styled.div(({theme})=>{
    const mode = theme.palette.mode
    return {
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
    }
})