export const BASIC_SEARCHABLES = [
    {
        name: "Home",
        description: "View queries, labels, and dashboards",
        href: "/",
        icon: "page",
        type: "page",
        state: null
    },
    {
        name: "Users",
        description: "View and manage your users",
        href: "/users",
        icon: "page",
        type: "page",
        state: null
    },
    {
        name: "Data sources",
        description: "View and manage your data sources",
        href: "/sources",
        icon: "page",
        type: "page",
        state: null
    },
]

// <CreateItem icon={<UilChartLine />} title={"Events"} description={"Visualize events, balance, transactions, and activity over time."} href={`/query/event/create`} onClose={() => setCreatePanel(false)} />
// <CreateItem icon={<UilChartPie />} title={"Composition"} description={"Overlaps among users including NFTs, tokens, and contracts."} href={`/query/composition/create`} onClose={() => setCreatePanel(false)} />
// <CreateItem icon={<SyncIcon />} title={"Retention"} description={"Explore likelihood of returning addresses to contract methods."} href={`/query/retention/create`} onClose={() => setCreatePanel(false)} />
// <CreateItem icon={<UilFilter />} title={"Funnel"} description={"Visualize smart contract method conversion rates."} href={`/query/funnel/create`} onClose={() => setCreatePanel(false)} />
// <CreateItem icon={<RouteIcon />} title={"Pathfinder"} description={"Map user behavior with paths of on-chain actions."} href={`/query/pathfinder/create`} onClose={() => setCreatePanel(false)} />



export const QUERY_SEARCHABLE_FIELDS = [
    {
        name: "Event Query",
        description: "Visualize events, balance, transactions, and activity over time.",
        href: "/query/event/create",
        icon: "plus",
        type: "action",
        state: null
    },
    {
        name: "Composition Query",
        description: "Overlaps among users including NFTs, tokens, and contracts.",
        href: "/query/composition/create",
        icon: "plus",
        type: "action",
    },
    {
        name: "Retention Query",
        description: "Explore likelihood of returning addresses to contract methods.",
        href: "/query/retention/create",
        icon: "plus",
        type: "action",
    },
    {
        name: "Funnel Query",
        description: "Visualize smart contract method conversion rates.",
        href: "/query/funnel/create",
        icon: "plus",
        type: "action",
    },
    {
        name: "Pathfinder Query",
        description: "Map user behavior with paths of on-chain actions.",
        href: "/query/pathfinder/create",
        icon: "plus",
        type: "action",
    },
]

export const SETTINGS_SEARCHABLE_FIELDS = [
    {
        name: "Workspace",
        description: "Manage your workspace settings",
        href: "/settings",
        icon: "settings",
        type: "page",
        state: null
    },
    {
        name: "Team",
        description: "Manage team members and permissions",
        href: "/settings/team",
        icon: "settings",
        type: "page",
        state: null
    },
    {
        name: "Profile",
        description: "Manage your account information and preferences",
        href: "/settings/profile",
        icon: "settings",
        type: "page",
        state: null
    }
]


// export const LABEL_SEARCHABLE_FIELDS = [
//     {
//         name: "Labels",
//         description: "View and manage your labels",
//         href: "/labels",
//         icon: "page",
//         type: "page",
//         state: null
//     },
//     // {
//     //     name: "Create Label",
//     //     description: "Create a new label",
//     //     href: "/labels/create",
//     //     icon: "plus",
//     //     type: "action",
//     //     state: null
//     // },
// ]
