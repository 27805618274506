import styles from "./Modal.module.css"
import { Modal as MuiModal } from "@mui/material"
import { Card } from "components/Card/Card"
import { CardTitle } from "components/CardTitle/CardTitle"
import { LoadingButton } from "@mui/lab"
import styled from "@emotion/styled"
import { useState } from "react"

export function Modal({ isOpen, onClose, children, title, width, noPadding = false, classes = {} }) {
    return <MuiModal disableAutoFocus={true} open={isOpen} onClose={onClose}>
        {/* <div className={styles.dialog}> */}
        <div style={{ width: "100%", margin: "0 auto", outline: "none", overflow: "scroll", height: "100vh" }} onClick={onClose}>
            <div className={`${styles.outerDialog} ${classes.card ?? ""}`} style={{ width: width != null ? width : "" }} onClick={(e) => e.stopPropagation()}>
                <div className={`${styles.innerDialog} ${classes.dialog ?? ""}`} onClick={(e) => e.stopPropagation()} style={{ padding: noPadding ? 0 : "" }}>
                    {title && <div className={styles.titleWrap}>{title}</div>}
                    {children}
                </div>
            </div>
        </div>
    </MuiModal>
}

export function ModalTitle({children}){
    return <div className={styles.title}>{children}</div>
}

export function ModalText({children}){
    return <div className={styles.description}>{children}</div>
}

export function ModalInputLabel({children, optional = false}){
    return <div className={styles.labelWrap}>
        <div className={styles.inputLabel}>{children}</div>
        {optional && <div className={styles.optional}>Optional</div>}
    </div>
}

export function ModalInput({ register, ...props }) {
    const [isFocused, setIsFocused] = useState(false)
    const onFocus = () => setIsFocused(true)
    const onBlur = () => setIsFocused(false)

    return <input className={`${styles.input} ${isFocused ? styles.isOpen : ""}`}  {...register} {...props}  onFocus={onFocus} onBlur={onBlur} />;
}

export function ModalTextArea({register, ...props}){
    const [isFocused, setIsFocused] = useState(false)
    const onFocus = () => setIsFocused(true)
    const onBlur = () => setIsFocused(false)
    return <textarea className={`${styles.textarea} ${isFocused ? styles.isOpen : ""}`} {...register} {...props} onFocus={onFocus} onBlur={onBlur} resize={"none"} />
}