import { useState, useEffect } from 'react'
import styles from "./Login.module.css"
import { useForm } from 'react-hook-form';
import { signInWithCustomToken, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from 'firebase-config'
import { CustomLoadingButton } from 'components/LoadingButton/LoadingButton';
import { useGlobal } from 'hooks/global.js';
import { postEndpoint, postEndpointWithToken } from 'ApiConnector';
import { Collapse } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';

export default function Login() {

    const queryClient = useQueryClient()

    useEffect(() => {
        async function invalidateAll() {
            await queryClient.resetQueries()
        }
        invalidateAll()
    }, [])

    const { register, handleSubmit, formState: { errors, isValid }, setError, setValue } = useForm({ mode: 'onChange', delayError: 2000 });
    // dev form
    const { register: registerDev, handleSubmit: handleSubmitDev, formState: { errors: errorsDev, isValid: isValidDev }, setError: setErrorDev, setValue: setValueDev } = useForm({ mode: 'onChange', delayError: 2000 });
    const [loading, setLoading] = useState(false);
    const [showDev, setShowDev] = useState(false);
    const { pingSession } = useGlobal()

    function doLogin(data) {
        setLoading(true)

        async function getDefaultWorkspaceForUser(uid) {
            const { data } = await postEndpoint("user/auth/default", { uid })
            if (data == null) return null
            return data
        }

        async function l() {
            try {
                const { email, password } = data;
                const res = await signInWithEmailAndPassword(auth, email, password)
                const { workspace: defaultWorkspace, status } = await getDefaultWorkspaceForUser(auth.currentUser.uid) //temp
                if (status.type === "disabled") {
                    signOut(auth)
                    setError("password", {
                        type: "manual",
                        message: "This account has been disabled. Please reach out at https://t.me/emmettm on Telegram or support@multibase.co.",
                    })
                    setLoading(false)
                    return
                }
                const authResponse = await postEndpointWithToken("user/auth/login", { workspace: defaultWorkspace })
                const { token } = authResponse;
                await signInWithCustomToken(auth, token)
                await pingSession()

            } catch (error) {
                const code = error?.code
                if (code?.includes("wrong-password") || code?.includes("user-not-found")) {
                    setError("password", {
                        type: "manual",
                        message: "Incorrect password",
                    })
                } else if (code?.includes("auth/too-many-requests")) {
                    setError("password", {
                        type: "manual",
                        message: "Too many attempts. Try again later",
                    })
                } else {
                    setError("password", {
                        type: "manual",
                        message: "There was a connection error. Please try again",
                    })
                }
                setLoading(false)
            }
        }
        l()
    }

    function doDevLogin(data) {
        setLoading(true)

        async function l() {
            try {
                const { workspace } = data;
                const authResponse = await postEndpoint("user/auth/dev", { workspace, token: process.env.REACT_APP_DEV_TOKEN })
                const { token } = authResponse;
                await signInWithCustomToken(auth, token)
                await pingSession()
            } catch (error) {
                const code = error?.code
                if (code?.includes("wrong-password") || code?.includes("user-not-found")) {
                    setError("password", {
                        type: "manual",
                        message: "Incorrect password",
                    })
                } else if (code?.includes("auth/too-many-requests")) {
                    setError("password", {
                        type: "manual",
                        message: "Too many attempts. Try again later",
                    })
                } else {
                    setError("password", {
                        type: "manual",
                        message: "There was a connection error. Please try again",
                    })
                }
                setLoading(false)
            }
        }
        l()
    }

    return (<>
        {/* <NavLimited /> */}
        <div className={styles.pageWrap}>
            <div className={styles.top}>
                <div className={styles.right}>
                    Don't have an account? <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.link}>Request access</a>
                </div>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.formWrapper}>
                    <div className={styles.formWrap}>
                        <div className={styles.title}>Multibase</div>
                        {/* write a cheeky subtitle */}
                        <div className={styles.subtitle}>Welcome back</div>
                        {/* <img src={logo} alt="logo" className={styles.logo} /> */}
                        <form onSubmit={handleSubmit(doLogin)} noValidate>
                            <div className={styles.inputTitle}>Email</div>
                            <input placeholder={"Email"} type="email" {...register('email', {
                                required: {
                                    value: true,
                                    message: "Email is required"
                                }, maxLength: {
                                    value: 255,
                                    message: "Please enter a valid email"
                                }, pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
                                    message: 'Please enter a valid email',
                                }
                            })} className={`${styles.normal} ${errors.email ? styles.error : ""}`} />
                            {/* {<span className={styles.errorTxt} style={errors.email ? { maxHeight: "1.2em" } : { maxHeight: "0" }}>{errors.email?.message}</span>} */}
                            <Collapse in={Boolean(errors.email)} timeout={100}><div className={styles.errorTxt}>{errors?.email?.message}</div></Collapse>
                            <div className={styles.inputTitle}>Password</div>
                            <input placeholder={"Password"} type="password" {...register('password', {
                                required: {
                                    value: true,
                                    message: "Password is required"
                                },
                                minLength: {
                                    value: 6,
                                    message: "Your password must be at least 6 characters"
                                },
                                maxLength: {
                                    value: 255,
                                    message: "Max password length is 255 characters"
                                }
                            })} className={`${styles.normal} ${errors.password ? styles.error : ""}`} />
                            <Collapse in={Boolean(errors.password)} timeout={100}><div className={styles.errorTxt}>{errors?.password?.message}</div></Collapse>
                            {/* <div className={styles.bottomWrap}>
                                <Link className={styles.other} to='/reset'>Forgot password?</Link>
                            </div> */}

                            <CustomLoadingButton
                                buttonType={"submit"}
                                sx={{ color: "var(--alpha-text)" }}
                                loading={loading}
                                // disabled={!errors.password?.type === "manual"}
                                className={styles.loginBtn}
                            >
                                Continue
                            </CustomLoadingButton>
                        </form>
                        <div className={styles.otherWrap}>
                            <Link className={styles.bottomLink} to={"/join"}>
                                Belong to a workspace? <span className={styles.fauxLink}>Join existing workspace</span>
                            </Link>
                        </div>

                        {process.env.NODE_ENV === "development" && <>
                            <button onClick={() => setShowDev(!showDev)} className={styles.devBtn}>Dev Login</button>
                            <Collapse in={showDev} timeout={100}>
                                <form onSubmit={handleSubmitDev(doDevLogin)} noValidate>
                                    <div className={styles.inputTitle}>Dev Login</div>
                                    <div className={styles.devLogin}>
                                        <input
                                            autoComplete='off'
                                            placeholder={"Workspace"}
                                            type="text"
                                            {...registerDev('workspace', {
                                                required: {
                                                    value: true,
                                                    message: "Workspace is required"
                                                }, maxLength: {
                                                    value: 255,
                                                    message: "Please enter a valid workspace"
                                                }, pattern: {
                                                    value: /^[a-zA-Z0-9_-]+$/, //eslint-disable-line
                                                    message: 'Please enter a valid workspace',
                                                }
                                            })} className={`${styles.normal} ${errorsDev.workspace ? styles.error : ""}`} />
                                        <Collapse in={Boolean(errorsDev.workspace)} timeout={100}><div className={styles.errorTxt}>{errorsDev?.workspace?.message}</div></Collapse>
                                        <CustomLoadingButton
                                            buttonType={"submit"}
                                            sx={{ color: "var(--alpha-text)" }}
                                            loading={loading}
                                            disabled={!(isValidDev || errorsDev.workspace?.type === "manual")}
                                            className={`${styles.loginBtn} ${(isValidDev || errorsDev.workspace?.type === "manual") ? styles.active : styles.inactive}`}
                                        >
                                            Continue
                                        </CustomLoadingButton>
                                    </div>
                                </form>
                            </Collapse>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

