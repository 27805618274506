import styled from "@emotion/styled"
import { InputBase } from "@mui/material"


export function Input({icon, value, onChange, placeholder, registerProps, className}) {
    return <InputWrapped>
    <SvgWrap>
    {icon}

    </SvgWrap>
        <CustomInput value={value} onChange={onChange} placeholder={placeholder} className={className} inputProps={{...registerProps}} icon={icon} />
    </InputWrapped>
}

const InputWrapped = styled.div(({ theme }) => ({
    minWidth: "250px",
    position: "relative"
}))

const SvgWrap = styled.div(({theme})=>({
    ' svg': svgStyle
}))

const svgStyle = {
    left: "12px",
    top: "50%",
    transform: "translateY(-50%)",
    width: "14px",
    height: "14px",
    color: "var(--input-placeholder)",
    position: "absolute"
}

const CustomInput = styled(InputBase, {
    shouldForwardProp: (prop) => !['icon'].includes(prop),
})(({ theme, icon }) => {

    const paddingL = icon == null ? "12px" : "32px"
    return {
        width: "100%",
        '& .MuiInputBase-input': {
            position: 'relative',
            fontSize: 13,
            height: "22px",
            background: "rgb(var(--alpha) / 2.5%)",
            paddingLeft: paddingL,
            display: "flex",
            fontWeight: "400",
            alignItems: "center",
            border: "1px solid rgb(255 255 255 / 5%)",
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            '&:focus': {
            },
            "&::placeholder": {
                color: "var(--input-placeholder)",
                opacity: 1,
            }
        },
    }
})

// const CustomInputWrapper = CustomInput.extend();