import styled from "@emotion/styled";
import { InputBase } from "@mui/material";
import { Card } from "components/Card/Card";
import styles from "./Spotlight.module.css"
import { UilSearch, UilUsersAlt, UilChartPie, UilPlusCircle, UilSetting, UilFile, UilTimes } from '@iconscout/react-unicons'
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useGlobal } from "hooks/global";
import { formatTimeRecentDobj, formatTimeRecentISO, getUserById, smartTrim } from "helpers";
import { BASIC_SEARCHABLES, QUERY_SEARCHABLE_FIELDS, SETTINGS_SEARCHABLE_FIELDS } from "Searchables";
import { Modal } from "components/Modal/Modal";
import { useGetEndpoint } from "ApiConnector";

const SEARCHABLES = [...BASIC_SEARCHABLES, ...QUERY_SEARCHABLE_FIELDS, ...SETTINGS_SEARCHABLE_FIELDS]

export function Spotlight({ onOpen, isOpen, onClose }) {

    const [query, setQuery] = useState("")
    const [tab, setTab] = useState(undefined)
    const [results, setResults] = useState(SEARCHABLES)
    const [baseResults, setBaseResults] = useState(SEARCHABLES)
    const inputRef = useRef(null);
    const { workspace } = useGlobal()
    const { data: homeData } = useGetEndpoint("general/home")

    function handleKey(event) {
        // input or textarea or editable div
        if (event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA") return
        if (event.key === "a" && event.metaKey) {
            event.preventDefault()
            setTimeout(() => {
                isOpen ? onClose() : onOpen()
            }, 10);
        }
        return
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKey, false);
        return () => {
            document.removeEventListener("keydown", handleKey, false);
        };
    }, [isOpen])

    useEffect(() => {
        if(homeData == null) return
        const queryItems = homeData.filter((x) => x.itemType === "query")
        const qr = queryItems.map((x) => {
            const user_name = getUserById(workspace, x.author)?.name
            const date_string = formatTimeRecentISO(x.updated)
            let description = ""
            if(user_name == null){
                description = `Updated ${date_string}`
            }else{
                description = `Query by ${user_name}. Updated ${date_string}`
            }

            return {
                name: x.name,
                description,
                href: `/query/${x.type}/edit/${x.id}`,
                icon: "query",
                type: "query",
                state: null
            }
        })
        const labelItems = homeData.filter((x) => x.itemType === "label")
        const lr = labelItems.map((x) => {
            const user_name = getUserById(workspace, x.author)?.name
            const date_string = formatTimeRecentISO(x.updated)
            let description = ""
            if(user_name == null){
                description = `Updated ${date_string}`
            }else{
                description = `Label by ${user_name}. Updated ${date_string}`
            }

            return {
                name: x.name,
                description,
                href: `/labels/edit/${x.id}`,
                icon: "label",
                type: "label",
                state: null
            }
        })
        setResults([...qr, ...lr, ...SEARCHABLES])
        setBaseResults([...qr, ...lr, ...SEARCHABLES])
    }, [homeData])

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                inputRef.current.focus()
            }, 10);
            loadResults()
        } else {
            setQuery("")
            setTab(undefined)
        }
    }, [isOpen])

    useEffect(() => {
        loadResults()
    }, [tab])

    useEffect(() => {
        loadResults()
    }, [query])

    function loadResults() {
        if (query == null || query === "") {
            if (tab == null) {
                setResults([...baseResults])
                return
            }
            if (tab != null) {
                setResults([...baseResults.filter((x) => x.type === tab)])
                return
            }
        }

        if (tab != null) {
            const filtered = baseResults.filter(x => x.name.toLowerCase().includes(query.toLowerCase())).filter((x) => x.type === tab)
            setResults(filtered)
        } else {
            const filtered = baseResults.filter(x => x.name.toLowerCase().includes(query.toLowerCase()))
            setResults(filtered)
        }
    }

    // function filter(query) {
    //     if (query === "") {
    //         setResults(SEARCHABLES)
    //         return
    //     }
    //     const filtered = SEARCHABLES.filter(x => x.name.toLowerCase().includes(query.toLowerCase()))
    //     setResults(filtered)
    // }

    return <Modal isOpen={isOpen} onClose={onClose} noPadding>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{padding: "16px 16px 0 16px"}}>
            <div className={styles.inputWrap}>
                {/* <CustomInput className="" inputRef={inputRef} value={query} placeholder="Search..." onChange={(e) => setQuery(e.target.value)} /> */}
                <input ref={inputRef} value={query} placeholder="Search..." onChange={(e) => setQuery(e.target.value)} className={styles.input} />
                <UilSearch className={styles.inputIcon} />
                {/* <div className={styles.commandTab}>⌘A</div> */}
            </div>
            <div className={styles.actionBtnOuter}>
                <div className={styles.actionBtnsWrap}>
                    <button className={`${tab === "query" ? styles.selected : ""} ${styles.actionBtn}`} onClick={() => setTab("query")}>Queries</button>
                    <button className={`${tab === "label" ? styles.selected : ""} ${styles.actionBtn}`} onClick={() => setTab("label")}>Labels</button>
                    <button className={`${tab === "action" ? styles.selected : ""} ${styles.actionBtn}`} onClick={() => setTab("action")}>Actions</button>
                    <button className={`${tab === "page" ? styles.selected : ""} ${styles.actionBtn}`} onClick={() => setTab("page")}>Pages</button>
                </div>
                <div>
                    {tab !== undefined && <button className={styles.clearBtn} onClick={() => setTab(undefined)}><UilTimes /> Clear</button>}
                </div>
            </div>
            </div>
            <div className={styles.resultWrap}>
                {results.map((x, idx) => {
                    const icon = getIcon(x.icon)
                    return <Result key={idx} icon={icon} title={x.name} description={x.description} to={x.href} state={x.state} onClose={onClose} />
                })}
                {query !== "" && <Result icon={<UilSearch />} title={`Search for address ${smartTrim(query, 7)}`} description={"Open a single-wallet analysis"} to={`/users/${query}`} onClose={onClose} />}
            </div>
        </div>
    </Modal>
}


const CustomInput = styled(InputBase)(({ theme }) => {
    return {
        width: "100%",
        '& .MuiInputBase-input': {
            position: 'relative',
            fontSize: 14,
            height: "35px",
            paddingLeft: "48px",
            display: "flex",
            fontWeight: "350",
            alignItems: "center",
            // borderBottom: "1px solid var(--border-00)",
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            '&:focus': {
            },
            "&::placeholder": {
                color: "var(--input-placeholder)",
                opacity: 1,
            }
        },
    }
})

function Result({ icon, title, description, to, state, onClose }) {

    const onClick = (e) => {
        onClose()
    }

    return <Link to={to} state={state} className={styles.result} onClick={onClick}>
        <div className={styles.resultInner}>
            <div className={styles.resultIcon}>
                {icon}
            </div>
            <div className={styles.resultText}>
                <div className={styles.resultTitle}>{title}</div>
                <div className={styles.resultDescription}>{description}</div>
            </div>
        </div>
    </Link>
}

function getIcon(iconStr) {
    if (iconStr === "page") { return <UilFile /> }
    if (iconStr === "plus") { return <UilPlusCircle /> }
    if (iconStr === "settings") { return <UilSetting /> }
    if (iconStr === "query") { return <UilChartPie /> }
    if (iconStr === "label") { return <UilUsersAlt /> }
}