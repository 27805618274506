import { formatNumber, formatNumberAuto } from "helpers";
import Highcharts from "highcharts"
import HighchartSankey from "highcharts/modules/sankey";
import HighchartsReact from "highcharts-react-official";
import HC_patternFill from "highcharts-pattern-fill";
import moment from "moment";
import styles from "./MiniChart.module.css";
import numeral from "numeral";

HC_patternFill(Highcharts);
HighchartSankey(Highcharts);

const DEFAULT_X_AXIS = {
    type: "datetime",
    labels: {
        style: {
            color: "rgb(var(--alpha) / 60%)",
            fontSize: "10px",
            fontFamily: 'var(--main-font)',
        }
    },
    crosshair: {
        color: "rgb(var(--alpha) / 25%)",
    },
    gridLineWidth: 0,
    // gridLineColor: "var(--grid-line-color)",
    lineColor: "var(--grid-line-color)",
    zoomEnabled: false,
    panningEnabled: false,
}

const DEFAULT_Y_AXIS = {
    labels: {
        enabled: true,
        formatter: function () {
            return numeral(this.value).format("0.[00]a");
        },
        style: {
            color: "rgb(255 255 255 / 50%)",
            fontSize: "11px",
            fontFamily: 'var(--main-font)',
        },
    },
    title: {
        enabled: false,
    },
    gridLineColor: "var(--grid-line-color)",
    gridLineWidth: 1,
    offset: 0,
    minPadding: 0.1,
    maxPadding: 0.1,
    endOnTick: false,
    startOnTick: false,
}

export function WidgetBarChart({ data, color, name, formatOptions }) {

    function getCurrentOptions() {
        const options = {
            chart: {
                animation: false,
                type: "column",
                backgroundColor: "rgb(255,255,255,0)",
            },
            title: { text: null },
            legend: { enabled: false },
            credits: { enabled: false },
            series: [{
                data: data?.map((v) => [moment(v[0]).unix() * 1000, v[1]]),
                // color,
            }],
            plotOptions: {
                series: {
                    animation: false,
                    borderRadius: 1,
                    borderWidth: 0,
                    color,
                }
            },
            yAxis: DEFAULT_Y_AXIS,
            xAxis: DEFAULT_X_AXIS,
            useHTML: true,
            tooltip: {
                outside: true,
                useHTML: true,
                hideDelay: 0,
                borderColor: 'transparent',
                backgroundColor: 'var(--sdp02)',
                borderRadius: 8,
                borderWidth: 0,
                padding: 0,
                shadow: false,
                crosshairs: true,
                followPointer: true,
                shared: true,
                style: {
                    fontFamily: 'var(--main-font)',
                },
                formatter: function () {
                    const point = this.points[0]
                    const timestampRaw = point.x;
                    const date = new Date(timestampRaw);
                    const fmtDate = moment(date).format("MMM D, YYYY");
                    const timeText = fmtDate
                    const isLast = point.index === point.series.points.length - 1
                    const pointFormatted = formatNumber({
                        number: point.y,
                        type: formatOptions?.numberFormat,
                        abbreviate: formatOptions?.abbreviate,
                        decimals: formatOptions?.decimals
                    });

                    return `<div class="${styles.normalTooltipWrap}"><div class="${styles.tooltipValueWrap}">${timeText}</div>
                        <div class="${styles.normalInfoWrap}"><div class="${styles.dot}" style="background-color: ${color}"></div><div class="${styles.tooltipText}">${name}: <span class="${styles.tooltipValue}">${pointFormatted}${isLast ? "*" : ""}</span></div></div>
                    </div>`

                },
            }
        }

        return options;
    }

    const options = getCurrentOptions();

    return <HighchartsReact
        highcharts={Highcharts}
        options={options}
        constructorType={"chart"}
    />

}