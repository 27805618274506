import styled from '@emotion/styled';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
import styles from "./LoadingButton.module.css"

export function CustomLoadingButton({ onClick, className, colorVariant, disabled = false, loading, children, buttonType, style }) {

    return <ExtendedLoadingButton
        loading={loading}
        colorVariant={colorVariant}
        className={className}
        disableRipple={true}
        type={buttonType}
        onClick={onClick}
        disabled={disabled || loading}
        style={style}
    >
        {children}
    </ExtendedLoadingButton>
}


export function LoadingButtonInline({ loading, onClick, children, className, color }) {

    if (loading) {
        return <div className={`${styles.loadingButtonInlineLoader} ${className}`}>
            <CircularProgress sx={{ color: color }} size="1rem" />
        </div>
    }


    return <button
        disabled={loading}
        onClick={() => onClick && onClick()}
        className={`${styles.loadingButtonInline} ${className}`}
    >
        {children}
    </button>
}

const ExtendedLoadingButton = styled(LoadingButton, {
    shouldForwardProp: (prop) => !['colorVariant'].includes(prop),
})(({ theme, colorVariant }) => {
    let textColor = "white"
    let backgroundColor = "rgb(var(--primary))"
    let highlightColor = "rgb(var(--primary) / 80%)"
    let border = "none"


    if(colorVariant === "outline"){
        textColor = "white"
        backgroundColor = "transparent"
        highlightColor = "rgb(var(--alpha) / 3.5%)"
        border = "1px solid var(--border-00)"
    }

    if(colorVariant === "destructive"){
        backgroundColor = "rgb(var(--alpha-red) / 10%)"
        textColor = "rgb(var(--alpha-red))"
        highlightColor = "rgb(var(--alpha-red) / 8%)"
        border = "1px solid rgb(var(--alpha-red))"
    }

    if(colorVariant === "opacity"){
        textColor = "rgb(var(--primary))"
        backgroundColor = "rgb(var(--primary) / 10%)"
        highlightColor = "rgb(var(--primary) / 8%)"
    }

    return {
        textTransform: "none",
        background: backgroundColor,
        padding: "4px 12px",
        fontSize: "13px",
        color: textColor,
        boxShadow: "none",
        border: border,
        // height: "32px",
        height: "unset",
        ':hover': {
            background: highlightColor,
            boxShadow: "none"
        },
        '&.Mui-disabled': {
            color: 'rgb(255 255 255 / 65%)',
        },
        '&.MuiLoadingButton-loading.Mui-disabled': {
            color: 'transparent'
        },
        ' .MuiLoadingButton-loadingIndicator': {
            color: textColor
        },
        // ' :disabled': {
        //     color: 'rgba(255, 255, 255, 0.58)',
        //     // opacity: 0.5
        // },
        ' .MuiLoadingButton-loading': {
            color: "transparent"
        }

        // rgba(255, 255, 255, 0.58)
    }
})

export function SidebarLinkLodaing({ icon = undefined, loading, disabled, selected, text }) {

    return <div className={styles.sideLinkWrap}>
        <LoadingButton className={`${styles.sideLink} ${selected ? styles.selected : ""}`}>
            <div className={styles.sideLinkIcon}>
                {icon}
            </div>
            <div className={styles.sideLinkText}>
                {text}
            </div>
        </LoadingButton>
    </div>
}
