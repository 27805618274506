import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { defaultRender } from 'utils/rows'
import { memo } from 'react'

const columnHelper = createColumnHelper()

function UnmemoMiniTable({ data: iData, columns: iColumns, classes }) {
    const [data, setData] = useState(iData)


    useEffect(() => {
        if(!iData) return setData(null)
        const data = iData?.map((row, index) => ({ ...row, id: index }))
        setData(data)
    }, [iData])

    const columns = iColumns?.map(column => {
        const { key, title, renderCell = defaultRender } = column
        return columnHelper.accessor(key, {
            id: key,
            header: () => title,
            cell: info => renderCell({ value: info.getValue(), data: info.row.original }),
            footer: info => info.column.id,
        })
    })


    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    })

    if(data == null) return


    const { table: tableClass, thead: theadClass, theadCell: theadCellClass, tbody: tbodyClass, tbodyRow: tbodyRowClass, tbodyCell: tbodyCellClass } = classes || {}

    return <table className={tableClass}>
        <thead className={theadClass}>
            {table.getHeaderGroups().map((headerGroup, idx) => (
                <tr key={idx}>
                    {headerGroup.headers.map((header, idx) => (
                        <th key={idx} className={theadCellClass}>
                            {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
        <tbody className={tbodyClass}>
            {table.getRowModel().rows.map((row,idx) => (
                <tr key={idx} className={tbodyRowClass}>
                    {row.getVisibleCells().map((cell, idx) => (
                        <td key={idx} className={tbodyCellClass}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>
}

export const MiniTable = memo(UnmemoMiniTable)