import styles from "./RetentionQuery.module.css"
import { Card } from "components/Card/Card"
import { useEffect, useRef, useState } from "react"
import { CONDITION_OPTIONS, EVENT_CONDITION_OPTIONS_V2, QUERY_TYPES, standardSections } from "Constants"
import { CircularProgress } from "@mui/material"
import { useGetEndpoint, usePostEndpoint, useQueryFunctions } from "ApiConnector"
import { formatNumber, getMinFromInterval, compareObjectsRegardlessOfKeyOrder } from "helpers"
import { NormalChart, TimeseriesChart } from "components/Chart/Chart"
import { Button } from "components/Button/Button"
import { CustomLoadingButton } from "components/LoadingButton/LoadingButton"
import { useNavigate, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useGlobal } from "hooks/global"
import { deleteQuery, getQuery, saveQuery } from "helpers/query"
import { Modal } from "components/Modal/Modal"
import PageLoading from "components/PageLoading/PageLoading"
import { Menu } from "components/Menu/Menu"
import { toast } from "react-hot-toast"
import { TopBar } from "components/TopBar/TopBar"
import { Content } from "components/Content/Content"
import moment from "moment"
import { CardV2 } from "components/CardV2/CardV2"
import { InputButton } from "components/InputButton/InputButton"
import { SelectV2 } from "components/SelectV2/SelectV2"
import { AutoAwesomeRounded, ElectricBoltRounded, ExpandMoreOutlined, LayersRounded, Search } from "@mui/icons-material"
import { IntegratedDateSelector } from "components/IntegratedDateFormatter/IntegratedDateSelector"
import { Filters } from "components/Filters/Filters"
import { ReactComponent as Empty } from 'assets/empty/retention.svg';
import { ClassicSaveModal } from "components/ClassicSave/ClassicSaveModal"
import { DeleteModal } from "components/DeleteModal/DeleteModal"
import { DropdownFreeform } from "components/DropdownFreeform/DropdownFreeform"
import { UilFilterSlash } from "@iconscout/react-unicons"
import { getRequestFilters, getStoredFilters } from "utils/filter"

export function RetentionQuery() {
    const { action, query } = useParams();
    const { invalidate } = useQueryFunctions()
    const [queryData, setQueryData] = useState(undefined)
    const [loadingSave, setLoadingSave] = useState(action === "edit")
    const [originalRequestOptions, setOriginalRequestOptions] = useState(undefined)
    const [saving, setSaving] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [saveModal, setSaveModal] = useState(false)

    const [events, setEvents] = useState([null, null])
    const [date, setDate] = useState({ mode: "period", period: FALLBACK_PERIOD, min: null, max: null })
    const [granularity, setGranularity] = useState("mo")
    const [filters, setFilters] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        if (action !== "edit") return
        if (events[0] != null || events[1] != null || filters.length !== 0) return
        async function f() {
            setLoadingSave(true)
            const queryData = await getQuery(query)
            const queryInnerData = queryData.data
            const { date: d, granularity: g, events: e, filters: f } = queryInnerData

            setDate(d)
            setGranularity(g)
            setEvents(e)
            setFilters(f)

            const oro = getRequestOptions(e, f, d, g)
            setQueryData(queryData)
            setOriginalRequestOptions(oro)
            setLoadingSave(false)
        }
        f()
    }, [action, query])

    async function save({ name, description }) {
        if (name === "") return
        const store = {
            events,
            filters: getStoredFilters(filters),
            date,
            granularity,
        }
        if (action === "create") {
            setSaveModal(false)
            setSaving(true)
            const id = await saveQuery({
                name,
                description,
                type: "retention",
                created: new Date(),
                updated: new Date(),
                data: store,
            })
            invalidate("general/home")
            navigate(`/query/retention/edit/${id}`)
            setSaving(false)
            setQueryData({ id, name, description, data: store })
        }
        if (action === "edit") {
            const { id } = queryData
            if (id == null) return
            setSaveModal(false)
            setSaving(true)
            await saveQuery({
                id,
                updated: new Date(),
                name,
                description,
                data: store,
            })
            invalidate("general/home")
            setSaving(false)
            setQueryData({ ...queryData, name, description, data: store })
        }
        const oro = getRequestOptions(events, filters, date, granularity)
        setOriginalRequestOptions(oro)
    }

    async function deleteChart() {
        const promise = new Promise(async (resolve, reject) => {
            try {
                const { id } = queryData
                await deleteQuery(id)
                invalidate("general/home")
                navigate("/")
                resolve()
            } catch (e) {
                reject()
            }
        })

        toast.promise(promise, {
            loading: "Deleting chart...",
            error: "There was an error deleting this chart",
            success: "Successfully deleted chart"
        })

    }

    const isValid = isRequestValid(events, filters, date, granularity)
    const requestOptions = getRequestOptions(events, filters, date, granularity)
    const { data } = usePostEndpoint({
        endpoint: "query/retention", body: requestOptions, skip: !isValid
    })

    const dirty = action === "create" ? true : !compareObjectsRegardlessOfKeyOrder(originalRequestOptions, requestOptions)

    if (action === "edit" && loadingSave) {
        return <PageLoading />
    }

    const { name, description } = queryData || {}

    return <>
        <ClassicSaveModal
            title={action === "create" ? "Save Chart" : "Edit Chart"}
            isOpen={saveModal}
            onClose={() => setSaveModal(false)}
            onSave={save}
            existing={{ name, description }}
        />
        <DeleteModal
            isOpen={deleteModal}
            onClose={() => setDeleteModal(false)}
            onDelete={deleteChart}
        />
        <Content flex>
            <TopBarWrap
                type={"retention"}
                action={action}
                dirty={dirty}
                saving={saving}
                onSaveClicked={() => {
                    if (action === "create") {
                        setSaveModal(true)
                        return
                    }
                    save({ name, description })
                }}
                onDeleteClicked={() => setDeleteModal(true)}
                onEditClicked={() => setSaveModal(true)}
            />
            <div className={styles.topContent}>
                <CardV2 className={styles.queryCard}>
                    <Events
                        events={events}
                        setEvents={setEvents}
                    />
                    <div className={styles.title}>Filters</div>
                    <Filters
                        filters={filters}
                        setFilters={setFilters}
                        excludeResources={["user"]}
                        // excludeTemplates={["wallet_address", "contract_address", "event_type", "chain"]}
                        templates={EVENT_CONDITION_OPTIONS_V2.filter((x) => ["wallet_address", "contract_address", "event_type", "chain"].includes(x.id))}
                    />
                </CardV2>
                <RetentionResults
                    data={data}
                    date={date}
                    setDate={setDate}
                    isValid={isValid}
                    setGranularity={setGranularity}
                    granularity={granularity}
                />
            </div>
            <RetentionTable
                data={data}
                isValid={isValid}
                granularity={granularity}
            />

        </Content>
    </>
}

const staticEvents = [
    {
        id: {
            type: "automatic",
            id: "ANY_EVENT"
        },
        name: "Any event",
        icon: AutoAwesomeRounded,
    },
    {
        id: {
            type: "automatic",
            id: "ANY_ONCHAIN"
        },
        name: "Any on-chain event",
        icon: AutoAwesomeRounded,
    },
    {
        id: {
            type: "automatic",
            id: "ANY_OFFCHAIN"
        },
        name: "Any off-chain event",
        icon: AutoAwesomeRounded,
    },
]

function Events({ events, setEvents }) {

    const [activeEvent, setActiveEvent] = useState({ isActive: false, el: null, value: null, index: null })
    const { data: eventData } = useGetEndpoint("general/singleResource", { resource: "events" })

    function onEventChanged({ index, update }) {
        if (events.length === 0) {
            setEvents([{ ...update }, {}])
            return
        }
        let newEvents = [...events]
        newEvents[index] = { ...newEvents[index], ...update }
        // if newEvents doesnt have a blank event at the end, add one
        if (newEvents[newEvents.length - 1].id != null) newEvents = [...newEvents, {}]
        setEvents(newEvents)
    }

    function onEventDelete(index) {
        const newEvents = events.filter((_, idx) => idx !== index)
        setEvents(newEvents)
    }

    function addBlankEvent() {
        if (events.length === 0) {
            setEvents([{}, {}])
            return
        }
        setEvents([...events, {}])
    }

    function duplicateEvent(index) {
        if (events.length === 0) {

        }
        const newEvents = [...events, { ...events[index] }]
        if (newEvents.length === 1) {

        }
        setEvents(newEvents)
    }

    const allEvents = [...eventData?.map(e => ({
        name: e.event_text,
        id: e.event,
        type: "custom",
    })) ?? []]

    const { data: methodData } = usePostEndpoint({ endpoint: "methods" })
    const allMethods = [...methodData?.map(e => ({
        name: e.name,
        id: e.id,
        type: "template",
    })) ?? []]

    const eventOptions = allEvents?.map((e) => {
        const { id, name, type } = e
        const idOption = { type: "custom", id }
        return { id: idOption, name, icon: LayersRounded, section: type }
    }) ?? []

    const methodOptions = allMethods?.map((e) => {
        const { id, name, type } = e
        const idOption = { type: "template", id }
        return { id: idOption, name, icon: ElectricBoltRounded, section: type }
    }) ?? []

    let staticEventsFn = [...staticEvents]
    if (eventData == null || eventData?.length === 0) {
        staticEventsFn = staticEventsFn.filter((x) => x.id.id !== "ANY_ONCHAIN" && x.id.id !== "ANY_OFFCHAIN")
    }
    const allOptions = [...staticEventsFn, ...eventOptions, ...methodOptions]

    const eventOneEl = useRef(null)
    const eventTwoEl = useRef(null)

    const eventOne = events?.[0]
    const eventTwo = events?.[1]

    const eventOneEmpty = eventOne == null || eventOne.id == null
    const eventTwoEmpty = eventTwo == null || eventTwo.id == null

    const eventOneText = eventOneEmpty ? "Starting event..." : eventOne.name
    const eventTwoText = eventTwoEmpty ? "Returning event..." : eventTwo.name

    return <>
        {/* <Dropdown
            anchorEl={activeEvent.el}
            activeValue={activeEvent.value}
            isLoadingAsyncOptions={eventData == null}
            isOpen={activeEvent.isActive}
            onClose={() => setActiveEvent({ isActive: false, index: null, value: null, el: null })}
            options={allOptions}
            // sections={eventSections}
            onChange={(val) => {

                const { id } = val
                if (activeEvent.index === 0) {
                    setEvents([allEvents.find((e) => e.id === id), eventTwo])
                }
                if (activeEvent.index === 1) {
                    setEvents([eventOne, allEvents.find((e) => e.id === id)])
                }
            }}
        /> */}
        <DropdownFreeform
            anchorEl={activeEvent.el}
            isLoadingAsyncOptions={eventData == null || methodData == null}
            isOpen={activeEvent.isActive}
            onClose={() => setActiveEvent({ isActive: false, index: null, value: null, el: null })}
            options={allOptions}
            sections={standardSections}
            onChange={(val) => {
                // const { section } = val
                if (activeEvent.index === 0) {
                    setEvents([{ ...val }, eventTwo])
                }
                if (activeEvent.index === 1) {
                    setEvents([eventOne, { ...val }])
                }
                // if (section === "custom") {
                //     // const fullObj = allEvents.find((e) => e.id === id)
                //     onEventChanged({
                //         index: activeEvent.index, update: val
                //     })
                // }
                // if (section === "freeform") {
                //     onEventChanged({
                //         index: activeEvent.index, update: val
                //     })

                // }
                // if (section === "template") {
                //     // const fullObj = allMethods.find((e) => e.id === id)
                //     onEventChanged({
                //         index: activeEvent.index, update: val
                //     })
                // }
            }}
        />
        <div className={styles.title}>Starting event</div>
        <InputButton
            buttonText={eventOneText}
            empty={eventOneEmpty}
            pref={eventOneEl}
            onOpen={() => setActiveEvent({ isActive: true, el: eventOneEl, value: eventOne, index: 0 })}
            fill
            sx={{ marginBottom: 16 }}
        />
        <div className={styles.title}>Returning event</div>
        <InputButton
            buttonText={eventTwoText}
            empty={eventTwoEmpty}
            pref={eventTwoEl}
            onOpen={() => setActiveEvent({ isActive: true, el: eventTwoEl, value: eventTwo, index: 1 })}
            fill
            sx={{ marginBottom: 16 }}
        />

    </>
}

function RetentionResults({ data, isValid, date, setDate, granularity: gid, setGranularity }) {

    const { mode: dateMode, min: activeDateMininmumISO, max: activeDateMaximumISO } = date
    const dateValue = [activeDateMininmumISO, activeDateMaximumISO]

    function onDateChangeFreeform({ mode, min, max }) {
        const minTs = min ? moment(min).toISOString() : null
        const maxTs = max ? moment(max).toISOString() : null
        setDate({ mode, min: minTs, max: maxTs })
    }

    const granularity = retentionGranularities.find((x) => x.id === gid)
    const validPeriods = granularity.periods

    function onGranularityChange(x) {
        const fullGran = retentionGranularities.find((y) => y.id === x)
        const { id, periods } = fullGran
        setGranularity(id)
        const periodToChange = periods[1].id
        setDate({ mode: "period", period: periodToChange, min: null, max: null })
    }

    function onPeriodSelect(period) {
        // onDateChange("period", period.id, null, null)
        setDate({ mode: "period", period: period.id, min: null, max: null })
    }

    const loading = data === undefined

    let top = <div className={styles.top}>
        <div></div>
        <div className={styles.right}>
            <div>
                {validPeriods.map((x, idx) => {
                    const selected = date.mode === "period" && date.period === x.id
                    return <button key={idx} className={`${styles.period} ${selected && styles.selected}`} onClick={() => onPeriodSelect(x)}>{x.name}</button>
                })}
            </div>
            <IntegratedDateSelector
                mode={dateMode}
                rawDate={date}
                date={dateValue}
                onChange={onDateChangeFreeform}
                classes={{ wrap: styles.integrated }}
            />
            <SelectV2
                classes={{
                    wrap: styles.periodSelectWrap,
                    select: styles.periodSelect,
                    menuWrap: styles.periodMenu
                }}
                placeholder="Granularity..."
                options={retentionGranularities}
                option={granularity.id}
                onChange={(x) => onGranularityChange(x)}
            />
        </div>
    </div>

    if (!isValid) {
        return <CardV2 noPadding>
            <div className={styles.graphWrap}>
                <div className={styles.noResultsWrap}>
                    <div className={styles.noResultsWrapInner}>
                        <div className={styles.emptyIcon}>
                            <Empty />
                        </div>
                        <div className={styles.title}>Select a metric to get started</div>
                        <div className={styles.description}>In order to see results, you must select a starting event and a returning event.</div>
                    </div>
                </div>
            </div>
        </CardV2>

    }

    if (loading) {
        return <CardV2 noPadding>
            {top}
            <div className={styles.graphWrap}>
                <div className={styles.noResultsWrap}>
                    <div className={styles.noResultsWrapInner} style={{ marginBottom: 50 }}>
                        <CircularProgress sx={{ color: "var(--alpha-text)" }} />
                    </div>
                </div>
            </div>
        </CardV2>
    }

    if (data.length === 0) {
        return <CardV2 noPadding>
            {top}
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner} style={{ marginBottom: 50 }}>
                    <div className={styles.notFoundIcon}>
                        <UilFilterSlash />
                    </div>
                    <div className={styles.title}>No events found</div>
                    <div className={styles.description}>Please change the parameters to see results</div>
                </div>
            </div>
        </CardV2>
    }

    return <CardV2 noPadding>
        {top}
        <div className={styles.graphWrap}>
            <RetentionGraphWrap
                data={data}
            />
        </div>
    </CardV2>
}

function averageArrays(data) {
    const transposed = transpose(data)
    const averaged = transposed.map((x) => {
        const total = x.reduce((acc, curr) => acc + curr.total, 0)
        const users = x.reduce((acc, curr) => acc + curr.users, 0)
        return { users, total }
    })

    return averaged
}

function transpose(data) {
    const arr = data.map((x) => x.retention)
    const indexedTotal = data.reduce((acc, curr, index) => {
        acc[index] = curr.count
        return acc
    }, {})

    let transposed = []
    for (let i = 0; i < arr[0].length; i++) {
        let row = []
        for (let j = 0; j < arr.length; j++) {
            if (arr[j][i] === undefined) continue
            row.push({
                users: arr[j][i],
                total: indexedTotal[j]
            })
        }
        transposed.push(row)
    }

    return transposed
}

function RetentionTable({ data, isValid, granularity: gid }) {

    if (!isValid) return

    const granularity = retentionGranularities.find((x) => x.id === gid)
    const dateFormat = granularity.formatDate

    const loading = data === undefined

    // let top = <div className={styles.top}>
    // </div>

    // if (loading) {
    //     return <Card noPadding>
    //         {top}
    //         <div className={styles.noResultsWrap}>
    //             <div className={styles.noResultsWrapInner}>
    //                 <CircularProgress sx={{ color: "var(--alpha-text)" }} />
    //             </div>
    //         </div>
    //     </Card>
    // }
    if (data == null) return
    if (data.length === 0) return

    const allUsers = averageArrays(data)
    const sumOfIndex0 = data.reduce((a, b) => a + b.count, 0)

    return <Card noPadding>
        <div className={styles.resultsWrapOuter}>
            <HeaderColumn rows={data} allUserCount={sumOfIndex0} dateFormat={dateFormat} />
            <div className={styles.resultsWrap}>
                <TimeRow row={data[0].retention} granularity={granularity} />
                <AverageRow values={allUsers} />
                {data.map((row, idx) =>
                    <RetentionRow key={idx} values={row.retention} count={data.length} total={row.count} />
                )}
            </div>
        </div>
    </Card>
}

function HeaderColumn({ rows, allUserCount, dateFormat }) {

    return <div className={styles.headerColumn}>
        <div className={styles.headerColumnRow}>
            <div className={`${styles.periodCell} ${styles.header}`}>Cohort</div>
            <div className={`${styles.periodCell} ${styles.header}`}>Total</div>
        </div>
        <div className={styles.headerColumnRow}>
            <div className={`${styles.periodCell} ${styles.header}`} style={{ fontWeight: "600" }}>Average</div>
            <div className={`${styles.periodCell} ${styles.header}`} style={{ fontWeight: "600" }}>{formatNumber({ number: allUserCount, type: "decimal", abbreviate: true, decimals: 2 })}</div>
        </div>
        {rows.map((row, idx) => {
            const { time, count } = row
            const date = moment(time)
            // const fmted = formatDate(date, dateFormat)
            const fmted = date.format("MMM D, YYYY")

            return <div key={idx} className={styles.headerColumnRow}>
                <div className={`${styles.periodCell} ${styles.header}`}>{fmted}</div>
                <div className={`${styles.periodCell} ${styles.header}`}>{formatNumber({ number: count, type: "integer", abbreviate: true, decimals: 2 })}</div>
            </div>
        })}
    </div>
}

function TimeRow({ row, granularity }) {
    return <div className={styles.retentionRowWrap}>
        {row.map((_, idx) => {
            return <div key={idx} className={`${styles.periodCell} ${styles.topHeader}`}>{granularity.title} {idx + 1}</div>
        })}
    </div>
}

function AverageRow({ values }) {
    return <div className={styles.retentionRowWrap}>
        {values.map((obj, idx) => {
            const { total, users } = obj

            if (total === 0) {
                return <div className={styles.periodCell}>
                    <div className={styles.periodName}>-</div>
                </div>
            }

            const value = users / total
            const percentage = value * 100
            const color = "rgb(var(--primary) / " + percentage + "%)"
            const percentageFormatted = formatNumber({ number: value, type: "percent", decimals: 1 })
            return <div className={styles.periodCell} style={{ backgroundColor: color }}>
                <div className={styles.periodName}>{percentageFormatted}</div>
            </div>
        })}
    </div>
}

function RetentionRow({ values, count, total, isAverage = false }) {
    const blankCellCount = count - values.length
    return <div className={styles.retentionRowWrap}>
        {values.map((cohortUserCount, idx) => (
            <PeriodCell key={idx} userCount={cohortUserCount} totalUsers={total} isLast={!isAverage && idx === values.length - 1} />
        ))}
        {blankCellCount >= 0 && Array(blankCellCount).fill(null).map((_, idx) => (
            <div className={styles.periodCellBlank} key={idx}></div>
        ))}
    </div>
}

function PeriodCell({ userCount, totalUsers, isLast }) {

    if (userCount === null) {
        return <div className={styles.periodCell}>
            <div className={styles.periodName}>-</div>
        </div>
    }


    const ratio = userCount === 0 ? 0 : userCount / totalUsers
    let percentage = ratio * 100
    if (percentage === 0) { percentage = 1 }

    // round percentage down to nearest 2%
    percentage = Math.floor(percentage / 2) * 2

    const color = "rgb(var(--primary) / " + percentage + "%)"
    const percentageFormatted = formatNumber({ number: ratio, type: "percent", decimals: 1 })

    let style = {
        backgroundColor: color,
    }


    if (isLast) {
        style = {
            // backgroundImage: `linear-gradient(45deg, ${stripeOne} 17.86%, ${stripeTwo} 17.86%, ${stripeTwo} 50%, ${stripeOne} 50%, ${stripeOne} 67.86%, ${stripeTwo} 67.86%, ${stripeTwo} 100%)`,
            // backgroundSize: "10px 10px",
            color: "rgb(255 255 255 / 50%)",
            fontWeight: "400",
        }
    }

    return <div className={`${styles.periodCell} ${isLast && styles.periodCellBlank}`} style={style}>
        <div className={styles.periodName}>{isLast && "*"}{percentageFormatted}</div>
    </div>
}


const includedConditions = ["wallet_address", "contract_address", "event_type", "contract_method", "chain", "label"]
const desiredOrder = ["contract_address", "event_type", "contract_method", "label", "chain", "wallet_address"]
const RETENTION_CONDITION_OPTIONS = CONDITION_OPTIONS.filter((x) => includedConditions.includes(x.id)).sort((a, b) => desiredOrder.indexOf(a.id) - desiredOrder.indexOf(b.id))

function isRequestValid(events, filters, date, granularity) {
    // if some event is null, return false
    if (events.some((x) => x == null)) { return false }
    return true
}

function getRequestOptions(events, filters, date, granularity) {
    return {
        events: events.map((x) => x?.id),
        filters: getRequestFilters(filters),
        date: getDateParams(date),
        granularity,
    }
}

function getDateParams(date) {
    const { mode, period, min, max } = date

    if (mode === "period") {
        const minFromIntervalUnroundedISO = getMinFromInterval(period)
        //load into moment, and get the start of the hour
        const minFromIntervalISO = moment(minFromIntervalUnroundedISO).startOf("hour").toISOString()
        return {
            minimum: minFromIntervalISO
        }
    }
    return {
        minimum: min,
        maximum: max
    }

}

function RetentionGraphWrap({ data }) {

    const ts = data.map((x) => x.time)
    const allUsers = averageArrays(data)
    const graphData = ts.map((x, idx) => {
        const unixTs = moment(x).unix()
        const { users, total } = allUsers[idx] || {}
        if (total === 0) { return [unixTs * 1000, 0] }
        // if (allUsers[idx] === 0) { return [unixTs * 1000, 0] }
        // const percentage = allUsers[idx] / allUsers[0]
        const percentage = users / total
        return [unixTs * 1000, percentage]
    })

    return <div className={styles.graphWrap}>
        <NormalChart
            data={[{
                id: "retention",
                values: graphData
            }]}
            constants={{
                retention: {
                    name: "Retention",
                    overrideMinimum: 0,
                    formatOptions: {
                        numberFormat: "percent",
                        decimals: 2
                    },
                }
            }}
        />
    </div>
}

function TopBarWrap({ type, action, dirty, saving, onSaveClicked, onDeleteClicked, onEditClicked }) {

    const [optionsOpen, setOptionsOpen] = useState(false)
    const openOptions = () => setOptionsOpen(true)
    const closeOptions = () => setOptionsOpen(false)
    const optionsEl = useRef(null)

    return <>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            {action === "edit" && <>
                <Button buttonRef={optionsEl} onClick={openOptions} className={styles.outlineBtn} colorVariant="outline">Options</Button>
                <Menu
                    open={optionsOpen}
                    onClose={closeOptions}
                    anchorEl={optionsEl?.current}
                    options={[
                        { onClick: () => onEditClicked(), text: "Edit" },
                        { onClick: () => onDeleteClicked(), text: "Delete" }
                    ]}
                />
            </>}
            <CustomLoadingButton disabled={!dirty} loading={saving} className={styles.addBtn} onClick={onSaveClicked}>
                Save
            </CustomLoadingButton>
        </div>
    </>
}


const retentionGranularities = [
    {
        id: "d",
        name: "Daily",
        formatDate: "MMM D YYYY",
        periods: [
            { id: "7d", name: "7D", days: 7 },
            { id: "14d", name: "14D", days: 14 },
            { id: "30d", name: "30D", days: 30 },
            { id: "90d", name: "90D", days: 90 },
        ],
    },
    {
        id: "w",
        name: "Weekly",
        formatDate: "MMM D YYYY",
        periods: [
            { id: "3mo", name: "3M", days: 90 },
            { id: "6mo", name: "6M", days: 180 },
            { id: "1y", name: "1Y", days: 365 },
            { id: "2y", name: "2Y", days: 730 },
        ]
    },
    {
        id: "mo",
        name: "Monthly",
        formatDate: "MMM YYYY",
        periods: [
            { id: "1y", name: "1Y", days: 365 },
            { id: "2y", name: "2Y", days: 730 },
            { id: "3y", name: "3Y", days: 1095 },
            { id: "5y", name: "5Y", days: 1825 },
        ]
    },
]

const DEFAULT_MINIMUM = moment().subtract(1, "year").toISOString()
const DEFAULT_MAXIMUM = moment().toISOString()
const FALLBACK_GRANULARITY = "mo"
const FALLBACK_PERIOD = retentionGranularities.find((x) => x.id === FALLBACK_GRANULARITY).periods[1].id
