import styled from "@emotion/styled";
import { Fade, Tooltip as MuiTooltip } from "@mui/material";
import { useGlobal } from "hooks/global";
import styles from "./Tooltip.module.css"

export function Tooltip({ children, text, placement = "top", extraStyles = {} }) {

    const { theme } = useGlobal();

    let placementStyles = {}
    if (placement === "top") {
        placementStyles = { marginBottom: "10px" }
    } else if (placement === "bottom") {
        placementStyles = { marginTop: "10px" }
    }

    if (text === null) {
        return <ExtendedTooltip
            componentsProps={{
                tooltip: {
                    style: { top: 0, ...placementStyles, ...extraStyles },
                }
            }}
            TransitionComponent={Fade}
            disableInteractive
            title={null}
            placement={placement}
            arrow={true}
            classes={{
                tooltip: `${styles.customTooltip} ${theme === "light" ? styles.light : ""}`,
                arrow: styles.customTooltipArrow,
            }}
        >
            {children}
        </ExtendedTooltip>
    }

    return <ExtendedTooltip
        componentsProps={{
            tooltip: {
                style: { top: 0, ...placementStyles, ...extraStyles }
            }
        }}
        TransitionComponent={Fade}
        disableInteractive
        title={
            <div className={styles.tooltipWrap}>
                <div className={styles.tooltipInner}>{text}</div>
            </div>
        }
        placement={placement}
        // sx={{ top: 0  }}
        arrow={true}
        classes={{
            tooltip: `${styles.customTooltip} ${theme === "light" ? styles.light : ""}`,
            arrow: styles.customTooltipArrow,
        }}

    >
        {children}
    </ExtendedTooltip>
}

const ExtendedTooltip = styled(MuiTooltip)({
})