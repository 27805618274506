import { DatePickerDropdown } from "components/DatePickerV2/DatePickerV2"
import { InputButton } from "components/InputButton/InputButton"
import { FilterDate } from "models/date"
import { useRef, useState } from "react"
import { formatDateString } from "utils/date"

function isValidMode(d) {
    const {mode} = d
    if(mode === "since" || mode === "between" || mode === "last") return true
    return false
}

export function DateSelector({ date, onChange, icon, placeholder = "Date...", arrow = true, dimPlaceholder = true }) {
    const el = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const empty = date == null || !isValidMode(date)
    const dateText = empty ? placeholder : formatDateString(date)

    return <>
        <DatePickerDropdown
            anchorEl={el}
            isOpen={isOpen}
            value={isValidMode(date) ? date : undefined}
            onClose={() => setIsOpen(false)}
            onChange={(val) => {
                const valDate = new FilterDate(val)
                onChange(valDate)
            }}
        />
        <InputButton
            pref={el}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
            empty={empty && dimPlaceholder}
            buttonText={dateText}
            icon={icon}
            arrow={arrow}
            fill={false}
        />
    </>
}