import styled from "@emotion/styled";
import { DataGrid, GridRow, GridCellModesModel, GridCellModes } from "@mui/x-data-grid";
import { LoadingBody } from "components/LoadingBody/LoadingBody";
import { useGlobal } from "hooks/global";
import React, { useState, useCallback } from "react";
import styles from "./Table.module.css"
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { formatDate, formatNumber, formatNumberAuto, formatSecondsDelta, leftTrim, rowify } from "helpers";
import { DIVERGENT_COLORS } from "Constants";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Link } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import numeral from 'numeral'
import { TableV2 } from "components/TableV2/TableV2";
import { AccessAlarmRounded, PercentRounded, TagRounded } from "@mui/icons-material";

const MemoizedRow = React.memo(GridRow);

export function PagedTable({
    rows,
    columns,
    pageSize = 25,
    height = "auto",
    sort,
    apiRef,
    onCellClick = undefined,
    onHeaderClick = undefined,
    tablePadding = undefined,
    getRowHref = undefined,
    editable = false,
    wrapping = false,
    isLoading = false
}) {
    const { theme } = useGlobal()
    const [page, setPage] = useState(0)
    const [cellModesModel, setCellModesModel] = useState({})
    const [sortModel, setSortModel] = useState(sort)

    if (!rows || !columns) {
        return (<div className={styles.tableWrap} style={{ height: "500px" }}><LoadingBody /></div>)
    }

    const selectable = onCellClick != null

    let components = {
        "loadingOverlay": MultibaseProgress,
        ColumnSortedAscendingIcon: ArrowBackIosNewOutlinedIcon,
        ColumnSortedDescendingIcon: ArrowForwardIosOutlinedIcon,
    }

    if (getRowHref != null) {
        components["row"] = (props) => {
            return <Link to={getRowHref(props.row)}><MemoizedRow {...props} /></Link>
        }
    }

    return (
        <>
            <div className={styles.tableWrap} style={{ height: height }}>
                <StickyDataGrid
                    className={styles.grid}
                    apiRef={apiRef}
                    editMode="cell"
                    autoHeight={height === "auto"}
                    experimentalFeatures={{ newEditingApi: editable }}
                    selectable={selectable}
                    getRowHeight={wrapping ? () => 'auto' : undefined}
                    getRowClassName={(params) => `${styles.tableRow} ${params.indexRelativeToCurrentPage % 2 === 0 ? styles.even : styles.odd} ${params.isLastVisible ? styles.isLast : ""} ${wrapping ? styles.wrapping : ""} ${theme}`}
                    onCellClick={onCellClick}
                    onCellEditStart={(p, e) => {
                        const { reason } = p || {}
                        if (["enterKeyDown", "printableKeyDown"].includes(reason)) {
                            e.defaultMuiPrevented = true;
                        }
                    }}
                    onCellDoubleClick={(p, e) => {
                        e.defaultMuiPrevented = true;
                    }}
                    columnHeaderHeight={44}
                    onColumnHeaderClick={onHeaderClick}
                    autoPageSize={false}
                    rows={rows}
                    columns={columns}
                    paginationModel={{
                        page: page,
                        pageSize: pageSize
                    }}
                    rowSelection={false}
                    hideFooter={true}
                    rowHeight={40}
                    sortModel={sortModel}
                    onSortModelChange={(model) => {
                        setSortModel(model)
                    }}
                    slots={components}
                    loading={isLoading}
                    disableColumnMenu={true}
                    disableSelectionOnClick={true}
                    pagination={true}
                    tablePadding={tablePadding}
                // components={components}

                />
                {rows.length !== 0 && <div className={`${styles.bottom}`}>
                    <div className={`${styles.bottomInner}`}>
                        <TablePagination
                            count={rows.length}
                            rowsPerPage={pageSize}
                            page={page}
                            onPageChange={(e) => setPage(parseInt(e.target.value))}
                            setPage={(page) => setPage(parseInt(page))}
                        />
                    </div>
                </div>}
            </div>

        </>)
}

export function TimeseriesTable({ data, constants, active = [], onActiveChange, tablePadding = undefined }) {

    if (data == null || data?.length === 0) { return }

    function onCheckboxClick(id) {
        if (active.includes(id) && active.length <= 1) {
            return
        }
        onActiveChange(id)
    }

    // TIME ROWS
    const timeValues = data[0].values.map((d, i) => d[0])

    const columns = timeValues.map((x, idx) => {
        return {
            field: `time_${idx}`,
            headerName: formatDate(new Date(x), 'MMM D YYYY, h:mm A'),
            minWidth: 150,
            sortable: false,
            renderHeader: ({ colDef: { headerName } }) => {
                return <div className={styles.resultsHeader}>{headerName}</div>
            },
            renderCell: ({ value: row }) => {
                const { value } = row
                const { formatOptions } = row
                const { numberFormat, decimals = 0 } = formatOptions || {}
                return <div className={styles.resultCell}>{formatNumber({ number: value, type: numberFormat, decimals: decimals })}</div>
            }
        }
    })

    let rows = []
    data.forEach((group, idx) => {
        const { id, values } = group;
        const { formatOptions } = constants[id] || {}
        let row = { id: idx, "formatOptions": formatOptions }
        values.forEach((x, idx) => {
            row[`time_${idx}`] = {
                value: x[1],
                formatOptions: formatOptions
            }
        })
        rows.push(row)
    })

    return <div className={styles.timeseriesWrap}>
        <div className={styles.headerWrap}>
            {/* <div className={`${styles.headerCellTop} ${styles.even}`}>Metric</div> */}
            <div className={styles.headerCellTop}>
                <div className={styles.headerCheckboxEmpty}><div className={styles.invisibleCheckbox}></div></div>
                <div className={styles.headerTopContent}>Metric</div>
            </div>
            {
                data.map((group, idx) => {
                    const { id } = group;
                    const { name } = constants[id] || {}
                    const color = DIVERGENT_COLORS[idx % DIVERGENT_COLORS.length]
                    return <button key={idx} className={`${styles.headerCellLeft} ${styles.even}`} onClick={() => onCheckboxClick(id)}>
                        <div className={styles.headerCheckboxOuter}><Checkbox className={styles.checkbox} selected={active.includes(id)} /></div>
                        <div className={styles.dot} style={{ backgroundColor: color }}></div>
                        <div className={styles.text}>{name}</div>
                    </button>
                })
            }
        </div>
        <ResultsTableWrap
            rows={rows}
            columns={columns}
            tablePadding={tablePadding}

        />
    </div >
}

function ResultsTableWrap({ rows, columns, onCellClick = undefined, tablePadding = undefined }) {

    const pageSize = 20
    const { theme } = useGlobal()
    const [page, setPage] = useState(0)

    if (!rows || !columns) {
        return (<div className={styles.tableWrap}><LoadingBody /></div>)
    }

    return (
        <>
            <div className={styles.resultsWrapper}>
                <StickyDataGrid
                    borderColor={"var(--grid-line-color)"}
                    autoPageSize={false}
                    autoHeight={true}
                    pageSize={pageSize}
                    page={page}
                    rows={rows}
                    className={styles.grid}
                    getRowClassName={(params) => `${styles.resultRow} ${params.indexRelativeToCurrentPage % 2 === 0 ? styles.even : styles.odd} ${theme}`}
                    columns={columns}
                    hideFooter={true}
                    rowHeight={44}
                    headerHeight={44}
                    rowsPerPage={25}
                    disableColumnMenu={true}
                    checkboxSelection={false}
                    disableSelectionOnClick={true}
                    onCellClick={onCellClick}
                    tablePadding={tablePadding}
                    componentsProps={{
                        header: {
                            sortArrow: {
                                style: { color: "white" },
                            },
                        },
                    }}
                />
            </div>
            {/* <div className={`${styles.bottom}`}>
                <div className={`${styles.bottomInner}`}>
                    {pageSize != null ? <TablePagination
                        count={rows.length}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={(e) => setPage(parseInt(e.target.value))}
                        setPage={(page) => setPage(parseInt(page))}
                    /> : <div>&nbsp;</div>}
                </div>
            </div> */}

        </>)
}

export function FunnelTable({ events, data, constants, tablePadding = undefined }) {

    const [isMedian, setIsMedian] = useState(false)

    const validEvents = events.filter((event) => { return event.id != null })

    const rows = validEvents.map((event, idx) => {
        const dataForEvent = data[idx]
        const countFromPrevious = idx === 0 ? null : data[idx - 1].count
        const conversionFromPrevious = countFromPrevious === 0 ? null : dataForEvent.count / countFromPrevious
        
        return {
            id: idx,
            name: event.name,
            count: dataForEvent.count,
            conversion: conversionFromPrevious,
            average_conversion_time: dataForEvent.average_conversion_time,
            median_conversion_time: dataForEvent.median_conversion_time
        }
    })

    const maxCount = Math.max(...rows.map((row) => row.count))
    const maxConversion = Math.max(...rows.map((row) => row.conversion))
    const maxTime = Math.max(...rows.map((row) => row.average_conversion_time))
    const maxMedianTime = Math.max(...rows.map((row) => row.median_conversion_time))

    return <>
        <div className={styles.funnelGrid}>
            <div className={styles.funnelCell}>
                <div className={styles.funnelCellHeaderWrap}>
                    <h1 className={styles.funnelGridTitle}>Funnel Summary</h1>
                </div>
            </div>
            <div className={styles.funnelCell}>
                {/* Unique Users */}
                <div className={styles.funnelCellHeaderWrap}>
                    <div className={styles.funnelCellHeaderTitle}>Unique Users</div>
                    <div className={styles.funnelCellHeaderSubtitle}>Number of users who made it to this step</div>
                </div>
            </div>
            <div className={styles.funnelCell}>
                <div className={styles.funnelCellHeaderWrap}>
                    <div className={styles.funnelCellHeaderTitle}>Conversion</div>
                    <div className={styles.funnelCellHeaderSubtitle}>% of users who made it to the next step</div>
                </div>
            </div>
            <div className={styles.funnelCell}>
                {/* Average time to convert */}
                <div className={styles.funnelCellHeaderWrap}>
                    <div className={styles.funnelCellHeaderTitle}>Average time to convert</div>
                    <div className={styles.funnelCellHeaderSubtitle}>Average time it took users to make it to this step</div>
                </div>
            </div>

            {rows.map((row, idx) => {
                const { name, count, conversion, average_conversion_time, median_conversion_time } = row
                const countWidth = ((count / maxCount) * 0.8) * 100
                const conversionWidth = ((conversion / maxConversion) * 0.8) * 100
                const timeWidth = ((average_conversion_time / maxTime) * 0.8) * 100
                const medianTimeWidth = ((median_conversion_time / maxMedianTime) * 0.8) * 100

                if (idx === 0) {
                    return <>
                        <div className={`${styles.funnelCell} ${styles.titleCell}`} key={idx}>
                            <div className={styles.funnelCellTitleWrap}>
                                <div className={styles.funnelCellIndex}>{idx + 1}</div>
                                <div className={styles.funnelCellName}>{name}</div>
                            </div>
                        </div>
                        <div className={`${styles.funnelCell} ${styles.value}`}>
                            {numeral(count).format('0,0')}
                        </div>
                        <div className={`${styles.funnelCell} ${styles.value}`}>
                            {numeral(1).format('0.0%')}
                        </div>
                        <div className={styles.funnelCell}></div>
                    </>
                }

                return <>
                    <div className={styles.funnelCellLine} />
                    <div className={`${styles.funnelCell} ${styles.titleCell}`} key={idx}>
                        <div className={styles.funnelCellTitleWrap}>
                            <div className={styles.funnelCellIndex}>{idx + 1}</div>
                            <div className={styles.funnelCellName}>{name}</div>
                        </div>
                    </div>
                    <div className={`${styles.funnelCell} ${styles.value}`}>
                        {numeral(count).format('0,0')}
                    </div>
                    <div className={`${styles.funnelCell} ${styles.value}`}>
                        {numeral(conversion).format('0.0%')}
                    </div>
                    <div className={`${styles.funnelCell} ${styles.value}`}>
                        {formatSecondsDelta(average_conversion_time, "m")}
                    </div>
                </>
            })}
        </div>
    </>

    // return <div className={styles.funnelTable}>
    //     <div className={styles.funnelRow}>
    //         {validEvents.map((event, idx) => {
    //             const dataForEvent = data[idx]
    //             const countFromPrevious = idx === 0 ? null : data[idx - 1].count
    //             const conversionFromPrevious = countFromPrevious === 0 ? null : dataForEvent.count / countFromPrevious
    //             return <div className={styles.funnelCell} key={idx}>
    //                 <div className={styles.funnelCellTitleWrap}>
    //                     <div className={styles.funnelCellIndex}>{idx + 1}</div>
    //                     <div className={styles.funnelCellName}>{event.name}</div>
    //                 </div>
    //                 <div className={styles.funnelContent}>
    //                     <div className={styles.funnelCellContentColumn}>
    //                         {/* <div>Unique Users</div> */}
    //                         <div className={styles.funnelCellContentIcon}>
    //                             <TagRounded />
    //                         </div>
    //                         <div>{numeral(dataForEvent?.count).format('0,0')}</div>
    //                     </div>
    //                     {idx !== 0 && <div className={styles.funnelCellContentColumn}>
    //                         {/* <div>Conversion From Previous</div> */}
    //                         <div className={styles.funnelCellContentIcon}>
    //                             <PercentRounded />
    //                         </div>
    //                         <div>{numeral(conversionFromPrevious).format('0.0%')}</div>
    //                     </div>}
    //                     {(idx !== 0 && !isMedian) && <div className={styles.funnelCellContentColumn}>
    //                         {/* <div className={styles.conversionTimeWrapper}>
    //                             <button className={styles.inlineButton} onClick={() => setIsMedian(true)}>Average</button>
    //                             <div>time to convert</div>
    //                         </div> */}
    //                         <div className={styles.funnelCellContentIcon}>
    //                             <AccessAlarmRounded />
    //                         </div>
    //                         <div>{formatSecondsDelta(dataForEvent?.average_conversion_time, "m")}</div>
    //                     </div>}
    //                     {(idx !== 0 && isMedian) && <div className={styles.funnelCellContentColumn}>
    //                         {/* <div className={styles.conversionTimeWrapper}>
    //                             <button className={styles.inlineButton} onClick={() => setIsMedian(false)}>Median</button>
    //                             <div>time to convert</div>
    //                         </div> */}
    //                         <div>{formatSecondsDelta(dataForEvent?.median_conversion_time, "m")}</div>
    //                     </div>}
    //                 </div>
    //             </div>
    //         })}
    //     </div>
    // </div>

}

export const StickyDataGrid = styled(DataGrid, {
    shouldForwardProp: (prop) => !['borderColor', 'tablePadding'].includes(prop),
})(({ theme, borderColor = "var(--border-00)", tablePadding = 8 }) => ({
    color: "var(--alpha-text)",
    // .MuiDataGrid-row.Mui-selected
    // unset selection colour
    '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: "unset",
    },
    '& .MuiDataGrid-columnHeaders': {
        position: "sticky",
        // Replace background colour if necessary
        // Display header above grid data, but below any popups
        zIndex: 1,
        top: -37,
        left: 0,
        borderBottom: `1px solid ${borderColor}`,
        borderRadius: "0",
        padding: `0 ${tablePadding}px`,
        color: "white",
        background: "rgb(var(--background))",
        fontSize: 12
    },
    "& .MuiDataGrid-sortIcon": {
        opacity: 1,
        color: "white",
    },
    "& .MuiDataGrid-menuIconButton": {
        opacity: 1,
        color: "white"
    },
    '& .MuiDataGrid-virtualScroller': {
        // Undo the margins that were added to push the rows below the previously fixed header
        marginTop: "0 !important"
    },
    '& .MuiDataGrid-main': {
        // Not sure why it is hidden by default, but it prevented the header from sticking
        overflow: "visible"
    },
    ' .MuiDataGrid-cell': {
        border: "none",
        outline: "none!important"
    },
    ' .MuiDataGrid-columnSeparator--sideRight': {
        display: "none"
    },
    ' .MuiDataGrid-columnHeader': {
        border: "none",
        outline: "none!important",
    },
    //row
    '& .MuiDataGrid-row': {
        padding: `0 ${tablePadding}px`
    },
}))



function TablePagination({ count, rowsPerPage, page, onPageChange, setPage }) {

    if (rowsPerPage == null || count == null || page == null) {
        return
    }

    function decrementPage() {
        if (page === 0) { return }
        setPage(page - 1)
    }

    function incrementPage() {
        const max = parseInt(count / rowsPerPage);
        if (page === max) { return }
        setPage(page + 1)
    }

    if (rowsPerPage === 0 || count === 0) {
        return
    }

    const pages = parseInt(Math.ceil(count / rowsPerPage));

    return (<div className={styles.pagination}>
        <div className={styles.pageSelectWrap}>
            <select value={page} onChange={(value) => onPageChange(value)} className={styles.pageSelect}>
                {[...Array(pages).keys()].map((pageNumber, idx) => {
                    return (<option value={pageNumber} key={pageNumber}>{pageNumber + 1}</option>)
                })}
            </select>
            <div className={styles.pageSelectIcon}><ExpandMoreOutlinedIcon /></div>
        </div>
        <div className={styles.txt}>of {pages} pages</div>
        <div className={styles.btnWrap}>
            <button className={styles.arrowBtn} disabled={page === 0} onClick={() => decrementPage()}><ArrowBackIosNewOutlinedIcon /></button>
            <button className={styles.arrowBtn} disabled={page === pages - 1} onClick={() => incrementPage()}><ArrowForwardIosOutlinedIcon /></button>
        </div>
    </div>)

}

const MultibaseProgress = styled(LinearProgress)({
    height: 4,
    backgroundColor: "rgb(var(--primary) / 20%)",
    '& .MuiLinearProgress-bar': {
        borderRadius: 4,
        backgroundColor: "rgb(var(--primary))"
    }
})