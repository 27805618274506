import styled from "@emotion/styled"

export function GraidentCircle({ text, size = 20, fontSize = 10 }) {
    return <Wrap size={size} fontSize={fontSize}>
        {text}
    </Wrap>
}

// styled.div({})

const Wrap = styled('div', {shouldForwardProp: (prop) => !['size', 'fontSize'].includes(prop)})(({size, fontSize}) => (
    {
        position: "relative",
        color: "white",
        display: "flex",
        height: size,
        width: size,
        backgroundColor: "rgb(237,77,112)",
        backgroundImage: "linear-gradient(180deg, rgba(237,77,112,1) 0%, rgba(238,147,167,1) 100%)",
        borderRadius: "50%",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
        fontSize: fontSize,
        fontWeight: 600
    }
))
