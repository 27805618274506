import { Popover } from "@mui/material"
import styles from "./EmptyDropdown.module.css"

export function EmptyDropdown({
    onClose,
    isOpen,
    anchorEl,
    children,
    align = "left",
    style
}) {
    return <Popover
        open={isOpen}
        anchorEl={anchorEl?.current}
        transitionDuration={0}
        onClose={onClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: align,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: align
          }}
        
    >
        <div className={styles.dropdown} style={style}>
            {children}
        </div>
    </Popover>
}