import { createElement, useEffect, useRef, useState } from "react"
import styles from "./DropdownFreeform.module.css"
import { CircularProgress, LinearProgress, Popover } from "@mui/material"
import { emptyCheck, getArrowIndex } from "helpers"
import styled from "@emotion/styled";
import { ALL_CODE, standardSections } from "Constants";
import { SelectDropdownInner } from "components/SelectV2/SelectV2";
import { EditRounded } from "@mui/icons-material";

function areObjectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}


function compare(x, y) {
    // x and y might be null, object, or string
    if (x == null && y == null) return true
    if (x == null || y == null) return false
    if (typeof x === "object" && typeof y === "object") return areObjectsEqual(x, y)
    return x === y
}

export function DropdownFreeform({
    options,
    onChange: onChangeProp,
    onClose,
    isOpen,
    activeValue,
    anchorEl,
    isLoadingAsyncOptions,
    sections = standardSections,
    fullWidth = true,
}) {
    const scrollerRef = useRef(null)
    const [section, setSection] = useState({ isOpen: false, id: ALL_CODE })
    const [highlightedIndex, setHighlightedIndex] = useState(undefined)
    const [hasMouseMovedLast, setHasMouseMovedLast] = useState(true)
    const [query, setQuery] = useState("")
    const [filteredOptions, setFilteredOptions] = useState(options)
    const [activeIndex, setActiveIndex] = useState(undefined)
    const [searchRef, setSearchRef] = useState(null)

    function onChange(e, obj) {
        e?.stopPropagation()
        onClose()
        onChangeProp(obj)
    }

    function searchKeyChange(value) {
        setQuery(value)
        resetFilterOptions()
    }

    useEffect(() => {
        if (!isOpen) {
            setQuery("")
            setSection({ isOpen: false, id: ALL_CODE })
            setActiveIndex(undefined)
            setFilteredOptions(options)
            setHighlightedIndex(undefined)
            scrollerRef.current?.scrollTo(0, 0)
        }
        if (isOpen && searchRef) {
            searchRef.focus()
        }
    }, [isOpen, searchRef])

    useEffect(() => {
        resetFilterOptions()
    }, [section, options])

    function resetFilterOptions() {
        const queryOption = {
            name: query,
            icon: EditRounded,
            section: "freeform",
            id: {
                type: "freeform",
                id: query
            }
        }
        // const noFreeform = options.filter(e => e.section !== "freeform")
        const newOptions = [queryOption, ...options].filter((x) => {
            const { name, section: optionSection } = x
            if (section.id !== ALL_CODE && section.id !== optionSection) return false
            if (name != null) return name.toLowerCase().includes(query.toLowerCase())
            return false
        })
        // const filtered = options.filter(e => e.name.toLowerCase().includes(query.toLowerCase()))
        // setFilteredOptions([queryOption, ...filtered])

        setFilteredOptions(newOptions)
    }

    useEffect(() => {
        if (options == null) return
        resetFilterOptions()
        // setFilteredOptions(options.filter(e => e.name.toLowerCase().includes(query.toLowerCase())))
    }, [query, options])

    useEffect(() => {
        if (!isOpen) return
        // on mouse move, set hasMouseMovedLast to true
        const handleMouseMove = () => {
            setHasMouseMovedLast(true)
        }
        window.addEventListener("mousemove", handleMouseMove)
        return () => {
            window.removeEventListener("mousemove", handleMouseMove)
        }
    }, [isOpen])

    useEffect(() => {
        if (!isOpen) return
        if (activeValue == null) return
        if (filteredOptions == null) return
        const activeIndex = filteredOptions.findIndex(e => {
            return compare(e.id, activeValue)
        })
        if (activeIndex === -1) return
        setActiveIndex(activeIndex)
        const el = document.getElementById(`option-${activeIndex}`)
        el?.scrollIntoView({ block: "start" })
    }, [activeValue, filteredOptions, isOpen])

    useEffect(() => {
        if (!isOpen) return
        const handleKeyDown = (e) => {
            if (section.isOpen) return
            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault()
                const index = getArrowIndex({
                    up: e.key === "ArrowUp",
                    highlightedIndex,
                    activeIndex,
                    optionsLength: filteredOptions.length
                })
                setHighlightedIndex(index)
                const el = document.getElementById(`option-${index}`)
                el?.scrollIntoView({ block: "nearest" })
                setHasMouseMovedLast(false)
            }

            if (e.key === "Enter") {
                if (highlightedIndex == null) return
                e?.preventDefault()
                // const { id, name } = filteredOptions[highlightedIndex]
                onChange(null, filteredOptions[highlightedIndex])
                onClose()
            }
        }
        window.addEventListener("keydown", handleKeyDown)
        return () => window.removeEventListener("keydown", handleKeyDown)
    }, [highlightedIndex, filteredOptions, isOpen, activeIndex, section])

    useEffect(() => {
        if (filteredOptions == null) return
        if (filteredOptions.length === 0) {
            setHighlightedIndex(undefined)
            return
        }
        if (activeIndex == null) {
            setHighlightedIndex(0)
            return
        }
        setHighlightedIndex(undefined)
    }, [filteredOptions, activeIndex])

    if (!isOpen) return

    const countBySection = options.reduce((acc, cur) => {
        if (acc[cur.section] == null) acc[cur.section] = 0
        acc[cur.section]++
        return acc
    }, {})
    const nonZeroSections = sections?.filter(e => countBySection[e.id] > 0 && e.id !== ALL_CODE)

    const shouldShowSectioned = sections != null && sections.length > 1 && nonZeroSections.length > 1
    const sectionData = {
        isEnabled: shouldShowSectioned,
        section,
        setSection,
        sections: sections?.filter(e => e.id === ALL_CODE || countBySection[e.id] > 0)
    }

    return <Popover
        open={isOpen}
        anchorEl={anchorEl?.current}
        transitionDuration={0}
        onClose={onClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
    >
        <div className={styles.dropdown} style={{ width: fullWidth ? "300px" : "auto" }}>
            {isLoadingAsyncOptions && <div className={styles.linearProgressWrap}>
                <MultibaseProgress />
            </div>}
            <input ref={(r)=>{
                setSearchRef(r)
            }} className={styles.search} placeholder="Enter a value..." value={query} onChange={(e) => searchKeyChange(e.target.value)} />
            {(isLoadingAsyncOptions && filteredOptions.length === 0) && <div className={styles.loading}>
                <CircularProgress size={16} sx={{ color: "rgb(var(--primary))" }} />
                <div className={styles.loadingText}>Loading...</div>
            </div>}
            {/* {(query === "" && shouldShowSectioned) && <DropdownContentBroadWithSections
                options={filteredOptions}
                isOpen={isOpen}
                highlightedIndex={highlightedIndex}
                setHighlightedIndex={setHighlightedIndex}
                activeIndex={activeIndex}
                sections={sections}
                scrollerRef={scrollerRef}
                onChange={onChange}
                onClose={onClose}
                hasMouseMovedLast={hasMouseMovedLast}
            />} */}
            <DropdownContent
                options={filteredOptions}
                sections={sections}
                highlightedIndex={highlightedIndex}
                setHighlightedIndex={setHighlightedIndex}
                activeIndex={activeIndex}
                scrollerRef={scrollerRef}
                onChange={onChange}
                onClose={onClose}
                hasMouseMovedLast={hasMouseMovedLast}
                sectionData={sectionData}
            />
        </div>
    </Popover>

}

// function DropdownContentBroadWithSections({ isOpen, options = [], sections, highlightedIndex, setHighlightedIndex, activeIndex, scrollerRef, onChange, hasMouseMovedLast }) {

//     const [collapsed, setCollapsed] = useState([]);

//     useEffect(() => {
//         if (isOpen) return
//         setCollapsed([])
//     }, [isOpen])

//     function collapseClick(section) {
//         if (collapsed.includes(section)) {
//             setCollapsed(collapsed.filter(x => x !== section))
//         } else {
//             setCollapsed([...collapsed, section])
//         }
//     }

//     const countBySection = options.reduce((acc, event) => {
//         const { section } = event
//         if (acc[section] == null) {
//             acc[section] = 0
//         }
//         acc[section] += 1
//         return acc
//     }, {})

//     // const isTemplateOnly = countByType.custom === 0
//     if (options.length === 0) return

//     return <div className={styles.dropdownContent} ref={scrollerRef}>
//         {sections?.map((section, index) => {
//             const { id: sectionId, name: sectionName } = section
//             return <>
//                 <button className={styles.sectionTitleWrap} onClick={() => collapseClick(sectionId)}>
//                     <div className={`${styles.sectionArrow} ${collapsed.includes(sectionId) ? styles.isOpen : ""}`}><KeyboardArrowDownRoundedIcon /></div>
//                     <div className={styles.sectionTitle}>{sectionName}</div>
//                 </button>
//                 {!collapsed.includes(sectionId) && countBySection[sectionId] > 0 && options.filter((x) => x.section === sectionId).map((option, index) => {
//                     const { id, name, icon, supertype } = option
//                     return <Option
//                         key={index}
//                         name={name}
//                         icon={icon}
//                         supertype={supertype}
//                         index={index}
//                         isHighlighted={highlightedIndex === index}
//                         isActive={activeIndex === index}
//                         setHighlightedIndex={setHighlightedIndex}
//                         onChange={(e) => onChange(e, id)}
//                         hasMouseMovedLast={hasMouseMovedLast}
//                     />
//                 })}
//             </>
//         })}
//     </div>
// }

function DropdownContent({ options = [], highlightedIndex, setHighlightedIndex, activeIndex, scrollerRef, onChange, hasMouseMovedLast, sectionData }) {

    const anchorEl = useRef(null)
    const { isEnabled, section, setSection, sections = [] } = sectionData;
    const sectionText = sections.find(e => e.id === section.id)?.inline

    return <div className={styles.dropdownContent} ref={scrollerRef}>
        {isEnabled && <div className={styles.dropdownTop}>
            <div className={styles.dropdownTopFilter}>
                Showing
                <button
                    ref={anchorEl}
                    className={styles.dropdownTopButton}
                    onClick={() => setSection({ ...section, isOpen: true })}
                >{sectionText}...</button>
            </div>
            <SelectDropdownInner
                isOpen={section.isOpen}
                onClose={(e) => {
                    e?.stopPropagation()
                    setSection({ ...section, isOpen: false })
                }}
                anchorEl={anchorEl}
                onChange={(e, event) => {
                    e?.stopPropagation()
                    setSection({ isOpen: false, id: event })
                }}
                option={section.id}
                options={sections}
                classes={{
                    option: styles.dropdownTopSelectOption,
                }}
            />
        </div>}
        {options.map((option, index) => {
            const { id, name, icon, supertype } = option
            return <Option
                key={index}
                name={name}
                icon={icon}
                supertype={supertype}
                index={index}
                isHighlighted={highlightedIndex === index}
                isActive={activeIndex === index}
                setHighlightedIndex={setHighlightedIndex}
                onChange={(e) => onChange(e, option)}
                hasMouseMovedLast={hasMouseMovedLast}
            />
        })}
    </div>
}

const MultibaseProgress = styled(LinearProgress)({
    height: 2,
    backgroundColor: "rgb(var(--primary) / 20%)",
    '& .MuiLinearProgress-bar': {
        borderRadius: 4,
        backgroundColor: "rgb(var(--primary))"
    }
})

function Option({ name, icon, supertype, isHighlighted, setHighlightedIndex, isActive, onChange, hasMouseMovedLast, index }) {
    return <button className={`${styles.event} ${isHighlighted ? styles.highlighted : ""} ${isActive ? styles.active : ""}`}
        onClick={onChange}
        id={`option-${index}`}
        onMouseEnter={() => {
            if (hasMouseMovedLast) setHighlightedIndex(index)
        }}
    >
        {icon && <div className={styles.icon}>
            {createElement(icon)}
        </div>}
        {supertype && <><div className={styles.supertype}>{supertype}</div>
            <div className={styles.supertypeIcon}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
            </div>
        </>}
        <div className={styles.eventName}>{emptyCheck(name)}</div>
    </button>
}
