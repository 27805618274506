import styles from "./OnchainFilters.module.css"
import { ContentCopyRounded, DeleteOutlineRounded } from "@mui/icons-material";
import { ALL_CODE, EVENT_CONDITION_OPTIONS_V2, SIMPLE_STRUCTURE, SINGLE_STRUCTURE, standardOptionFn } from "Constants";
import { Dropdown } from "components/Dropdown/Dropdown";
import { InputButton } from "components/InputButton/InputButton";
import { MultiDropdown } from "components/MultiDropdown/MultiDropdown";
import { ValueInput } from "components/ValueInput/ValueInput";
import { getOperatorsFromDataType, getValueString } from "helpers";
import { useRef, useState } from "react";
import { BLANK_FILTER, isFilterRequired, onFilterKeyChange, shouldFilterResetFields, useFilterValueOptions } from "utils/filter";

export function OnchainFilters({ filters, setFilters, requiredFilters, optionFn = standardOptionFn, templates = EVENT_CONDITION_OPTIONS_V2 }) {
    const filterOptions = getOnchainFilterOptions(templates, optionFn);

    function onFilterChanged({ index, update }) {
        if (filters.length === 0) {
            const blankFilter = { ...update }
            if (shouldFilterResetFields(update) && update.filterOperator == null) blankFilter.filterOperator = "eq"
            setFilters([blankFilter])
            return
        }
        const newFilters = [...filters]
        newFilters[index] = { ...newFilters[index], ...update }
        if (shouldFilterResetFields(update)) {
            newFilters[index].filterValue = undefined
            if (update.filterOperator == null) newFilters[index].filterOperator = "eq"
        }
        setFilters(newFilters)
    }

    function onFilterDelete(index) {
        setFilters(filters.filter((_, i) => i !== index))
    }

    function duplicateFilter(index) {
        const newFilters = [...filters, { ...filters[index] }]
        setFilters(newFilters)
    }

    function addBlankFilter() {
        setFilters([...filters, BLANK_FILTER])
    }

    return <div className={styles.filters}>
        <div className={styles.filtersFlex}>
            {filters.map((filter, idx) => {
                return <FilterLineFull
                    key={idx}
                    filter={filter}
                    required={isFilterRequired(requiredFilters, filter)}
                    filterOptions={filterOptions}
                    onChange={(val) => {
                        onFilterChanged({ index: idx, update: val })
                    }}
                    onFilterDelete={() => onFilterDelete(idx)}
                    onFilterDuplicate={() => duplicateFilter(idx)}
                    depth={0}
                />
            })}
            <FilterWrap>
                {filters.length > 0 && <FilterPretext />}
                <button className={`${styles.add} ${filters.length === 0 ? styles.only : ""}`} onClick={addBlankFilter}>
                    <div className={styles.plus}>+</div>
                    <div className={styles.text}>Add filter</div>
                </button>
            </FilterWrap>
        </div>
    </div>

}

function FilterLineFull({
    filter,
    filterOptions,
    required,
    onChange,
    onFilterDelete,
    onFilterDuplicate
}) {
    const { filterStructure } = filter;
    if (filterOptions == null) return <>null</>
    let el = <></>

    if (filterStructure === "BLANK") el = <>
        <FilterPretext>where</FilterPretext>
        <FilterKey
            filter={null}
            onChange={onChange}
            filterOptions={filterOptions}
            fill={false}
        />
    </>

    if (filterStructure === SINGLE_STRUCTURE) el = <>
        <FilterPretext>where</FilterPretext>
        <FilterKey
            filter={filter}
            onChange={onChange}
            filterOptions={filterOptions}
            fill={false}
        />
    </>

    if (filterStructure === SIMPLE_STRUCTURE) el = <>
        <FilterPretext>where</FilterPretext>
        <FilterKey
            filter={filter}
            onChange={onChange}
            filterOptions={filterOptions}
            fill={false}
        />
        <FilterOperator
            filter={filter}
            onChange={onChange}
            fill={false}
        />
        <FilterValue
            filter={filter}
            onChange={onChange}
            fill={false}
        />
    </>

    return <FilterWrap>
        {el}
        <Actions
            deletable={!required}
            onDuplicate={onFilterDuplicate}
            onFilterDelete={onFilterDelete}
        />
    </FilterWrap>
}

// function FilterLineLight({
//     filter,
//     filterOptions,
//     required,
//     onChange,
//     onFilterDelete,
//     onFilterDuplicate
// }) {
//     const { filterStructure } = filter;
//     if (filterOptions == null) return <>null</>
//     let el = <></>

//     if (filterStructure === SINGLE_STRUCTURE) el = <FilterWrap>
//         <FilterKey
//             filter={filter}
//             onChange={onChange}
//             filterOptions={filterOptions}
//         />
//         <Actions
//             deletable={!required}
//             onDuplicate={onFilterDuplicate}
//             onFilterDelete={onFilterDelete}
//         />
//     </FilterWrap>

//     if (filterStructure === SIMPLE_STRUCTURE) el = <FilterWrap>
//         <FilterKey
//             filter={filter}
//             onChange={onChange}
//             filterOptions={filterOptions}
//         />
//         <FilterOperator
//             filter={filter}
//             onChange={onChange}
//         />
//         <FilterValue
//             filter={filter}
//             onChange={onChange}
//         />
//         <Actions
//             deletable={!required}
//             onDuplicate={onFilterDuplicate}
//             onFilterDelete={onFilterDelete}
//         />
//     </FilterWrap>

//     return <>
//         {el}
//     </>
// }


function FilterWrap({ children }) {
    return <div className={styles.filter}>
        {children}
    </div>
}

function Actions({ deletable, onDuplicate, onFilterDelete }) {
    return <div className={styles.actions}>
        <button onClick={onDuplicate} style={{ width: 24, height: 24 }}><ContentCopyRounded /></button>
        {deletable && <button onClick={onFilterDelete} style={{ width: 26, height: 26 }}><DeleteOutlineRounded /></button>}
    </div>

}

function FilterKey({ filter, onChange, filterOptions, fill = true }) {

    const el = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const filterEmpty = filter == null
    const filterText = filterEmpty ? "Add filter..." : filter.name

    return <>
        <Dropdown
            anchorEl={el}
            activeValue={filter?.id}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            options={filterOptions}
            sections={[
                { id: ALL_CODE, name: "All Filters", inline: "all filters" },
                { id: "custom", name: "Custom", inline: "custom" },
                { id: "label", name: "Labels", inline: "labels" },
                { id: "template", name: "On-chain", inline: "on-chain" },
            ]}
            onChange={(val) => {
                onFilterKeyChange(val, onChange)
            }}
        />
        <InputButton
            pref={el}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
            empty={filterEmpty}
            buttonText={filterText}
            fill={fill}
        />
    </>
}

function FilterOperator({ filter, onChange, fill = true }) {
    const el = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    if (filter == null) return

    const empty = filter == null || filter.filterOperator == null
    const operatorOptions = getOperatorsFromDataType(filter.filterDatatype)
    const operatorText = empty ? "Operator..." : operatorOptions.find(opt => opt.id === filter.filterOperator)?.name

    return <>
        <Dropdown
            anchorEl={el}
            activeValue={filter?.filterOperator}
            fullWidth={false}
            search={false}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            options={getOperatorsFromDataType(filter?.filterDatatype)}
            onChange={(val) => {
                const { id } = val;
                onChange({ filterOperator: id })
            }}
        />
        <InputButton
            pref={el}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
            empty={empty}
            buttonText={operatorText}
            fill={fill}
        />
    </>
}

function FilterValue({ filter, onChange, fill = true }) {

    const el = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const { options: filterValueOptions, settings, loading: filterValueLoading } = useFilterValueOptions(filter)

    if (filter == null) return

    const { filterDatatype, filterValue } = filter

    const empty = filter == null || filterValue == null
    let valueText = null
    if (filterDatatype === "string" && !empty) {
        const filVals = filterValue.map((x) => x.name)
        valueText = empty ? "Select value..." : getValueString(filVals)
    } else {
        valueText = empty ? "Select value..." : filterValue
    }

    return <>
        <MultiDropdown
            anchorEl={el}
            value={filterDatatype === "string" ? filterValue : undefined}
            isLoadingAsyncOptions={filterValueLoading}
            isOpen={isOpen && filterDatatype === "string"}
            onClose={() => setIsOpen(false)}
            options={filterValueOptions}
            settings={settings}
            onChange={(val) => {
                onChange({ filterValue: val })
                // onFilterChanged({ index: activeFilter.index, update: { filterValue: val } })
            }}
        />
        <ValueInput
            value={filterValue}
            onChange={(val) => {
                onChange({ filterValue: val })
            }}
            datatype={filterDatatype}
            pref={el}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
            empty={empty}
            buttonText={valueText}
            fill={fill}
        />
    </>

}

function FilterPretext({ children }) {
    return <div className={styles.linePretext}>{children}</div>
}

function getOnchainFilterOptions(templates, optionFn) {
    const mapped = templates.map(e => ({
        id: { filterResourceType: "template", filterObject: null, filterKey: e.id, filterDatatype: e.datatype },
        filterResourceType: e.resourceType || "template",
        filterStructure: e.structure,
        filterObject: null,
        filterKey: e.id,
        filterDatatype: e.datatype,
        defaultValues: e.defaultValues,
        icon: e.icon,
        subfilterOptions: e.subfilterOptions,
        staticOptions: e.staticOptions,
        hasAsyncOptions: e.hasAsyncOptions,
        name: e.name,
        type: "template",
        favor: 1
    }))

    // sort events by favor 
    mapped.sort((a, b) => {
        if (a.favor > b.favor) return -1
        if (a.favor < b.favor) return 1
        return 0
    })

    return mapped.map((filter) => {
        const { filterResourceType, filterObject, filterDatatype, filterKey, filterStructure, defaultValues, subfilterOptions, staticOptions, hasAsyncOptions, id, icon, name, type } = filter
        const { supertype } = optionFn(filter)
        return { id, filterResourceType, filterObject, filterDatatype, filterKey, filterStructure, defaultValues, subfilterOptions, staticOptions, hasAsyncOptions, name, icon, supertype, section: type }
    })
}