import { formatNumber } from "helpers/index";

export function getNumberRow({
    key,
    title,
    format,
    abbreviate = false,
    minWidth = 125,
    decimals = undefined,
    redGreen = false,
    showPlus = false,
    flex = undefined,
    extraParameters = {},
}: {
    key: string;
    title: string;
    format: string;
    abbreviate?: boolean;
    minWidth?: number;
    decimals?: number;
    redGreen?: boolean;
    showPlus?: boolean;
    flex?: number;
    extraParameters?: Object
}) {
    return {
        field: key,
        headerName: title,
        minWidth: minWidth,
        flex: flex,
        valueGetter: ({ value }: { value: any }) => {
            return value
        },
        renderCell: ({ value }: { value: any }) => {
            return (
                <span
                    className={`normalCell ${redGreen ? (value > 0 ? "green" : "red") : ""
                        }`}
                >
                    {formatNumber({
                        number: value,
                        type: format,
                        abbreviate: abbreviate,
                        decimals: decimals,
                        showPlus: showPlus,
                    })}
                </span>
            );
        },
        ...extraParameters
    };
}


export function getClassicRow({
    key,
    title,
    minWidth = 125,
    flex = undefined,
}: {
    key: string;
    title: string;
    minWidth?: number;
    flex?: number;
}) {
    return {
        field: key,
        headerName: title,
        minWidth: minWidth,
        renderCell: ({ value }: { value: any }) => {
            if(value == null || value === "") return <span className="nullCell">–</span>
            return <span className="normalCell">{value}</span>
        },
        flex: flex,
    };
}

export function getCustomRow({
    key,
    title,
    renderCellContent,
    minWidth = 125,
    flex = undefined,
}: {
    key: string;
    title: string;
    renderCellContent: (params: any) => any;
    minWidth?: number;
    flex?: number;
}) {
    return {
        field: key,
        headerName: title,
        minWidth: minWidth,
        renderCell: (params: any) => {
            const { row: data, value } = params
            return <span className="normalCell">{renderCellContent({ data, value })}</span>
        },
        flex: flex,
    };
}