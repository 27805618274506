import { Content } from "components/Content/Content";
import styles from "./Composition.module.css"
import { CardV2 } from "components/CardV2/CardV2";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputButton } from "components/InputButton/InputButton";
import { Dropdown } from "components/Dropdown/Dropdown";
import { usePostEndpoint, useQueryFunctions } from "ApiConnector";
import { ALL_CODE, EVENT_CONDITION_OPTIONS_V2 } from "Constants";
import { compareObjectsRegardlessOfKeyOrder, getMinFromInterval, rowify } from "helpers";
import { PieChart } from "components/Chart/Chart";
import { ElectricBoltRounded, FunctionsRounded, TextFieldsRounded, ToggleOnRounded } from "@mui/icons-material";
import { Button } from "components/Button/Button";
import { Menu } from "components/Menu/Menu";
import { CustomLoadingButton } from "components/LoadingButton/LoadingButton";
import { CircularProgress } from "@mui/material";
import { CardTitle } from "components/CardTitle/CardTitle";
import { getCustomRow, getNumberRow } from "helpers/Rows/rows";
import { IntegratedDateSelector } from "components/IntegratedDateFormatter/IntegratedDateSelector";
import { PagedTable } from "components/Table/Table";
import { Filters } from "components/Filters/Filters";
import { ReactComponent as Empty } from 'assets/empty/pie.svg';
import { deleteQuery, getQuery, saveQuery } from "helpers/query";
import { DeleteModal } from "components/DeleteModal/DeleteModal";
import { ClassicSaveModal } from "components/ClassicSave/ClassicSaveModal";
import { toast } from "react-hot-toast";
import PageLoading from "components/PageLoading/PageLoading";
import { UilFilterSlash } from '@iconscout/react-unicons'
import { filterEquals, getRequestFilters, getStoredFilters } from "utils/filter";
import { exportCSV } from "utils/export";
import { DateSelector } from "components/DateSelector/DateSelector";
import { FilterDate } from "models/date";

export function Composition() {
    const { action, query } = useParams();
    const { invalidate } = useQueryFunctions()
    const [queryData, setQueryData] = useState(undefined)
    const [originalRequestOptions, setOriginalRequestOptions] = useState(undefined)
    const [loadingSave, setLoadingSave] = useState(action === "edit")
    const [saving, setSaving] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [saveModal, setSaveModal] = useState(false)

    const [date, setDate] = useState(new FilterDate({
        mode: "since",
        min: DEFAULT_MINIMUM,
        max: DEFAULT_MAXIMUM,
    }))
    const [property, setProperty] = useState(null)
    const [filters, setFilters] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        if (action !== "edit") return
        if (property != null || filters.length !== 0) return
        async function f() {
            setLoadingSave(true)
            const queryData = await getQuery(query)
            const queryInnerData = queryData.data
            const { date: d, property: p, filters: f } = queryInnerData

            const date = new FilterDate(d)
            setDate(date)
            setProperty(p)
            setFilters(f)

            const oro = getRequestOptions(p, f, date)
            setQueryData(queryData)
            setOriginalRequestOptions(oro)
            setLoadingSave(false)
        }
        f()
    }, [action, query])

    async function save({ name, description }) {
        if (name === "") return
        const store = {
            property,
            filters: getStoredFilters(filters),
            date,
        }
        if (action === "create") {
            setSaveModal(false)
            setSaving(true)
            const id = await saveQuery({
                name,
                description,
                type: "composition",
                created: new Date(),
                updated: new Date(),
                data: store,
            })
            invalidate("general/home")
            navigate(`/query/composition/edit/${id}`)
            setSaving(false)
            setQueryData({ id, name, description, data: store })
        }

        if (action === "edit") {
            const { id } = queryData
            if (id == null) return
            setSaveModal(false)
            setSaving(true)
            await saveQuery({
                id,
                updated: new Date(),
                name,
                description,
                data: store,
            })
            invalidate("general/home")
            setSaving(false)
            setQueryData({ ...queryData, name, description, data: store })
        }
        const oro = getRequestOptions(property, filters, date)
        setOriginalRequestOptions(oro)
    }

    async function deleteChart() {
        const promise = new Promise(async (resolve, reject) => {
            try {
                const { id } = queryData
                await deleteQuery(id)
                invalidate("general/home")
                navigate("/")
                resolve()
            } catch (e) {
                reject()
            }
        })

        toast.promise(promise, {
            loading: "Deleting chart...",
            error: "There was an error deleting this chart",
            success: "Successfully deleted chart"
        })

    }

    const isValid = isQueryValid(property, filters)
    const requestOptions = getRequestOptions(property, filters, date)
    const { data, error, refetch } = usePostEndpoint({ endpoint: "query/composition", body: requestOptions, overrideQueryKeyObj: requestOptions, minKey: "minimum", maxKey: "maximum", skip: !isValid || requestOptions == null })

    const dirty = action === "create" ? true : !compareObjectsRegardlessOfKeyOrder(originalRequestOptions, requestOptions)

    if (action === "edit" && loadingSave) {
        return <PageLoading />
    }

    const { name, description } = queryData || {}

    return <>
        <ClassicSaveModal
            title={action === "create" ? "Save Chart" : "Edit Chart"}
            isOpen={saveModal}
            onClose={() => setSaveModal(false)}
            onSave={save}
            existing={{ name, description }}
        />
        <DeleteModal
            isOpen={deleteModal}
            onClose={() => setDeleteModal(false)}
            onDelete={deleteChart}
        />
        <Content flex>
            <TopBarWrap
                type={"composition"}
                action={action}
                dirty={dirty}
                saving={saving}
                onSaveClicked={() => {
                    if (action === "create") {
                        setSaveModal(true)
                        return
                    }
                    save({ name, description })
                }}
                onDeleteClicked={() => setDeleteModal(true)}
                onEditClicked={() => setSaveModal(true)}
            />
            <div className={styles.topContent}>
                <CardV2 className={styles.queryCard}>
                    <div className={styles.queryContainer}>
                        <Properties
                            property={property}
                            setProperty={setProperty}

                        // filters={filters}
                        // setFilters={setFilters}
                        />
                        <div className={styles.section}>
                            <div className={styles.title}>Filters</div>
                            <Filters
                                filters={filters}
                                setFilters={setFilters}
                                excludeResources={["user"]}
                                // excludeTemplates={["wallet_address", "transaction_role", "chain"]}
                                templates={EVENT_CONDITION_OPTIONS_V2.filter((x) => ["wallet_address", "transaction_role", "event_type", "contract_address", "chain"].includes(x.id))}
                            />
                        </div>
                    </div>
                </CardV2>
                <CardV2 className={styles.queryResultsChart}>
                    <PieResults results={data} isValid={isValid} date={date} setDate={setDate} />
                </CardV2>
            </div>
            <TableResults results={data} />
            {/* <Results
                events={events}
                setEvents={setEvents}
                data={data}
                error={error}
                refetch={refetch}
                date={date}
                onDateChange={onDateChange}
                granularity={granularity}
                onGranularityChange={onGranularityChange}
                onPeriodSelect={onPeriodSelect}
                isValid={isValid}
            /> */}
        </Content>
    </>

}

function TopBarWrap({ type, action, dirty, saving, onSaveClicked, onDeleteClicked, onEditClicked }) {

    const [optionsOpen, setOptionsOpen] = useState(false)
    const openOptions = () => setOptionsOpen(true)
    const closeOptions = () => setOptionsOpen(false)
    const optionsEl = useRef(null)

    return <>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            {action === "edit" && <>
                <Button buttonRef={optionsEl} onClick={openOptions} className={styles.outlineBtn} colorVariant="outline">Options</Button>
                <Menu
                    open={optionsOpen}
                    onClose={closeOptions}
                    anchorEl={optionsEl?.current}
                    options={[
                        { onClick: () => onEditClicked(), text: "Edit" },
                        { onClick: () => onDeleteClicked(), text: "Delete" }
                    ]}
                />
            </>}
            <CustomLoadingButton disabled={!dirty} loading={saving} className={styles.addBtn} onClick={onSaveClicked}>
                Save
            </CustomLoadingButton>
        </div>
    </>
}

function Properties({ property, setProperty, filters, setFilters }) {

    const [activeProperty, setActiveProperty] = useState({ isActive: false, value: null, el: null })

    const { data: propertyData } = usePostEndpoint({ endpoint: "filters/keys", body: { object: ["user", "event"] } })
    const asyncPropertiesFormatted = [...propertyData?.map(e => {
        const { filterResourceType, filterObject, filterKey, filterDatatype, name } = e;
        return {
            ...e,
            id: { filterResourceType, filterObject, filterKey, filterDatatype },
            name: name,
            favor: 1
        }
    }) ?? []]

    const allProperties = [...asyncPropertiesFormatted, ...COMPOSITION_OPTIONS.map((e) => {
        return {
            ...e,
            filterResourceType: "template",
            filterObject: null,
            filterKey: e.id,
            filterDatatype: "string",
            id: {
                filterResourceType: "template",
                filterObject: null,
                filterKey: e.id,
                filterDatatype: "string"
            },
            favor: 1,
        }
    })]

    // sort events by favor 
    allProperties.sort((a, b) => {
        if (a.favor > b.favor) return -1
        if (a.favor < b.favor) return 1
        return 0
    })

    const propertyOptions = allProperties.map((filter) => {
        const { filterResourceType, filterObject, filterDatatype, filterKey, id, name } = filter
        let icon = null
        let supertype = null
        if (filterDatatype === "string") icon = TextFieldsRounded
        if (filterDatatype === "numeric") icon = FunctionsRounded
        if (filterDatatype === "bool") icon = ToggleOnRounded
        if (filterResourceType === "template") icon = ElectricBoltRounded
        if (filterResourceType === "user") supertype = "User"
        return { id, filterResourceType, filterObject, filterDatatype, filterKey, name, icon, supertype }
    })

    // const activeValue = { }

    return <div className={styles.section}>
        <div className={styles.title}>Properties</div>
        <Dropdown
            anchorEl={activeProperty.el}
            activeValue={activeProperty.value?.id}
            sections={[
                { id: ALL_CODE, name: "All properties", inline: "all properties" },
                { id: "custom", name: "Custom properties", inline: "custom properties" },
                { id: "template", name: "On-chain Events", inline: "on-chain events" },
            ]}
            isLoadingAsyncOptions={propertyData == null}
            isOpen={activeProperty.isActive}
            onClose={() => setActiveProperty({ isActive: false, value: null, el: null })}
            options={propertyOptions}
            onChange={(val) => {
                const fullObj = allProperties.find((x) => filterEquals(x, val))
                setProperty(fullObj)
            }}
        />
        <div className={styles.sectionInner}>
            <Property
                property={property}
                activeProperty={activeProperty}
                setActiveProperty={setActiveProperty}
                propertyOptions={allProperties}
            />
        </div>
    </div>
}

function Property({
    index,
    property,
    activeProperty,
    setActiveProperty,
    propertyOptions,
}) {

    const propertyEl = useRef(null)
    const propertyEmpty = property == null || property === ""
    const propertyButtonText = propertyEmpty ? "Select Property" : property.name;

    return <>
        <InputButton
            pref={propertyEl}
            buttonText={propertyButtonText}
            onOpen={() => setActiveProperty({ isActive: true, value: property, el: propertyEl })}
            isOpen={activeProperty.isActive && activeProperty.index === index}
            empty={propertyEmpty}
            fill
        />
    </>
}

function PieResults({ results, isValid, property, filters, date, setDate }) {
    const { series } = results || {}

    let top = <div className={styles.chartTop}>
        <div />
        <div className={styles.right}>
            <DateSelector
                date={date}
                onChange={(val) => setDate(val)}
            />
            {/* <IntegratedDateSelector
                mode={dateMode}
                rawDate={date}
                date={dateValue}
                onChange={onDateChangeFreeform}
                classes={{ wrap: styles.integrated }}
            /> */}
        </div>
    </div>

    if (!isValid) return <div className={styles.chartWrap}>
        <div className={styles.noResultsWrap}>
            <div className={styles.noResultsWrapInner}>
                <div className={styles.emptyIcon}>
                    <Empty />
                </div>
                <div className={styles.title}>Select a metric to get started</div>
                <div className={styles.description}>In order to see results, you must select at least one metric from the left panel</div>
            </div>
        </div></div>

    if (series == null) return <div className={styles.chartWrap}>
        {top}
        <div className={styles.noResultsWrap}>
            <div className={styles.noResultsWrapInner}>
                <CircularProgress sx={{ color: "var(--alpha-text)" }} size={36} />
            </div>
        </div>
    </div>

    if (series?.length === 0) {
        return <>
            {top}
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <div className={styles.iconWrap}>
                        <UilFilterSlash />
                    </div>
                    <div className={styles.title}>No events found</div>
                    <div className={styles.description}>Please change the parameters to see results</div>
                </div>
            </div>
        </>

    }

    const seriesRows = series.map((item) => {
        const { name, count, percent } = item
        const row = [name, count, percent]
        return row
    })

    return <div className={styles.chartWrap}>
        {top}
        <PieChart data={seriesRows} />
    </div>
}

function TableResults({ results, property, filters, date }) {

    const { series, metadata = {
        group: "Group",
        value: "Users"
    } } = results || {}

    function exportCSVClicked() {
        if (!series) { return }
        const headers = [metadata.group, metadata.value, "% of total"]
        const seriesRows = series.map((item) => {
            const { name, count, percent } = item
            const row = [name, count, percent]
            return row
        })
        const csvData = [headers, ...seriesRows]
        exportCSV(csvData, "Composition")
    }

    const rows = series == null ? undefined : series?.map((row, idx) => ({ rank: idx + 1, ...row }))

    if (rows == null) return null
    if (series?.length === 0) return null

    return <CardV2 noPadding>
        <div className={styles.tableTop}>
            <div>
                <CardTitle>Table</CardTitle>
            </div>
            <div>
                <Button colorVariant="outline" onClick={exportCSVClicked}>Export to CSV</Button>
            </div>
        </div>
        <PagedTable
            rows={rowify(rows)}
            columns={[
                // getClassicRow({
                //     key: "name",
                //     title: metadata.group,
                //     flex: 0.5
                // }),
                getCustomRow({
                    key: "name",
                    title: metadata.group,
                    flex: 0.5,
                    renderCellContent: ({ data, value }) => {
                        const { link } = data || {}
                        if (link == null) return value
                        return <a className="normalCellLink" href={link} target={"_blank"} rel={"noreferrer"}>{value}</a>
                    },
                }),
                getNumberRow({
                    key: "count",
                    title: metadata.value,
                    flex: 0.5,
                    extraParameters: {
                        sortable: false
                    }
                }),
            ]}
        />
        {/* <DistributionTableV2
            rows={rowify(rows)}
        /> */}
    </CardV2>
}

function isQueryValid(property, filters) {
    if (property == null) return false
    return true
}

function getRequestOptions(property, filters, date) {
    if (date == null) return
    return {
        property: property,
        filters: getRequestFilters(filters),
        date: date.toJSON()
    }
}

// function getDateParams(date) {
//     const { mode, period, min, max } = date

//     if (mode === "period") {
//         const minFromIntervalUnroundedISO = getMinFromInterval(period)
//         //load into moment, and get the start of the hour
//         const minFromIntervalISO = moment(minFromIntervalUnroundedISO).startOf("hour").toISOString()
//         return {
//             minimum: minFromIntervalISO
//         }
//     }
//     return {
//         minimum: min,
//         maximum: max
//     }

// }

const DEFAULT_MINIMUM = moment().subtract(1, "year").toISOString()
const DEFAULT_MAXIMUM = moment().toISOString()

const COMPOSITION_OPTIONS = [
    { id: "balance_distribution", name: "Balance Distribution" },
    { id: "tokens_received", name: "Tokens Received" },
    { id: "tokens_sent", name: "Tokens Sent" },
    { id: "tokens_transferred", name: "Tokens Transferred" },
    { id: "contract_interactions", name: "Contract Interactions" },
    { id: "nfts_transferred", name: "NFTs Transferred" },
    { id: "nfts_received", name: "NFTs Received" },
    { id: "nfts_sent", name: "NFTs Sent" },
    // { id: "nft_hold_time", name: "NFT Hold Time" },
    { id: "methods", name: "Methods" },
]