import { FilterDate } from "models/date"
import moment from "moment"

export function isDateValid(date: any) {
    const {mode, min, max, value, units} = date || {}
    if(mode === "last"){
        return units != null && units !== "" && value != null && value !== ""
    }
    if(mode === "between"){
        return min != null && min !== "" && max != null && max !== ""
    }
    if(mode === "since"){
        return min != null && min !== ""
    }
    return false
}

export function formatDateString(date: FilterDate) {
    const { mode, min } = date || {}
    if (mode == null) return
    if (mode === "since") {
        const { min } = date
        const minFormatted = moment(min).format("MMM D, YYYY")
        return `Since ${minFormatted}`
    }
    if (mode === "last") {
        const { value, units } = date
        if(value == null) return
        const s = value > 1 ? "s" : ""
        return `Last ${value} ${units}${s}`
    }
    if (mode === "between") {
        const { min, max } = date
        if(min == null || max == null) return
        const minFormatted = moment(min).format("MMM D, YYYY")
        const maxFormatted = moment(max).format("MMM D, YYYY")
        return `Between ${minFormatted} and ${maxFormatted}`
    }
}

export function getTimezone(): string{
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}