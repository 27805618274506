import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./TableV2.module.css";
import { defaultRender } from "utils/rows";
import { Progress } from "components/Progress/Progress";
import { bclass } from "helpers";
import { CircularProgress } from "@mui/material";
import { UilFilterSlash } from '@iconscout/react-unicons';

export function TableV2({
    data: iData,
    selected: iSelected,
    loading,
    hardLoading,
    rowHeight,
    headerHeight,
    top,
    sortModel,
    onSortModelChange,
    onCheckboxClick,
    columns,
    checkboxes,
    getRowHref,
    noData,
    classes
}) {
    const initialMaxWidths = columns.reduce((acc, column) => {
        acc[column.key] = 0;
        return acc;
    }, {});


    const [data, setData] = useState(iData);
    const navigate = useNavigate();
    const selected = iSelected?.map(item => item.id);

    const [cellWidths, setCellWidths] = useState(initialMaxWidths);

    const handleCellRender = ({ key, width }) => {
        setCellWidths(prevWidths => {
            return { ...prevWidths, [key]: Math.max(prevWidths[key], width) };
        });
    };
    
    const {
        wrap: wrapClass,
        table: tableClass,
        tbody: tbodyClass,
    } = classes || {};

    function onRowClicked(row) {
        if (getRowHref == null) return
        const href = getRowHref(row);
        if (href) navigate(href);
    }

    useEffect(() => {
        if (!iData) return setData(null);
        const data = iData?.map((row, index) => ({ ...row, id: index }));
        setData(data);
    }, [iData]);

    if (data == null || hardLoading) {
        return <TableDataContextProvider value={{ handleCellRender, columns }}>
            <div className={`${styles.tableWrapOuter} ${wrapClass}`}>
                {top && <div className={styles.tableTop}>{top}</div>}
                <div className={`${styles.tableWrap}`}>
                    <div className={`${styles.table} ${tableClass}`}>
                        <TableHeaders
                            columns={columns}
                            classes={classes}
                            cellWidths={cellWidths}
                            height={headerHeight || rowHeight}
                            checkboxes={checkboxes}
                            sortModel={sortModel}
                            onSortModelChange={onSortModelChange}
                        />
                        <div className={styles.noResultsWrap}>
                            <div className={styles.noResultsWrapInner}>
                                <CircularProgress sx={{ color: "var(--alpha-text)" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TableDataContextProvider>
    }

    if (data.length === 0) {
        return <TableDataContextProvider value={{ handleCellRender, columns }}>
            <div className={`${styles.tableWrapOuter} ${wrapClass}`}>
                {top && <div className={styles.tableTop}>{top}</div>}
                <div className={`${styles.tableWrap}`}>
                    <div className={`${styles.table} ${tableClass}`}>
                        <TableHeaders
                            columns={columns}
                            classes={classes}
                            cellWidths={cellWidths}
                            height={headerHeight || rowHeight}
                            checkboxes={checkboxes}
                            sortModel={sortModel}
                            onSortModelChange={onSortModelChange}
                        />
                        <div className={styles.noResultsWrap}>
                            <div className={styles.noResultsWrapInner}>
                                <div className={styles.emptyIcon}>
                                    <UilFilterSlash />
                                </div>
                                <div className={styles.title}>{noData?.title || "No results"}</div>
                                <div className={styles.description}>{noData?.description || "Try changing your filters or search terms to find what you're looking for"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TableDataContextProvider>
    }

    return <TableDataContextProvider value={{ handleCellRender, columns }}>
        <div className={`${styles.tableWrapOuter} ${wrapClass}`}>
            {top && <div className={styles.tableTop}>{top}</div>}
            <div className={`${styles.tableWrap}`}>
                <div className={`${styles.table} ${tableClass}`}>
                    <TableHeaders
                        columns={columns}
                        classes={classes}
                        cellWidths={cellWidths}
                        height={headerHeight || rowHeight}
                        checkboxes={checkboxes}
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                    />
                    {loading && <div className={styles.loaderWrap}>
                        <Progress size={2} />
                    </div>}
                    <div className={tbodyClass}>
                        {data?.map((row, idx) => {
                            const isSelected = selected?.includes(row.id);
                            return (
                                <TableRow
                                    key={idx}
                                    row={row}
                                    cellWidths={cellWidths}
                                    columns={columns}
                                    height={rowHeight}
                                    checkboxes={checkboxes}
                                    selected={isSelected}
                                    onClick={() => onRowClicked(row)}
                                    onSelect={() => {
                                        onCheckboxClick({
                                            item: row,
                                            selected: !isSelected
                                        })
                                    }}
                                    classes={classes}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    </TableDataContextProvider>



    // return <div className={`${styles.tableWrapOuter} ${wrapClass}`}>
    //     {top && <div className={styles.tableTop}>{top}</div>}
    //     <div className={`${styles.tableWrap}`}>
    //         <table className={`${styles.table} ${tableClass}`}>
    //             <TableHeaders
    //                 columns={columns}
    //                 classes={classes}
    //                 height={headerHeight || rowHeight}
    //                 checkboxes={checkboxes}
    //                 sortModel={sortModel}
    //                 onSortModelChange={onSortModelChange}
    //             />
    //             {loading && <div className={styles.loaderWrap}>
    //                 <Progress size={2} />
    //             </div>}
    //             <tbody className={tbodyClass}>
    //                 {data?.map((row, idx) => {
    //                     const isSelected = selected?.includes(row.id);
    //                     return (
    //                         <TableRow
    //                             key={idx}
    //                             row={row}
    //                             columns={columns}
    //                             height={rowHeight}
    //                             checkboxes={checkboxes}
    //                             selected={isSelected}
    //                             onClick={() => onRowClicked(row)}
    //                             onSelect={() => {
    //                                 onCheckboxClick({
    //                                     item: row,
    //                                     selected: !isSelected
    //                                 })
    //                             }}
    //                             classes={classes}
    //                         />
    //                     );
    //                 })}
    //             </tbody>
    //         </table>
    //     </div>
    // </div>

}

function TableHeaders({ columns, height, cellWidths, checkboxes, sortModel, onSortModelChange, classes }) {

    const {
        thead: theadClass,
        theadCell: theadCellClass,
    } = classes || {};

    function sortHeaderChanged(column) {
        if (!column.sortable) return;
        const { key } = column;
        const { key: sortKey, direction } = sortModel || {};
        if (sortKey === key) {
            if (direction === "asc") {
                onSortModelChange(null)
                return
            }

            onSortModelChange({
                key,
                direction: "asc"
            })

            return
        }

        onSortModelChange({
            key,
            direction: "desc"
        })
    }

    return <div className={`${styles.tableHeaders} ${theadClass}`} style={{ height: `${height}px` }}>
        {checkboxes && <div className={styles.checkboxButton} style={{ cursor: "default", height: `${height}px` }}>
            <div className={styles.checkboxHeader} />
        </div>}
        {columns.map((column, idx) => {

            const { sortable, title, minWidth: minWidthDef, maxWidth: maxWidthDef, height } = column;
            const minWidth = cellWidths?.[column.key] || minWidthDef || 170;
            const maxWidth = maxWidthDef || cellWidths?.[column.key];
            const flex = undefined;

            if (!sortable) return <div key={idx} className={`${styles.tableCell} ${theadCellClass}`} style={{ minWidth, maxWidth, flex, height}}>
                {title}
            </div>

            const isSortedAsc = sortModel?.key === column.key && sortModel?.direction === "asc";
            const isSortedDesc = sortModel?.key === column.key && sortModel?.direction === "desc";

            return <div key={idx} className={`${styles.tableCell} ${theadCellClass}`} style={{ minWidth, maxWidth, flex, height }}>
                <button className={styles.sortableTableHeader} onClick={() => sortHeaderChanged(column)}>
                    <div className={styles.tableHeaderText}>
                        {column.title}
                    </div>
                    <div className={styles.sortIcon}>
                        <svg className={bclass(isSortedAsc, styles.active)} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                            <path d="M9.207 1A2 2 0 0 0 6.38 1L.793 6.586A2 2 0 0 0 2.207 10H13.38a2 2 0 0 0 1.414-3.414L9.207 1Z" />
                        </svg>
                        <svg className={bclass(isSortedDesc, styles.active)} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                            <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z" />
                        </svg>
                    </div>
                </button>
            </div>
        })}
    </div>
}

function TableRow({ columns, height, row, cellWidths, onClick, selected, onSelect, checkboxes, classes }) {
    const { tbodyRow: tbodyRowClass, tbodyCell: tbodyCellClass } =
        classes || {};

    return (
        <div className={`${styles.tableRow} ${tbodyRowClass}`} onClick={onClick} style={{ height: `${height}px` }}>
            {checkboxes && <Checkbox selected={selected} onClick={onSelect} />}
            {columns.map((column, idx) => {
                return <TableCell key={idx} column={column} row={row} cellWidths={cellWidths} />
            })}
        </div>
    );
}

function TableCell({ column, row, cellWidths }) {

    const { handleCellRender, columns } = useTableContext();

    useEffect(() => {
        const { width } = ref.current.getBoundingClientRect();
        const { key } = column;
        handleCellRender({ key, width });
    }, [columns, row])

    const ref = useRef();
    const value = row[column.key];

    const { renderCell = defaultRender, minWidth: minWidthDef, maxWidth: maxWidthDef, flex, height } = column;
    const minWidth = cellWidths?.[column.key] || minWidthDef || 170;
    const maxWidth = flex != null ? undefined : maxWidthDef || cellWidths?.[column.key];

    return <div className={styles.tableCell} style={{ minWidth, maxWidth, flex, height }} ref={ref}>
        {renderCell({ value, data: row })}
    </div>
}

function Checkbox({ selected, onClick }) {
    return <div>
        <button className={styles.checkboxButton} onClick={(e) => {
            e.stopPropagation();
            onClick()
        }}>
            <div className={`${styles.checkbox} ${selected ? styles.selected : styles.unselected}`}>
                {selected && <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>}
            </div>
        </button>
    </div>
}


const TableDataContext = createContext();
function TableDataContextProvider({ children, value }) {
    return <TableDataContext.Provider value={value}>
        {children}
    </TableDataContext.Provider>
}
function useTableContext() {
    const context = useContext(TableDataContext)
    if (context === undefined) {
        throw new Error('useTableContext must be used within TableDataContext')
    }
    return context
}