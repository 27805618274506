import { useRef, useState } from "react"
import styles from "./ValueInput.module.css"
import { UilAngleDown } from '@iconscout/react-unicons'
import { SelectV2 } from "components/SelectV2/SelectV2"

export function ValueInput({ datatype, value, onChange, onOpen, className, isOpen, buttonText, empty, fill, locked, pref }) {

    const sx = getStyles({ fill })

    if (datatype === "numeric") {
        return <PropertyValueNumeric
            value={value}
            onChange={onChange}
            sx={sx}
        />
    }

    if (datatype === "string") {
        return <button className={`${styles.select} ${locked ? styles.locked : ""} ${isOpen ? styles.isOpen : ""} ${className}`} style={sx} onClick={(e) => {
            if (locked) return;
            onOpen()
        }} ref={pref}>
            <div className={`${styles.selectText} ${empty ? styles.placeholder : ""}`}>{buttonText}</div>
            <div className={styles.arrowWrap}><UilAngleDown /></div>
        </button>
    }

    if (datatype === "bool") {
        return <SelectV2
            sx={sx}
            option={value}
            placeholder="Select..."
            options={[
                { id: true, name: "True" },
                { id: false, name: "False" },
            ]}
            onChange={(mode) => {
                onChange(mode)
            }}
        />
    }

}

function PropertyValueNumeric({ value, onChange, sx }) {
    const inputRef = useRef(null)
    const [focused, setFocused] = useState(false)
    const [tempValue, setTempValue] = useState(value || "")

    function handleKeyPress(event) {
        if (event.keyCode === 13) inputRef.current.blur()
    }

    function blurAndCommitChange() {
        setFocused(false)
        commitChange()
    }

    function commitChange() {
        // parse float then set value
        const parsedValue = tempValue === "" ? undefined : parseFloat(tempValue)
        if (isNaN(parsedValue)) {
            setTempValue(value)
            return
        }
        setTempValue(parsedValue)
        onChange(parsedValue)
    }

    function onChangeParseNumeric(event) {
        const value = event.target.value
        // remove any non-numeric characters
        const valueOnlyNumbers = value.replace(/[^0-9.]/g, "")
        // const parsedValue = value === "" ? undefined : parseFloat(value)
        // if (isNaN(parsedValue)) return
        setTempValue(valueOnlyNumbers)
    } 

    return <div className={`${styles.select} ${styles.numeric} ${focused ? styles.isOpen : ""}`} style={sx}>
        <input
            ref={inputRef}
            className={styles.selectInput}
            type="text"
            onFocus={() => setFocused(true)}
            onBlur={blurAndCommitChange}
            placeholder="Enter value..."
            value={tempValue}
            onChange={onChangeParseNumeric}
            onKeyDown={handleKeyPress}
        />
    </div>
}

function getStyles({ fill }) {
    let styles = {}
    if (fill) {
        styles.width = "100%"
        styles.maxWidth = "unset"
    }
    return styles
}