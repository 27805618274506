import { validGranularities } from "Constants";
import styles from "./EventsNew.module.css";
import { useQueryFunctions } from "ApiConnector";
import { deleteQuery, getQuery, saveQuery } from "helpers/query";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

export function EventsNew() {
    const { action, query } = useParams();
    const { invalidate } = useQueryFunctions()
    const [queryData, setQueryData] = useState(undefined)
    const [loadingSave, setLoadingSave] = useState(action === "edit")
    const [originalRequestOptions, setOriginalRequestOptions] = useState(undefined)
    const [saving, setSaving] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [saveModal, setSaveModal] = useState(false)

    const [granularity, setGranularity] = useState(FALLBACK_GRANULARITY)
    const [date, setDate] = useState({ mode: "period", period: FALLBACK_PERIOD, min: null, max: null })
    const navigate = useNavigate()
    const [events, setEvents] = useState([])
    const [filters, setFilters] = useState([])

    // async function fetchQueryData() {
    //     setLoadingSave(true)
    //     const queryData = await getQuery(query)
    //     const queryInnerData = queryData.data
    //     const { date: d, granularity: g, events: e, filters: f } = queryInnerData

    //     setDate(d)
    //     setGranularity(g)
    //     setEvents(e)
    //     setFilters(f)

    //     const oro = getRequestOptions(e, f, d, g)
    //     setQueryData(queryData)
    //     setOriginalRequestOptions(oro)
    //     setLoadingSave(false)
    // }

    // useEffect(() => {
    //     // purpose: allow for switch to create from edit
    //     if (action !== "create") return
    //     if (queryData == null) return
    //     setQueryData(undefined)
    //     setOriginalRequestOptions(undefined)
    //     setEvents([])
    //     setFilters([])
    //     setDate({ mode: "period", period: FALLBACK_PERIOD, min: null, max: null })
    //     setGranularity(FALLBACK_GRANULARITY)
    // }, [action])

    // useEffect(() => {
    //     // purpose: allow for navigating to a query from the home page
    //     if (queryData == null) return
    //     if (!query) return
    //     setQueryData(undefined)
    //     fetchQueryData()
    // }, [query])

    // useEffect(() => {
    //     // purpose: allow for loading a query after click
    //     if (queryData != null) return
    //     if (action !== "edit") return
    //     if (events.length !== 0 || filters.length !== 0) return
    //     fetchQueryData()
    // }, [action, query])

    // async function save({ name, description }) {
    //     if (name === "") return
    //     const store = {
    //         events,
    //         // filters: getStoredFilters(filters),
    //         date,
    //         granularity,
    //     }
    //     if (action === "create") {
    //         setSaveModal(false)
    //         setSaving(true)
    //         const id = await saveQuery({
    //             name,
    //             description,
    //             type: "event",
    //             created: new Date(),
    //             updated: new Date(),
    //             data: store,
    //         })
    //         invalidate("general/home")
    //         navigate(`/query/event/edit/${id}`)
    //         setSaving(false)
    //         setQueryData({ id, name, description, data: store })
    //     }
    //     if (action === "edit") {
    //         const { id } = queryData
    //         if (id == null) return
    //         setSaveModal(false)
    //         setSaving(true)
    //         await saveQuery({
    //             id,
    //             updated: new Date(),
    //             name,
    //             description,
    //             data: store,
    //         })
    //         invalidate("general/home")
    //         setSaving(false)
    //         setQueryData({ ...queryData, name, description, data: store })
    //     }
    //     // const oro = getRequestOptions(events, filters, date, granularity)
    //     // setOriginalRequestOptions(oro)
    // }

    // async function deleteChart() {
    //     const promise = new Promise(async (resolve, reject) => {
    //         try {
    //             const { id } = queryData
    //             await deleteQuery(id)
    //             invalidate("general/home")
    //             navigate("/")
    //             resolve()
    //         } catch (e) {
    //             reject()
    //         }
    //     })

    //     toast.promise(promise, {
    //         loading: "Deleting chart...",
    //         error: "There was an error deleting this chart",
    //         success: "Successfully deleted chart"
    //     })

    // }

    // function onDateChange(mode, period, minimum, maximum) {
    //     setDate({ mode, period, min: minimum, max: maximum })
    // }

    // function onGranularityChange(ng) {
    //     setGranularity(ng)
    //     const g = validGranularities.find((x) => x.id === ng)
    //     setDate({ mode: "period", period: g.periods[1].id, min: null, max: null })
    // }

    // function onPeriodSelect(period) {
    //     onDateChange("period", period.id, null, null)
    // }


    return <div className={styles.container}>
        <div className={styles.sidebar}>
            <EventsWrapper events={events} setEvents={setEvents} />
            <FiltersWrapper />
            <GroupWrapper />
        </div>
        <div className={styles.content}>

        </div>
    </div>
}

function EventsWrapper({ events, setEvents }) {
    return <div className={styles.events}>
        <div>Events</div>
        {events.map((event) => {
            return <div>{event}</div>
        })}
    </div>
}

function FiltersWrapper() {
    return <div>filters</div>
}

function GroupWrapper() {
    return <div>group</div>
}

const FALLBACK_GRANULARITY = "d"
const FALLBACK_PERIOD = validGranularities.find((x) => x.id === FALLBACK_GRANULARITY).periods[1].id
