import moment from 'moment';
import { cloneElement, forwardRef, useEffect, useRef, useState } from 'react';
import RDP from 'react-datepicker'
import styles from './DatePicker.module.css'
import { UilAngleLeftB, UilAngleRightB } from '@iconscout/react-unicons'
import { Popover } from '@mui/material';

export function SingleDatePicker({ date: dateISO, onChange, min = null, max = null, classes = {} }) {

    const date = moment(dateISO).toDate()
    const dateFormatted = moment(date).format('MMM DD, YYYY');
    const minMomentObj = getMinRange(min)
    const maxMomentObj = getMaxRange(max)
    const validDateRange = [{ start: minMomentObj.toDate(), end: maxMomentObj.toDate() }]

    return (
        <RDP
            selected={date}
            onChange={onChange}
            includeDateIntervals={validDateRange}
            dayContainerClassName={styles.dayContainer}
            calendarContainer={CalendarContainer}
            renderCustomHeader={Header}
            customInput={cloneElement(<CustomInput />, { classes, dateFormatted })}
            popperModifiers={[
                {
                    name: "preventOverflow",
                    options: {
                        rootBoundary: "viewport",
                        tether: false,
                        altAxis: true,
                    },
                },
            ]}
        />
    )
}

const CustomInput = forwardRef(({ classes, onClick, dateFormatted }, ref) => {

    return <button ref={ref} className={classes?.input} onClick={onClick}>{dateFormatted}</button>
})

const CustomInputMulti = forwardRef(({ classes, onClick, startDate, endDate }, ref) => {

    const startDateFormatted = formatDate(startDate)
    const endDateFormatted = formatDate(endDate)
    let dateFormatted = `${startDateFormatted} - ${endDateFormatted}`;
    if (startDateFormatted === '' && endDateFormatted === '') {
        dateFormatted = 'select range'
    }

    return <button ref={ref} className={classes?.input} onClick={onClick}>{dateFormatted}</button>
})

function CalendarContainer({ children }) {
    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}

function Header({
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
}) {
    //Format monthDate to be like this: January 2021 using moment
    const monthDateFormatted = moment(monthDate).format('MMM YYYY');
    //only show next if the first of next month is not in the future
    const nextMonth = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 1);
    const showNext = nextMonth <= new Date();

    return <div className={styles.headerContainer}>
        <button className={styles.headerNavButton} onClick={decreaseMonth}><UilAngleLeftB /></button>
        <span>{monthDateFormatted}</span>
        {showNext ? <button className={styles.headerNavButton} onClick={increaseMonth}><UilAngleRightB /></button> : <div className={styles.headerNavButtonEmpty}>&nbsp;</div>}
    </div>
}



export function MultiDatePicker({ date: dateISO, onChange, min = null, max = null, classes = {} }) {

    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);
    const [dateDraft, setDateDraft] = useState(dateISO);

    function onApply() {
        if (dateDraft[0] === null || dateDraft[1] === null) return;
        onChange([...dateDraft])
        onClose();
    }

    // listen for enter key and onApply()
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.key === 'Enter') {
                onApply();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [dateDraft]);

    function onDateDraftChange(datesObj) {
        // const startTimestamp = dateToSavedDate(datesObj[0])
        // const endTimestamp = dateToSavedDate(datesObj[1])
        setDateDraft([datesObj[0], datesObj[1]])
    }

    const [startDate, endDate] = dateDraft;

    const minMomentObj = getMinRange(min)
    const maxMomentObj = getMaxRange(max)
    const validDateRange = [{ start: minMomentObj.toDate(), end: maxMomentObj.toDate() }]
    const [activeStartDate, activeEndDate] = dateISO

    // startDate should be null if its invalid
    const startDateObj = moment(startDate).isValid() ? moment(startDate).toDate() : null
    const endDateObj = moment(endDate).isValid() ? moment(endDate).toDate() : null

    return (
        <RDP
            // selected={selectedDate}
            startDate={startDateObj}
            endDate={endDateObj}
            open={open}
            onInputClick={onOpen}
            onClickOutside={onApply}
            selectsRange
            includeDateIntervals={validDateRange}
            onChange={onDateDraftChange}
            dayContainerClassName={styles.dayContainer}
            calendarContainer={CalendarContainer}
            renderCustomHeader={(props) => <HeaderMulti start={startDateObj} end={endDateObj} onChange={onDateDraftChange} min={minMomentObj.toDate()} max={maxMomentObj.toDate()}  {...props} />}
            customInput={cloneElement(<CustomInputMulti />, { classes, startDate: activeStartDate, endDate: activeEndDate })}
            popperModifiers={[
                {
                    name: "preventOverflow",
                    options: {
                        rootBoundary: "viewport",
                        tether: false,
                        altAxis: true,
                        padding: 16,
                    },
                },
            ]}
        >
            <div className={styles.bottomWrap}>
                <button className={styles.outlineButton} onClick={onClose}>Cancel</button>
                <button className={styles.applyButton} onClick={onApply}>Apply</button>
            </div>
        </RDP>
    )
}

// function timestampToDt(timestamp) {
//     if (timestamp) {
//         const dt = new Date(timestamp * 1000);
//         return dt
//     }
//     return null;
// }

function formatDate(dt) {
    if (dt) {
        const dtFmt = moment(dt).format('MMM DD, YYYY');
        return dtFmt
    }
    return '';
}


function HeaderMulti({
    monthDate,
    changeYear,
    changeMonth,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    start,
    end,
    min,
    max,
    onChange,
    ...props
}) {
    function onStartChange(startDt) {
        const startISO = moment(startDt).toISOString()
        const endISO = moment(end).toISOString()
        onChange([startISO, endISO])
        changeMonth(startDt.getMonth());
        changeYear(startDt.getFullYear());
    }

    function onEndChange(endDt) {
        const startISO = moment(start).toISOString()
        const endISO = moment(endDt).toISOString()
        onChange([startISO, endISO])
        changeMonth(endDt.getMonth());
        changeYear(endDt.getFullYear());
    }

    const monthDateFormatted = moment(monthDate).format('MMM YYYY');
    const nextMonth = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 1);
    const showNext = nextMonth <= new Date();

    return <>
        <div className={styles.headerInputWrap}>
            <SmartDateInput
                className={styles.datepickerInput}
                date={start}
                min={min}
                max={max}
                onValidDate={onStartChange}
            />
            <SmartDateInput
                className={styles.datepickerInput}
                date={end}
                min={min}
                max={max}
                onValidDate={onEndChange}
            />
        </div>
        <div className={styles.headerContainer}>
            <button className={styles.headerNavButton} onClick={decreaseMonth}><UilAngleLeftB /></button>
            <span>{monthDateFormatted}</span>
            {showNext ? <button className={styles.headerNavButton} onClick={increaseMonth}><UilAngleRightB /></button> : <div className={styles.headerNavButtonEmpty}>&nbsp;</div>}
        </div></>
}


export function SingleDatePickerInline({ date: dateISO, onChange, max }) {
    const date = moment(dateISO).toDate();

    return (
        <RDP
            selected={date}
            inline
            onChange={(v) => {
                const startISO = moment(v).toISOString()
                onChange(startISO)
            }}
            // minDate={min}
            maxDate={max || moment().toDate()}
            dayContainerClassName={styles.dayContainer}
            calendarContainer={CalendarContainer}
            renderCustomHeader={Header}
            popperModifiers={[
                {
                    name: "preventOverflow",
                    options: {
                        rootBoundary: "viewport",
                        tether: false,
                        altAxis: true,
                    },
                },
            ]}
        >
        </RDP>
    )
}


export function MultiDatePickerInline({ date, onChange }) {

    const {min: startDateISO, max: endDateISO} = date;
    const startDate = startDateISO != null ? moment(startDateISO).toDate() : null;
    const endDate = endDateISO != null ? moment(endDateISO).toDate() : null;

    const maxDt = moment().toDate()
    // minDt 10 years ago
    const minDt = moment().subtract(10, 'years').toDate()
    const validDateRange = [{ start: minDt, end: maxDt }]

    return (
        <RDP
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            onChange={(v) => {
                const [startDt, endDt] = v
                const startISO = moment(startDt).toISOString()
                const endISO = moment(endDt).toISOString()
                // onChange([startISO, endISO])
                onChange({min: startISO, max: endISO})
            }}
            includeDateIntervals={validDateRange}
            dayContainerClassName={styles.dayContainer}
            calendarContainer={CalendarContainer}
            renderCustomHeader={(props) => <HeaderMulti start={startDate} end={endDate} onChange={onChange}
                min={minDt}
                max={maxDt}
                {...props} 
            />}
            popperModifiers={[
                {
                    name: "preventOverflow",
                    options: {
                        rootBoundary: "viewport",
                        tether: false,
                        altAxis: true,
                        padding: 16,
                    },
                },
            ]}
        >
        </RDP>
    )
}

export function SmartDateInput({ date, onValidDate, min, max, className }) {

    const [dateInput, setDateInput] = useState(date ?? "");
    const ref = useRef(null);

    useEffect(() => {
        // if startRef (input) is focused, return
        if (ref.current === document.activeElement) {
            return;
        }
        const fmt = moment(date, 'MM/DD/YYYY', true);
        setDateInput(fmt.isValid() ? fmt.format('MM/DD/YYYY') : "")
    }, [date])

    function onDateInputChange(e) {
        const dateInputNewVal = e.target.value;
        setDateInput(dateInputNewVal);
        const dtObj = validateDate(dateInputNewVal, min, max);
        if (dtObj != null) {
            onValidDate(dtObj);
        }
    }

    return <input
        className={className}
        type={"text"}
        value={dateInput}
        onChange={onDateInputChange}
        ref={ref}
    />
}

function validateDate(dateText, min, max) {

    const tenYearsAgo = new Date();
    tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);
    const testFormats = ["MM/DD/YYYY", "M/DD/YYYY", "MM/D/YYYY", "M/D/YYYY"]
    for (let i = 0; i < testFormats.length; i++) {
        const format = testFormats[i];
        const date = moment(dateText, format, true);
        if (date.isValid() && date.isAfter(tenYearsAgo) && date.isBefore(new Date())) {
            return date.toDate();
        }
    }

    return null;
}

function getMinRange(min) {
    // if min null, return ten years ago
    if (min == null) {
        // const tenYearsAgo = new Date();
        // tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);
        const tenYearsAgo = moment().subtract(10, 'years');
        return tenYearsAgo
    }
    return min
}

function getMaxRange(max) {
    // if max null, return today
    if (max == null) {
        return moment()
    }
    return max
}