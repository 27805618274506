import { Popover } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import styles from './SelectWithInput.module.css';

export function SelectWithInput({ options: initialOptions = null, value = "", unit = "", placeholder = "Select a value...", preText = null, postText = null, type = "number", onChange, formatValue, icon = undefined, classes = {}, maximum = undefined, startAdornment, placement = 'left' }) {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(initialOptions ?? []);
    const anchorRef = useRef(null);
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const [highlighted, setHighlighted] = useState(0);
    const [warning, setWarning] = useState(null)

    useEffect(() => {

        try {
            if (type === "number") {
                const parsed = parseFloat(inputValue);
                if (isNaN(parsed)) {
                    return
                }
                if (maximum != null && parsed > maximum) {
                    setWarning({
                        message: `Input exceeds max of ${maximum} ${unit}s`
                    })
                    return
                }
                setWarning(null)
            }
            if (type === "integer") {
                const parsed = parseInt(inputValue);
                if (isNaN(parsed)) {
                    return
                }
                if (maximum != null && parsed > maximum) {
                    setWarning({
                        message: `Input exceeds max of ${maximum} ${unit}s`
                    })
                    return
                }
                setWarning(null)
            }
        } catch { }
    }, [inputValue])

    useEffect(() => {

        if (!open) return

        const validIn = getValidInput(inputValue);
        const effectiveOptions = [validIn, ...options ?? []];

        function handleKeyDown(e) {

            if (e.key === "Enter") {
                e.preventDefault()

                if (highlighted >= 0 && highlighted < effectiveOptions.length) {
                    if (highlighted === 0) {
                        if (validIn == null) {
                            return
                        }
                        btnClick(validIn);
                    } else {
                        btnClick(effectiveOptions[highlighted].value);
                    }
                }
            }
        }
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [inputValue, highlighted, options, onChange, open, setOpen]);


    // listen for arrow keys
    useEffect(() => {
        if (!open) return
        const validIn = getValidInput(inputValue);
        const effectiveOptions = [validIn, ...options ?? []];

        function handleKeyDown(e) {

            if (e.key === "ArrowDown") {
                e.preventDefault()
                const newHighlighted = highlighted >= effectiveOptions.length - 1 ? effectiveOptions.length - 1 : highlighted + 1
                setHighlighted(newHighlighted)
            }
            if (e.key === "ArrowUp") {
                e.preventDefault()
                const newHighlighted = highlighted <= 0 ? 0 : highlighted - 1
                setHighlighted(newHighlighted)
            }
        }
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [highlighted, options, onChange, open, setOpen]);

    useEffect(() => {
        if (initialOptions === null) return;
        setOptions(initialOptions);
    }, [initialOptions])

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 10);
        }

        if (!open) {
            setInputValue("");
        }

    }, [open])

    function inputChange(e) {

        const v = e.target.value;
        setInputValue(v);
        //filter options
        if (v === "") {
            setOptions(initialOptions);
            return;
        }
        const filtered = initialOptions.filter(o => o.name.toString().toLowerCase().includes(v.toLowerCase()));
        setOptions(filtered);
    }

    function getValidInput(iv) {
        if (type === "number") {
            const parsed = parseFloat(iv);
            if (isNaN(parsed)) {
                return null
            }
            if (maximum != null && parsed > maximum) {
                // setWarning({
                //     message: `Input exceeds max of ${maximum} days`
                // })
                return null
            }
            return parsed.toString();
        }
        if (type === "integer") {
            const parsed = parseInt(iv);
            if (isNaN(parsed)) {
                return null
            }
            if (maximum != null && parsed > maximum) {
                // setWarning({
                //     message: `Input exceeds max of ${maximum} days`
                // })
                return null
            }
            return parsed.toString();
        }
        return null;
    }

    function btnClick(value) {
        onChange(value);
        setOpen(false);
    }

    const formatted = formatValue ? formatValue(value) : value;
    const valueFalsy = formatted === "" || formatted == null;
    const txt = valueFalsy ? placeholder : <>{startAdornment} {formatted}</>;

    const defaultValue = type === "number" ? 0 : "(empty)"

    const validInput = getValidInput(inputValue);

    const iconEl = icon ? <span className={styles.icon}>{icon}</span> : null;

    return (
        <>
            <button style={{ position: "relative" }} ref={anchorRef} onClick={() => setOpen(true)} className={`${styles.topButton} ${classes?.input} ${valueFalsy ? styles.placeholder : ""}`}>{preText != null && `${preText} `}{txt}{postText != null && ` ${postText}`}{iconEl}</button>
            <Popover
                open={open}
                onClose={() => setOpen(false)}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: placement,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: placement,
                }}
                transitionDuration={0}
            >
                <div className={styles.selectorWrap}>
                    <div className={styles.selectorHeader}>
                        <input ref={inputRef} value={inputValue} onChange={inputChange} placeholder={"Enter a value..."} className={styles.daysInput} type={"text"} />
                    </div>
                    {validInput && <button className={`${styles.option} ${highlighted === 0 ? styles.optionHighlighted : ""}`} onClick={() => btnClick(validInput)}>{validInput}</button>}
                    <div className={styles.selectorBody}>
                        {warning && <div className={styles.warningInline}>
                            {warning.message}
                        </div>}
                        {(options.length === 0 && (inputValue === "" || inputValue == null)) && <button className={`${styles.option} ${defaultValue === value && styles.selected}`} onClick={() => btnClick(defaultValue)}>{defaultValue}</button>}
                        {options.map((option, i) => {
                            return <button key={i} className={`${styles.option} ${option.value === value && styles.selected} ${highlighted === i + 1 ? styles.optionHighlighted : ""}`} onClick={() => btnClick(option.value)}>{option.value}</button>
                        })}
                    </div>
                </div>

            </Popover>
        </>
    )

}