import { CircularProgress } from '@mui/material';
import styles from './CustomLoadingButtonV2.module.css';

export function CustomLoadingButtonV2({ loading, className, onClick, light, children }) {
    if (loading) {
        const color = light ? "rgb(255,255,255)" : "rgb(0,0,0)"
        return <div className={`${className} ${styles.stockLoadingButton}`}>
            <CircularProgress size={12} sx={{ color }} />
            {children}
        </div>
    }

    return <button className={className} onClick={onClick}>
        {children}
    </button>
}