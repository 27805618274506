import { useEffect, useState } from 'react'
import styles from "./Actions.module.css"
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Collapse } from '@mui/material';
import { useForm } from 'react-hook-form';
// import PageLoading from 'Components/PageLoading';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { postEndpoint } from 'ApiConnector';
import { signInWithCustomToken, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from 'firebase-config';
import toast from 'react-hot-toast';
import NavLimited from 'components/NavLimited/NavLimited';
import PageLoading from 'components/PageLoading/PageLoading';
import { CustomLoadingButton } from 'components/LoadingButton/LoadingButton';
import { useGlobal } from 'hooks/global.js';

export default function Actions() {

    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors, isValid }, setError, getValues } = useForm({ mode: 'onChange', delayError: 2000 });
    const [searchParams, setSearchParams] = useSearchParams();
    const [resetting, setResetting] = useState(false);
    const [resending, setResending] = useState(false);
    const [pendingLogin, setPendingLogin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorText, setErrorText] = useState(undefined)
    const { user } = useGlobal()

    const oobCode = searchParams.get("oobCode")
    const mode = searchParams.get("mode")

    useEffect(() => {
        if (!pendingLogin || user == null) { return }
        setTimeout(() => {
            navigate("/")
        }, 500);
    }, [pendingLogin, user])


    function completeReset(data) {
        if (data.password !== data.confirm) {
            setError("confirm", {
                type: "manual",
                message: "Your passwords must match",
            })
            return
        }
        setResetting(true)

        async function c() {
            try {
                const res = await postEndpoint("user/completeReset", { "oobCode": oobCode, "newPassword": data.password })
                const token = res.data.token;
                await signInWithCustomToken(auth, token)
                setPendingLogin(true)
                // setTimeout(() => {
                //     navigate("/")
                // }, 2000);
                // toast.success("Successfully set password")
            } catch (error) {
                setErrorText({ "title": "Link expired", "body": "Your reset link has expired. Please try requesting a new link." })
            }
        }

        c()
    }

    function resendEmailClicked() {
        setResending(true)
        async function v() {
            try {
                const res = await verifyPasswordResetCode(auth, oobCode)
                navigate("/login")
            } catch {
                navigate("/reset")
            }
        }

        v()
    }

    if (mode == null || oobCode == null) {
        navigate("/")
        return
    }

    if (loading == null) {
        return <PageLoading extraStyles={{ minHeight: "100vh" }} />
    }

    if (errorText !== undefined) {
        return <div className={styles.pageWrap}>
            <div className={styles.wrapper}>
                <div className={styles.innerWrap}>
                    <ErrorOutlinedIcon sx={{ color: "rgb(var(--alpha-red))", width: "50px", height: "50px", marginBottom: "16px" }} />
                    <div className={styles.pageTitle}>{errorText.title}</div>
                    <div className={styles.pageSubtitle}>{errorText.body}</div>
                    <CustomLoadingButton
                        loading={resending}
                        onClick={resendEmailClicked}
                        className={`${styles.btn} ${(isValid || errors.password?.type === "manual") ? styles.active : styles.inactive}`}
                        disableRipple={true}
                    >
                        Resend Email
                    </CustomLoadingButton>
                    <div className={styles.otherWrap}><Link className={styles.other} to="/login">Back to Login</Link></div>
                </div>
            </div>
        </div>
    }

    if (mode === "resetPassword") {
        return <>
            <NavLimited />
            <div className={styles.pageWrap}>
                <div className={styles.wrapper}>
                    <div className={styles.innerWrap}>
                        <div className={styles.pageTitle}>Set password</div>
                        <form onSubmit={handleSubmit(completeReset)} className={styles.columnForm} noValidate>
                            <input placeholder={"Password"} type="password" {...register('password', {
                                required: {
                                    value: true,
                                    message: "Password is required"
                                },
                                minLength: {
                                    value: 6,
                                    message: "Your password must be at least 6 characters"
                                },
                                maxLength: {
                                    value: 255,
                                    message: "Max password length is 255 characters"
                                }
                            })} className={errors.password ? styles.error : ""} />
                            <Collapse in={Boolean(errors.password)} timeout={300}><div className={styles.errorTxt}>{errors.password?.message}</div></Collapse>
                            <input type={"password"} placeholder={"Confirm Password"} {...register('confirm', {
                                required: "Confirmation is required",
                                validate: (value) => value === watch('password') ? true : "Your passwords must match",
                            })} className={errors.confirm ? styles.error : ""} />
                            <Collapse in={Boolean(errors.confirm)} ><div className={styles.errorTxt} timeout={100}>{errors.confirm?.message}</div></Collapse>
                            <CustomLoadingButton
                                type={"submit"}
                                loading={resetting}
                                disabled={(!isValid || errors.password?.type === "manual")}
                                className={`${styles.btn} ${(isValid || errors.password?.type === "manual") ? styles.active : styles.inactive}`}
                            >
                                Continue
                            </CustomLoadingButton>
                        </form>
                        <div className={styles.otherWrap}><Link className={styles.other} to="/login">Back to Login</Link></div>
                    </div>
                </div>
            </div>
        </>
    }

    navigate("/login")
}