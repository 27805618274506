import styles from "./Cohorts.module.css"
import { ContentCopyRounded, DeleteOutlineRounded, FilterAltRounded, MoreHoriz } from "@mui/icons-material";
import { EVENT_STRUCTURE, LABEL_STRUCTURE, PROPERTY_STRUCTURE, standardOptionFn } from "Constants";
import { DatePickerDropdown } from "components/DatePickerV2/DatePickerV2";
import { Dropdown } from "components/Dropdown/Dropdown";
import { InputButton } from "components/InputButton/InputButton";
import { MultiDropdown } from "components/MultiDropdown/MultiDropdown";
import { ValueInput } from "components/ValueInput/ValueInput";
import { bclass, getOperatorsFromDataType, getValueString, mergeTexts } from "helpers";
import { useRef, useState } from "react";
import { UilCalender } from '@iconscout/react-unicons'
import { isFilterRequired } from "utils/filter";
import { onCohortKeyChange, useCohortKeyOptions, useCohortPropertyValueOptions, BLANK_GROUP } from "utils/cohort";
import { CohortFilter } from "models/cohort";
import { formatDateString } from "utils/date";

export function Cohorts({ cohort, setCohort, requiredFilters, style }) {
    const { groups } = cohort || {}
    const { options: filterOptions, loading: filterKeyLoading } = useCohortKeyOptions()

    function setFilters(groupIndex, filters) {
        const newGroups = [...groups]
        newGroups[groupIndex].filters = filters
        setCohort({ ...cohort, groups: newGroups })
    }

    function onGroupAdd(index) {
        const newGroups = [...groups]
        newGroups.splice(index + 1, 0, { ...BLANK_GROUP })
        setCohort({ ...cohort, groups: newGroups })
    }

    function onGroupChange(index, update) {
        const newGroups = [...groups]
        newGroups[index] = { ...newGroups[index], ...update }
        setCohort({ ...cohort, groups: newGroups })
    }

    return <div className={`${styles.cohortsWrap}`} style={style}>

        {groups.map((group, idx) => {

            const afterOr = idx > 0 && groups[idx - 1].crossGroupOperator === "or"

            return <Group
                key={idx}
                group={group}
                index={idx}
                length={groups.length}
                afterOr={afterOr}
                filterOptions={filterOptions}
                requiredFilters={requiredFilters}
                filterKeyLoading={filterKeyLoading}
                onGroupAdd={() => onGroupAdd(idx)}
                onGroupChange={(val) => onGroupChange(idx, val)}
                setFilters={(val) => setFilters(idx, val)}
            />
        })}
    </div>
}

function Group({ group, index, length, afterOr, filterOptions, requiredFilters, filterKeyLoading, onGroupAdd, onGroupChange, setFilters }) {
    const { filters, groupOperator, crossGroupOperator } = group || {}
    const groupFiltersValid = filters.every((x) => x.isValid())
    function addBlankFilter() {
        const blank = new CohortFilter({
            structure: "BLANK"
        })
        setFilters([...filters, blank])
    }

    function setSubgroup(index, update) {
        const newFilters = [...filters]
        newFilters[index].subgroup = update
        setFilters(newFilters)
    }

    function onFilterChanged({ index, update, type }) {

        const targetFilter = filters[index]
        const { id: targetId, structure } = targetFilter || {}
        const { id: updateId } = update || {}

        if (type === "KEY"){
            if(targetId === updateId) return
            const newFilters = [...filters]
            newFilters[index] = update
            setFilters(newFilters)
            return
        }

        // if the update type isn't KEY, then the structure must be the same

        if (structure === "BLANK") {
            // blank filter is only editable by FilterKey
            console.error("BLANK filter should not be editable")
            return
        }

        if (structure === LABEL_STRUCTURE) {
            // label filter is only editable by FilterKey
            console.error("LABEL_STRUCTURE filter should not be editable")
            return
        }

        if (structure === PROPERTY_STRUCTURE) {
            const newFilters = [...filters]
            newFilters[index].property = update.property
            setFilters(newFilters)
            return
        }

        if (structure === EVENT_STRUCTURE) {
            const newFilters = [...filters]
            newFilters[index].event = update.event
            setFilters(newFilters)
            return
        }

    }


    function onSubfilterChange({ index, subfilterIndex, update, type }) {

        const targetFilter = filters[index]
        const { subgroup } = targetFilter || {}
        const { filters: subFilters = [] } = subgroup || {}
        const targetSubfilter = subFilters[subfilterIndex]
        const { id: targetId, structure } = targetSubfilter || {}
        const { id: updateId } = update || {}

        if (type === "KEY"){
            if(targetId === updateId) return
            const newFilters = [...filters]
            newFilters[index].subgroup.filters[subfilterIndex] = update
            setFilters(newFilters)
            return
        }

        // if the update type isn't KEY, then the structure must be the same

        if (structure === "BLANK") {
            // blank filter is only editable by FilterKey
            console.error("BLANK filter should not be editable")
            return
        }

        if (structure === LABEL_STRUCTURE) {
            // label filter is only editable by FilterKey
            console.error("LABEL_STRUCTURE filter should not be editable")
            return
        }

        if (structure === PROPERTY_STRUCTURE) {
            const newFilters = [...filters]
            newFilters[index].subgroup.filters[subfilterIndex].property = update.property
            setFilters(newFilters)
            return
        }

        if (structure === EVENT_STRUCTURE) {
            const newFilters = [...filters]
            newFilters[index].subgroup.filters[subfilterIndex].event = update.event
            setFilters(newFilters)
            return
        }

    }

    function onSubfilterAdd(index) {

        const subgroup = filters[index].subgroup
        const { filters: subfilters = [] } = subgroup || {}
        if(subfilters.length === 0){
            const newFilters = [...filters]
            newFilters[index].subgroup = { ...BLANK_GROUP }
            newFilters[index].subgroup.filters = [
                new CohortFilter({ structure: "BLANK" })
            ]
            setFilters(newFilters)
            return
        }

        const newFilters = [...filters]
        const newSubgroup = { ...newFilters[index].subgroup }
        const newSubgroupFilters = [...newSubgroup.filters]
        newSubgroupFilters.push(new CohortFilter({ structure: "BLANK" }))
        newSubgroup.filters = newSubgroupFilters
        newFilters[index].subgroup = newSubgroup

        setFilters(newFilters)
    }

    function onSubfilterDelete({ index, subfilterIndex }) {
        const newFilters = [...filters]
        newFilters[index].subgroup.filters.splice(subfilterIndex, 1)
        setFilters(newFilters)
    }

    function onSubfilterDuplicate({ index, subfilterIndex }) {
        const newFilters = [...filters]
        const newSubgroup = { ...newFilters[index].subgroup }
        const newSubgroupFilters = [...newSubgroup.filters]
        newSubgroupFilters.splice(subfilterIndex + 1, 0, { ...newSubgroupFilters[subfilterIndex] })
        newSubgroup.filters = newSubgroupFilters
        newFilters[index].subgroup = newSubgroup

        setFilters(newFilters)
    }

    function addBlankFilter() {
        const blank = new CohortFilter({
            structure: "BLANK"
        })
        setFilters([...filters, blank])
    }

    function onFilterDelete(index) {
        setFilters(filters.filter((_, i) => i !== index))
    }

    function duplicateFilter(index) {
        const newFilters = [...filters, { ...filters[index] }]
        setFilters(newFilters)
    }

    const crossGroupOperatorOptions = [
        { id: "and", name: "and" },
        { id: "or", name: "or" },
    ]

    const isLast = index === length - 1
    const isFirst = index === 0

    const crossGroupOperatorText = crossGroupOperatorOptions.find(opt => opt.id === crossGroupOperator)?.name
    const beforeOr = crossGroupOperator === "or"

    return <>
        <div className={`${styles.group} ${bclass(isLast, styles.lastGroup)} ${bclass(isFirst, styles.firstGroup)} ${bclass(beforeOr, styles.beforeOr)} ${bclass(afterOr, styles.afterOr)}`}>
            <div className={styles.filtersFlex}>
                {filters.map((filter, idx) => {
                    return <FilterLine
                        key={idx}
                        index={idx}
                        filter={filter}
                        filterOptions={filterOptions}
                        groupOperator={groupOperator}
                        required={isFilterRequired(requiredFilters, filter)}
                        filterKeyLoading={filterKeyLoading}
                        onChange={(val, type) => {
                            onFilterChanged({ index: idx, update: val, type })
                        }}
                        onGroupOperatorChange={(val) => { onGroupChange({ groupOperator: val }) }}
                        onSubgroupOperatorChange={(val) => {
                            const { subgroup } = filters[idx] || {}
                            setSubgroup(idx, {
                                ...subgroup,
                                groupOperator: val
                            })
                        }}
                        onSubfilterChange={(subfilterIndex, val, type) => {
                            onSubfilterChange({ index: idx, subfilterIndex, update: val, type })
                        }}
                        onSubfilterDelete={(subfilterIndex) => {
                            onSubfilterDelete({ index: idx, subfilterIndex })
                        }}
                        onSubfilterDuplicate={(subfilterIndex) => {
                            onSubfilterDuplicate({ index: idx, subfilterIndex })
                        }}
                        onFilterDelete={() => onFilterDelete(idx)}
                        onFilterDuplicate={() => duplicateFilter(idx)}
                        onSubfilterAdd={() => onSubfilterAdd(idx)}
                        depth={0}
                    />
                })}
            </div>
            {groupFiltersValid && <button className={`${styles.add} ${filters.length === 0 ? styles.only : ""}`} onClick={addBlankFilter}>
                <div className={styles.plus}>+</div>
                <div className={styles.text}>Add filter</div>
            </button>}
            {isLast && <div className={styles.groupAdd}>
                <div className={styles.groupAddIcon}>
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                        <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                    </svg>
                </div>
                <button className={styles.groupAddButton} onClick={onGroupAdd}>
                    <div className={styles.groupAddButtonIcon}>
                        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.4" d="M9 1v16M1 9h16" />
                        </svg>
                    </div>
                    <div className={styles.groupAddText}>Add group</div>
                </button>
            </div>}
            {!isLast && <div className={`${styles.groupOperator} ${bclass(crossGroupOperator === "or", styles.or)}`}>
                <button className={styles.groupOperatorButton} onClick={() => onGroupChange({ crossGroupOperator: crossGroupOperator === "and" ? "or" : "and" })}>
                    <div className={styles.groupOperatorText}>{crossGroupOperatorText}</div>
                </button>
            </div>}
        </div>
    </>
}

function FilterLine({
    index,
    filter,
    filterKeyLoading,
    filterOptions,
    groupOperator,
    required,
    onChange,
    onFilterDelete,
    onFilterDuplicate,
    onGroupOperatorChange,
    onSubgroupOperatorChange,
    onSubfilterAdd,
    onSubfilterChange,
    onSubfilterDelete,
    onSubfilterDuplicate,
    depth
}) {

    const { subgroup, subfilterOptions, structure, type } = filter;
    // if (filterOptions == null) return <>null</>

    let el = <></>

    if (structure === "BLANK") el = <>
        <FilterPretextV2
            index={index}
            isUserProperty={type !== "event"}
            operator={groupOperator}
            onOperatorChanged={onGroupOperatorChange}
        />
        <FilterKey
            filter={null}
            onChange={onChange}
            filterOptions={filterOptions}
            filterKeyLoading={filterKeyLoading}
        />
    </>

    if (structure === LABEL_STRUCTURE) el = <>
        <FilterPretextV2
            index={index}
            isUserProperty={type !== "event"}
            operator={groupOperator}
            onOperatorChanged={onGroupOperatorChange}
        />
        <FilterKey
            filter={filter}
            onChange={onChange}
            filterOptions={filterOptions}
            filterKeyLoading={filterKeyLoading}
        />
    </>

    if (structure === PROPERTY_STRUCTURE) el = <>
        <FilterPretextV2
            index={index}
            isUserProperty={type !== "event"}
            operator={groupOperator}
            onOperatorChanged={onGroupOperatorChange}
        />
        <PropertyStructureFilter
            filter={filter}
            filterKeyLoading={filterKeyLoading}
            filterOptions={filterOptions}
            onChange={onChange}
        />
    </>

    if (structure === EVENT_STRUCTURE) el = <>
        <FilterPretextV2
            index={index}
            isUserProperty={type !== "event"}
            operator={groupOperator}
            onOperatorChanged={onGroupOperatorChange}
        />
        <EventField
            filter={filter}
            filterKeyLoading={filterKeyLoading}
            filterOptions={filterOptions}
            onChange={onChange}
        />
    </>

    return <>
        <FilterWrap>
            {el}
            <Actions
                deletable={!required}
                onDuplicate={onFilterDuplicate}
                onDelete={onFilterDelete}
                hasSubfilters={subfilterOptions != null && depth < 2}
                onSubfilterAdd={onSubfilterAdd}
            />
        </FilterWrap>
        {(depth < 2 && subgroup != null) && <Subgroup
            subgroup={subgroup}
            subfilterOptions={subfilterOptions}
            onGroupOperatorChange={onSubgroupOperatorChange}
            onChange={onSubfilterChange}
            onDelete={onSubfilterDelete}
            onDuplicate={onSubfilterDuplicate}
            depth={depth + 1}
        />}
    </>
}

function Subgroup({ subgroup, subfilterOptions, depth, onGroupOperatorChange, onChange, onDelete, onDuplicate }) {
    const { filters: subfilters, groupOperator } = subgroup || {}
    if (subfilters == null || subfilters.length === 0) return null

    return <div className={styles.subfilters}>
        {subfilters.map((subfilter, idx) => {
            return <FilterLine
                key={idx}
                index={idx}
                filter={subfilter}
                required={false}
                filterOptions={subfilterOptions}
                // filterKeyLoading={filterKeyLoading}
                groupOperator={groupOperator}
                onGroupOperatorChange={onGroupOperatorChange}
                onChange={(val, type) => {
                    onChange(idx, val, type)
                }}
                onFilterDelete={() => onDelete(idx)}
                onFilterDuplicate={() => onDuplicate(idx)}
                depth={depth}
            />
        })}
        {/* <FilterWrap>
            <FilterPretext>where</FilterPretext>
            <FilterKey
                filter={null}
                onChange={(val) => {
                    onChange(subfilters.length, val)
                }}
                filterOptions={filterOptions}
                // filterKeyLoading={filterKeyLoading}
            />
        </FilterWrap> */}
    </div>
}

function PropertyStructureFilter({
    filter,
    filterKeyLoading,
    filterOptions,
    onChange
}) {

    const { property } = filter || {}
    const { operator, datatype } = property || {}

    return <>
        <FilterKey
            filter={filter}
            onChange={onChange}
            filterOptions={filterOptions}
            filterKeyLoading={filterKeyLoading}
        />
        <FilterOperator
            operator={operator}
            datatype={datatype}
            onChange={(val) => {
                onChange({
                    ...filter, property: {
                        ...property,
                        operator: val
                    }
                })
            }}
        />
        <FilterPropertyValue
            filter={filter}
            onChange={onChange}
        />
    </>
}

function EventField({
    filter,
    filterKeyLoading,
    filterOptions,
    onChange
}) {

    const el = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const { event } = filter || {}
    const { behavior, value, operator, date } = event || {}

    const empty = filter == null || event == null || value == null
    const valueText = empty ? "Select value..." : value


    return <>
        <FilterEventPrep
            value={behavior}
            onChange={(val) => {
                onChange({
                    ...filter, event: {
                        ...event,
                        ...val
                    }
                })
            }}
        />
        <FilterKey
            filter={filter}
            onChange={onChange}
            filterOptions={filterOptions}
            filterKeyLoading={filterKeyLoading}
        />
        {behavior === "EVENT_DID" && <FilterOperator
            operator={operator}
            datatype={"numeric"}
            onChange={(val) => {
                onChange({
                    ...filter, event: {
                        ...event,
                        operator: val
                    }
                })
            }}
        />}
        {behavior === "EVENT_DID" && <ValueInput
            value={value}
            onChange={(val) => {
                onChange({
                    ...filter, event: {
                        ...event,
                        value: val
                    }
                })
            }}
            datatype={"numeric"}
            pref={el}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
            empty={empty}
            buttonText={valueText}
        // fill
        />}
        <FilterDate
            date={date}
            onChange={(val) => {
                onChange({
                    ...filter, event: {
                        ...event,
                        date: val
                    }
                })
            }}
        />
    </>
}


function FilterWrap({ children }) {
    return <div className={styles.filter}>
        {children}
    </div>
}

function Actions({ deletable, hasSubfilters, onDuplicate, onSubfilterAdd, onDelete }) {

    const el = useRef(null)
    const [menuOpen, setMenuOpen] = useState(false)

    let options = [{ id: "duplicate", icon: ContentCopyRounded, name: "Duplicate" }]
    if (hasSubfilters) options.splice(0, 0, { id: "filter", icon: FilterAltRounded, name: "Filter" })

    return <div className={styles.actions}>
        <Dropdown
            anchorEl={el}
            isOpen={menuOpen}
            onClose={() => setMenuOpen(false)}
            options={options}
            onChange={(val) => {
                if (val.id === "duplicate") onDuplicate()
                if (val.id === "filter") onSubfilterAdd()
            }}
            search={false}
            fullWidth={false}
        />
        <button ref={el} onClick={() => setMenuOpen(true)} style={{ width: 24, height: 24 }}><MoreHoriz /></button>
        {deletable && <button onClick={onDelete} style={{ width: 26, height: 26 }}><DeleteOutlineRounded /></button>}
    </div>

}

const eventBehaviorOptions = [
    {
        id: "EVENT_DID",
        name: "Did",
    },
    {
        id: "EVENT_DID_NOT",
        name: "Did not",
    },
]

function FilterEventPrep({ value, onChange }) {

    const el = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const empty = value == null
    const eventText = empty ? "Did..." : eventBehaviorOptions.find(opt => opt.id === value).name

    return <>
        <Dropdown
            anchorEl={el}
            activeValue={value}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            options={eventBehaviorOptions}
            onChange={(val) => {
                if (val.id === "EVENT_DID_NOT") {
                    onChange({ behavior: val.id, operator: undefined, value: undefined })
                    return
                }
                onChange({ behavior: val.id, operator: "gt" })
            }}
            search={false}
            fullWidth={false}
        />
        <InputButton
            pref={el}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
            empty={empty}
            buttonText={eventText}
        // fill
        />
    </>
}


function FilterKey({ filter, onChange, filterOptions, filterKeyLoading }) {

    const el = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const filterEmpty = filter == null
    const filterText = filterEmpty ? "Add filter..." : filter.name

    const { id, activeIcon, activePretext } = filter || {}

    return <>
        <Dropdown
            anchorEl={el}
            activeValue={id}
            isLoadingAsyncOptions={filterKeyLoading}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            options={filterOptions?.map((x) => x.toOption())}
            onChange={({ id }) => {
                if(filterOptions == null) return
                const fullVal = filterOptions.find((x) => x.id === id)
                onCohortKeyChange(fullVal, onChange)
            }}
        />
        <InputButton
            pref={el}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
            empty={filterEmpty}
            buttonText={mergeTexts(activePretext, filterText)}
            icon={activeIcon}
        />
    </>
}

function FilterOperator({ operator, datatype, onChange }) {
    const el = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const empty = operator == null
    const operatorOptions = getOperatorsFromDataType(datatype)
    const operatorText = empty ? "Operator..." : operatorOptions.find(opt => opt.id === operator)?.name

    return <>
        <Dropdown
            anchorEl={el}
            activeValue={operator}
            fullWidth={false}
            search={false}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            options={getOperatorsFromDataType(datatype)}
            onChange={(val) => {
                const { id } = val;
                onChange(id);
            }}
        />
        <InputButton
            pref={el}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
            empty={empty}
            buttonText={operatorText}
        // fill
        />
    </>
}

function FilterPropertyValue({ filter, onChange }) {
    const el = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const { property } = filter || {};
    const { datatype, value } = property || {}
    const { options: filterValueOptions, settings, loading: filterValueLoading } = useCohortPropertyValueOptions(property)

    if (filter == null) return

    const empty = filter == null || value == null
    let valueText = null
    if (datatype === "string" && !empty) {
        const filVals = value.map((x) => x.name)
        valueText = empty ? "Select value..." : getValueString(filVals)
    } else {
        valueText = empty ? "Select value..." : value
    }

    return <>
        <MultiDropdown
            anchorEl={el}
            value={datatype === "string" ? value : undefined}
            isLoadingAsyncOptions={filterValueLoading}
            isOpen={isOpen && datatype === "string"}
            onClose={() => setIsOpen(false)}
            options={filterValueOptions}
            settings={settings}
            onChange={(val) => {
                onChange({
                    ...filter, property: {
                        ...property,
                        value: val
                    }
                })
            }}
        />
        <ValueInput
            value={value}
            onChange={(val) => {
                onChange({
                    property: {
                        ...property,
                        value: val
                    }
                })
            }}
            datatype={datatype}
            pref={el}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
            empty={empty}
            buttonText={valueText}
        // fill
        />
    </>

}

function FilterDate({ date, onChange }) {
    const el = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const empty = date == null
    const dateText = empty ? "Date..." : formatDateString(date)

    return <>
        <DatePickerDropdown
            anchorEl={el}
            isOpen={isOpen}
            value={date}
            onClose={() => setIsOpen(false)}
            onChange={(val) => {
                onChange(val)
            }}
        />
        <InputButton
            pref={el}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
            empty={empty}
            buttonText={dateText}
            icon={UilCalender}
        // fill
        />
    </>
}

function FilterPretextV2({ index, isUserProperty, operator, onOperatorChanged }) {
    const [isOpen, setIsOpen] = useState(false)
    const el = useRef(null)

    const facingText = isUserProperty ? "where" : "who"
    if (index === 0) return <div className={styles.linePretext}>{facingText}</div>

    const options = [
        { id: "and", name: "and" },
        { id: "or", name: "or" },
    ]
    const operatorText = options.find(opt => opt.id === operator)?.name

    return <>
        <Dropdown
            anchorEl={el}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            options={options}
            activeValue={operator}
            onChange={(val) => {
                onOperatorChanged(val.id)
            }}
            search={false}
            fullWidth={false}
        />
        <button className={`${styles.linePretextButton} ${bclass(isOpen, styles.active)}`} ref={el} onClick={() => setIsOpen(true)}>
            {operatorText}
        </button>
    </>
}