import { useParams } from "react-router-dom";
import { RetentionQuery } from "sections/RetentionQuery/RetentionQuery";
import { Pathfinder } from "sections/Pathfinder/Pathfinder";
import { Events } from "sections/Events/Events";
import { Composition } from "sections/Composition/Composition";
import { Funnel } from "sections/Funnel/Funnel";
import { EventsNew } from "sections/EventsNew/EventsNew";

export function QueryMap() {
    const { type } = useParams();

    return <>
        {type === "retention" && <RetentionQuery />}
        {type === "event" && <Events />}
        {/* {type === "event" && <EventsNew />} */}
        {type === "composition" && <Composition />}
        {type === "funnel" && <Funnel />}
        {type === "pathfinder" && <Pathfinder />}
    </>

}