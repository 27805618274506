import styled from "@emotion/styled";
import { GraidentCircle } from "components/GradientCircle/GraidentCircle"
import { Tooltip } from "components/Tooltip/Tooltip";
import { getInitials } from "helpers"

export function CustomIcon({ workspace = undefined, user = undefined, size = 20, fontSize = 10, showName = false }) {

    if (user != null) {
        return <UserIcon user={user} size={size} fontSize={fontSize} showName={showName} />
    }

    if (workspace != null) {
        return <WorkspaceIcon workspace={workspace} size={size} />
    }

    return <></>

}

function UserIcon({ user, size, fontSize, showName }) {
    const { name, icon } = user;
    const initials = getInitials(name)

    const imgEl = icon == null ? <GraidentCircle text={initials} size={size} fontSize={fontSize} /> : <ImgCircle size={size} src={icon} />

    if (showName) {
        return <Tooltip text={user.name}>
            <div>
                {imgEl}
            </div>
        </Tooltip>
    }

    return imgEl
}

function WorkspaceIcon({ workspace, size }) {
    const { icon } = workspace;

    const imgEl = icon == null ? <GraidentCircle size={size} /> : <ImgCircle size={size} src={icon} />

    return imgEl

}

// const styled
const ImgCircle = styled('img', {
    shouldForwardProp: (prop) => !['size'].includes(prop),
})(({ size }) => ({
    width: size,
    height: size,
    borderRadius: "50%",
    overflow: "hidden"
}))