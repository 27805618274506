import { Modal, ModalText, ModalTitle } from "components/Modal/Modal";
import styles from "./DeleteModal.module.css"

export function DeleteModal({ subject = "chart", isOpen, onClose, onDelete }) {
    return <Modal onClose={onClose} isOpen={isOpen} width={350}>
        <div>
            <ModalTitle>Delete {capitalize(subject)}</ModalTitle>
            <ModalText>Are you sure you want to delete this {subject}? This action cannot be undone</ModalText>
            <div className={styles.actions}>
                <button className={styles.outline} onClick={onClose}>Cancel</button>
                <button onClick={onDelete} className={styles.fill}>Delete</button>
            </div>
        </div>
    </Modal>
}

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}