import { EVENT_STRUCTURE, SIMPLE_STRUCTURE, SINGLE_STRUCTURE } from "Constants"
import { isDateValid } from "./date"
import { usePostEndpoint } from "ApiConnector"
import { FunctionsRounded, Person2Outlined, TextFieldsRounded, ToggleOnRounded } from "@mui/icons-material"

function standardFilterValidity(filter) {
    const { filterValue } = filter || {}
    return filterValue != null && filterValue !== ""
}

function filterWithDateValidity(filter) {
    const { filterValue, filterDate } = filter || {}
    return filterValue != null && filterValue !== "" && isDateValid(filterDate)
}

export function isFilterValid(filter) {
    const { filterDate, filterStructure, filterEventBehavior } = filter || {}
    if (filterStructure === EVENT_STRUCTURE && filterEventBehavior === "EVENT_DID") {
        return filterWithDateValidity(filter)
    }
    if (filterStructure === EVENT_STRUCTURE && filterEventBehavior === "EVENT_DID_NOT") {
        return isDateValid(filterDate)
    }
    return standardFilterValidity(filter)
}

export function convertFilterValue(filterValue) {
    if (filterValue == null) return null
    if (filterValue === "") return null
    if (Array.isArray(filterValue)) return filterValue.map((x) => x.id)
    return filterValue
}

export function getRequestFilters(filters) {
    let filtered = []
    for (let i = 0; i < filters.length; i++) {
        if (!isFilterValid(filters[i])) continue
        filtered.push(filters[i])
    }

    const filteredFilterVals = filtered.map((filter) => {
        const {
            filterDatatype = null,
            filterKey = null,
            filterObject = null,
            filterOperator = null,
            filterResourceType = null,
            filterValue = null,
            filterDate = null,
            filterEventBehavior = null,
            filterSubfilters = [],
        } = filter

        return {
            filterDatatype,
            filterKey,
            filterObject,
            filterOperator,
            filterResourceType,
            filterDate,
            filterEventBehavior,
            filterSubfilters: getRequestFilters(filterSubfilters),
            filterValue: convertFilterValue(filterValue),
        }
    })

    return filteredFilterVals;
}

export function getStoredFilters(filters) {
    if (filters == null) return
    let filtered = []
    for (let i = 0; i < filters.length; i++) {
        const { filterValue } = filters[i] || {}
        const isValid = filterValue != null && filterValue !== ""
        if (!isValid) continue
        filtered.push(filters[i])
    }

    const filteredFilterVals = filtered.map((filter) => {
        // filterDatatype, filterKey, filterObject, filterOperator, filterResourceType, filterValue are all necessary
        // unsure: section, supertype, icon
        const {
            filterDatatype = null,
            filterKey = null,
            filterObject = null,
            filterOperator = null,
            filterResourceType = null,
            filterValue = null,
            filterDate = null,
            filterEventBehavior = null,
            filterSubfilters: filterSubfiltersPrestore,
            filterStructure = SIMPLE_STRUCTURE,
            name = null
        } = filter
        const filterSubfilters = getStoredFilters(filterSubfiltersPrestore)
        return { filterDatatype, filterKey, filterObject, filterOperator, filterResourceType, filterValue, name, filterDate, filterEventBehavior, filterSubfilters: filterSubfilters, filterStructure }
    })
    return filteredFilterVals;
}

export function filterEquals(x, y) {
    if (x == null || y == null) return false
    // x and y are objs with keys {entity, source, key, datatype}
    // they are all strings and we want to check if each are equal
    // return x.entity === y.entity && x.source === y.source && x.key === y.key && x.datatype === y.datatype
    return x.filterResourceType === y.filterResourceType && x.filterObject === y.filterObject && x.filterKey === y.filterKey && x.filterDatatype === y.filterDatatype
}


const resourceTypes = ["user", "event", "label"]

export function useFilterKeyOptions(optionFn, exclude, templates = []) {
    const resourceTypeExclude = resourceTypes.filter(x => !exclude.includes(x))
    const { data: propertyData } = usePostEndpoint({ endpoint: "filters/keys", body: { object: resourceTypeExclude } })

    const asyncPropertiesFormatted = [...propertyData?.map(e => {
        const { filterResourceType, filterObject, filterKey, filterDatatype, name, type } = e;
        let icon = undefined
        if (filterDatatype === "string") icon = TextFieldsRounded
        if (filterDatatype === "numeric") icon = FunctionsRounded
        if (filterDatatype === "bool") icon = ToggleOnRounded
        if (filterResourceType === "label") icon = Person2Outlined

        return {
            ...e,
            id: { filterResourceType, filterObject, filterKey, filterDatatype },
            filterStructure: filterResourceType === "label" ? SINGLE_STRUCTURE : SIMPLE_STRUCTURE,
            subfilterOptions: null,
            hasAsyncOptions: false,
            type,
            icon,
            name,
            favor: 1
        }
    }) ?? []]

    // const templatesFiltered = templates.filter(x => {
    //     const { families = [] } = x || {}
    //     return chainOrigins.some(y => families.includes(y))
    // })

    const allFilters = [...asyncPropertiesFormatted, ...templates.map(e => ({
        id: { filterResourceType: "template", filterObject: null, filterKey: e.id, filterDatatype: e.datatype },
        filterResourceType: e.resourceType || "template",
        filterStructure: e.structure,
        filterObject: null,
        filterKey: e.id,
        filterDatatype: e.datatype,
        defaultValues: e.defaultValues,
        icon: e.icon,
        subfilterOptions: e.subfilterOptions,
        staticOptions: e.staticOptions,
        hasAsyncOptions: e.hasAsyncOptions,
        name: e.name,
        type: "template",
        favor: 1
    }))]

    // sort events by favor 
    allFilters.sort((a, b) => {
        if (a.favor > b.favor) return -1
        if (a.favor < b.favor) return 1
        return 0
    })

    const filterOptions = allFilters.map((filter) => {
        const { filterResourceType, filterObject, filterDatatype, filterKey, filterStructure, defaultValues, subfilterOptions, staticOptions, hasAsyncOptions, id, icon, name, type } = filter
        const { supertype } = optionFn(filter)
        return { id, filterResourceType, filterObject, filterDatatype, filterKey, filterStructure, defaultValues, subfilterOptions, staticOptions, hasAsyncOptions, name, icon, supertype, section: type }
    })

    return { options: filterOptions, loading: propertyData == null }
}

export function useFilterValueOptions(filterX) {

    const { filterKey, filterObject, filterResourceType, filterDatatype } = filterX
    const filter = { filterKey, filterObject, filterResourceType, filterDatatype }

    const { data: filterOptionData } = usePostEndpoint({ endpoint: "filters/values", body: { filter } })
    const { options: filterOptionDataFormatted, settings: filterSettings } = filterOptionData ?? {}

    return { options: filterOptionDataFormatted, settings: filterSettings, loading: filterOptionData == null }
}

export function isFilterRequired(required, filter) {
    if (required == null) return false
    return required.some((x) => filterEquals(x, filter))
}

export function onFilterKeyChange(val, fn) {
    const { filterResourceType, filterKey, filterStructure, defaultValues, name } = val
    if (filterResourceType === "label") {
        const newVal = { filterKey: null, filterDatatype: "string", filterResourceType, filterStructure, filterObject: null }
        const filterValue = [{ name, id: filterKey }]
        fn({ id: newVal, ...newVal, name, filterValue })
        return
    }
    let newVal = { ...val }
    if (defaultValues != null) newVal = { ...newVal, ...defaultValues }
    fn(newVal)
}

export function shouldFilterResetFields(update) {
    if (update.filterResourceType === "label") return false
    return update.filterKey != null || update.filterDatatype != null || update.filterResourceType != null || update.filterObject != null
}


export const BLANK_FILTER = { filterStructure: "BLANK" }