type FilterDateMode = "last" | "since" | "between"

export class FilterDate {
    mode: FilterDateMode
    min?: string
    max?: string
    value?: number
    units?: string

    constructor({ mode, min, max, value, units }: { mode: FilterDateMode, min?: string, max?: string, value?: number, units?: string }) {
        this.mode = mode
        if(mode === "last"){
            this.value = value
            this.units = units
        }
        if(mode === "since"){
            this.min = min
        }
        if(mode === "between"){
            this.min = min
            this.max = max
        }
    }

    toJSON() {
        return {
            mode: this.mode,
            min: this.min,
            max: this.max,
            value: this.value,
            units: this.units
        }
    }
}