import { usePostEndpoint } from "ApiConnector"
import { LABEL_STRUCTURE, PROPERTY_STRUCTURE, EVENT_STRUCTURE } from "Constants"
import { CohortEventFilter, CohortLabelFilter, CohortPropertyFilter } from "models/cohort"

// Hooks
export function useCohortKeyOptions() {
    const { data, isRefetching } = usePostEndpoint({ endpoint: "cohort/keys" })
    if(isRefetching) return {  options: null, loading: true }
    const { filters, eventProperties } = data || {}
    const filterObjects = filters?.map(f => {
        const { structure, subfilterOptions: staticSubfilterOptions } = f
        if (structure === LABEL_STRUCTURE) return new CohortLabelFilter(f)
        if (structure === PROPERTY_STRUCTURE) return new CohortPropertyFilter(f);
        if (structure === EVENT_STRUCTURE) {
            const properties = getEventSubfilterOptions(f, staticSubfilterOptions, eventProperties)
            return new CohortEventFilter({ ...f, subfilterOptions: properties });
        }
    })

    return {
        options: filterObjects,
        loading: false,
    }
}

export function useCohortPropertyValueOptions(property) {
    const { key, object, type, datatype } = property;
    const filter = { key, object, type, datatype }

    const { data: filterOptionData } = usePostEndpoint({ endpoint: "cohort/property/values", body: { filter } })
    const { options: filterOptionDataFormatted, settings: filterSettings } = filterOptionData ?? {}
    return { options: filterOptionDataFormatted, settings: filterSettings, loading: filterOptionData == null }
}

// Hook helpers

function getEventSubfilterOptions(f, staticSubfilterOptions, eventProperties) {
    if (staticSubfilterOptions != null) return staticSubfilterOptions
    if (eventProperties == null) return null
    const foundProp = eventProperties?.find((x) => x.event === f.event.key)
    if (foundProp == null) return null
    const { properties } = foundProp
    return properties
}

// Pipeline formatters

export function getRequestSubgroup(subgroup){
    if(subgroup == null) return undefined
    const { filters } = subgroup || {}
    if (filters == null) return undefined
    const validFilters = filters.filter((filter) => filter.isValid())
    if (validFilters.length === 0) return undefined
    const requestFilters = validFilters.map((filter) => filter.toRequest())
    return {
        ...subgroup,
        filters: requestFilters,
    }
}

export function getRequestCohort(cohort) {
    if(cohort == null) return null
    const { groups } = cohort || {}
    if (groups == null) return EMPTY_COHORT
    const validGroups = groups.filter((group) => isGroupValid(group))
    if (validGroups.length === 0) return EMPTY_COHORT
    const requestGroups = validGroups.map((group) => {
        const { groupOperator: go, crossGroupOperator: cgo, filters } = group || {}
        const validFilters = filters.filter((filter) => filter.isValid())
        const requestFilters = validFilters.map((filter) => filter.toRequest())
        let groupOperator = go
        let crossGroupOperator = cgo
        if (validFilters.length === 1) groupOperator = "and"
        if (validGroups.length === 1) crossGroupOperator = "and"
        return { groupOperator, crossGroupOperator, filters: requestFilters }
    })
    return { ...cohort, groups: requestGroups }
}

export function cohortModified(cohort) {
    const { groups } = cohort || {}
    if (groups == null) return false
    if (groups.length === 0) return false
    if (groups.length === 1) {
        const { filters } = groups[0] || {}
        if (filters == null) return false
        if (filters.length === 0) return false
        return true
    }
    return true
}

export function isEmptyCohort(cohort) {
    const { groups } = cohort || {}
    if (groups == null) return true
    const validGroups = groups.filter((group) => isGroupValid(group))
    return validGroups.length === 0
}

export function getStoredCohort(cohort) {
    const { groups } = cohort || {}
    if (groups == null) return null
    const validGroups = groups.filter((group) => isGroupValid(group))
    if (validGroups.length === 0) return null
    const storeGroups = validGroups.map((group) => {
        const { groupOperator: go, crossGroupOperator: cgo, filters } = group || {}
        const validFilters = filters.filter((filter) => filter.isValid())
        const requestFilters = validFilters.map((filter) => filter.toStore())
        let groupOperator = go
        let crossGroupOperator = cgo
        if (validFilters.length === 1) groupOperator = "and"
        if (validGroups.length === 1) crossGroupOperator = "and"
        return { groupOperator, crossGroupOperator, filters: requestFilters }
    })
    return { ...cohort, groups: storeGroups }
}

// Validity

export function isCohortValid(cohort) {
    const { groups } = cohort || {}
    if (groups == null) return false
    if (groups.length === 0) return false
    return groups.some((group) => isGroupValid(group))
}

export function isGroupValid(group) {
    const { filters } = group || {}
    if (filters == null) return false
    if (filters.length === 0) return false
    return filters.some((filter) => filter.isValid())
}

export function onCohortKeyChange(val, fn) {
    const { structure } = val || {}
    if (structure === LABEL_STRUCTURE) {
        const filterObject = new CohortLabelFilter(val)
        fn(filterObject, "KEY")
        return
    }

    if (structure === PROPERTY_STRUCTURE) {
        const filterObject = new CohortPropertyFilter(val)
        fn(filterObject, "KEY")
        return
    }

    if (structure === EVENT_STRUCTURE) {
        const filterObject = new CohortEventFilter(val)
        fn(filterObject, "KEY")
        return
    }

}

// Blank objects

export const BLANK_FILTER = { structure: "BLANK" }
export const BLANK_GROUP = {
    groupOperator: "and",
    crossGroupOperator: "and",
    filters: [],
}
const EMPTY_COHORT = {
    groups: [],
}

