import styled from "@emotion/styled"
import { CircularProgress } from "@mui/material"

export function LoadingBody({ height }) {
    return (
        <LoadingWrapDiv>
            <CircularProgress sx={{ color: "var(--alpha-text)" }} />
        </LoadingWrapDiv>
    )
}

const LoadingWrapDiv = styled.div(({theme})=>({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
}))