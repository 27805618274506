import styles from './Content.module.css';

function getStyles(options){
    let elStyles = {}
    if(options.maxWidth){
        elStyles.maxWidth = options.maxWidth;
    }
    return elStyles;
}

export function Content({ children, maxWidth, noPadding, flex, style: elStylesCustom = {} }) {

    const elStyles = getStyles({ maxWidth: maxWidth });
    const realStyles = {...elStyles, ...elStylesCustom};

    return <div className={styles.outerContent}>
        <div className={`${styles.content} ${flex ? styles.column : ""} ${noPadding ? styles.noPadding : ""}`} style={realStyles}>
            {children}
        </div>
    </div>
}