import styles from "./Checkbox.module.css"
import { UilCheck, UilMinus } from '@iconscout/react-unicons'
import { useState } from "react";

export function Checkbox({ selected, className = "", halfSelected = false }) {
    // const [selected, setSelected] = useState(false);

    // function onClick() {
    //     if(onCheck === undefined) return;
    //     onCheck(!selected)
    //     // setSelected(!selected)
    // }

    if (selected) {
        return <div className={`${styles.checkBoxWrap} ${className}`}>
            <div className={`${styles.checkbox} ${selected ? styles.selected : ""}`}>
                <UilCheck />
            </div>
        </div>
    }

    if (halfSelected) {
        return <div className={`${styles.checkBoxWrap} ${className}`}>
            <div className={`${styles.checkbox} ${styles.selected}`}>{halfSelected && <UilMinus />}</div>
        </div>
    }

    return <div className={`${styles.checkBoxWrap} ${className}`}>
        <div className={`${styles.checkbox}`}> </div>
    </div>
}

const empty_fn = () => void 0;