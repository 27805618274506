import { useNavigate, useParams } from "react-router-dom";
import styles from "./Pathfinder.module.css"
import { InputButton } from "components/InputButton/InputButton";
import { DeleteOutlineRounded, ElectricBoltRounded, LayersRounded } from "@mui/icons-material";
import { compareObjectsRegardlessOfKeyOrder, getMinFromInterval } from "helpers";
import { DropdownFreeform } from "components/DropdownFreeform/DropdownFreeform";
import { useEffect, useRef, useState } from "react"; 
import { useGetEndpoint, usePostEndpoint, useQueryFunctions } from "ApiConnector";
import { Content } from "components/Content/Content";
import { CardV2 } from "components/CardV2/CardV2";
import { Menu } from "components/Menu/Menu";
import { Button } from "components/Button/Button";
import { CustomLoadingButton } from "components/LoadingButton/LoadingButton";
import { ALL_CODE, EVENT_CONDITION_OPTIONS_V2 } from "Constants";
import moment from "moment";
import { IntegratedDateSelector } from "components/IntegratedDateFormatter/IntegratedDateSelector";
import { CircularProgress } from "@mui/material";
import { PathfinderChart } from "components/PathfinderChart/PathfinderChart";
import { UilPlus, UilTimes } from '@iconscout/react-unicons';
import { Filters } from "components/Filters/Filters";
import { ReactComponent as Empty } from 'assets/empty/path.svg';
import { deleteQuery, getQuery, saveQuery } from "helpers/query";
import { toast } from "react-hot-toast";
import { DeleteModal } from "components/DeleteModal/DeleteModal";
import PageLoading from "components/PageLoading/PageLoading";
import { ClassicSaveModal } from "components/ClassicSave/ClassicSaveModal";
import { getRequestFilters, getStoredFilters } from "utils/filter";
import { DateSelector } from "components/DateSelector/DateSelector";
import { FilterDate } from "models/date";
import { UilFilterSlash } from '@iconscout/react-unicons';

const DEFAULT_MINIMUM = moment().subtract(1, "year").toISOString()
const DEFAULT_MAXIMUM = moment().toISOString()

export function Pathfinder() {
    const { action, query } = useParams();
    const { invalidate } = useQueryFunctions()
    const [queryData, setQueryData] = useState(undefined)
    const [loadingSave, setLoadingSave] = useState(action === "edit")
    const [originalRequestOptions, setOriginalRequestOptions] = useState(undefined)
    const [saving, setSaving] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [saveModal, setSaveModal] = useState(false)

    const [event, setEvent] = useState(null)
    const [filters, setFilters] = useState([])
    const [date, setDate] = useState(new FilterDate({
        mode: "last",
        units: "day",
        value: 30
    }))
    const [steps, setSteps] = useState(5)

    const navigate = useNavigate()

    useEffect(() => {
        if (action !== "edit") return
        if (event != null) return
        async function f() {
            setLoadingSave(true)
            const queryData = await getQuery(query)
            const queryInnerData = queryData.data
            const { event: e, filters: f, date: d, steps: s } = queryInnerData
            const date = new FilterDate(d)

            setEvent(e)
            setFilters(f)
            setDate(date)
            setSteps(s)

            const oro = getRequestOptions(e, f, date, s)
            setQueryData(queryData)
            setOriginalRequestOptions(oro)
            setLoadingSave(false)
        }
        f()
    }, [action, query])

    async function save({ name, description }) {
        if (name === "") return
        const store = {
            event: getStoredEvent(event),
            filters: getStoredFilters(filters),
            date,
            steps
        }
        if (action === "create") {
            setSaveModal(false)
            setSaving(true)

            const id = await saveQuery({
                name,
                description,
                type: "pathfinder",
                created: new Date(),
                updated: new Date(),
                data: store,
            })
            invalidate("general/home")
            navigate(`/query/pathfinder/edit/${id}`)
            setSaving(false)
            setQueryData({ id, name, description, data: store })
        }
        if (action === "edit") {
            const { id } = queryData
            if (id == null) return
            setSaveModal(false)
            setSaving(true)
            await saveQuery({
                id,
                updated: new Date(),
                name,
                description,
                data: store,
            })
            invalidate("general/home")
            setSaving(false)
            setQueryData({ ...queryData, name, description, data: store })
        }
        const oro = getRequestOptions(event, filters, date, steps)
        setOriginalRequestOptions(oro)
    }

    async function deleteChart() {
        const promise = new Promise(async (resolve, reject) => {
            try {
                const { id } = queryData
                await deleteQuery(id)
                invalidate("general/home")
                navigate("/")
                resolve()
            } catch (e) {
                reject()
            }
        })

        toast.promise(promise, {
            loading: "Deleting chart...",
            error: "There was an error deleting this chart",
            success: "Successfully deleted chart"
        })

    }

    const isRequestValid = isQueryValid(event, filters, date, steps)
    const requestOptions = getRequestOptions(event, filters, date, steps)
    const { data: pathfinderResult, error, refetch: refetchResult } = usePostEndpoint({ endpoint: "query/pathfinder", body: requestOptions, skip: !isRequestValid })
    const { data: pathfinderLinks, total: pathfinderSum, names: pathfinderNames } = pathfinderResult || {}

    const dirty = action === "create" ? true : !compareObjectsRegardlessOfKeyOrder(originalRequestOptions, requestOptions)

    if (action === "edit" && loadingSave) {
        return <PageLoading />
    }

    const { name, description } = queryData || {}

    return <>
        <ClassicSaveModal
            title={action === "create" ? "Save Chart" : "Edit Chart"}
            isOpen={saveModal}
            onClose={() => setSaveModal(false)}
            onSave={save}
            existing={{ name, description }}
        />
        <DeleteModal
            isOpen={deleteModal}
            onClose={() => setDeleteModal(false)}
            onDelete={deleteChart}
        />
        <Content flex>
            <TopBarWrap
                type={"pathfinder"}
                action={action}
                dirty={dirty}
                saving={saving}
                onSaveClicked={() => {
                    if (action === "create") {
                        setSaveModal(true)
                        return
                    }
                    save({ name, description })
                }}
                onDeleteClicked={() => setDeleteModal(true)}
                onEditClicked={() => setSaveModal(true)}
            />
            <CardV2>
                <div className={styles.queryContainer}>
                    <Events
                        event={event}
                        setEvent={setEvent}
                        filters={filters}
                        setFilters={setFilters}
                    />
                    <div className={styles.section}>
                        <div className={styles.title}>Filters</div>
                        <Filters
                            filters={filters}
                            setFilters={setFilters}
                            excludeResources={["user"]}
                            templates={EVENT_CONDITION_OPTIONS_V2.filter((x) => ["wallet_address", "contract_address", "chain"].includes(x.id))}
                        />
                    </div>
                </div>
            </CardV2>
            <ResultsWrapper
                data={pathfinderLinks}
                total={pathfinderSum}
                names={pathfinderNames}
                isValid={isRequestValid}
                error={error}
                date={date}
                setDate={setDate}
                steps={steps}
                setSteps={setSteps}
                refetch={refetchResult}
            />
        </Content>
    </>
}

const eventSections = [
    { id: ALL_CODE, name: "All Events", inline: "all events" },
    { id: "offchain", name: "Custom Events", inline: "off-chain events" },
    { id: "template", name: "On-chain Events", inline: "on-chain events" },
]

function Events({ event, setEvent, filters }) {
    const [activeEvent, setActiveEvent] = useState({ isActive: false, el: null, index: null })
    const contractFilters = filters.filter((x) => x.filterKey === "contract_address" && x.filterValue != null).flatMap((x) => x.filterValue.map((x) => x.id))

    const { data: eventData } = useGetEndpoint("general/singleResource", { resource: "events" })
    const allEvents = [...eventData?.map(e => ({
        name: e.event_text,
        id: e.event,
        type: "offchain",
    })) ?? []]

    let methodResourceParams = { }
    if (contractFilters.length > 0) {
        methodResourceParams = { contracts: contractFilters }
    }
    const { data: methodData } = usePostEndpoint({endpoint: "methods", body: methodResourceParams})
    const allMethods = [...methodData?.map(e => ({
        name: e.name,
        id: e.id,
        type: "template",
    })) ?? []]

    function onEventChanged(event) {
        // if (events.length === 0) {
        //     setEvents([{ ...update }, {}])
        //     return
        // }
        // let newEvents = [...events]
        // newEvents[index] = { ...newEvents[index], ...update }
        // // if newEvents doesnt have a blank event at the end, add one
        // if (newEvents[newEvents.length - 1].id != null) newEvents = [...newEvents, {}]
        // setEvents(newEvents)
        setEvent(event)
    }

    function onEventDelete() {
        setEvent({})
    }

    const eventOptions = allEvents?.map((e) => {
        const { id, name, type } = e
        const idOption = { type: "offchain", id }
        return { id: idOption, name, icon: LayersRounded, section: type }
    }) ?? []

    const methodOptions = allMethods?.map((e) => {
        const { id, name, type } = e
        const idOption = { type: "onchain", id }
        return { id: idOption, name, icon: ElectricBoltRounded, section: type }
    }) ?? []

    const allOptions = [...eventOptions, ...methodOptions]

    return <>
        {/* <DropdownFreeform
            anchorEl={activeEvent.el}
            isLoadingAsyncOptions={eventData == null || methodData == null}
            isOpen={activeEvent.isActive}
            onClose={() => setActiveEvent({ isActive: false, index: null, value: null, el: null })}
            options={allOptions}
            sections={eventSections}
            onChange={(val) => {
                const { id, type } = val
                if (type === "custom") {
                    const fullObj = allEvents.find((e) => e.id === id)
                    onEventChanged({ ...fullObj })
                }
                if (type === "freeform") {
                    onEventChanged({ id, type, name: id })
                }
                if (type === "onchain") {
                    const fullObj = allMethods.find((e) => e.id === id)
                    onEventChanged({ ...fullObj })
                }
            }}
        /> */}
        <DropdownFreeform
            anchorEl={activeEvent.el}
            isLoadingAsyncOptions={eventData == null || methodData == null}
            isOpen={activeEvent.isActive}
            onClose={() => setActiveEvent({ isActive: false, index: null, value: null, el: null })}
            options={allOptions}
            sections={eventSections}
            onChange={(val) => {
                const { section } = val
                if (section === "offchain") {
                    onEventChanged(val)
                }

                if (section === "freeform") {
                    onEventChanged(val)
                }

                if (section === "template") {
                    onEventChanged(val)
                }
            }}
        />
        <div className={styles.section}>
            <div className={styles.sectionInner}>
                <Event
                    index={0}
                    event={event}
                    eventOptions={allEvents}
                    methodOptions={allMethods}
                    activeEvent={activeEvent}
                    setActiveEvent={setActiveEvent}
                    onEventDelete={onEventDelete}
                    deletable={event != null && event?.id != null}
                />
                {/* {events.map((event, idx) => <Event
                    key={idx}
                    index={idx}
                    event={event}
                    eventOptions={allEvents}
                    methodOptions={allMethods}
                    activeEvent={activeEvent}
                    setActiveEvent={setActiveEvent}
                    onEventDelete={() => onEventDelete(idx)}
                    duplicateEvent={() => duplicateEvent(idx)}
                    deletable={events.length > 1 || (events.length === 1 && event.id != null && event.id !== "")}

                />)}
                {events.length === 0 && <Event
                    index={0}
                    eventOptions={allEvents}
                    methodOptions={allMethods}
                    activeEvent={activeEvent}
                    setActiveEvent={setActiveEvent}
                    onEventDelete={() => onEventDelete(0)}
                    duplicateEvent={() => duplicateEvent(0)}
                    deletable={false}
                />} */}
                {/* <button className={styles.add} onClick={addBlankEvent}>
                    <div className={styles.plus}>+</div>
                    <div className={styles.text}>Add event</div>
                </button> */}
            </div>
        </div>
    </>
}

function Event({
    index,
    event,
    activeEvent,
    setActiveEvent,
    deletable,
    onEventDelete,
    eventOptions,
    methodOptions,
}) {
    const eventEl = useRef(null)
    const eventEmpty = event == null || event?.id == null || event?.id === ""
    const eventText = eventEmpty ? "Add an event..." : event?.name

    return <div className={styles.event}>
        {/* <div className={styles.letter}>
            {convertToNumberingScheme(index)}
        </div> */}
        <InputButton
            pref={eventEl}
            onOpen={() => setActiveEvent({ isActive: true, index, el: eventEl, value: event?.id })}
            isOpen={activeEvent.isActive && index === activeEvent.index}
            empty={eventEmpty}
            buttonText={eventText}
            fill
        />
        <div className={styles.actions}>
            {deletable && <button onClick={onEventDelete} style={{ width: 26, height: 26 }}><DeleteOutlineRounded /></button>}
        </div>
    </div>
}

function TopBarWrap({ type, action, dirty, saving, onSaveClicked, onDeleteClicked, onEditClicked }) {

    const [optionsOpen, setOptionsOpen] = useState(false)
    const openOptions = () => setOptionsOpen(true)
    const closeOptions = () => setOptionsOpen(false)
    const optionsEl = useRef(null)

    return <>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            {action === "edit" && <>
                <Button buttonRef={optionsEl} onClick={openOptions} className={styles.outlineBtn} colorVariant="outline">Options</Button>
                <Menu
                    open={optionsOpen}
                    onClose={closeOptions}
                    anchorEl={optionsEl?.current}
                    options={[
                        { onClick: () => onEditClicked(), text: "Edit" },
                        { onClick: () => onDeleteClicked(), text: "Delete" }
                    ]}
                />
            </>}
            <CustomLoadingButton disabled={!dirty} loading={saving} className={styles.addBtn} onClick={onSaveClicked}>
                Save
            </CustomLoadingButton>
        </div>
    </>
}

function ResultsWrapper({ data, total, names, error, refetch, isValid: isValidOverride, date, setDate, steps, setSteps }) {

    let top = <div className={styles.top}>
        <div />
        <div className={styles.right}>
            <DateSelector
                date={date}
                onChange={(val)=>setDate(val)}
            />
            {/* <IntegratedDateSelector
                mode={dateMode}
                rawDate={date}
                date={dateValue}
                onChange={onDateChangeFreeform}
                classes={{ wrap: styles.integrated }}
            /> */}
        </div>
    </div>

    const isValid = isValidOverride
    // is empty if sum of each data.series.data is 0
    const noResults = data?.length === 0
    const loading = data === undefined && isValid && !error
    const noQuery = data === undefined && !isValid && !error

    if (loading) {
        return <CardV2 noPadding>
            {top}
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <CircularProgress sx={{ color: "var(--alpha-text)" }} />
                </div>
            </div>
        </CardV2>
    }

    if (noQuery) {
        return <CardV2 noPadding>
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <div className={styles.emptyIcon}>
                        <Empty />
                    </div>
                    <div className={styles.title}>Select a metric to get started</div>
                    <div className={styles.description}>In order to see results, you must select at least one metric from the left panel</div>
                </div>
            </div>
        </CardV2>
    }

    if (noResults) {
        return <CardV2 noPadding>
            {top}
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <div className={styles.iconWrap}>
                        <UilFilterSlash />
                    </div>
                    <div className={styles.title}>No events found</div>
                    <div className={styles.description}>Please change the parameters to see results</div>
                </div>
            </div>
        </CardV2>
    }

    if (error) {
        return <CardV2 noPadding>
            {top}
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <div className={styles.title}>Error</div>
                    <div className={styles.description}>There was an error loading this query. Please try again or email <a className={styles.supportEmail} href="mailto: support@multibase.co">support@multibase.co</a></div>
                    <Button onClick={refetch} className={styles.errorBtn}>Reload</Button>
                </div>
            </div>
        </CardV2>
    }

    return <>
        <CardV2 noPadding>
            {top}
            <div className={styles.resultsWrap}>
                <PathfinderResultWrap
                    data={data}
                    total={total}
                    names={names}
                    steps={steps}
                    setSteps={setSteps}
                />
            </div>
        </CardV2>
    </>
}

function getRequestOptions(event, filters, date, steps) {
    return {
        event: event?.id,
        filters: getRequestFilters(filters),
        date: date.toJSON(),
        steps
    }
}

function isQueryValid(event, filters, date, steps) {
    if (event == null || event.id == null) return false
    return true
}

function getDateParams(date) {
    const { mode, period, min, max } = date

    if (mode === "period") {
        return {
            minimum: getMinFromInterval(period)
        }
    }
    return {
        minimum: min,
        maximum: max
    }

}

function PathfinderResultWrap({ data, total, names, steps, setSteps }) {

    const nodes = {}

    for (const path of data) {
        if (!nodes[path.source]) {
            const pathData = path.source.split("_")
            const step = parseInt(pathData[0])
            const isDropoff = pathData[1] === "DROPOFF"
            const isOther = pathData[1] === "OTHER"
            const metadata = {}
            const type = (isDropoff || isOther) ? null : pathData[1] === "offchain" ? "offchain" : "onchain"
            if(type === "offchain") {
                const event_text = pathData[3]
                metadata.text = event_text
            }
            if(type === "onchain") {
                const [method_name, contract_name] = pathData.slice(-2)
                metadata.text = names.methods[method_name] || method_name
                metadata.subtext = names.contracts[contract_name] || contract_name
            }
            nodes[path.source] = { name: path.source, step, isDropoff, isOther, type, metadata }
        }
        if (!nodes[path.target]) {
            if(path.target == null) continue
            const pathData = path.target.split("_")
            const step = parseInt(pathData[0])
            const isDropoff = pathData[1] === "DROPOFF"
            const isOther = pathData[1] === "OTHER"
            const metadata = {}
            const type = (isDropoff || isOther) ? null : pathData[1] === "offchain" ? "offchain" : "onchain"
            if(type === "offchain") {
                const event_text = pathData[3]
                metadata.text = event_text
            }
            if(type === "onchain") {
                const [method_name, contract_name] = pathData.slice(-2)
                metadata.text = names.methods[method_name] || method_name
                metadata.subtext = names.contracts[contract_name] || contract_name
            }
            nodes[path.target] = { name: path.target, step, isDropoff, isOther, type, metadata }
        }
    }

    const nodeData = Object.values(nodes)
    const linkData = data

    return (
        <div className={styles.pathfinderSankeyWrap} id={"pathfinderSankeyWrap"}>
            <div className={styles.chartTop}>
                {Array(steps - 1).fill(0).map((x, i) => {
                    let txt = ""
                    txt = `N+${i}`

                    return <button
                        key={i}
                        className={`${styles.arrowWrap} ${i === 0 ? styles.initial : ""} ${steps <= 2 ? styles.removeDisabled : ""}`} onClick={() => {
                            if (steps <= 2 || i === 0) { return }
                            setSteps(steps - 1)
                        }}
                    >
                        {(steps > 2 || i !== 0) && <div className={styles.remove}>
                            <div className={styles.arrowIcon}>
                                <UilTimes />
                            </div>Remove Step
                        </div>}
                        <div className={styles.arrowLeft} />
                        <div className={styles.arrowLabel}>{txt}</div>
                        <div className={styles.arrowRight} />
                    </button>
                })}
                {steps < 10 && <button
                    className={`${styles.arrowWrap} ${styles.add}`}
                    onClick={() => {
                        if (steps > 10) { return }
                        setSteps(steps + 1)
                    }}
                    style={{
                        width: `calc(100% / ${steps})`
                    }}
                >
                    <div className={styles.arrowLeft} />
                    <div className={styles.arrowLabel}>
                        <div className={styles.arrowIcon}>
                            <UilPlus />
                        </div>
                        Add Step</div>
                    <div className={styles.arrowRight} />

                </button>}
            </div>
            <PathfinderChart
                nodeData={nodeData}
                linkData={linkData}
                names={names}
                total={total}
                steps={steps}
            />
        </div>
    )
}

// function PathfinderResultWrap({ data, steps, setSteps }) {

//     console.log(data)
//     return

//     const sumByStep = data.reduce((acc, curr) => {
//         const { step, count } = curr
//         if (acc[step]) {
//             acc[step] += count
//         } else {
//             acc[step] = count
//         }
//         return acc
//     }, {})

//     const linkData = data.filter((x) => x.step !== 1).map((x) => {
//         const {
//             id,
//             previousId,
//             count,
//             uids,
//             instances
//         } = x

//         return {
//             source: previousId,
//             target: id,
//             value: count,
//             uids,
//             instances
//         }

//     })

//     const dataDistinctOnIds = data.reduce((acc, curr) => {
//         const { id } = curr
//         if (acc[id]) {
//             acc[id] = {
//                 ...acc[id],
//                 refs: acc[id].count + curr.count
//             }
//         } else {
//             acc[id] = curr
//         }
//         return acc

//     }, {})


//     const nodeData = Object.values(dataDistinctOnIds).map((x) => {
//         const {
//             id,
//             type,
//             step,
//             currentEvent,
//             name,
//             subtext,
//             conversion,
//             count,
//             uids,
//             instances,
//         } = x

//         // let methodName = currentMethod
//         // let methodNameExists = false
//         // if (names.methods[currentMethod]) {
//         //     methodName = names.methods[currentMethod]
//         //     methodNameExists = true
//         // }


//         // let contractName = currentContract
//         // let contractNameExists = false
//         // if (names.contracts[currentContract]) {
//         //     contractName = names.contracts[currentContract]
//         //     contractNameExists = true
//         // }

//         return {
//             id,
//             type,
//             step,
//             currentEvent,
//             // method: currentMethod,
//             // contract: currentContract,
//             // chain: currentChain,
//             uids,
//             instances,
//             conversion,
//             count,
//             name,
//             subtext
//             // metadata: {
//             //     name: "bruh",
//             //     subtext: "bruh2"
//             // method: methodName,
//             // contract: contractName,
//             // formattingInfo: {
//             //     methodNameExists: methodNameExists,
//             //     contractNameExists: contractNameExists,
//             // }
//             // }
//         }
//     })

//     return (
//         <div className={styles.pathfinderSankeyWrap} id={"pathfinderSankeyWrap"}>
//             <div className={styles.chartTop}>
//                 {Array(steps - 1).fill(0).map((x, i) => {
//                     let txt = ""
//                     // if (i < methods.length) {
//                     //     const methodCorrespond = names.methods[methods[i]]
//                     //     if (methodCorrespond != null) {
//                     //         const methodParams = methodCorrespond.split("(")[0]
//                     //         txt = leftTrim(methodParams, 25)
//                     //     } else {
//                     //         txt = leftTrim(methods[i], 10)
//                     //     }
//                     // } else {
//                     //     txt = `N+${i}`
//                     // }
//                     txt = `N+${i}`

//                     return <button
//                         key={i}
//                         className={`${styles.arrowWrap} ${i === 0 ? styles.initial : ""} ${steps <= 2 ? styles.removeDisabled : ""}`} onClick={() => {
//                             if (steps <= 2 || i === 0) { return }
//                             setSteps(steps - 1)
//                         }}
//                     >
//                         {(steps > 2 || i !== 0) && <div className={styles.remove}>
//                             <div className={styles.arrowIcon}>
//                                 <UilTimes />
//                             </div>Remove Step
//                         </div>}
//                         <div className={styles.arrowLeft} />
//                         <div className={styles.arrowLabel}>{txt}</div>
//                         {/* {i !== steps - 1 && <div className={styles.arrowLabel}>End</div>} */}
//                         <div className={styles.arrowRight} />
//                     </button>
//                 })}
//                 {steps < 10 && <button
//                     className={`${styles.arrowWrap} ${styles.add}`}
//                     onClick={() => {
//                         if (steps > 10) { return }
//                         setSteps(steps + 1)
//                     }}
//                     style={{
//                         width: `calc(100% / ${steps})`
//                     }}
//                 >
//                     <div className={styles.arrowLeft} />
//                     <div className={styles.arrowLabel}>
//                         <div className={styles.arrowIcon}>
//                             <UilPlus />
//                         </div>
//                         Add Step</div>
//                     <div className={styles.arrowRight} />

//                 </button>}
//             </div>
//             <PathfinderChart
//                 nodeData={nodeData}
//                 linkData={linkData}
//                 sumByStep={sumByStep}
//                 steps={steps}
//             />
//         </div>
//     )
// }

function getStoredEvent(event) {
    // MULTIPLE EVENTS:
    // let filtered = []
    // for (let i = 0; i < events.length; i++) {
    //     const { id } = events[i] || {}
    //     if(isObjectEmpty(events[i])) continue
    //     if (id == null || id === "") continue
    //     filtered.push(events[i])
    // }

    // const filteredEvents = filtered.map((filter) => {
    //     const { id, name, section } = filter
    //     return { id, name, section } 
    // })
    // return filteredEvents;
    if (event == null) return null
    const { id, name, section } = event
    return { id, name, section }
}