import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyCeW8Yf-jCOpPWoiCyoMdVmOFkces8lGUs",
    authDomain: "multibase-1.firebaseapp.com",
    projectId: "multibase-1",
    storageBucket: "multibase-1.appspot.com",
    messagingSenderId: "828361756527",
    appId: "1:828361756527:web:5f075ed40eefdef0f8229a",
    measurementId: "G-X8D4ZLRJ49"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);