import { getEndpoint, postEndpoint } from "ApiConnector";

export async function saveQuery(data) {
    const results = await postEndpoint("query/save", data)
    if (results == null) return
    if(data.id != null) return
    const { data: response } = results
    return response.id
}

export async function deleteQuery(id) {
    const results = await postEndpoint("query/delete", { id })
    if (results == null) return
}

export async function getQuery(id) {
    const results = await getEndpoint("query", { id })
    if (results == null) return
    return results.data
}