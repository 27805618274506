export function exportCSV(data, name) {
    if (data == null) { return }
    const csvData = data.map((item) => {
        return item.join(",");
    }).join("\n");
    let csvContent = "data:text/csv;charset=utf-8," + csvData;
    var encodedUri = encodeURI(csvContent);
    var dlLink = document.createElement("a");
    const filename = `${name} - [Multibase] ${new Date().toISOString()}.csv`
    dlLink.download = filename;
    dlLink.href = encodedUri;
    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
}