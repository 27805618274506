import styles from "./EventManager.module.css";
import { CardV2 } from "components/CardV2/CardV2";
import { Content } from "components/Content/Content";
import { useEffect, useRef, useState } from "react";
import { formatNumber, formatTimeRecentISO, getExplorerLink, rowify, smartTrim } from "helpers";
import { getEndpoint, postEndpoint, usePostEndpoint } from "ApiConnector";
import moment from "moment";
import { toast } from "react-hot-toast";
import { CircularProgress, LinearProgress } from "@mui/material";
import { ClassicSaveModal } from "components/ClassicSave/ClassicSaveModal";
import { PLATFORMS, SINGLE_STRUCTURE, userOptionFn } from "Constants";
import { Link, useSearchParams } from "react-router-dom";
import PageLoading from "components/PageLoading/PageLoading";
import { getRequestFilters, getStoredFilters } from "utils/filter";
import { Filters } from "components/Filters/Filters";
import { ArrowForwardIosOutlined, ExpandMoreOutlined, RefreshRounded } from "@mui/icons-material";
import styled from "@emotion/styled";

export function EventManager() {
    const [query, setQuery] = useState("");
    const [eventType, setEventType] = useState("all")
    const [collapsed, setCollapsed] = useState([])
    const [filters, setFilters] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [offset, setOffset] = useState(0)
    const [events, setEvents] = useState(undefined)
    const [lastUpdated, setLastUpdated] = useState(moment().toISOString())
    const [loadingBlockingData, setLoadingBlockingData] = useState(searchParams.get("label") != null)

    useEffect(() => {
        const label = searchParams.get("label")
        if (label == null) return

        setLoadingBlockingData(true)

        async function f() {
            const { data } = await postEndpoint("labels", { ids: [label] })
            if (data == null) return
            const { id, name } = data[0]
            setFilters([
                ...filters,
                {
                    filterResourceType: "label",
                    filterObject: null,
                    filterKey: null,
                    filterStructure: SINGLE_STRUCTURE,
                    filterDatatype: "string",
                    filterOperator: "eq",
                    filterValue: [{
                        id: label,
                    }],
                    name,
                }
            ])
            setLoadingBlockingData(false)
            searchParams.delete("label")
            setSearchParams(searchParams)
        }
        f()
    }, [searchParams.get("label")])

    const requestOptions = getRequestOptions(filters, offset, lastUpdated, eventType)
    const { data: eventResponse, status, remove } = usePostEndpoint({ endpoint: "events", body: requestOptions, shouldRound: false })

    function onEventTypeChange(type) {
        setEventType(type)
        setEvents(undefined)
        remove()
        setOffset(0)
        setCollapsed([])
    }

    useEffect(() => {
        if (eventResponse == null) return
        if (offset === 0) {
            setEvents(eventResponse)
            return
        }
        if (events == null) {
            setEvents(eventResponse)
            return
        }

        const newEventsExist = eventResponse.some(newEvent => !events.some(oldEvent => oldEvent.id === newEvent.id))
        if (!newEventsExist) return
        setEvents([...events, ...eventResponse])
    }, [eventResponse, events])

    function reset() {
        setQuery("")
        setFilters([])
    }

    const isEmpty = query === "" && filters.length === 0

    if (loadingBlockingData) return <PageLoading />

    return <>
        <Content>
            <TopBar
                onRefresh={() => {
                    setLastUpdated(moment().toISOString())
                    setOffset(0)
                    setEvents(undefined)
                    setCollapsed([])
                }}
                updated={lastUpdated}
            />
            <CardV2 style={{ marginBottom: 24 }}>
                <div className={styles.title}>Filters</div>
                <div className={styles.filters}>
                    <Filters filters={filters} setFilters={setFilters} layout="FULL" />
                </div>
            </CardV2>
            <div className={styles.barAboveTable}>
                <div className={styles.barAboveTableLeft}>
                    <button className={`${styles.eventTypeSelect} ${eventType === "all" && styles.selected}`} onClick={() => onEventTypeChange("all")}>All events</button>
                    <button className={`${styles.eventTypeSelect} ${eventType === "onchain" && styles.selected}`} onClick={() => onEventTypeChange("onchain")}>On-chain events</button>
                    <button className={`${styles.eventTypeSelect} ${eventType === "custom" && styles.selected}`} onClick={() => onEventTypeChange("custom")}>Custom events</button>
                </div>
                <div className={styles.barAboveTableRight}>
                    <button className={`${styles.outline} ${isEmpty ? styles.disabled : ""}`} onClick={reset}>Reset all</button>
                </div>
            </div>
            <ResultsTable
                data={events}
                hardLoading={eventResponse === undefined && (events == null || events?.length === 0)}
                softLoading={status === "loading" && events?.length < offset + 100}
                broadLoading={status === "loading"}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                offset={offset}
                setOffset={setOffset}
                eventType={eventType}
            />
        </Content>
    </>
}

function getRequestOptions(filters, offset, lastUpdated, eventType) {
    return {
        filters: getRequestFilters(filters),
        offset,
        max: lastUpdated,
        type: eventType
    }
}

function ResultsTable({ data, hardLoading, softLoading, broadLoading, offset, setOffset, collapsed, setCollapsed, eventType }) {

    const rows = data == null ? undefined : rowify(data)

    function rowClicked(row) {
        setCollapsed(collapsed.includes(row.id) ? collapsed.filter(x => x !== row.id) : [...collapsed, row.id])
    }

    if (hardLoading || rows == null) {
        return <CardV2 noPadding>
            <div className={styles.loading}>
                <CircularProgress sx={{ color: "white" }} />
            </div>
        </CardV2>
    }

    const columnsToUse = getColumns(eventType)

    return <div className={styles.eventTable}>
        <CardV2 noPadding>
            {rows.length === 0 && <div className={styles.noWrap}>
                <div className={styles.noTitle}>No events</div>
                <div className={styles.noDescription}>Adjust your filters or import some events</div>
            </div>}
            {rows.length > 0 && <HeaderRow columns={columnsToUse} />}
            {broadLoading && <div className={styles.linearProgressWrap}>
                <MultibaseProgress />
            </div>}
            {rows.map((row, i) => {
                const isCollapsed = collapsed.includes(row.id)
                return <EventRow key={i} row={row} collapsed={isCollapsed} onClick={() => rowClicked(row)} columns={columnsToUse} />
            })}
        </CardV2>
        <EventBottom
            length={rows.length}
            lastTime={rows[rows.length - 1]?.ts}
            loading={softLoading}
            onClick={() => {
                setOffset(offset + 100)
            }}
        />
    </div>
}

function getColumns(eventType) {
    switch (eventType) {
        case "all":
            return columnsForAll
        case "onchain":
            return columnsForOnchain
        case "custom":
            return columnsForCustom
        default:
            return columnsForAll
    }
}

const nullableRender = ({ value, row }) => value == null || value === "" ? <span className={styles.null}>–</span> : value

const columnsForAll = [
    { key: "ts", title: "Time", renderCell: ({ value: ts }) => moment(ts).format("YYYY-MM-DD HH:mm:ss") },
    { key: "event", title: "Event", renderCell: nullableRender },
    {
        key: "uid", title: "User", renderCell: ({ value, data }) => {
            const { user_alias } = data
            const userText = (user_alias != null && user_alias !== "") ? user_alias : smartTrim(value, 15)
            return <Link to={`/users/${value}`} className={styles.userLink}>{userText}</Link>
        }
    },
    {
        key: "type", title: "Type", renderCell: ({ value: rowType }) => {
            if (rowType == null) return <div>–</div>
            if (rowType === "custom") return <div>Custom</div>
            if (rowType === "onchain") return <div>On-chain</div>
        }
    }
]

const columnsForCustom = [
    { key: "ts", title: "Time", renderCell: ({ value: ts }) => moment(ts).format("YYYY-MM-DD HH:mm:ss") },
    { key: "event", title: "Event" },
    {
        key: "uid", title: "User", renderCell: ({ value, data }) => {
            const { user_alias } = data
            const userText = (user_alias != null && user_alias !== "") ? user_alias : smartTrim(value, 15)
            return <Link to={`/users/${value}`} className={styles.userLink}>{userText}</Link>
        }
    },
    { key: "city", title: "City", renderCell: nullableRender },
    { key: "region", title: "Region", renderCell: nullableRender },
    { key: "country", title: "Country", renderCell: nullableRender },
]

const columnsForOnchain = [
    { key: "ts", title: "Time", renderCell: ({ value: ts }) => moment(ts).format("YYYY-MM-DD HH:mm:ss") },
    { key: "event", title: "Event", renderCell: nullableRender },
    {
        key: "chain", title: "Chain", renderCell: ({ value: chain }) => {
            if (chain == null) return <div>–</div>
            const pObj = PLATFORMS.find((x) => x.id === chain)
            if (pObj == null) return <div>–</div>
            return <div className={styles.simpleFlex}>
                <img src={pObj?.imgURL} className={styles.platformIcon} />
                <div>{pObj?.name}</div>
            </div>
        }
    },
    {
        key: "uid", title: "User", renderCell: ({ value, data }) => {
            const { user_alias } = data
            const userText = (user_alias != null && user_alias !== "") ? user_alias : smartTrim(value, 15)
            return <Link to={`/users/${value}`} className={styles.userLink}>{userText}</Link>
            // if (user_alias != null) return <Link to={`/users/${uid}`} className={styles.userLink}>{user_alias}</Link>
            // return <Link to={`/users/${uid}`} className={styles.userLink}>{uid}</Link>
        }
    },
    {
        key: "interacted", title: "Interacted", renderCell: ({ value, data }) => {
            const { chain, interacted_alias } = data
            const interactedText = (interacted_alias != null && interacted_alias !== "") ? interacted_alias : smartTrim(value, 15)
            return <a href={getExplorerLink(chain, "address", value)} target="_blank" rel="noreferrer" className={styles.userLink}>{interactedText}</a>
        }
    },
    // {
    //     key: "from_address", title: "From", renderCell: ({ value, data }) => {
    //         const { is_sender, chain } = data
    //         if (is_sender) {
    //             return <Link to={`/users/${value}`} className={styles.userLink}>{value}</Link>
    //         }
    //         return <a href={getExplorerLink(chain, "address", value)} target="_blank" rel="noreferrer" className={styles.userLink}>{value}</a>
    //     }
    // },
    // { key: "value", title: "Value", renderCell: ({ value }) => formatNumber(value) },
]

function HeaderRow({ row, columns }) {

    const columnWidth = 100 / columns.length

    return <div className={styles.eventHeaderRow}>
        <div className={styles.collapseButtonEmpty} />
        {columns.map((column, i) => {
            return <EventHeaderColumn key={i} text={column.title} width={columnWidth} />
        })}
    </div>
}

function EventHeaderColumn({ text, width }) {
    return <div className={styles.eventHeaderColumn} style={{ width: `${width}%` }}>
        {text}
    </div>
}

function EventRow({ row, collapsed, onClick, columns }) {

    const columnWidth = 100 / columns.length

    return <>
        <div className={styles.eventRow} onClick={onClick}>
            <button onClick={onClick} className={styles.collapseButton} style={{ transform: collapsed ? "rotate(90deg)" : "rotate(0deg)" }}>
                {collapsed ? <div className={styles.collapseIcon}><ArrowForwardIosOutlined /></div> : <div className={styles.collapseIcon}><ArrowForwardIosOutlined /></div>}
            </button>
            {columns.map((column, i) => {
                const { renderCell = ({ value }) => value } = column
                const text = renderCell({ value: row[column.key], data: row })
                return <EventCell key={i} text={text} width={columnWidth} />
            })}
        </div>
        {collapsed && <div className={styles.collapsed}>
            <CollapseEventBody row={row} />
        </div>}
    </>
}

function CollapseEventBody({ row }) {
    const { properties, metadata, type } = row
    if (type === "custom") return <CollapseEventBodyCustom properties={properties} />
    if (type === "onchain") return <CollapseEventBodyOnchain metadata={metadata} />
}

function CollapseEventBodyCustom({ properties }) {
    const indexGreaterThanForLastRow = properties.length % 3 === 0 ? properties.length - 3 : properties.length - properties.length % 3
    return <div className={styles.collapsedInner}>
        {properties.map((property, i) => {
            const isOnLastLine = properties.length <= 3 ? true : i >= indexGreaterThanForLastRow
            return <div key={i} className={`${styles.collapsedCell} ${isOnLastLine ? styles.lastLine : ""}`}>
                <div className={styles.collapsedTitle}>{property.name}:</div>
                <div className={styles.collapsedText}>{property.value}</div>
            </div>
        })}
    </div>
}

function CollapseEventBodyOnchain({ metadata }) {
    const { chain, to_address, from_address, hash, is_sender, value } = metadata
    const platformObj = PLATFORMS.find((x) => x.id === chain)

    const properties = [
        {
            name: "Chain",
            value: platformObj?.name
        }, {
            name: "From",
            value: <a href={getExplorerLink(chain, "address", from_address)} target="_blank" rel="noreferrer" className={styles.collapseLinkInline}>{smartTrim(from_address, 15)}</a>
        },
        {
            name: "To",
            value: <a href={getExplorerLink(chain, "address", to_address)} target="_blank" rel="noreferrer" className={styles.collapseLinkInline}>{smartTrim(to_address, 15)}</a>
        },
        {
            name: "Hash",
            value: <a href={getExplorerLink(chain, "tx", hash)} target="_blank" rel="noreferrer" className={styles.collapseLinkInline}>{smartTrim(hash, 25)}</a>
        },
        {
            name: "Value",
            value: <>{formatNumber({ number: value, decimals: 2 })} {platformObj?.token}</>
        }
    ]

    const indexGreaterThanForLastRow = properties.length % 3 === 0 ? properties.length - 3 : properties.length - properties.length % 3

    // we want to link to tx on Etherscan, then lay out the rest of the data

    return <>

        <div className={styles.collapsedTop}>
            <Link to={`/users/${is_sender ? to_address : from_address}`} className={styles.collapseLink}>View user</Link>
            <a href={getExplorerLink(chain, "address", is_sender ? to_address : from_address)} target="_blank" rel="noreferrer" className={styles.collapseLink}>View user on explorer</a>
            <a href={getExplorerLink(chain, "tx", hash)} target="_blank" rel="noreferrer" className={styles.collapseLink}>View transaction on explorer</a>
        </div>

        <div className={styles.collapsedInner}>

            {properties.map((property, i) => {
                const isOnLastLine = properties.length <= 3 ? true : i >= indexGreaterThanForLastRow
                return <div key={i} className={`${styles.collapsedCell} ${isOnLastLine ? styles.lastLine : ""}`}>
                    <div className={styles.collapsedTitle}>{property.name}:</div>
                    <div className={styles.collapsedText}>{property.value}</div>
                </div>
            })}
        </div>
    </>
}

function EventCell({ text, width }) {
    return <div className={styles.eventCell} style={{ width: `${width}%` }}>
        {text}
    </div>
}

function TopBar({ onRefresh, updated }) {

    return <div className={styles.topBar}>
        <div className={styles.left}>
            <div className={styles.topTitleContainer}>
                <div className={styles.topTitle}>Events</div>
                {updated == null && <>&nbsp;</>}
                {updated != null && <button onClick={onRefresh} className={styles.refreshButton}><RefreshRounded /></button>}
                {updated != null && <div className={styles.topSubtitle}>Updated {formatTimeRecentISO(updated)}</div>}
            </div>
        </div>
    </div>
}

function EventBottom({ length, lastTime, loading, onClick }) {

    return <div className={styles.eventBottom}>
        <div className={styles.eventBottomInner}>
            <div>Showing {length} results through {moment(lastTime).format("YYYY-MM-DD HH:mm:ss")}</div>
            <CustomLoadingButtonV2 className={styles.loadBtn} light={true} loading={loading} onClick={onClick}>
                Load more
            </CustomLoadingButtonV2>
        </div>
    </div>
}

function CustomLoadingButtonV2({ loading, className, onClick, light, children }) {
    if (loading) {
        const color = light ? "rgb(255,255,255)" : "rgb(0,0,0)"
        return <div className={`${className} ${styles.stockLoadingButton}`}>
            <CircularProgress size={12} sx={{ color }} />
            {children}
        </div>
    }

    return <button className={className} onClick={onClick}>
        {children}
    </button>
}

const MultibaseProgress = styled(LinearProgress)({
    height: 4,
    backgroundColor: "rgb(var(--primary) / 20%)",
    '& .MuiLinearProgress-bar': {
        borderRadius: 4,
        backgroundColor: "rgb(var(--primary))"
    }
})