import { useGlobal } from 'hooks/global';
import styles from './Banner.module.css';
import { timeDifference } from 'helpers';

export function Banner({ absolute = false }) {
    const { workspace } = useGlobal();
    if (workspace == null) return null
    const { status } = workspace || {}
    if (status == null) return null
    const { type, expiry } = status || {}
    if (type !== "trial") return null
    const dateFmt = timeDifference(expiry, true)

    return <div className={`${styles.banner} ${absolute && styles.abs}`}>
        <div className={styles.bannerLeft}>
            <div className={styles.bannerIcon}>
                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m8.806 5.614-4.251.362-2.244 2.243a1.058 1.058 0 0 0 .6 1.8l3.036.356m9.439 1.819-.362 4.25-2.243 2.245a1.059 1.059 0 0 1-1.795-.6l-.449-2.983m9.187-12.57a1.536 1.536 0 0 0-1.26-1.26c-1.818-.313-5.52-.7-7.179.96-1.88 1.88-5.863 9.016-7.1 11.275a1.05 1.05 0 0 0 .183 1.25l.932.939.937.936a1.049 1.049 0 0 0 1.25.183c2.259-1.24 9.394-5.222 11.275-7.1 1.66-1.663 1.275-5.365.962-7.183Zm-3.332 4.187a2.115 2.115 0 1 1-4.23 0 2.115 2.115 0 0 1 4.23 0Z" />
                </svg>
            </div>
            <div className={styles.bannerText}>
                <div className={styles.bannerTextTitle}>Multibase Trial</div>
                <div className={styles.bannerTextDesc}>Your Multibase trial expires in {dateFmt}</div>
            </div>
        </div>
        <div className={styles.bannerRight}>
            <a href="https://t.me/emmettm" target="_blank" className={styles.bannerButton}>
                <div className={styles.bannerButtonIcon}>
                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                    </svg>
                </div>
                <div>Contact us</div>
            </a>
        </div>
    </div>
}