import { cloneElement, createElement, useEffect, useRef, useState } from "react"
import styles from "./MultiDropdown.module.css"
import { CircularProgress, LinearProgress, Popover } from "@mui/material"
import { emptyCheck, getArrowIndex } from "helpers"
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import styled from "@emotion/styled";
import { UilCheck } from '@iconscout/react-unicons'
import { EditRounded } from "@mui/icons-material";

export function MultiDropdown({
    options,
    value,
    onChange: onChangeProp,
    onClose,
    isOpen,
    anchorEl,
    isLoadingAsyncOptions,
    settings
}) {
    const [tempValue, setTempValue] = useState(value)
    const [query, setQuery] = useState("")
    const [highlightedIndex, setHighlightedIndex] = useState(undefined)
    const [filteredOptions, setFilteredOptions] = useState(options)
    const [hasMouseMovedLast, setHasMouseMovedLast] = useState(false)
    const [activeIndex, setActiveIndex] = useState(undefined)
    const scrollerRef = useRef(null)
    const [searchRef, setSearchRef] = useState(null)
    const [searching, setSearching] = useState(false)
    const [searchTimer, setSearchTimer] = useState(null)
    const { freeform = false } = settings || {}

        // // execute search after 200ms of inactivity
        // setPendingSearch(true)
        // if (searchTimer) clearTimeout(searchTimer)
        // const timer = setTimeout(() => executeSearch(), 500)
        // setSearchTimer(timer)


    function searchKeyChange(value) {
        setQuery(value)
        setSearching(true)
        if (searchTimer) clearTimeout(searchTimer)
        const timer = setTimeout(() => {
            resetFilterOptions(value)
            setSearching(false)
        }, 400)
        setSearchTimer(timer)
    }

    function onCommit() {
        onChangeProp(tempValue)
        onClose()
    }

    function onChange({ id, name }) {
        let existingArray = [...tempValue || []]
        // if (existingArray.some((item) => item.id === value.id)) {
        if (existingArray.some((x) => x.id === id)) {
            existingArray = existingArray.filter((item) => item.id !== id)
        } else {
            existingArray.push({ id, name })
        }
        if (existingArray.length === 0) existingArray = null
        setTempValue(existingArray)
    }

    useEffect(() => {
        setTempValue(value)
    }, [value, isOpen])

    useEffect(() => {
        if (!isOpen) {
            setQuery("")
            setActiveIndex(undefined)
            // setFilteredOptions(undefined)
            setHighlightedIndex(undefined)
            scrollerRef.current?.scrollTo(0, 0)
        }
        if (isOpen && searchRef) {
            searchRef.focus()
        }
    }, [isOpen, searchRef])

    useEffect(() => {
        if (!isOpen) return
        if (filteredOptions?.length === 0 || filteredOptions == null) return
        if (value == null) return
        const firstActiveIndex = filteredOptions.findIndex(e => value.includes(e.name))
        if (firstActiveIndex === -1) return
        setActiveIndex(firstActiveIndex)
        setHighlightedIndex(firstActiveIndex)
        const el = document.getElementById(`option-${firstActiveIndex}`)
        el?.scrollIntoView({ block: "start" })
    }, [value, isOpen, filteredOptions])

    useEffect(() => {
        if (!isOpen) return
        // on mouse move, set hasMouseMovedLast to true
        const handleMouseMove = () => {
            setHasMouseMovedLast(true)
        }
        window.addEventListener("mousemove", handleMouseMove)
        return () => {
            window.removeEventListener("mousemove", handleMouseMove)
        }
    }, [isOpen])

    useEffect(() => {
        if (!isOpen) return
        const handleKeyDown = (e) => {
            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault()
                const index = getArrowIndex({
                    up: e.key === "ArrowUp",
                    highlightedIndex,
                    activeIndex,
                    optionsLength: filteredOptions.length,
                })
                setHighlightedIndex(index)
                const el = document.getElementById(`option-${index}`)
                el?.scrollIntoView({ block: "nearest" })
                setHasMouseMovedLast(false)
            }

            if (e.key === "Enter") {
                if (highlightedIndex == null) return
                e?.preventDefault()
                const opt = filteredOptions[highlightedIndex]
                onChange(opt)
            }

            // if command + enter, commit
            if (e.key === "Enter" && e.metaKey) {
                onCommit()
            }
        }
        window.addEventListener("keydown", handleKeyDown)
        return () => window.removeEventListener("keydown", handleKeyDown)
    })

    function getOptions(inputOptions){
        return [...tempValue || [], ...inputOptions.filter((x)=> !tempValue?.some((y) => y.id === x.id))]
    }    

    function resetFilterOptions(inputQuery) {

        const queryToUse = inputQuery != null ? inputQuery : query

        if(queryToUse === ""){
            setFilteredOptions(getOptions(options))
            return
        }

        const queryOption = {
            name: queryToUse,
            icon: EditRounded,
            section: "freeform",
            id: queryToUse
        }

        const newOptions = getOptions([queryOption, ...options]).filter((x) => {
            const { name } = x
            if (name != null) return name.toLowerCase().includes(queryToUse.toLowerCase())
            return false
        })

        setFilteredOptions(newOptions)
    }

    useEffect(() => {
        if(!isOpen) return
        if (options == null) return
        resetFilterOptions()
    }, [isOpen, options])

    const dirty = JSON.stringify(value || []) !== JSON.stringify(tempValue || [])
    const addDisabled = !dirty
    const addText = tempValue == null && dirty ? "Reset" : "Add"
    const inputPlaceholder = freeform ? "Enter a value..." : "Search..."

    if (!isOpen) return
    if (anchorEl == null) return

    return <Popover
        open={isOpen}
        anchorEl={anchorEl?.current}
        transitionDuration={0}
        onClose={onClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
    >
        <div className={styles.dropdown}>
            {(isLoadingAsyncOptions || searching) && <div className={styles.linearProgressWrap}>
                <MultibaseProgress />
            </div>}
            <input ref={(r) => {
                setSearchRef(r)
            }} className={styles.search} placeholder={inputPlaceholder} value={query} onChange={(e) => searchKeyChange(e.target.value)} />
            {(isLoadingAsyncOptions || searching) && <div className={styles.loading}>
                <CircularProgress size={16} sx={{ color: "rgb(var(--primary))" }} />
                <div className={styles.loadingText}>Loading...</div>
            </div>}
            {(!isLoadingAsyncOptions && !searching) && <div className={styles.dropdownContent} ref={scrollerRef}>
                {filteredOptions?.map((option, index) => {
                    return <PropertyValueOption
                        key={index}
                        option={option}
                        checked={tempValue?.some((x) => x.id === option.id)}
                        hasMouseMovedLast={hasMouseMovedLast}
                        isHighlighted={highlightedIndex === index}
                        setHighlightedIndex={setHighlightedIndex}
                        index={index}
                        onChange={onChange}
                    />
                })}
            </div>}
            <button className={`${styles.add} ${addDisabled ? "" : styles.notDisabled}`} onClick={onCommit} disabled={addDisabled}>{addText}</button>
        </div>
    </Popover>
}


function PropertyValueOption({ option, hasMouseMovedLast, isHighlighted, setHighlightedIndex, checked, index, onChange }) {
    return <button
        onMouseEnter={() => {
            if (hasMouseMovedLast) setHighlightedIndex(index)
        }}
        onClick={(e) => { onChange(option) }}
        className={`${styles.propertyValueOption} ${isHighlighted ? styles.highlighted : ""}`}
        id={`option-${index}`}
    >
        <div className={`${styles.checkboxWrap} ${checked ? styles.checked : ""}`}>
            {checked && <UilCheck />}
        </div>
        <div className={styles.optionLabel}>{emptyCheck(option.name)}</div>
    </button>
}

const MultibaseProgress = styled(LinearProgress)({
    height: 2,
    backgroundColor: "rgb(var(--primary) / 20%)",
    '& .MuiLinearProgress-bar': {
        borderRadius: 4,
        backgroundColor: "rgb(var(--primary))"
    }
})