import axios from 'axios';
import { getLeading } from 'helpers';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { auth } from './firebase-config'
import { getTimezone } from 'utils/date';

const API_ROUTING = window.location.hostname === "staging.multibase.co" ? "staging" : "internal"
const BASE_URL = process.env.NODE_ENV === "development" ? "http://localhost:8000" : "https://api.multibase.co"

function getHeaders() {
    return {
        'Content-Type': 'application/json',
        'X-Client': 'web',
        'X-Timezone': getTimezone()
    }
}

export function useGetEndpoint(endpoint, params = undefined, skip = false, transformer = undefined) {

    const queryKey = getQueryKey(endpoint, params);

    const fetchMetricEndpoint = async () => {
        const response = await getEndpoint(endpoint, params)
        if (!response) {
            throw new Error("Error")
        }
        return response.data
    }

    const enabled = skip === false && endpoint != null
    const x = useQuery(queryKey, fetchMetricEndpoint, { keepPreviousData: false, retry: 2, refetchOnMount: false, refetchOnWindowFocus: false, staleTime: 600, notifyOnChangeProps: ["data", "error"], enabled: enabled, select: transformer })
    return x
}

export function usePostEndpoint({ endpoint, body = {}, params = {}, overrideQueryKeyObj = undefined, minKey = "min", shouldRound = true, skip = false }) {

    let objToQueryKey = overrideQueryKeyObj === undefined ? body : overrideQueryKeyObj

    const queryKey = getQueryKey(endpoint, objToQueryKey, minKey, shouldRound);

    const fetchMetricEndpoint = async () => {
        const response = await postEndpoint(endpoint, body, params)
        if (!response) {
            throw new Error("Error")
        }
        return response.data
    }

    const enabled = skip === false && endpoint != null
    const x = useQuery(queryKey, fetchMetricEndpoint, { keepPreviousData: false, retry: 2, refetchOnMount: false, refetchOnWindowFocus: false, staleTime: 600, notifyOnChangeProps: ["data", "error"], enabled: enabled })
    return x
}

// export function useInfiniteEndpoint({ endpoint, method = "POST", params = {}, skip = false, getNextPage, getPreviousPage }) {

//     const queryKey = getQueryKey(endpoint, params);

//     const fetchMetricEndpoint = async ({ pageParam = 0 }) => {
//         let response = null
//         if (method === "POST") {
//             response = await postEndpoint(endpoint, { ...params, page: pageParam })
//         }
//         if (method === "GET") {
//             response = await getEndpoint(endpoint, { ...params, page: pageParam })
//         }
//         if (!response) {
//             throw new Error("Error")
//         }
//         return response.data
//     }

//     const enabled = skip === false && endpoint != null
//     // const x = useInfiniteQuery(queryKey, fetchMetricEndpoint, { keepPreviousData: false, retry: 2, refetchOnMount: false, refetchOnWindowFocus: false, staleTime: 600, notifyOnChangeProps: ["data", "error"], enabled: enabled }, 
//     // return x
//     return useInfiniteQuery({
//         queryKey,
//         queryFn: fetchMetricEndpoint,
//         getNextPageParam: getNextPage,
//         getPreviousPageParam: getPreviousPage,
//         g
//     })
// }

export function useQueryFunctions() {

    const queryClient = useQueryClient()

    function invalidate(endpoint) {
        const allQueryKeys = queryClient.getQueryCache().findAll().map(({ queryKey: queryKeyStr }) => JSON.parse(queryKeyStr))
        const found = allQueryKeys.filter((queryKeyObj) => queryKeyObj.endpoint === endpoint)
        if (found.length === 0) return
        const queryKeys = found.map((queryKeyObj) => JSON.stringify(queryKeyObj))
        for (const queryKey of queryKeys) {
            queryClient.invalidateQueries({ queryKey: queryKey })
        }
    }

    return { invalidate }
}

export async function getEndpoint(endpoint, params = {}) {
    const url = formURL(endpoint, params)
    try {
        const response = await axios.get(url, { withCredentials: true, headers: getHeaders() });
        return response.data;
    } catch {
        return (undefined)
    }
}

export async function postEndpoint(endpoint, body = {}, params = {}) {
    const url = formURL(endpoint, params)
    try {
        const response = await axios.post(url, body, {
            withCredentials: true, headers: getHeaders()
        });
        return response.data;
    } catch {
        return (undefined)
    }
}

export async function postEndpointWithErrorListening(endpoint, body = {}, params = {}) {
    const url = formURL(endpoint, params)
    try {
        const response = await axios.post(url, body, { withCredentials: true, headers: getHeaders() });
        return { data: response.data, error: null, message: null };
    } catch (error) {
        return { data: null, error: error.response?.data };
    }
}

export async function postEndpointWithToken(endpoint, body = {}, params = {}) {
    const url = formURL(endpoint, params)
    const token = await auth.currentUser.getIdToken()
    try {
        const response = await axios.post(url, body, { headers: { ...getHeaders(), Authorization: `Bearer ${token}` }, withCredentials: true });
        return response.data;
    } catch {
        return (undefined)
    }
}


function formURL(endpoint, params) {
    const formedURL = process.env.NODE_ENV === "development" ?
        `${BASE_URL}/${endpoint}${dictToURI(params)}` :
        `${BASE_URL}/${API_ROUTING}/v1/${endpoint}${dictToURI(params)}`
    return formedURL
}

function dictToURI(dict) {
    if (!dict) { return "" }
    if (Object.keys(dict).length === 0) { return "" }
    var str = [];
    for (var p in dict) {
        if (dict[p] === undefined) {
            continue
        }
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(dict[p]));
    }
    return "?" + str.join("&");
}

function getQueryKey(endpoint, params, minKey = "min", maxKey = "max", shouldRound = true) {

    const pc = params || {}
    let copy = { ...pc }

    if (shouldRound) {
        const minVal = copy[minKey]
        if (minVal != null) {
            delete copy[minKey]
            copy.min = getLeading(minVal, 300)
        }
        const maxVal = pc[maxKey]
        if (maxVal != null) {
            delete copy[maxKey]
            copy.max = getLeading(maxVal, 300)
        }
    }

    const queryKeyObj = { endpoint, params: copy }
    const queryKey = JSON.stringify(queryKeyObj)
    // const keyencoded = sha1(query_key).toString()
    return queryKey
}
