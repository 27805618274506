import { createElement } from "react"
import styles from "./InputButton.module.css"
import { UilAngleDown } from '@iconscout/react-unicons'

export function InputButton({ onOpen, className, isOpen, buttonText, empty, locked, pref, icon, arrow = true, fill = false, sx = {} }) {
    const sxb = getStyles({fill, empty})
    const s = { ...sxb, ...sx}

    const selectInputStyles = {}
    if(icon != null) selectInputStyles.paddingLeft = "8px"
    if(!arrow) selectInputStyles.paddingRight = "8px"

    const iconStyles = {}
    if(!arrow) iconStyles.marginLeft = "8px"

    return <button className={`${styles.select} ${locked ? styles.locked : ""} ${isOpen ? styles.isOpen : ""} ${className}`} style={s} onClick={(e) => {
        if (locked) return;
        onOpen()
    }} ref={pref}>
        {icon && <div className={styles.iconWrap} style={iconStyles}>{createElement(icon)}</div>}
        <div 
            className={`${styles.selectText} ${empty ? styles.placeholder : ""}`}
            style={selectInputStyles}
        >{buttonText}</div>
        {arrow && <div className={styles.arrowWrap}><UilAngleDown /></div>}
    </button>
}

function getStyles({fill, empty}){
    let styles = {}
    if(fill){
        styles.width = "100%"
        styles.maxWidth = "unset"
    } else {
        styles.width = "fit-content"
    }
    // if(empty) styles.minWidth = "200px"
    // else {
    //     // set maxWidth to null 
    //     styles.minWidth = undefined
    //     styles.paddingRight = "24px"
    // }
    return styles
}