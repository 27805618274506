import styled from "@emotion/styled"
import styles from "./SelectV2.module.css"
import { UilAngleDown } from '@iconscout/react-unicons'
import { useEffect, useRef, useState } from "react"
import { LinearProgress, Popover } from "@mui/material"
import { getArrowIndex } from "helpers"

export function SelectV2({ option, options, onChange, className, sx, placeholder = "Select..." }) {

    const [isOpen, setIsOpen] = useState(false)
    const anchorEl = useRef(null)

    const optionTxt = option == null ? placeholder : options.find(opt => opt.id === option).name

    return <button className={`${styles.select} ${isOpen ? styles.isOpen : ""} ${className}`} style={sx} onClick={(e) => {
        setIsOpen(true)
    }} ref={anchorEl}>
        <SelectDropdownInner
            isOpen={isOpen}
            onClose={(e) => {
                e?.stopPropagation()
                setIsOpen(false)
            }}
            anchorEl={anchorEl}
            onChange={(e, event) => {
                e?.stopPropagation()
                setIsOpen(false)
                onChange(event)
            }}
            option={option}
            options={options}
        />
        <div className={`${styles.selectText} ${option == null ? styles.placeholder : ""}`}>{optionTxt}</div>
        <div className={styles.arrowWrap}><UilAngleDown /></div>
    </button>
}

export function SelectDropdownInner({ option, options, isLoadingAsyncOptions, anchorEl, isOpen, onClose, onChange, classes = {} }) {

    const scrollerRef = useRef(null)
    const [highlightedIndex, setHighlightedIndex] = useState(undefined)
    const [hasMouseMovedLast, setHasMouseMovedLast] = useState(true)

    useEffect(() => {
        if (!isOpen) {
            setHighlightedIndex(undefined)
            scrollerRef.current?.scrollTo(0, 0)
        }
    }, [isOpen])

    useEffect(() => {
        if (!isOpen) return
        // on mouse move, set hasMouseMovedLast to true
        const handleMouseMove = () => {
            setHasMouseMovedLast(true)
        }
        window.addEventListener("mousemove", handleMouseMove)
        return () => {
            window.removeEventListener("mousemove", handleMouseMove)
        }
    }, [isOpen])

    useEffect(() => {
        if (!isOpen) return
        const handleKeyDown = (e) => {
            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e?.stopPropagation()
                // activeindex is first value
                const activeIndex = options.findIndex((x) => x.id === option)
                const index = getArrowIndex({
                    up: e.key === "ArrowUp",
                    highlightedIndex,
                    activeIndex,
                    optionsLength: options.length,
                })
                setHighlightedIndex(index)
                const el = document.getElementById(`select-${index}`)
                el?.scrollIntoView({ block: "nearest" })
                setHasMouseMovedLast(false)
            }

            if (e.key === "Enter") {
                if(highlightedIndex == null) return
                e?.preventDefault()
                onChange(null, options[highlightedIndex].id)
                // onClose()
                    // I commented this out becaue it was causing the filter select to 
                    // setSection to something we dont want.

                    // if this bugs out, move onClose above onChange
            }
        }
        window.addEventListener("keydown", handleKeyDown)
        return () => window.removeEventListener("keydown", handleKeyDown)
    }, [highlightedIndex, isOpen])

    useEffect(() => {
        setHighlightedIndex(0)
    }, [options])

    return <Popover
        open={isOpen}
        anchorEl={anchorEl?.current}
        keepMounted={true}
        transitionDuration={0}
        onClose={onClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
    >
        <div className={styles.dropdown}>
            {isLoadingAsyncOptions && <div className={styles.linearProgressWrap}>
                <MultibaseProgress />
            </div>}
            {options.map((opt, index) => {

                const { id, name } = opt
                const isHighlighted = index === highlightedIndex
                const isActive = option === id

                return <button
                    key={index}
                    className={`${styles.option} ${classes.option} ${isHighlighted ? styles.highlighted : ""} ${isActive ? styles.active : ""}`}
                    onClick={(e) => {
                        onChange(e, id)
                    }}
                    id={`select-${index}`}
                    onMouseEnter={() => {
                        if (hasMouseMovedLast) setHighlightedIndex(index)
                    }}
                >
                    <div className={styles.eventName}>{name}</div>
                </button>
            })}
        </div>
    </Popover>
}


const MultibaseProgress = styled(LinearProgress)({
    height: 2,
    backgroundColor: "rgb(var(--primary) / 20%)",
    '& .MuiLinearProgress-bar': {
        borderRadius: 4,
        backgroundColor: "rgb(var(--primary))"
    }
})