import { Popover } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { UilCalender, UilAngleDown } from '@iconscout/react-unicons'
import styles from "./IntegratedDateSelector.module.css";
import { MultiDatePicker, MultiDatePickerInline, SingleDatePicker, SingleDatePickerInline } from "components/DatePicker/DatePicker";
import { SelectWithInput } from "components/SelectWithInput/SelectWithInput";
import moment from "moment";
import { dateDeltaSeconds, getSecondsForIntervalAdvanced } from "helpers";
 
export function IntegratedDateSelector({ mode, date, rawDate, limitInSeconds = null, onChange, classes = {}, periods = [{ value: 30, name: "30" }, { value: 60, name: "60" }, { value: 90, name: "90" }]}) {

    const [modeDraft, setModeDraft] = useState(mode)
    const [dateDraft, setDateDraft] = useState(date);
    const [open, setOpen] = useState(false);

    // useEffect(() => {
    //     if (open && !["since", "before", "last", "between"].includes(mode)) {
    //         setModeDraft("since")
    //     }
    // }, [open])

    const anchorEl = useRef(null);

    const onClose = () => { setOpen(false) }


    function onOpen(){
        const oldMode = modeDraft
        if(oldMode === "period"){
            const guess = createGuess(rawDate)
            if(guess != null){
                const dateFromGuess = moment().subtract(guess, "seconds").toISOString();
                setDateDraft([dateFromGuess, null])
            }else{
                const oneWeekAgoDate = moment().subtract(1, "week").toISOString()
                setDateDraft([oneWeekAgoDate, null])
            }
        }
        setModeDraft("since")
        setOpen(true) 
    }

    function onApply() {
        if(modeDraft === "between" && (dateDraft[0] === null || dateDraft[1] === null)){
            return
        }
        onChange({ mode: modeDraft, min: dateDraft[0], max: dateDraft[1] })
        onClose();
    }

    function onModeChange(newMode) {
        if(newMode === "since" || newMode === "before" || newMode === "last"){
            if(dateDraft[0] == null){
                const oneWeekAgo = moment().subtract(1, "week").toISOString()
                setDateDraft([oneWeekAgo, null])
            }else{
                setDateDraft([dateDraft[0], null])
            }
        }

        if(newMode === "between"){
            let firstDate = moment().subtract(1, "week").toISOString()
            if(dateDraft[0] != null){
                firstDate = dateDraft[0]
            }
            setDateDraft([firstDate, moment().toISOString()])
        }

        setModeDraft(newMode)
    }

    function onDraftChange(val) {
        if (modeDraft === "since" || modeDraft === "before") {
            const dateValue = moment(val).toISOString()
            setDateDraft([dateValue, null])
        }
        if (modeDraft === "last") {
            const dateValue = moment(val).toISOString()
            setDateDraft([dateValue, null])
        }
        if (modeDraft === "between") {
            const [min, max] = val
            const minISO = min != null ? moment(min).toISOString() : null
            const maxISO = max != null ? moment(max).toISOString() : null
            setDateDraft([minISO, maxISO])
        }
    }

    // function changeMode(newMode) {
    //     let nv = dateDraft

    //     if (newMode === "between" && !Array.isArray(dateDraft)) {
    //         const now = moment().unix()
    //         const sevenDaysAgo = moment().subtract(7, "days").unix()

    //         nv = [sevenDaysAgo, now]
    //     }

    //     if (newMode !== "between" && Array.isArray(dateDraft)) {
    //         if (dateDraft[0] === null) {
    //             const onwWeekAgo = moment().subtract(1, "week").unix()
    //             nv = onwWeekAgo
    //         } else {
    //             nv = dateDraft[0]
    //         }
    //     }

    //     setDateDraft(nv)
    //     setModeDraft(newMode)
    // }

    // listen for enter key and onApply()
    useEffect(() => {
        if(!open){return}
        function handleKeyDown(e) {
            if (e.target.tagName === "INPUT") { return }
            if (e.key === 'Enter') {
                e.preventDefault();
                onApply();
            }
        }

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [open, dateDraft, modeDraft, setOpen])

    let absoluteMinimum = undefined
    let daysMaximum = undefined
    if(limitInSeconds != null){
        absoluteMinimum = dateDeltaSeconds(limitInSeconds)
        daysMaximum = limitInSeconds / 86400
    }
    const absoluteMaximum = moment().toDate()

    let date_el = <></>

    if (modeDraft === "since" || modeDraft === "before") {
        const dateMin = dateDraft[0]
        date_el = <SingleDatePickerInline 
            date={dateMin} 
            min={absoluteMinimum} 
            max={absoluteMaximum}
            onChange={(val) => onDraftChange(val)} 
            classes={{ input: styles.dateInput }} 
        />
    }

    if (modeDraft === "between") {
        date_el = <MultiDatePickerInline 
            date={dateDraft} 
            min={absoluteMinimum} 
            max={absoluteMaximum}
            limit={limitInSeconds} 
            onChange={(val) => onDraftChange(val)} 
            classes={{ input: styles.dateInput }} 
        />
    }

    if (modeDraft === "last") {
        const dateMin = dateDraft[0]
        const dateInSeconds = moment(dateMin)
        const nowTs = moment()
        const dayDiff = nowTs.diff(dateInSeconds, "days")
        // const differenceSeconds = nowTs - dateInSeconds
        // const dayDiff = Math.round(differenceSeconds / 86400)
        date_el =
            <div className={styles.lastWrap}>
                Last
                <SelectWithInput
                    value={dayDiff}
                    maximum={daysMaximum}
                    type={"integer"}
                    options={periods}
                    classes={{ input: styles.lastDateBtn }}
                    icon={<UilAngleDown />}
                    onChange={(val) => {
                        const nowTs = moment().unix()
                        const newTs = nowTs - (86400 * val)
                        const newDateObj = new Date(newTs * 1000)
                        onDraftChange(newDateObj)
                    }}
                />
                days
            </div>
    }

    let selectDateText = <button className={`${styles.selectBtn} ${classes.wrap}`} ref={anchorEl} onClick={onOpen}><UilCalender className={styles.selectIcon} /> Select Date</button>

    if(mode != null && mode !== "period") {
        let dateText = "" 

        if(mode === "since" || mode === "before") {
            dateText = moment(date[0]).format("MMM D, YYYY")
        }
        if(mode === "last") {
            const nowMomentObj = moment()
            const dateMomentObj = moment(date[0])
            const dayDiff = nowMomentObj.diff(dateMomentObj, "days")
            dateText = `Last ${dayDiff} days`
        }
        if(mode === "between") {
            dateText = `${moment(date[0]).format("MMM D, YYYY")} - ${moment(date[1]).format("MMM D, YYYY")}`
        }
        selectDateText = <button className={`${styles.selectBtn} ${styles.selected} ${classes.wrap}`} ref={anchorEl} onClick={onOpen}><UilCalender className={styles.selectIcon} /> {dateText}</button>
    }

    // if(dateDraft[0] == null && dateDraft[1] == null){
    //     return <>null</>
    // }

    return <>
        {selectDateText}
        {/* <button className={`${styles.selectBtn} ${classes.wrap}`} ref={anchorEl} onClick={onOpen}><UilCalender className={styles.selectIcon} /> {selectDateText}</button> */}
        <Popover
            open={open}
            onClose={onClose}
            transitionDuration={0}
            anchorEl={anchorEl?.current}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div className={styles.selectorWrap}>
                <div className={styles.selectorHeader}>
                    <button className={`${styles.selectorHeaderButton} ${modeDraft === "since" && styles.selected}`} onClick={() => onModeChange("since")}>Since</button>
                    <button className={`${styles.selectorHeaderButton} ${modeDraft === "last" && styles.selected}`} onClick={() => onModeChange("last")}>In the last</button>
                    <button className={`${styles.selectorHeaderButton} ${modeDraft === "between" && styles.selected}`} onClick={() => onModeChange("between")}>Between</button>
                </div>
                <div className={styles.selectorBody}>
                    {date_el}
                </div>
                <div className={styles.bottomWrap}>
                    <button className={styles.outlineButton} onClick={onClose}>Cancel</button>
                    <button className={styles.applyButton} onClick={onApply}>Apply</button>
                </div>
            </div>
        </Popover>
    </>
}

function createGuess(rawDate){
    if(rawDate == null){return null}
    if(rawDate?.mode === "period" && rawDate?.period != null){
        return getSecondsForIntervalAdvanced(rawDate?.period)
    }
    return null
}