import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import iconWhite from "assets/branding/icon-white.png";
import { useGlobal } from "hooks/global";
import { Spotlight } from "components/Spotlight/Spotlight";
import { useEffect, useRef, useState } from "react";
import { EmptyDropdown } from "components/EmptyDropdown/EmptyDropdown";
import { UilChartLine, UilChartPie, UilFilter } from "@iconscout/react-unicons";
import {
    Logout,
    PowerSettingsNewRounded,
    Route,
    Sync,
} from "@mui/icons-material";
import { useLimits } from "hooks/limits";
import numeral from "numeral";
import { logout, useLogout } from "helpers/account";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { timeDifference } from "helpers";
import moment from "moment";
import styled from "@emotion/styled";

const createItems = [
    {
        icon: <UilChartLine />,
        title: "Events",
        description:
            "Visualize events, balance, transactions, and activity over time.",
        href: "/query/event/create",
        color: "#4DAB9A",
    },
    {
        icon: <UilChartPie />,
        title: "Composition",
        description:
            "Overlaps among users including NFTs, tokens, and contracts.",
        href: "/query/composition/create",
        color: "#529CCA",
    },
    {
        icon: <Sync />,
        title: "Retention",
        description:
            "Explore likelihood of returning addresses to contract methods.",
        href: "/query/retention/create",
        color: "#FF7369",
    },
    {
        icon: <UilFilter />,
        title: "Funnel",
        description: "Visualize smart contract method conversion rates.",
        href: "/query/funnel/create",
        color: "#9A6DD7",
    },
    {
        icon: <Route />,
        title: "Pathfinder",
        description: "Map user behavior with paths of on-chain actions.",
        href: "/query/pathfinder/create",
        color: "#FFA344",
    },
];

export function Header() {
    const [spotlight, setSpotlight] = useState(false);
    const accountEl = useRef(null);
    const [accountPanel, setAccountPanel] = useState(false);
    const reportEl = useRef(null);
    const [reportPanel, setReportPanel] = useState(false);
    const { workspace } = useGlobal();
    const {
        icon: workspaceIcon,
        name: workspaceName,
        plan: workspacePlan,
    } = workspace || {};
    let workspaceInitial = "";
    if (workspaceName != null) {
        workspaceInitial = workspaceName[0].toUpperCase();
    }

    return (
        <>
            <Spotlight
                onOpen={() => setSpotlight(true)}
                isOpen={spotlight}
                onClose={() => setSpotlight(false)}
            />
            <AccountDropdown
                isOpen={accountPanel}
                onClose={() => setAccountPanel(false)}
                anchorEl={accountEl}
            />
            <ReportDropdown
                isOpen={reportPanel}
                onClose={() => setReportPanel(false)}
                anchorEl={reportEl}
            />
            <div className={styles.header}>
                <div
                    className={styles.section}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    {/* left */}
                    <Link to={"/"}>
                        <img src={iconWhite} className={styles.icon} />
                    </Link>
                    <div className={styles.navWrap}>
                        <Link to={"/"} className={styles.headerLink}>
                            Home
                        </Link>
                        <button
                            className={`${styles.headerLink} ${styles.headerLinkFlex
                                } ${reportPanel && styles.active}`}
                            ref={reportEl}
                            onClick={() => setReportPanel(true)}
                        >
                            <div>Reports</div>
                            <div className={styles.headerLinkIconWrap}>
                                <svg
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 8"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                                    />
                                </svg>
                            </div>
                        </button>
                        <Link to={"/events"} className={styles.headerLink}>
                            Events
                        </Link>
                        <Link to={"/users"} className={styles.headerLink}>
                            Users
                        </Link>
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.searchWrap}>
                        <button
                            type={"text"}
                            className={styles.searchInputButton}
                            onClick={() => {
                                setSpotlight(true);
                            }}
                        >
                            Search...
                        </button>
                        <div className={styles.searchIconWrap}>
                            <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                            </svg>
                        </div>
                        <div className={styles.commandWrap}>
                            <div className={styles.commandIconWrap}>⌘A</div>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.section}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: 16,
                        marginLeft: 32
                    }}
                >
                    {/* <TrialButton /> */}
                    {/* app button here */}
                    {/* <Link to={"/discovery"} className={styles.headerLink}>
                        <div className={styles.headerLinkSingleIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" fill="currentColor">
                                <path d="M347.94 129.86L203.6 195.83a31.938 31.938 0 0 0-15.77 15.77l-65.97 144.34c-7.61 16.65 9.54 33.81 26.2 26.2l144.34-65.97a31.938 31.938 0 0 0 15.77-15.77l65.97-144.34c7.61-16.66-9.54-33.81-26.2-26.2zm-77.36 148.72c-12.47 12.47-32.69 12.47-45.16 0-12.47-12.47-12.47-32.69 0-45.16 12.47-12.47 32.69-12.47 45.16 0 12.47 12.47 12.47 32.69 0 45.16zM248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 448c-110.28 0-200-89.72-200-200S137.72 56 248 56s200 89.72 200 200-89.72 200-200 200z" />
                            </svg>
                        </div>
                        <div>Discover</div>
                    </Link> */}
                    {/* <Link to={"/campaigns"} className={styles.headerLink}>
                        <div className={styles.headerLinkSingleIcon} style={{ width: 15, height: 15 }}>
                            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
                                <path d="M15 1.943v12.114a1 1 0 0 1-1.581.814L8 11V5l5.419-3.871A1 1 0 0 1 15 1.943ZM7 4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v5a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V4ZM4 17v-5h1v5H4ZM16 5.183v5.634a2.984 2.984 0 0 0 0-5.634Z" />
                            </svg>
                        </div>
                        <div>Campaigns</div>
                    </Link> */}
                    <Link to={"/contacts"} className={styles.headerLink}>
                        <div
                            className={styles.headerLinkSingleIcon}
                            style={{ width: 15, height: 15 }}
                        >
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 18">
                                <path d="M7 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm2 1H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
                            </svg>
                        </div>
                        <div>Contacts</div>
                    </Link>
                    <Link to={"/sources"}
                        className={styles.headerLink}
                    >
                        <div className={styles.headerLinkSingleIcon} style={{ width: 14, height: 14 }} >
                            <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 18 20"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.8"
                                    d="M17 4c0 1.657-3.582 3-8 3S1 5.657 1 4m16 0c0-1.657-3.582-3-8-3S1 2.343 1 4m16 0v6M1 4v6m0 0c0 1.657 3.582 3 8 3s8-1.343 8-3M1 10v6c0 1.657 3.582 3 8 3s8-1.343 8-3v-6"
                                />
                            </svg>
                        </div>
                        <div>Sources</div>
                    </Link>
                    <button
                        className={styles.workspaceWrap}
                        onClick={() => setAccountPanel(true)}
                        ref={accountEl}
                    >
                        <div className={styles.workspaceIconWrap}>
                            {workspaceIcon == null ? (
                                <div className={styles.workspaceIconText}>
                                    {workspaceInitial}
                                </div>
                            ) : (
                                <div className={styles.workspaceIconImage}>
                                    <img src={workspaceIcon} />
                                </div>
                            )}
                        </div>
                        <div className={styles.workspaceTextWrap}>
                            <div className={styles.workspaceTitle}>
                                {workspaceName}
                            </div>
                            <div className={styles.workspaceSubtitle}>
                                {workspacePlan} plan
                            </div>
                        </div>
                    </button>
                </div >
            </div >
        </>
    );
}

function ReportDropdown({ isOpen, onClose, anchorEl }) {
    return (
        <EmptyDropdown
            isOpen={isOpen}
            onClose={onClose}
            anchorEl={anchorEl}
            align="center"
        >
            <div className={styles.reportDropdown}>
                {createItems.map((item, idx) => {
                    return (
                        <Link
                            key={idx}
                            to={item.href}
                            className={styles.reportDropdownItem}
                            onClick={onClose}
                            style={{ color: item.color }}
                        >
                            <div className={styles.reportDropdownItemIcon}>
                                {item.icon}
                            </div>
                            <div className={styles.reportDropdownItemText}>
                                <div className={styles.reportDropdownItemTitle}>
                                    {item.title}
                                </div>
                                <div
                                    className={
                                        styles.reportDropdownItemDescription
                                    }
                                >
                                    {item.description}
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </EmptyDropdown>
    );
}

function AccountDropdown({ isOpen, onClose, anchorEl }) {
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const { workspace } = useGlobal();
    // const {logout} = useLogout();
    const { plan: workspacePlan } = workspace || {};
    const limits = useLimits();
    const { wallets: walletLimit } = limits || {};

    function logoutClicked() {
        const p = new Promise(async (resolve, reject) => {
            setIsLoggingOut(true);
            await logout();
            resolve();
            setIsLoggingOut(false);
        });

        toast.promise(p, {
            loading: <>Loading...</>,
            success: <>Logged out sucessfully</>,
            error: <>Error logging out</>,
        });
    }

    if (workspace?.usage == null) return;

    let percent = Math.round((workspace.usage.addresses / walletLimit) * 100);
    if (percent > 100) percent = 100;

    const formattedLimit = numeral(walletLimit).format("0.[00]a");
    const formattedLimitTwo = numeral(walletLimit).format("0,0");
    const formattedUsage = numeral(workspace.usage.addresses).format("0,0");

    return (
        <EmptyDropdown
            isOpen={isOpen}
            onClose={onClose}
            anchorEl={anchorEl}
            align="right"
        >
            <div className={styles.settingsDropdown}>
                <div
                    className={styles.settingsDropdownSection}
                    style={{ padding: "8px 0 8px 0" }}
                >
                    <Link
                        to={"/settings"}
                        className={styles.settingsDropdownLink}
                        onClick={onClose}
                    >
                        <div className={styles.settingsDropdownLinkIcon}>
                            <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path d="M18 7.5h-.423l-.452-1.09.3-.3a1.5 1.5 0 0 0 0-2.121L16.01 2.575a1.5 1.5 0 0 0-2.121 0l-.3.3-1.089-.452V2A1.5 1.5 0 0 0 11 .5H9A1.5 1.5 0 0 0 7.5 2v.423l-1.09.452-.3-.3a1.5 1.5 0 0 0-2.121 0L2.576 3.99a1.5 1.5 0 0 0 0 2.121l.3.3L2.423 7.5H2A1.5 1.5 0 0 0 .5 9v2A1.5 1.5 0 0 0 2 12.5h.423l.452 1.09-.3.3a1.5 1.5 0 0 0 0 2.121l1.415 1.413a1.5 1.5 0 0 0 2.121 0l.3-.3 1.09.452V18A1.5 1.5 0 0 0 9 19.5h2a1.5 1.5 0 0 0 1.5-1.5v-.423l1.09-.452.3.3a1.5 1.5 0 0 0 2.121 0l1.415-1.414a1.5 1.5 0 0 0 0-2.121l-.3-.3.452-1.09H18a1.5 1.5 0 0 0 1.5-1.5V9A1.5 1.5 0 0 0 18 7.5Zm-8 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z" />
                            </svg>
                        </div>
                        <div className={styles.settingsDropdownLinkText}>
                            Settings
                        </div>
                    </Link>
                </div>
                <div
                    className={styles.settingsDropdownSection}
                    style={{ padding: 0, paddingBottom: 8 }}
                >
                    <div
                        className={`${styles.settingsDropdownSectionTitle} ${styles.padded}`}
                    >
                        Support
                    </div>
                    <a
                        href="https://docs.multibase.co"
                        target="_blank"
                        className={styles.settingsDropdownLink}
                    >
                        <div className={styles.settingsDropdownLinkIcon}>
                            <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 16 20"
                            >
                                <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2Zm-3 15H4.828a1 1 0 0 1 0-2h6.238a1 1 0 0 1 0 2Zm0-4H4.828a1 1 0 0 1 0-2h6.238a1 1 0 1 1 0 2Z" />
                                <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                            </svg>
                        </div>
                        <div className={styles.settingsDropdownLinkText}>
                            Documentation
                        </div>
                    </a>
                    <a
                        href="https://multibase.co?support=true"
                        target="_blank"
                        className={styles.settingsDropdownLink}
                    >
                        <div className={styles.settingsDropdownLinkIcon}>
                            <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z" />
                            </svg>
                        </div>
                        <div className={styles.settingsDropdownLinkText}>
                            Live Support
                        </div>
                    </a>
                </div>
                <div className={styles.settingsDropdownSection}>
                    <div className={styles.settingsDropdownPlanWrap}>
                        <div className={styles.settingsDropdownPlan}>
                            {workspacePlan} Plan
                        </div>
                        <div className={styles.settingsDropdownPlanText}>
                            {formattedLimitTwo} users
                        </div>
                    </div>
                </div>
                <div className={styles.settingsDropdownSection}>
                    <div className={styles.progressTitleWrap}>
                        <div className={styles.progressTitle}>Users</div>
                        <div className={styles.progressText}>
                            {formattedUsage}/{formattedLimit}
                        </div>
                    </div>
                    <div className={styles.progressOuter}>
                        <div
                            className={styles.progress}
                            style={{ width: `${percent}%` }}
                        />
                    </div>
                </div>
                <div style={{ padding: 8 }}>
                    <button
                        onClick={logoutClicked}
                        className={`${styles.logoutBtn} ${isLoggingOut && styles.disabled
                            }`}
                        disabled={isLoggingOut}
                    >
                        <div className={styles.logoutBtnIcon}>
                            {isLoggingOut && (
                                <CircularProgress
                                    size={12}
                                    sx={{ color: "white" }}
                                />
                            )}
                            {!isLoggingOut && <PowerSettingsNewRounded />}
                        </div>
                        <div className={styles.logoutBtnText}>
                            {isLoggingOut ? "Logging out..." : "Logout"}
                        </div>
                    </button>
                </div>
            </div>
        </EmptyDropdown>
    );
}