import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./Funnel.module.css"
import { InputButton } from "components/InputButton/InputButton";
import { ClearOutlined, ContentCopyRounded, DeleteOutlineRounded, ElectricBoltRounded, ExpandMoreOutlined, FileDownloadRounded, FilterAltRounded, GroupOutlined, ImportExportOutlined, LayersRounded, OpenInNewRounded, Person, PersonOutline } from "@mui/icons-material";
import { compareObjectsRegardlessOfKeyOrder, convertToNumberingScheme, emptyCheck, formatSecondsDelta, getExplorerLink, getMinFromInterval, isObjectEmpty, leftTrim, smartTrim } from "helpers";
import { Dropdown } from "components/Dropdown/Dropdown";
import { DropdownFreeform } from "components/DropdownFreeform/DropdownFreeform";
import { createElement, useEffect, useRef, useState } from "react";
import { postEndpoint, useGetEndpoint, usePostEndpoint, useQueryFunctions } from "ApiConnector";
import { Content } from "components/Content/Content";
import { CardV2 } from "components/CardV2/CardV2";
import { Menu } from "components/Menu/Menu";
import { Button } from "components/Button/Button";
import { CustomLoadingButton } from "components/LoadingButton/LoadingButton";
import { ALL_CODE, EVENT_CONDITION_OPTIONS_V2, QUERY_TYPES } from "Constants";
import { TopBar } from "components/TopBar/TopBar";
import moment from "moment";
import { IntegratedDateSelector } from "components/IntegratedDateFormatter/IntegratedDateSelector";
import { CircularProgress, Popover, Tooltip } from "@mui/material";
import { UilFilterSlash } from "@iconscout/react-unicons"
import { FunnelChart } from "components/Chart/Chart";
import { FunnelTable } from "components/Table/Table";
import { Filters } from "components/Filters/Filters";
import { SelectV2 } from "components/SelectV2/SelectV2";
import { InputV2 } from "components/InputV2/inputV2";
import { ReactComponent as Empty } from 'assets/empty/funnel.svg';
import PageLoading from "components/PageLoading/PageLoading";
import { deleteQuery, getQuery, saveQuery } from "helpers/query";
import { DeleteModal } from "components/DeleteModal/DeleteModal";
import { ClassicSaveModal } from "components/ClassicSave/ClassicSaveModal";
import { useGlobal } from "hooks/global";
import { toast } from "react-hot-toast";
import { getRequestFilters, getStoredFilters } from "utils/filter";
import numeral from "numeral";
import { RightSidebar } from "components/RightSidebar/RightSidebar";
import Blockies from 'react-blockies';
import { exportCSV } from "utils/export";
import { DateSelector } from "components/DateSelector/DateSelector";
import { FilterDate } from "models/date";

const DEFAULT_MINIMUM = moment().subtract(1, "year").toISOString()
const DEFAULT_MAXIMUM = moment().toISOString()

export function Funnel() {
    const { action, query } = useParams();
    const { invalidate } = useQueryFunctions()
    const [queryData, setQueryData] = useState(undefined)
    const [loadingSave, setLoadingSave] = useState(action === "edit")
    const [originalRequestOptions, setOriginalRequestOptions] = useState(undefined)
    const [saving, setSaving] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [saveModal, setSaveModal] = useState(false)

    const [events, setEvents] = useState([])
    const [filters, setFilters] = useState([])
    const [window, setWindow] = useState({ value: 30, unit: "day" })
    const [date, setDate] = useState(new FilterDate({ mode: "since", min: DEFAULT_MINIMUM, max: DEFAULT_MAXIMUM }))

    const navigate = useNavigate()

    useEffect(() => {
        if (action !== "edit") return
        if (events.length !== 0 || filters.length !== 0) return
        async function f() {
            setLoadingSave(true)
            const queryData = await getQuery(query)
            const queryInnerData = queryData.data
            const { events: e, filters: f, date: d, window: w } = queryInnerData

            const date = new FilterDate(d)

            setEvents(e)
            setFilters(f)
            setDate(date)
            setWindow(w)

            const oro = getRequestOptions(e, f, w, date)
            setQueryData(queryData)
            setOriginalRequestOptions(oro)
            setLoadingSave(false)
        }
        f()
    }, [action, query])

    async function save({ name, description }) {
        if (name === "") return
        const store = {
            events: getStoredEvents(events),
            filters: getStoredFilters(filters),
            window,
            date
        }

        if (action === "create") {
            setSaveModal(false)
            setSaving(true)
            const id = await saveQuery({
                name,
                description,
                type: "funnel",
                created: new Date(),
                updated: new Date(),
                data: store,
            })
            invalidate("general/home")
            navigate(`/query/funnel/edit/${id}`)
            setSaving(false)
            setQueryData({ id, name, description, data: store })
        }
        if (action === "edit") {
            const { id } = queryData
            if (id == null) return
            setSaveModal(false)
            setSaving(true)
            await saveQuery({
                id,
                updated: new Date(),
                name,
                description,
                data: store,
            })
            invalidate("general/home")
            setSaving(false)
            setQueryData({ ...queryData, name, description, data: store })
        }
        const oro = getRequestOptions(events, filters, window, date)
        setOriginalRequestOptions(oro)
    }

    async function deleteChart() {
        const promise = new Promise(async (resolve, reject) => {
            try {
                const { id } = queryData
                await deleteQuery(id)
                invalidate("general/home")
                navigate("/")
                resolve()
            } catch (e) {
                reject()
            }
        })

        toast.promise(promise, {
            loading: "Deleting chart...",
            error: "There was an error deleting this chart",
            success: "Successfully deleted chart"
        })

    }

    const requestOptions = getRequestOptions(events, filters, window, date)
    const isValid = isRequestValid(events)
    const { data, error, refetch, isFetching } = usePostEndpoint({ endpoint: "query/funnel", body: requestOptions, overrideQueryKeyObj: requestOptions, minKey: "minimum", maxKey: "maximum", skip: !isValid || requestOptions == null })

    const dirty = action === "create" ? true : !compareObjectsRegardlessOfKeyOrder(originalRequestOptions, requestOptions)

    if (action === "edit" && loadingSave) {
        return <PageLoading />
    }

    const { name, description } = queryData || {}

    return <>
        <ClassicSaveModal
            title={action === "create" ? "Save Chart" : "Edit Chart"}
            isOpen={saveModal}
            onClose={() => setSaveModal(false)}
            onSave={save}
            existing={{ name, description }}
        />
        <DeleteModal
            isOpen={deleteModal}
            onClose={() => setDeleteModal(false)}
            onDelete={deleteChart}
        />
        <Content flex>
            <TopBarWrap
                type={"funnel"}
                action={action}
                dirty={dirty}
                saving={saving}
                onSaveClicked={() => {
                    if (action === "create") {
                        setSaveModal(true)
                        return
                    }
                    save({ name, description })
                }}
                onDeleteClicked={() => setDeleteModal(true)}
                onEditClicked={() => setSaveModal(true)}
            />
            <CardV2>
                <div className={styles.queryContainer}>
                    <Events
                        events={events}
                        setEvents={setEvents}
                        filters={filters}
                    />
                    <div className={styles.section}>
                        <div className={styles.title}>Filters</div>
                        <Filters
                            filters={filters}
                            setFilters={setFilters}
                            excludeResources={["user"]}
                            // excludeTemplates={["wallet_address", "contract_address", "transaction_role", "chain"]}
                            templates={EVENT_CONDITION_OPTIONS_V2.filter((x) => ["contract_address", "chain"].includes(x.id))}
                        />
                    </div>
                </div>
            </CardV2>
            <ResultsWrapper
                data={data}
                isValid={isValid}
                error={error}
                events={events}
                filters={filters}
                window={window}
                setWindow={setWindow}
                date={date}
                setDate={setDate}
                refetch={refetch}
            />
        </Content>
    </>
}

const eventSections = [
    { id: ALL_CODE, name: "All Events", inline: "all events" },
    { id: "custom", name: "Custom Events", inline: "custom events" },
    { id: "template", name: "On-chain Events", inline: "on-chain events" },
]

function Events({ events, setEvents, filters }) {

    const contractFilters = filters.filter((x) => x.filterKey === "contract_address" && x.filterValue != null).flatMap((x) => x.filterValue.map((x) => x.id))
    const [activeEvent, setActiveEvent] = useState({ isActive: false, el: null, index: null })

    const { data: eventData } = useGetEndpoint("general/singleResource", { resource: "events" })
    const allEvents = [...eventData?.map(e => ({
        name: e.event_text,
        id: e.event,
        type: "offchain",
    })) ?? []]

    let methodResourceParams = { }
    if (contractFilters.length > 0) {
        methodResourceParams = { contracts: contractFilters }
    }
    const { data: methodData } = usePostEndpoint({endpoint: "methods", body: methodResourceParams})
    const allMethods = [...methodData?.map(e => ({
        name: e.name,
        id: e.id,
        type: "onchain",
    })) ?? []]

    function onEventChanged({ index, update }) {
        if (events.length === 0) {
            setEvents([{ ...update }, {}])
            return
        }
        let newEvents = [...events]
        newEvents[index] = { ...newEvents[index], ...update }
        // if newEvents doesnt have a blank event at the end, add one
        if (newEvents[newEvents.length - 1].id != null) newEvents = [...newEvents, {}]
        setEvents(newEvents)
    }

    function onEventDelete(index) {
        const newEvents = events.filter((_, idx) => idx !== index)
        setEvents(newEvents)
    }

    function addBlankEvent() {
        if (events.length === 0) {
            setEvents([{}, {}])
            return
        }
        setEvents([...events, {}])
    }

    function duplicateEvent(index) {
        if (events.length === 0) {

        }
        const newEvents = [...events, { ...events[index] }]
        if (newEvents.length === 1) {

        }
        setEvents(newEvents)
    }


    const eventOptions = allEvents?.map((e) => {
        const { id, name, type } = e
        const idOption = { type: "offchian", id }
        return { id: idOption, name, icon: LayersRounded, section: type }
    }) ?? []

    const methodOptions = allMethods?.map((e) => {
        const { id, name, type } = e
        const idOption = { type: "onchain", id }
        return { id: idOption, name, icon: ElectricBoltRounded, section: type }
    }) ?? []

    const allOptions = [...eventOptions, ...methodOptions]

    return <>
        <DropdownFreeform
            anchorEl={activeEvent.el}
            isLoadingAsyncOptions={eventData == null || methodData == null}
            isOpen={activeEvent.isActive}
            onClose={() => setActiveEvent({ isActive: false, index: null, value: null, el: null })}
            options={allOptions}
            sections={eventSections}
            onChange={(val) => {
                const { section } = val
                if (section === "offchain") {
                    // const fullObj = allEvents.find((e) => e.id === id)
                    onEventChanged({
                        index: activeEvent.index, update: val
                    })
                }
                if (section === "freeform") {
                    onEventChanged({
                        index: activeEvent.index, update: val
                    })

                }
                if (section === "onchain") {
                    // const fullObj = allMethods.find((e) => e.id === id)
                    onEventChanged({
                        index: activeEvent.index, update: val
                    })
                }
            }}
        />
        <div className={styles.section}>
            <div className={styles.sectionInner}>
                {events.map((event, idx) => <Event
                    key={idx}
                    index={idx}
                    event={event}
                    eventOptions={allEvents}
                    methodOptions={allMethods}
                    activeEvent={activeEvent}
                    setActiveEvent={setActiveEvent}
                    onEventDelete={() => onEventDelete(idx)}
                    duplicateEvent={() => duplicateEvent(idx)}
                    deletable={events.length > 1 || (events.length === 1 && event.id != null && event.id !== "")}

                />)}
                {events.length === 0 && <Event
                    index={0}
                    eventOptions={allEvents}
                    methodOptions={allMethods}
                    activeEvent={activeEvent}
                    setActiveEvent={setActiveEvent}
                    onEventDelete={() => onEventDelete(0)}
                    duplicateEvent={() => duplicateEvent(0)}
                    deletable={false}
                />}
                <button className={styles.add} onClick={addBlankEvent}>
                    <div className={styles.plus}>+</div>
                    <div className={styles.text}>Add event</div>
                </button>
            </div>
        </div>
    </>
}

function Event({
    index,
    event,
    activeEvent,
    setActiveEvent,
    deletable,
    duplicateEvent,
    onEventDelete,
    eventOptions,
    methodOptions,
}) {
    const eventEl = useRef(null)
    const eventEmpty = event == null || event?.id == null || event?.id === ""
    const eventText = eventEmpty ? "Add an event..." : emptyCheck(event?.name)

    return <div className={styles.event}>
        <div className={styles.letter}>
            {index + 1}
        </div>
        <InputButton
            pref={eventEl}
            onOpen={() => setActiveEvent({ isActive: true, index, el: eventEl, value: event?.id })}
            isOpen={activeEvent.isActive && index === activeEvent.index}
            empty={eventEmpty}
            buttonText={eventText}
            fill
        />
        <div className={styles.actions}>
            {event != null && <button onClick={duplicateEvent} style={{ width: 24, height: 24 }}><ContentCopyRounded /></button>}
            {deletable && <button onClick={onEventDelete} style={{ width: 26, height: 26 }}><DeleteOutlineRounded /></button>}
        </div>
    </div>
}

// function TopBarWrap({ type, action, dirty, saving, onSaveClicked, onDeleteClicked, onEditClicked }) {

//     const [optionsOpen, setOptionsOpen] = useState(false)
//     const openOptions = () => setOptionsOpen(true)
//     const closeOptions = () => setOptionsOpen(false)
//     const optionsEl = useRef(null)
//     const navigate = useNavigate()

//     return <TopBar>
//         <div>
//             <div className={styles.queryTypeSelectWrap}>
//                 <select value={type} onChange={(e) => navigate(`/query/${e.target.value}/create`)} className={styles.queryTypeSelect}>
//                     {QUERY_TYPES.filter((x) => !x.deprecated).map((x, i) => {
//                         return <option key={i} value={x.id}>{x.name}</option>
//                     })}
//                 </select>
//                 <div className={styles.pageSelectIcon}><ExpandMoreOutlined /></div>
//             </div>
//         </div>
//         <div>
            // {action === "edit" && <>
            //     <Button buttonRef={optionsEl} onClick={openOptions} className={styles.outlineBtn} colorVariant="outline">Options</Button>
            //     <Menu
            //         open={optionsOpen}
            //         onClose={closeOptions}
            //         anchorEl={optionsEl?.current}
            //         // text={"Options"}
            //         options={[
            //             { onClick: () => onEditClicked(), text: "Edit" },
            //             { onClick: () => onDeleteClicked(), text: "Delete" }
            //         ]}
            //     />
            // </>}
            // <CustomLoadingButton disabled={!dirty} loading={saving} className={styles.addBtn} onClick={onSaveClicked}>Save</CustomLoadingButton>
//         </div>
//     </TopBar>
// }

function TopBarWrap({ type, action, dirty, saving, onSaveClicked, onDeleteClicked, onEditClicked }) {

    const [optionsOpen, setOptionsOpen] = useState(false)
    const openOptions = () => setOptionsOpen(true)
    const closeOptions = () => setOptionsOpen(false)
    const optionsEl = useRef(null)

    return <>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            {action === "edit" && <>
                <Button buttonRef={optionsEl} onClick={openOptions} className={styles.outlineBtn} colorVariant="outline">Options</Button>
                <Menu
                    open={optionsOpen}
                    onClose={closeOptions}
                    anchorEl={optionsEl?.current}
                    options={[
                        { onClick: () => onEditClicked(), text: "Edit" },
                        { onClick: () => onDeleteClicked(), text: "Delete" }
                    ]}
                />
            </>}
            <CustomLoadingButton disabled={!dirty} loading={saving} className={styles.addBtn} onClick={onSaveClicked}>
                Save
            </CustomLoadingButton>
        </div>
    </>
}

function ResultsWrapper({ data, error, refetch, events, filters, isValid: isValidOverride, date, setDate, window, setWindow, methodOptions }) {

    const [menuOpen, setMenuOpen] = useState(undefined)
    const [sidebarData, setSidebarData] = useState({ isOpen: false, data: undefined })

    function openMenu({ index, type, element }) {
        setMenuOpen({ index, type, element })
    }

    let top = <div className={styles.top}>
        <div />
        <div className={styles.right}>
            <ConversionWindow
                window={window}
                setWindow={setWindow}
            />
            <DateSelector 
                date={date}
                onChange={(val) => setDate(val)}
            />
            {/* <IntegratedDateSelector
                mode={dateMode}
                rawDate={date}
                date={dateValue}
                onChange={onDateChangeFreeform}
                classes={{ wrap: styles.integrated }}
            /> */}
        </div>
    </div>

    const isValid = isValidOverride

    const emptyCheck = data?.series?.every((x) => x.data.every((y) => y === 0))
    const noResults = data != null && emptyCheck
    const loading = data === undefined && isValid && !error
    const noQuery = data === undefined && !isValid && !error

    if (loading) {
        return <CardV2 noPadding>
            {top}
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <CircularProgress sx={{ color: "var(--alpha-text)" }} />
                </div>
            </div>
        </CardV2>
    }

    if (noQuery) {
        return <CardV2 noPadding>
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <div className={styles.emptyIcon}>
                        <Empty />
                    </div>
                    <div className={styles.title}>Select a metric to get started</div>
                    <div className={styles.description}>In order to see results, you must select at least one metric from the left panel</div>
                </div>
            </div>
        </CardV2>
    }

    if (noResults) {
        return <CardV2 noPadding>
            {top}
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <div className={styles.notFoundIcon}>
                        <UilFilterSlash />
                    </div>
                    <div className={styles.title}>No events found</div>
                    <div className={styles.description}>Please change the parameters to see results</div>
                </div>
            </div>
        </CardV2>
    }

    if (error) {
        return <CardV2 noPadding>
            {top}
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <div className={styles.title}>Error</div>
                    <div className={styles.description}>There was an error loading this query. Please try again or email <a className={styles.supportEmail} href="mailto: support@multibase.co">support@multibase.co</a></div>
                    <Button onClick={refetch} className={styles.errorBtn}>Reload</Button>
                </div>
            </div>
        </CardV2>
    }

    return <>
        <CardV2 noPadding>
            {top}
            <div className={styles.resultsWrap}>
                <UserMenu
                    isOpen={menuOpen != null}
                    onClose={() => setMenuOpen(undefined)}
                    onOpenUser={() => {
                        setSidebarData({ isOpen: true, data: menuOpen })
                        setMenuOpen(undefined)
                    }}
                    index={menuOpen?.index}
                    element={menuOpen?.element}
                    type={menuOpen?.type}
                    events={events}
                    data={data}
                />
                <RightSidebar
                    title={"View users"}
                    isOpen={sidebarData.isOpen}
                    onClose={() => setSidebarData({ isOpen: false, data: undefined })}
                >
                    <UserSidebar
                        results={data}
                        data={sidebarData.data}
                        events={events}
                        filters={filters}
                        window={window}
                        date={date}
                    />
                </RightSidebar>
                <FunnelChart
                    categories={events}
                    data={data}
                    // onBarClick={openMenu}
                    constants={{
                        formatOptions: {
                            numberFormat: "integer"
                        },
                        getConversionTimeFmt: (index) => {
                            const { table } = data || {}
                            const { rows } = table || {}
                            if (rows == null) { return "" }
                            const key = `conversion_${index}`
                            const seconds = rows[key]
                            return formatSecondsDelta(seconds, "m")
                        },
                        formatGroup: (event) => {
                            return leftTrim(event.name, 20)
                        }
                    }}
                />
            </div>
        </CardV2>
        <CardV2 noPadding>
            <div className={styles.resultsWrap}>
                <FunnelTable
                    data={data}
                    events={events}
                    constants={{
                        formatOptions: {
                            numberFormat: "integer"
                        },
                        formatGroup: (event) => {
                            return leftTrim(event.name, 20)
                        }
                    }}
                />
            </div>
        </CardV2>
    </>
}

function UserMenu({ isOpen, onClose, index, element, type, events, data, onOpenUser }) {
    if (!isOpen) return null
    if (index == null) return null
    if (element == null) return null

    const eventObj = events[index]
    const value = type === "full" ? data?.series[1].data[index] : data?.series[0].data[index]
    const total = data?.series[0].data[index] + data?.series[1].data[index]

    const conversionTxt = type === "full" ? "conversion" : "drop-off"

    return <Popover
        anchorEl={element}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
        }}
        transitionDuration={0}
    >
        <div className={styles.userMenu}>
            <button className={styles.userMenuClose} onClick={onClose}><ClearOutlined /></button>
            <div className={styles.userMenuTitleWrap}>
                <div className={styles.userMenuIcon}>{createElement(eventObj.icon)}</div>
                <div className={styles.userMenuTitle}>{eventObj.name}</div>
            </div>
            <div className={styles.userMenuValueWrap}>
                <div className={styles.userIcon}>
                    <FilterAltRounded />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className={`${styles.userMenuValue}`}>{numeral(value / total).format("0.0%")} {conversionTxt}</div>
                    <div className={`${styles.userMenuValue} ${styles.gray}`}>{numeral(value).format("0,0")} unique users</div>
                </div>
            </div>
            <div className={styles.userMenuActionWrap}>
                <button className={styles.userMenuAction} onClick={onOpenUser}>View users</button>
            </div>

        </div>
    </Popover>
}

function UserSidebar({ results, data, events, filters, window, date }) {

    const [saveModal, setSaveModal] = useState(false)
    const [exporting, setExporting] = useState(false)
    const { index, type } = data || {}

    const requestOptions = getRequestOptions(events, filters, window, date)
    const isValid = isRequestValid(events)
    const { data: funnelUserData } = usePostEndpoint({ endpoint: "query/funnel/users", body: { ...requestOptions, target: { index, type } }, skip: !isValid || data == null })

    function exportCSVClicked() {
        if (exporting) return

        const prom = new Promise(async (resolve, reject) => {
            setExporting(true)

            const { data: fullUsers } = await postEndpoint("query/funnel/users", { ...requestOptions, target: { index, type }, csv: true })

            const headers = ["User"]
            const csvData = [headers, ...fullUsers.map((x) => [x])]
            exportCSV(csvData, "Funnel Users")
            setExporting(false)
            resolve()
        })

        toast.promise(prom, {
            loading: "Exporting CSV...",
            error: "There was an error exporting this CSV",
            success: "Successfully exported CSV"
        })
    }

    async function save(data) {
        const { name, description } = data

        const prom = new Promise(async (resolve, reject) => {
            await postEndpoint("query/funnel/users", { ...requestOptions, target: { index, type }, label: { name, description } })
            setSaveModal(false)
            resolve()
        })

        toast.promise(prom, {
            loading: "Creating label...",
            error: "There was an error creating this label",
            success: "Successfully created label"
        })

    }

    if (index == null) return null

    const conversionTxt = type === "full" ? "conversion" : "drop-off"
    const value = type === "full" ? results?.series[1].data[index] : results?.series[0].data[index]
    const total = results?.series[0].data[index] + results?.series[1].data[index]
    const eventObj = events[index]
    // const dataObj = results?.series[0]?.data[index]
    // const total = results?.series[0]?.data[index] + results?.series[1]?.data[index]

    if (funnelUserData == null) {
        return <div className={styles.userSidebar}>
            <div className={styles.sidebarLoading}>
                <CircularProgress sx={{ color: "var(--alpha-text)" }} />
            </div>
        </div>
    }

    return <div className={styles.userSidebar}>
        <ClassicSaveModal
            title={"Create Label"}
            isOpen={saveModal}
            onClose={() => setSaveModal(false)}
            onSave={save}
        />
        <div className={styles.userTop}>
            <div className={styles.userTitle}>
                <div className={styles.stepsIcon} />
                <div className={styles.userText}>{eventObj.name}</div>
            </div>
            <div className={styles.userValsWrap}>
                <div className={styles.userSub}>{numeral(value / total).format("0.0%")} {conversionTxt}</div>
                <div className={styles.userSub}>{numeral(value).format("0,0")} unique users</div>
            </div>
            <div className={styles.userButtons}>
                <button className={styles.userButton} onClick={() => setSaveModal(true)}>
                    <div className={styles.userIcon}>
                        <Person />
                    </div>
                    Create label
                </button>
                <button className={styles.userButton} onClick={exportCSVClicked}>
                    <div className={styles.userIcon}>
                        <FileDownloadRounded />
                    </div>
                    Export CSV
                </button>
            </div>
        </div>
        <div className={styles.generalSidebar}>
            {funnelUserData == null && <div className={styles.sidebarLoading}>
                <CircularProgress sx={{ color: "var(--alpha-text)" }} />
            </div>}
            {funnelUserData != null && <UserList
                users={funnelUserData}
            />}
        </div>
    </div>
}

function UserList({ users }) {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(false)
    }, [])

    if (loading) {
        return <div className={styles.sidebarLoading}>
            <CircularProgress sx={{ color: "var(--alpha-text)" }} />
        </div>
    }

    return users?.map((uid, idx) => {
        const display = smartTrim(uid, 20)
        const explorerLink = getExplorerLink("eth", "address", uid)

        if (uid == null) return null

        return <div className={styles.sidebarRow} key={idx}>
            <Link className={`${styles.sidebarRowLink} ${styles.identiconWrap}`} to={`/users/${uid}`} target="_blank" rel="noreferrer" key={idx}>
                <Blockies
                    seed={uid}
                    size={10}
                    scale={10}
                    bgColor={"transparent"}
                    className={styles.identicon}
                />
                <div className={styles.addressesRowText}>
                    <div className={styles.sidebarRowLabel}>{display}</div>
                </div>
            </Link>
            <a className={styles.explorerAbsWrap} href={explorerLink} target="_blank" rel="noreferrer">
                <Tooltip text={"Open in explorer"}>
                    <div className={styles.explorerAbs}>
                        <OpenInNewRounded />
                    </div>
                </Tooltip>
            </a>
        </div>

    })
}

function ConversionWindow({ window, setWindow }) {
    const [isOpen, setIsOpen] = useState(false)
    const onClose = () => setIsOpen(false)
    const onOpen = () => setIsOpen(true)
    const anchorEl = useRef(null)

    function onSave({ value, unit }) {
        setWindow({ value, unit })
        onClose()
    }

    const { value, unit } = window

    const plural = value === 1 ? "" : "s"

    return <>
        <ConversionWindowInner
            isOpen={isOpen}
            onClose={onClose}
            anchorEl={anchorEl}
            onSave={onSave}
            window={window}
        />
        <InputButton
            pref={anchorEl}
            onOpen={onOpen}
            isOpen={isOpen}
            empty={false}
            buttonText={`Within ${value} ${unit}${plural}`}
        />

    </>
}

function ConversionWindowInner({ window, isOpen, onClose, anchorEl, onSave }) {
    const [tempValue, setTempValue] = useState(window)

    useEffect(() => {
        if (window == null) return
        setTempValue(window)
    }, [window])

    useEffect(() => {
        if (!isOpen) return
        setTempValue(window)
    }, [isOpen])

    function onValChange(val) {
    }

    function onSaveClicked() {
        onSave(tempValue)
    }

    const { value, unit } = tempValue
    const isDirty = window.value !== value || window.unit !== unit

    return <Popover
        open={isOpen}
        anchorEl={anchorEl?.current}
        keepMounted={true}
        transitionDuration={0}
        onClose={onClose}
        PaperProps={{
            sx: { borderRadius: "8px" }
        }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
    >
        <div className={styles.conversionWindowSelect} style={{ borderRadius: 8 }}>
            <div className={styles.conversionWindowInner}>
                <div className={styles.title}>Conversion window</div>
                <div className={styles.description}>
                    The conversion window is the starting point of each funnel step
                </div>
                <div className={styles.inputWrap}>
                    {/* <input className={styles.numberInput} value={value} onChange={(e) => onValChange(e.target.value)} /> */}
                    <InputV2
                        type={"numeric"}
                        value={value}
                        onChange={(val) => {
                            setTempValue({ ...tempValue, value: val })
                        }}
                        className={styles.conversionNumberInput}
                        saveOnChange
                    />
                    <SelectV2
                        className={styles.conversionUnitSelect}
                        option={tempValue.unit}
                        options={[
                            { id: "minute", name: "minutes" },
                            { id: "hour", name: "hours" },
                            { id: "day", name: "days" },
                            { id: "week", name: "weeks" },
                            { id: "month", name: "months" },
                            { id: "year", name: "years" },
                        ]}
                        onChange={(val) => {
                            setTempValue({ ...tempValue, unit: val })
                        }}
                    />
                </div>
            </div>
            <button className={`${styles.conversionAdd} ${isDirty ? styles.dirty : ""}`} onClick={onSaveClicked} disabled={!isDirty}>
                Save
            </button>
        </div>
    </Popover>
}

function getRequestOptions(events, filters, window, date) {
    const e = events?.filter((x) => x != null && x?.id != null).map((x) => x.id)
    const d = date.toJSON()
    return {
        events: e,
        filters: getRequestFilters(filters),
        window,
        date: d
    }
}

function isRequestValid(events) {
    const e = events?.filter((x) => x != null && x?.id != null).map((x) => x.id)
    return e?.length > 0
}

function getStoredEvents(events) {
    let filtered = []
    for (let i = 0; i < events.length; i++) {
        const { id } = events[i] || {}
        if (isObjectEmpty(events[i])) continue
        if (id == null || id === "") continue
        filtered.push(events[i])
    }

    const filteredEvents = filtered.map((filter) => {
        const { id, name, section } = filter
        return { id, name, section }
    })
    return filteredEvents;

}
