import { useState } from 'react'
import styles from "./Reset.module.css"
import { Link, useNavigate } from 'react-router-dom'
import { Collapse } from '@mui/material';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { auth } from 'firebase-config';
import toast from 'react-hot-toast';
import { sendPasswordResetEmail } from 'firebase/auth';
import NavLimited from 'components/NavLimited/NavLimited';

export default function Reset({ theme }) {

    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors, isValid }, setError, getValues } = useForm({ mode: 'onChange', delayError: 2000 });
    const [loading, setLoading] = useState(false);

    function sendForgot(data) {
        setLoading(true)
        sendPasswordResetEmail(auth, data.email).then(() => {
            navigate("/login")
            toast.success("Recovery email sent")
            setLoading(false)
        })
    }

    return <>
        <NavLimited />
        <div className={styles.pageWrap}>
            <div className={styles.wrapper}>
                <div className={styles.innerWrap}>
                    <div className={styles.pageTitle}>set password</div>
                    <form onSubmit={handleSubmit(sendForgot)} className={styles.columnForm} noValidate>
                        <input type={"email"} placeholder={"Email"} {...register('email', {
                            required: {
                                value: true,
                                message: "Email is required"
                            }, maxLength: {
                                value: 255,
                                message: "Please enter a valid email"
                            }, pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                            }
                        })} className={errors.email ? styles.error : ""} />
                        <Collapse in={errors.email} ><div className={styles.errorTxt} timeout={100}>{errors.email?.message}</div></Collapse>
                        <LoadingButton
                            type={"submit"}
                            className={`${styles.btn} ${(isValid || errors.password?.type === "manual") ? styles.active : styles.inactive}`}
                            disableRipple={true}
                            loading={loading}
                        >
                            Request Reset
                        </LoadingButton>
                    </form>
                    <div className={styles.otherWrap}><Link className={styles.other} to="/login">Back to Login</Link></div>
                </div>
            </div>
        </div>
    </>
}