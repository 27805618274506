import { useEffect, useRef, useState } from "react"
import styles from "./InputV2.module.css"
import { boolClass } from "helpers"

export function InputV2({ type, value, onChange, saveOnChange, className }) {

    const [focused, setFocused] = useState(false)
    const [tempValue, setTempValue] = useState(value?.toString() || "")
    const inputRef = useRef(null)

    useEffect(() => {
        if (focused) {
            inputRef.current.selectionStart = inputRef.current.value.length;
            inputRef.current.selectionEnd = inputRef.current.value.length;
        }
    }, [focused]);

    function blurAndCommitChange() {
        setFocused(false)
        onChange(tempValue)
    }

    function parseValue(value) {
        if (type === "numeric") {
            const valueOnlyNumbers = value.replace(/[^0-9.]/g, "")
            return valueOnlyNumbers
        }
    }

    function onFieldChange(value) {
        const p = parseValue(value)
        setTempValue(p)
        if (saveOnChange) onChange(p)
    }

    function handleKeyPress(event) {
        if (event.keyCode === 13) inputRef.current.blur()
    }

    return <div className={`${styles.inputWrap} ${boolClass(focused, styles.isOpen)} ${className}`}>
        <input
            ref={inputRef}
            className={styles.input}
            type="text"
            onFocus={()=>setFocused(true)}
            onBlur={blurAndCommitChange}
            placeholder="Enter value..."
            value={tempValue}
            onClick={(e) => {
                const input = e.target;
                input.selectionStart = input.selectionEnd = input.value.length;
            }}
            onChange={(e) => onFieldChange(e.target.value)}
            onKeyDown={handleKeyPress}
        />
    </div>
}