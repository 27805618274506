import { createContext, useContext } from "react"

const GlobalContext = createContext(null);

export function useGlobal() {
    const context = useContext(GlobalContext)
    if (context === undefined) {
        throw new Error('useGlobal must be used within GlobalContext')
    }
    return context
}

export function GlobalContextProvider({children, value}){
    return <GlobalContext.Provider value={value}>
        {children}
    </GlobalContext.Provider>
}