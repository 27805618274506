import { postEndpoint, postEndpointWithErrorListening } from "ApiConnector";
import { auth } from "firebase-config";
import { signOut } from "firebase/auth";

export function useLogout() {

    async function logout() {
        await postEndpoint("user/auth/logout")
        await signOut(auth)
    }

    return { logout }
}

export async function logout() {
    await postEndpoint("user/auth/logout")
    await signOut(auth)
}