import styles from "./Settings.module.css"
import moment from 'moment';
import { UilCopy, UilCheck, UilSetting, UilMultiply, UilUser, UilSignout, UilUsersAlt } from '@iconscout/react-unicons'
import { Card } from "components/Card/Card";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLogout } from "helpers/account";
import { useGlobal } from "hooks/global";
import { Button } from "components/Button/Button";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { CustomLoadingButton } from "components/LoadingButton/LoadingButton";
import { Input } from "components/Input/Input";
import { useForm } from "react-hook-form";
import { GraidentCircle } from "components/GradientCircle/GraidentCircle";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadString } from "firebase/storage";
import { Modal } from "components/Modal/Modal";
import { CircularProgress, Collapse } from "@mui/material";
import { Select } from "components/Select/Select";
import PageLoading from "components/PageLoading/PageLoading";
import { getInitials } from "helpers";
import { CustomIcon } from "components/CustomIcon/CustomIcon";
import { useGetEndpoint, usePostEndpoint } from "ApiConnector";
import { postEndpoint } from "ApiConnector";
import { useLimits } from "hooks/limits";


export default function Settings() {

    const [loggingOut, setLoggingOut] = useState(false);
    const { subpage } = useParams()
    const { logout } = useLogout()

    async function onLogout() {
        setLoggingOut(true)

        // setTimeout(() => {
        //     setLoggingOut(false)
        // }, 30000);
        const p = new Promise(async (resolve, reject) => {
            await logout()
            resolve()
        });


        toast.promise(p, {
            loading: <>Loading...</>,
            success: <>Logged out sucessfully</>,
            error: <>Error logging out</>
        })
        // await logoutWithSession()
    }

    return <div className={styles.wrap}>
        <SettingsSidebar onLogout={onLogout} loggingOut={loggingOut} />
        {subpage === undefined && <General />}
        {subpage === "team" && <Team />}
        {subpage === "profile" && <Account onLogout={onLogout} loggingOut={loggingOut} />}
    </div>
}



function SettingsSidebar({ onLogout, loggingOut }) {

    const { subpage } = useParams()
    const { workspace } = useGlobal()
    const { users } = workspace

    return <div className={styles.sidebar}>

        <div className={styles.sidebarTitle}>Workspace</div>
        <div className={styles.sidebarLinkWrap}>
            <SidebarLink text={"General"} href={"/settings"} selected={subpage === undefined} icon={<UilSetting />} />
            {(users.length !== 0 && workspace.id !== "uniswap")  && <SidebarLink text={"Team"} href={"/settings/team"} selected={subpage === "team"} icon={<UilUsersAlt />} />}
        </div>
        <div className={styles.sidebarTitle}>Account</div>
        <div className={styles.sidebarLinkWrap}>
            <SidebarLink text={"Profile"} href={"/settings/profile"} selected={subpage === "profile"} icon={<UilUser />} />
            <div className={styles.logoutBtnWrap}>
                <button className={styles.logoutBtn} onClick={onLogout} disabled={loggingOut}>
                    <div className={styles.logoutIcon}><UilSignout /></div>
                    <div className={styles.logoutText}>Logout</div>
                </button>
            </div>
        </div>
    </div>
}

function SidebarLink({ icon, selected, text, href }) {

    if (href != null) {
        return <div className={styles.sideLinkWrap}>
            <Link className={`${styles.sideLink} ${selected ? styles.selected : ""}`} to={href}>
                <div className={styles.sideLinkIcon}>
                    {icon}
                </div>
                <div className={styles.sideLinkText}>
                    {text}
                </div>
            </Link>
        </div>
    }

    return <div className={styles.sideLinkWrap}>
        <button className={`${styles.sideLink} ${selected ? styles.selected : ""}`}>
            <div className={styles.sideLinkIcon}>
                {icon}
            </div>
            <div className={styles.sideLinkText}>
                {text}
            </div>
        </button>
    </div>
}

async function updateWorkspace(body){
    await postEndpoint("workspace/update", body);
}

async function updateUser(body){
    await postEndpoint("workspace/user/update", body);
}

function General() {

    const { workspace, setWorkspace } = useGlobal()
    const { icon: workspaceIcon, name: workspaceName, id: workspaceId } = workspace
    const imageInput = useRef(null)
    const [updating, setUpdating] = useState(false)

    const { register, dirty, handleSubmit, formState: { isDirty, errors } } = useForm({ mode: 'onChange', delayError: 2000, defaultValues: { name: workspace.name } });

    async function uploadImage(image) {
        if (image.size > 2097152) {
            toast.error("This file is too large. Please upload a smaller image.")
            return
        };

        const url = URL.createObjectURL(image)
        const sn = await snapshotResize(url, 300, 300)

        const promise = new Promise(async (resolve, reject) => {
            const storage = getStorage();
            const storageRef = ref(storage, `workspaces/icons/${workspace.id}.png`);
            await uploadString(storageRef, sn, 'data_url')
            const downloadURL = await getDownloadURL(storageRef)
            await updateWorkspace({ icon: downloadURL })
            setWorkspace({ ...workspace, icon: downloadURL })
            resolve()
        })

        toast.promise(promise, {
            loading: "Uploading...",
            success: "Successfully uploaded icon",
            error: "There was an error uploading this icon"
        })
    }

    async function sub(data) {
        setUpdating(true)
        const { name } = data || {}
        if (name == null) { return }
        await updateWorkspace({ name })
        setTimeout(() => {
            setUpdating(false)
            setWorkspace({
                ...workspace,
                name: name
            })
        }, 500);
    }

    return <>
        <div className={styles.pageWrap}>
            <div className={styles.innerWrap}>
                <form onSubmit={handleSubmit(sub)} noValidate>

                    <div className={styles.largeTitle}>Workspace</div>
                    <div className={styles.subtitle}>Edit workspace settings</div>
                    <div className={styles.block} style={{ marginTop: "32px" }}>
                        <div>
                            <div className={styles.settingSubtitle}>Workspace icon</div>
                            <div className={styles.settingThirdTitle}>Manage your workspace logo</div>
                        </div>
                        <div>
                            <input
                                type="file"
                                ref={imageInput}
                                accept="image/png, image/gif, image/jpeg"
                                className={styles.hiddenInput}
                                onChange={(event) => {
                                    uploadImage(event.target.files[0])
                                    event.target.value = null;
                                }}
                            />
                            <button type={"button"} className={styles.iconWrap} onClick={() => {
                                imageInput.current.click()
                            }}>
                                {workspaceIcon == null ?
                                    <GraidentCircle text={workspaceName[0].toUpperCase()} size={48} fontSize={20} />
                                    :
                                    <img className={styles.iconImg} src={workspaceIcon} />}
                            </button>
                        </div>
                    </div>
                    <div className={styles.settingLine}></div>
                    <div className={styles.block}>
                        <div>
                            <div className={styles.settingSubtitle}>Workspace name</div>
                            <div className={styles.settingThirdTitle}>Manage your workspace name</div>
                        </div>
                        <div style={{ position: "relative" }}>
                            <Input
                                // value={workspace.name}
                                placeholder={"Workspace name..."}
                                className={`${styles.customInput} ${Boolean(errors.name) ? styles.error : ""}`}
                                registerProps={
                                    {
                                        ...register('name', {
                                            required: {
                                                value: true,
                                                message: "Workspace name is required"
                                            }
                                        })
                                    }
                                }
                            />
                            <div className={`${Boolean(errors.name) ? styles.errorWrap : ""}`}><div className={styles.errorTxt}>{errors.name?.message}</div></div>
                        </div>
                    </div>
                    {/* <ApiKey apiKey={key} /> */}
                    {isDirty && <CustomLoadingButton loading={updating} buttonType={"submit"} className={styles.saveBtn}>Save changes</CustomLoadingButton>}
                </form>

            </div>
        </div >
    </>
}

// function ApiKey({ apiKey }) {

//     const [copied, setCopied] = useState(false)

//     function copy(){
//         navigator.clipboard.writeText(apiKey.value)
//         setCopied(true)
//         setTimeout(() => {
//             setCopied(false)
//         }, 2000);
//     }

//     if (apiKey == null) { return null }

//     return <><div className={styles.settingLine} />
//         <div className={styles.block}>
//             <div>
//                 <div className={styles.settingSubtitle}>Project write key</div>
//                 <div className={styles.settingThirdTitle}>Use this key to write to your project</div>
//             </div>
//             <div>
//                 <button className={styles.copyBtn} onClick={copy}>
//                     <div className={styles.mono}>{apiKey.value}</div>
//                     <div className={styles.copyIcon}>
//                         {copied && <UilCheck />}
//                         {!copied && <UilCopy />}
//                     </div>
//                 </button>
//             </div>
//         </div>
//     </>
// }

function Team() {

    const [domainField, setDomainField] = useState("")
    const [domainModal, setDomainModal] = useState(false)
    const [inviteModal, setInviteModal] = useState(false)
    const [invited, setInvited] = useState([]);
    const { workspace } = useGlobal()
    const { users } = workspace
    const limits = useLimits();
    const { team } = limits

    const { data: domainData, refetch } = useGetEndpoint("team/domains/list")

    const openDomainModal = () => { setDomainModal(true) }
    const closeDomainModal = () => { setDomainModal(false) }
    const openInviteModal = () => { setInviteModal(true) }
    const closeInviteModal = () => { setInviteModal(false) }

    async function onRemoveDomain(domain) {
        const promise = new Promise(async (resolve, reject) => {
            const res = await postEndpoint("team/domains/remove", { domain })
            await refetch()
            resolve()
        })

        toast.promise(promise, {
            loading: "Removing domain...",
            success: "Successfully removed domain",
            error: "There was an error removing this domain"
        })
    }

    if (users == null) {
        return <PageLoading />
    }

    return <>
        <DomainModal open={domainModal} onClose={closeDomainModal} refetch={refetch} existingDomains={domainData?.map((x) => x.domain)} />
        <InviteModal open={inviteModal} onClose={closeInviteModal} existingEmails={users.map((x) => x.email)} invited={invited} setInvited={setInvited} />
        <div className={styles.pageWrap}>
            <div className={styles.innerWrap}>
                <div className={styles.bigBlock}>
                    <div className={styles.largeTitle}>Allowed email domains</div>
                    <div className={styles.subtitle}>Anyone with an email address at these domains will be able to automatically sign up to this workspace</div>
                    <button
                        className={styles.inviteBtn}
                        onClick={openDomainModal}
                    >Add a domain</button>
                    <div className={styles.teamWrap}>
                        {domainData?.map((domain, idx) => {
                            return <Domain key={idx} data={domain} onRemove={() => {
                                onRemoveDomain(domain.domain)
                            }} />
                        })}
                    </div>
                </div>
                <div className={styles.bigBlock} style={{ marginTop: 32 }}>
                    <div className={styles.largeTitle}>Team <span className={styles.teamLimits}>({users.length}/{team})</span></div>
                    <div className={styles.subtitle}>These users have access to this workspace. You have {team - users.length} seats remaining in your current plan. To add more seats, you need to <a href={"https://multibase.co/pricing"} target={"_blank"} rel="noreferrer">upgrade</a> your plan.</div>
                    <button
                        className={`${styles.inviteBtn} ${users.length >= team ? styles.disabled : ""}`}
                        onClick={openInviteModal}
                        disabled={users.length >= team}
                    >Invite team members</button>
                    {/* <div className={styles.inputWrap}>
                    <Input
                        value={domainField}
                        placeholder={"member@example.com"}
                    />
                    <Button className={styles.teamBtn}>Invite</Button>
                </div> */}
                    <div className={styles.teamWrap}>
                        {users.map((user, idx) => {
                            const isLocked = user.role === "owner" && users.filter((x) => x.role === "owner").length <= 1
                            return <Member key={idx} user={user} locked={isLocked} />
                        })}
                    </div>
                </div>

            </div>
        </div>
    </>
}

function DomainModal({ open, onClose, refetch, existingDomains }) {

    useEffect(() => {
        if (!open) {
            reset()
        }
    }, [open])

    const { register, handleSubmit, setError, reset, formState: { errors } } = useForm()

    function doesDomainExist(domainStr) {
        if (existingDomains.some((x) => x === domainStr)) {
            return true
        }
        return false
    }

    function isDomainValid(domainStr) {

        if (domainStr == null) { return false }

        var domainRegex = /^(?:(?:https?):\/\/)?(?:www\.)?([a-zA-Z0-9_-]+)(?:\.[a-zA-Z]{2,}){1,}$/;
        return domainRegex.test(domainStr);
    }


    function addDomain(data) {
        const { domain } = data

        const exists = doesDomainExist(domain)
        if (exists) {
            setError("domain", {
                type: "manual",
                message: "This domain is already added"
            })
            return
        }

        if (!isDomainValid(domain)) {
            setError("domain", {
                type: "manual",
                message: "Please enter a valid domain"
            })
            return
        }

        const promise = new Promise(async (resolve, reject) => {
            const res = await postEndpoint("team/domains/add", { domain })
            await refetch()
            reset()
            resolve()
        })

        toast.promise(promise, {
            loading: "Adding domain...",
            success: "Successfully added domain",
            error: "There was an error adding this domain"
        })

        onClose()
    }


    return <Modal
        isOpen={open}
        onClose={onClose}
        width={600}
        classes={{
            card: styles.modalCard
        }}
    >
        <div className={styles.modalTop}>
            <div className={styles.modalTitle}>Add a domain</div>
            <div className={styles.modalSubtitle}>Allow anyone at this domain to sign up for your workspace</div>
        </div>
        <div className={styles.modalLine} />
        <form onSubmit={handleSubmit(addDomain)}>
            <div className={styles.modalWrap}>
                <div className={styles.modalInputWrap}>
                    <div className={styles.inputLabel}>Domain</div>
                    <input
                        // value={workspace.name}
                        placeholder={"Enter a domain..."}
                        className={`${styles.domainInput} ${Boolean(errors.domain) ? styles.error : ""}`}
                        {...register('domain', {
                            required: {
                                value: true,
                                message: "Domain is required"
                            },
                            pattern: {
                                value: /^(?:(?:https?):\/\/)?(?:www\.)?([a-zA-Z0-9_-]+)(?:\.[a-zA-Z]{2,}){1,}$/,
                                message: "Please enter a valid domain"
                            },
                            validate: {
                                isUnique: (value) => {
                                    const exists = doesDomainExist(value)
                                    if (exists) {
                                        return "This domain already exists"
                                    }
                                    return true
                                }

                            }
                        })}
                    />
                    <Collapse in={Boolean(errors.domain)} timeout={300}><div className={styles.errorTxt}>{errors.domain?.message}</div></Collapse>
                </div>
            </div>
            <div className={styles.modalLine} />
            <div className={styles.modalBottom}>
                <button className={styles.modalButton} type="submit">Add domain</button>
            </div>
        </form>
    </Modal>
}

function InviteModal({ open, onClose, existingEmails, invited, setInvited }) {

    const { register, handleSubmit, setError, reset, formState: { errors } } = useForm()

    // on exit modal, reset
    useEffect(() => {
        if (!open) {
            reset()
        }
    }, [open])

    function hasUserAlreadyBeenInvited(emailStr) {
        if (invited.some((x) => x === emailStr)) {
            return true
        }
        return false
    }

    function doesEmailExist(emailStr) {
        if (existingEmails.some((x) => x === emailStr)) {
            return true
        }
        return false
    }

    function isEmailValid(emailStr) {
        if (emailStr == null) { return false }
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(emailStr);
    }


    function addEmail(data) {
        const { email } = data

        const exists = doesEmailExist(email)
        if (exists) {
            setError("email", {
                type: "manual",
                message: "This member has already been added"
            })
            return
        }

        const alreadyInvited = hasUserAlreadyBeenInvited(email)
        if (alreadyInvited) {
            setError("email", {
                type: "manual",
                message: "This email has already been invited"
            })
            return
        }

        if (!isEmailValid(email)) {
            setError("email", {
                type: "manual",
                message: "Please enter a valid email"
            })
            return
        }

        const promise = new Promise(async (resolve, reject) => {
            try {
                const res = await postEndpoint("team/member/invite", { email })
            } catch {
                setError("email", {
                    type: "manual",
                    message: "There was an error sending this invite"
                })
                reject()
                return
            }
            setInvited([...invited, email])
            reset()
            resolve()
        })

        toast.promise(promise, {
            loading: "Sending invite...",
            success: "Successfully sent invite",
            error: "There was an error sending this invite"
        })

        onClose()
    }


    return <Modal
        isOpen={open}
        onClose={onClose}
        width={600}
        classes={{
            card: styles.modalCard
        }}
    >
        <div className={styles.modalTop}>
            <div className={styles.modalTitle}>Add a new member</div>
            <div className={styles.modalSubtitle}>Invite a new member to your workspace</div>
        </div>
        <div className={styles.modalLine} />
        <form onSubmit={handleSubmit(addEmail)}>
            <div className={styles.modalWrap}>
                <div className={styles.modalInputWrap}>
                    <div className={styles.inputLabel}>Email</div>
                    <input
                        // value={workspace.name}
                        placeholder={"Enter an email address..."}
                        className={`${styles.domainInput} ${Boolean(errors.email) ? styles.error : ""}`}
                        {...register('email', {
                            required: {
                                value: true,
                                message: "Email is required"
                            },
                            pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Please enter a valid email"
                            },
                            validate: {
                                isUnique: (value) => {
                                    const exists = doesEmailExist(value)
                                    if (exists) {
                                        return "This member has already been added"
                                    }
                                    return true
                                },
                                redundant: (value) => {
                                    const exists = hasUserAlreadyBeenInvited(value)
                                    if (exists) {
                                        return "This email has already been invited"
                                    }
                                    return true
                                }
                            }
                        })}
                    />
                    <Collapse in={Boolean(errors.email)} timeout={300}><div className={styles.errorTxt}>{errors.email?.message}</div></Collapse>
                </div>
            </div>
            <div className={styles.modalLine} />
            <div className={styles.modalBottom}>
                <button className={styles.modalButton} type="submit">Send invite</button>
            </div>
        </form>
    </Modal>
}


function Member({ user, locked }) {

    // const [role, setRole] = useState(user.role)
    if(user == null){
        return <>null user</>
    }

    const roles = [
        { id: "owner", name: "Owner" },
        { id: "admin", name: "Admin" },
        { id: "member", name: "Member" }
    ]

    const roleText = roles.find((x) => x.id === user.role ).name;

    return <div className={styles.userWrap}>
        <div className={`${styles.col} ${styles.flex}`} style={{gap: 16}}>
            <CustomIcon user={user} size={36} fontSize={12} />
            <div className={styles.userTextWrap}>
                <div className={styles.memberName}>{user.name}</div>
                <div className={styles.memberEmail}>{user.email}</div>
            </div>
        </div>
        <div className={styles.col}>
            {/* <select value={queryType} onChange={(e)=>setQueryType(e.target.value)} className={styles.queryTypeSelect}>
                            <option value={"time"}>Time-based query</option>
                        </select> */}
            {/* <Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                options={[
                    { id: "owner", name: "Owner" },
                    { id: "admin", name: "Admin" },
                    { id: "member", name: "Member" }
                ]}
                locked={locked}
                lockText={"You must have at least one owner per workspace."}
            /> */}
            <div className={styles.roleText}>{roleText}</div>
        </div>
    </div>
}

function Account({ onLogout, loggingOut }) {

    const { user, setUser } = useGlobal()

    const { icon: userIcon } = user;
    const imageInput = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        if (user == null) { return }
    }, [user])


    async function uploadImage(image) {
        if (image.size > 2097152) {
            toast.error("This file is too large. Please upload a smaller image.")
            return
        };

        const url = URL.createObjectURL(image)
        const sn = await snapshotResize(url, 300, 300)

        const promise = new Promise(async (resolve, reject) => {
            const storage = getStorage();
            const storageRef = ref(storage, `users/icons/${user.id}.png`);
            await uploadString(storageRef, sn, 'data_url')
            const downloadURL = await getDownloadURL(storageRef)
            await updateUser({ icon: downloadURL })
            setUser({ ...user, icon: downloadURL })
            resolve()
        })

        toast.promise(promise, {
            loading: "Uploading...",
            success: "Successfully uploaded icon",
            error: "There was an error uploading this icon"
        })
    }

    if (!user) {
        return
    }

    const memberSince = moment(user.created).format("MMM DD, YYYY")
    const initials = getInitials(user.name)

    return <div className={styles.pageWrap}>
        <div className={styles.innerWrap}>
            <div className={styles.largeTitle}>Account</div>
            <div className={styles.subtitle}>Manage your Multibase profile</div>
            <div className={styles.block} style={{ marginTop: "32px" }}>
                <div>
                    <div className={styles.settingSubtitle}>Profile picture</div>
                    <div className={styles.settingThirdTitle}>Manage your profile picutre</div>
                </div>
                <div>
                    <input
                        type="file"
                        ref={imageInput}
                        accept="image/png, image/gif, image/jpeg"
                        className={styles.hiddenInput}
                        onChange={(event) => {
                            uploadImage(event.target.files[0])
                            event.target.value = null;
                        }}
                    />
                    <button type={"button"} className={styles.iconWrap} onClick={() => {
                        imageInput.current.click()
                    }}>
                        {userIcon == null ?
                            <GraidentCircle text={initials} size={48} fontSize={20} />
                            :
                            <img className={styles.iconImg} src={userIcon} />}
                    </button>
                </div>
            </div>
            <div className={styles.settingLine}></div>
            <div className={styles.block}>
                <div>
                    <div className={styles.settingSubtitle}>Email</div>
                    <div className={styles.settingThirdTitle}>Mulitbase account email</div>
                </div>
                <div>
                    {user.email}
                </div>
            </div>
            <div className={styles.settingLine}></div>
            <div className={styles.block}>
                <div>
                    <div className={styles.settingSubtitle}>Account created</div>
                    <div className={styles.settingThirdTitle}>Date of when you joined Multibase</div>
                </div>
                <div>
                    {memberSince}
                </div>
            </div>
            {/* <div className={styles.settingLine}></div>
            <div className={styles.block}>
                <div>
                    <div className={styles.settingSubtitle}>Password</div>
                    <div className={styles.settingThirdTitle}>Manage your password</div>
                </div>
                <div>
                    <Button href={"/reset"}>Reset</Button>
                </div>
            </div> */}
            <div className={styles.settingLine}></div>
            <div className={styles.block}>
                <div>
                    <div className={styles.settingSubtitle}>Sign out</div>
                    <div className={styles.settingThirdTitle}>Log out of Multibase</div>
                </div>
                <div>
                    <CustomLoadingButton
                        colorVariant="destructive"
                        onClick={onLogout}
                        loading={loggingOut}
                    >Logout
                    </CustomLoadingButton>
                </div>
            </div>
        </div>
    </div>
}

async function snapshotResize(srcData, width, height, callback) {

    const p = new Promise((resolve) => {
        var imageObj = new Image(),
            canvas = document.createElement("canvas"),
            ctx = canvas.getContext('2d'),
            xStart = 0,
            yStart = 0,
            aspectRadio,
            newWidth,
            newHeight;

        imageObj.src = srcData;
        imageObj.onload = () => {
            canvas.width = width;
            canvas.height = height;

            aspectRadio = imageObj.height / imageObj.width;

            if (imageObj.height < imageObj.width) {
                //horizontal
                aspectRadio = imageObj.width / imageObj.height;
                newHeight = height
                newWidth = aspectRadio * height;
                xStart = -(newWidth - width) / 2;
            } else {
                //vertical
                newWidth = width
                newHeight = aspectRadio * width;
                yStart = -(newHeight - height) / 2;
            }

            ctx.drawImage(imageObj, xStart, yStart, newWidth, newHeight);

            resolve(canvas.toDataURL("image/png"))
        }
    })

    const data = await p;

    return data
}

function Domain({ data, onRemove }) {
    const { created, domain } = data

    return <div className={styles.domainWrap}>
        <div className={styles.domainTextWrap}>
            <div className={styles.domainText}>{domain}</div>
            <div className={styles.domainDate}>Added {moment(created).format("MMM DD, YYYY")}</div>
        </div>
        <button className={styles.domainRemoveBtn} onClick={onRemove}>
            <div className={styles.removeIconWrap}>
                <UilMultiply />
            </div>
        </button>
    </div>
}