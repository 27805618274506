import moment from "moment";
import numeral from "numeral";

export const current_tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
export function formatNumber({
    number,
    type,
    abbreviate: abbreviateInput = undefined,
    decimals = undefined,
    showPlus = false,
}: {
    number: number;
    type: string;
    abbreviate?: boolean;
    decimals?: number;
    showPlus?: boolean;
}) {

    //if abbreviate is undefined, then we will abbreviate if the number is greater than 1000
    //if abbreviate is true or false, then we will abbreviate or not abbreviate accordingly
    const abbreviate = abbreviateInput === undefined ? number > 1000 : abbreviateInput;

    const whole_suffix = showPlus && number > 0 ? "+" : "";

    if (number === null || number === undefined || isNaN(number)) {
        return "–";
    }

    let decimalPoints = 0;
    const absNum = Math.abs(number);
    if (absNum > 10.0) {
        decimalPoints = 2;
    } else if (absNum > 0.01) {
        decimalPoints = 5;
    } else if (absNum > 0.0001) {
        decimalPoints = 7;
    } else if (absNum > 0.000001) {
        decimalPoints = 9;
    } else {
        decimalPoints = 0;
    }

    // const decimals = decimalsInput !== undefined ? decimalsInput : decimalPoints;

    if (type === "integer") {
        if (abbreviate) {
            return whole_suffix + abbreviateNumber(number, decimals);
        } else {
            return whole_suffix + numberWithCommas(number, decimals);
        }
    }
    if (type === "decimal") {
        if (abbreviate) {
            return whole_suffix + abbreviateNumber(
                number,
                decimals !== undefined ? decimals : decimalPoints
            );
        } else {
            return whole_suffix + numberWithCommas(
                number,
                decimals !== undefined ? decimals : decimalPoints
            );
        }
    }
    if (type === "currency") {
        const subSymbol = number < 0 ? "-" : "";
        if (abbreviate) {
            return (
                subSymbol + "$" + whole_suffix +
                abbreviateNumber(
                    Math.abs(number),
                    decimals !== undefined ? decimals : decimalPoints
                )
            );
        } else {
            return (
                subSymbol + "$" + whole_suffix +
                numberWithCommas(
                    Math.abs(number),
                    decimals !== undefined ? decimals : decimalPoints
                )
            );
        }
    }

    function abbreviateNumber(value: number, decimals?: number) {
        if ((value < 1000 && value > -1000) && Number.isInteger(value)) {
            return value.toString();
        }

        let newValue = value;
        const suffixes = ["", "K", "M", "B", "T", "P", "E", "Z", "Y"];
        let suffixNum = 0;

        if (value > 0) {
            while (newValue >= 1000) {
                newValue /= 1000;
                suffixNum++;
            }
            let newValueString = newValue.toFixed(decimals);
            newValueString += suffixes[suffixNum];
            return newValueString;
        } else {
            while (newValue <= -1000) {
                newValue /= 1000;
                suffixNum++;
            }
            let newValueString = newValue.toFixed(decimals);
            newValueString += suffixes[suffixNum];
            return newValueString;
        }
    }

    function numberWithCommas(x: number, fractionalDigits?: number) {
        return x.toLocaleString("en-US", {
            minimumFractionDigits: fractionalDigits,
            maximumFractionDigits: fractionalDigits,
        });
    }

    if (type === "percent") {
        //No abbreviation here
        return (
            whole_suffix +
            (number * 100).toLocaleString("en-US", {
                minimumFractionDigits: decimals,
            }) +
            "%"
        );

    }

    return numberWithCommas(number, 0);
}


export function timeDifferenceFutureDateObj(time_to_compare: Date) {
    const current = Math.floor(new Date().getTime()) / 1000;
    const compareTime = Math.floor(time_to_compare.getTime()) / 1000;

    var sPerMinute = 60;
    var sPerHour = sPerMinute * 60;
    var sPerDay = sPerHour * 24;
    var sPerMonth = sPerDay * 30;
    var sPerYear = sPerMonth * 365;

    var elapsed = compareTime - current;

    if (elapsed < 0) {
        return "now";
    }

    if (elapsed < sPerMinute) {
        return "in " + Math.round(elapsed) + " seconds";
    } else if (elapsed < sPerHour) {
        return "in " + Math.round(elapsed / sPerMinute) + " minutes";
    } else if (elapsed < sPerDay) {
        return "in " + Math.round(elapsed / sPerHour) + " hours";
    } else if (elapsed < sPerMonth) {
        return "in " + Math.round(elapsed / sPerDay) + " days";
    } else if (elapsed < sPerYear) {
        return "in " + Math.round(elapsed / sPerMonth) + " months";
    } else {
        return "in " + Math.round(elapsed / sPerYear) + " years";
    }
}

export function timeDifferencePastISO(time_to_compare: string) {
    const current = Math.floor(new Date().getTime()) / 1000;
    const compareTime = Math.floor(moment(time_to_compare).unix());

    var sPerMinute = 60;
    var sPerHour = sPerMinute * 60;
    var sPerDay = sPerHour * 24;
    var sPerMonth = sPerDay * 30;
    var sPerYear = sPerMonth * 365;

    var elapsed = current - compareTime;

    if (elapsed < 0) {
        return "now";
    }

    if (elapsed < sPerMinute) {
        return Math.round(elapsed) + ` second${Math.round(elapsed / sPerMonth) === 1 ? "" : "s"}`;
    } else if (elapsed < sPerHour) {
        return Math.round(elapsed / sPerMinute) + ` minute${Math.round(elapsed / sPerMonth) === 1 ? "" : "s"}`;
    } else if (elapsed < sPerDay) {
        return Math.round(elapsed / sPerHour) + ` hour${Math.round(elapsed / sPerMonth) === 1 ? "" : "s"}`;
    } else if (elapsed < sPerMonth) {
        return Math.round(elapsed / sPerDay) + ` day${Math.round(elapsed / sPerMonth) === 1 ? "" : "s"}`;
    } else if (elapsed < sPerYear) {
        return Math.round(elapsed / sPerMonth) + ` month${Math.round(elapsed / sPerMonth) === 1 ? "" : "s"}`;
    } else {
        return Math.round(elapsed / sPerYear) + ` year${Math.round(elapsed / sPerMonth) === 1 ? "" : "s"}`;
    }
}

export function rowify(data: Array<Object>, format: (x: Object, idx: number) => any = (x: Object, idx: number) => ({
    id: idx,
    ...x,
})) {

    return data?.map((x: Object, idx: number) => format(x, idx))
}

export function formatDate(date: Date, dateFormat: string) {
    try {
        const localDate = moment.utc(date).local();
        return localDate.format(dateFormat);
    } catch (error) {
        return "–";
    }
}

export function formatDateISO(date: string, dateFormat: string) {
    try {
        const localDate = moment(date).local();
        return localDate.format(dateFormat);
    } catch (error) {
        return "–";
    }
}

const sPerMinute = 60;
const sPerHour = sPerMinute * 60;
const sPerDay = sPerHour * 24;
const sPerMonth = sPerDay * 30;
const sPerYear = sPerMonth * 365;

export function timeDifference(date: string, future: boolean = false) {
    const diff = future ? moment(date).diff(moment(), 'seconds') : moment().diff(moment(date), 'seconds')

    if (diff < 0) return "now";

    if (diff < sPerMinute) {
        return `${Math.round(diff)} second${Math.round(diff) === 1 ? "" : "s"}`;
    } else if (diff < sPerHour) {
        return `${Math.round(diff / sPerMinute)} minute${Math.round(diff / sPerMinute) === 1 ? "" : "s"}`;
    } else if (diff < sPerDay) {
        return `${Math.round(diff / sPerHour)} hour${Math.round(diff / sPerHour) === 1 ? "" : "s"}`;
    } else if (diff < sPerMonth) {
        return `${Math.round(diff / sPerDay)} day${Math.round(diff / sPerDay) === 1 ? "" : "s"}`;
    } else if (diff < sPerYear) {
        return `${Math.round(diff / sPerMonth)} month${Math.round(diff / sPerMonth) === 1 ? "" : "s"}`;
    } else {
        return `${Math.round(diff / sPerYear)} year${Math.round(diff / sPerYear) === 1 ? "" : "s"}`;
    }
}


export function formatTimeRecentISO(date: string) {
    // date is an iso string
    const momentDt = moment(date);

    // difference between now and ommentDt in seconds
    const diff = moment().diff(momentDt, 'seconds')

    if (diff < 86400) {
        return formatDateISO(date, "h:mm A")
    }
    // var elapsed = current - time_to_compare;
    // if (elapsed < 86400) {
    //     return formatDate(d, "h:mm A");
    // }

    return formatDateISO(date, "MMM D YYYY, h:mm A");
}

export function formatTimeRecent(time_to_compare: number) {
    // time_to_compare is UTC timestamp in seconds

    const current = Math.floor(new Date().getTime()) / 1000;
    const d = new Date(time_to_compare * 1000);

    var elapsed = current - time_to_compare;
    if (elapsed < 86400) {
        return formatDate(d, "h:mm A");
    }

    return formatDate(d, "MMM D, h:mm A");
}

export function formatTimeRecentWithHelpingWords(time_to_compare: Date) {
    const current = Math.floor(new Date().getTime()) / 1000;
    const compareTs = Math.floor(time_to_compare.getTime() / 1000);
    // const compareTS = time_to_compare.getTime() / 1000);

    var elapsed = current - compareTs;
    if (elapsed < 86400) {
        return ["at", formatDate(time_to_compare, "h:mm A")];
    }

    return ["on", formatDate(time_to_compare, "MMM D, h:mm A")];
}

export function formatTimeRecentDobj(time_to_compare: Date) {
    const current = Math.floor(new Date().getTime()) / 1000;
    const d = time_to_compare.getTime() / 1000

    var elapsed = current - d;
    if (elapsed < 86400) {
        return formatDate(time_to_compare, "h:mm A");
    }

    return formatDate(time_to_compare, "MMM D, h:mm A");
}

export function isDevelopment() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
}

export function getDomain() {
    const dev = isDevelopment()
    return dev ? "localhost" : ".multibase.co"
}


export function getInitials(name: string) {
    let initials = name[0].toUpperCase()
    const splitName = name.split(" ")
    if (splitName.length === 1) { return initials }
    return splitName[0][0].toUpperCase() + splitName[1][0].toUpperCase()
}

export function mergeObjectArraysOnKey(array1: Array<any>, array2: Array<any>, key: string) {
    let merged = [];

    for (let i = 0; i < array1.length; i++) {
        merged.push({
            ...array1[i],
            ...(array2.find((x: any) => x[key] === array1[i][key]))
        }
        );
    }

    return (merged);

}

export function getColor(variable_name: string) {
    return getComputedStyle(document.body).getPropertyValue(`--${variable_name}`).trim();
}

function getNumFromInterval(input: string, interval: string) {
    const inputCopy = input.replace(interval, "");
    const inputNum = parseInt(inputCopy);
    return inputNum;
}

export function getSecondsForIntervalAdvanced(interval: string) {
    if (interval.includes("m") && !interval.includes("mo")) {
        const num = getNumFromInterval(interval, "m");
        return num * 60;
    }
    if (interval.includes("h")) {
        const num = getNumFromInterval(interval, "h");
        return num * 3600;
    }
    if (interval.includes("d")) {
        const num = getNumFromInterval(interval, "d");
        return num * 86400;
    }
    if (interval.includes("w")) {
        const num = getNumFromInterval(interval, "w");
        return num * 604800;
    }
    if (interval.includes("mo")) {
        const num = getNumFromInterval(interval, "mo");
        return num * 2592000;
    }
    if (interval.includes("y")) {
        const num = getNumFromInterval(interval, "y");
        return num * 31536000;
    }
    if (interval === "all") {
        return 315360000;
    }

    return 86400;
}

export function getMinFromInterval(interval: string) {
    const now = moment();
    if (interval === undefined) {
        return now.subtract(1, "day").toISOString();
    }
    return now.subtract(getSecondsForIntervalAdvanced(interval), "seconds").toISOString();
}

export function getUserById(workspace: any, id: string) {
    return workspace.users.find((x: any) => x.id === id)
}

export function leftTrim(string: string, maxLength: number) {

    if (!string) return string;
    if (maxLength < 1) return string;
    if (string.length <= maxLength) return string;
    if (maxLength == 1) return string.substring(0, 1) + '…';

    return string.substring(0, maxLength) + '…';
}

export function smartTrim(string: string, maxLength: number) {
    if (!string) return string;
    if (maxLength < 1) return string;
    if (string.length <= maxLength) return string;
    if (maxLength == 1) return string.substring(0, 1) + '…';

    var midpoint = Math.ceil(string.length / 2);
    var toremove = string.length - maxLength;
    var lstrip = Math.ceil(toremove / 2);
    var rstrip = toremove - lstrip;
    return string.substring(0, midpoint - lstrip) + '…'
        + string.substring(midpoint + rstrip);
}

export function objectByString(o: any, s: string) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        if (o === null) { return }
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

// return a random integer between min and max (inclusive)
export function getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function deleteFromObject(obj: any, prop: string): void {
    if (obj == null) {
        return;
    }

    let _index = prop.indexOf('.')
    if (_index > -1) {
        return deleteFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1));
    }

    delete obj[prop];
}

export function getLeading(iso: string, seconds: number) {
    const momentObj = moment(iso)
    const remainder = momentObj.unix() % seconds
    const newMoment = momentObj.subtract(remainder, "seconds")
    return newMoment.format("YYYY-MM-DD HH:mm:ss")
}

export function getDaysAgoTimestamp(daysAgo: number) {
    const now = new Date();
    const nowSeconds = Math.floor(now.getTime() / 1000);
    return nowSeconds - (daysAgo * 86400)
}

export function dateDeltaSeconds(seconds: number) {
    const now = new Date();
    const nowSeconds = Math.floor(now.getTime() / 1000);
    const newTs = nowSeconds - seconds
    return new Date(newTs * 1000)
}

export function convertToNumberingScheme(number: number) {
    var baseChar = ("A").charCodeAt(0),
        letters = "";

    do {
        number -= 1;
        letters = String.fromCharCode(baseChar + (number + 1 % 26)) + letters;
        number = (number / 26) >> 0; // quick `floor`
    } while (number > 0);

    return letters;
}

export function getExplorerLink(chain: string, type: string, address: string) {
    let regulatedType = type.toLowerCase()
    if (chain === "eth") {
        return `https://etherscan.io/${regulatedType}/${address}`
    }
    if (chain === "bsc") {
        return `https://bscscan.com/${regulatedType}/${address}`
    }
    if (chain === "matic") {
        return `https://polygonscan.com/${regulatedType}/${address}`
    }
    if (chain === "avax") {
        return `https://snowtrace.io/${regulatedType}/${address}`
    }
    if (chain === "arb") {
        return `https://arbiscan.io/${regulatedType}/${address}`
    }
    if (chain === "ftm") {
        return `https://ftmscan.com/${regulatedType}/${address}`
    }
    if (chain === "op") {
        return `https://explorer.optimism.io/${regulatedType}/${address}`
    }
    if(chain === "sol"){
        if(type === "address"){
            regulatedType = "account"
        }
        return `https://solscan.io/${regulatedType}/${address}`
    }
    return null
}

export function formatSecondsDelta(seconds: number, minInterval: string) {
    if (seconds < 0) {
        return "-"
    }

    // this function takes a seconds integer and returns a readable string
    // 86400 seconds = 1 day
    // 100000 seconds = 1d3h46min

    let days = 0
    let hours = 0
    let minutes = 0
    let secondsLeft = seconds

    if (secondsLeft >= 86400) {
        days = Math.floor(secondsLeft / 86400)
        secondsLeft = secondsLeft - (days * 86400)
    }
    if (secondsLeft >= 3600) {
        hours = Math.floor(secondsLeft / 3600)
        secondsLeft = secondsLeft - (hours * 3600)
    }
    if (secondsLeft >= 60) {
        minutes = Math.floor(secondsLeft / 60)
        secondsLeft = secondsLeft - (minutes * 60)
    }

    secondsLeft = Math.floor(secondsLeft)

    let returnString = ""
    if (days > 0) {
        returnString += `${days}d`
        if (minInterval === "d") {
            return returnString
        }
    }
    if (hours > 0) {
        returnString += `${hours}h`
        if (minInterval === "h") {
            return returnString
        }
    }
    if (minutes > 0) {
        returnString += `${minutes}min`
        if (minInterval === "m") {
            return returnString
        }
    }
    if (secondsLeft > 0) {
        returnString += `${secondsLeft}s`
        if (minInterval === "s") {
            return returnString
        }
    }

    return returnString

}
export function getReadableDate(date: any) {
    const { mode, min, max } = date

    if (mode === "since" || mode === "last") {
        const now = moment().format("MMM Do YYYY")
        const readableDate = moment(min).format("MMM Do YYYY")
        return `${readableDate} - ${now}`

    }

    if (mode === "between") {
        const readableMin = moment(min).format("MMM Do YYYY")
        const readableMax = moment(max).format("MMM Do YYYY")
        return `${readableMin} - ${readableMax}`
    }

    return "invalid date"
}

function getAndJoin(arr: Array<string>) {
    // join commas and add "and" before last item
    if (arr.length === 0) return ""
    if (arr.length === 1) return arr[0]
    if (arr.length === 2) return arr.join(" and ")
    return arr.slice(0, arr.length - 1).join(", ") + ", and " + arr[arr.length - 1]
}

export function emptyCheck(string: string | undefined | null) {
    if (string == null) return ""
    return string!.trim() === "" ? "(empty)" : string
}

export function getValueString(value: Array<string>, maxChars: number = 20) {
    const arraySpacesCheck = value.map(emptyCheck)
    const preliminaryValueString = getAndJoin(arraySpacesCheck)

    if (preliminaryValueString.length > maxChars && arraySpacesCheck.length === 1) {
        return preliminaryValueString.slice(0, maxChars) + "..."
    }

    if (preliminaryValueString.length > maxChars && arraySpacesCheck.length === 2) {
        const sliced = preliminaryValueString.slice(0, maxChars)
        return `${sliced}...`
    }

    if (preliminaryValueString.length > maxChars) {
        // slice last item
        // const withoutLast = arraySpacesCheck.slice(0, arraySpacesCheck.length - 1)
        const sliced = preliminaryValueString.slice(0, maxChars)
        const slicedArr = sliced.split(", ")
        const lengthOfSlicedArr = slicedArr.length
        const withoutLast = slicedArr.slice(0, lengthOfSlicedArr - 1)
        // we want to return x,y,z, and 3 more...
        return `${withoutLast.join(", ")}, and ${arraySpacesCheck.length - withoutLast.length} more...`
    }
    return preliminaryValueString
}

export function getArrowIndex({ up, highlightedIndex, activeIndex, optionsLength }: {
    up: boolean,
    highlightedIndex: number | null | undefined,
    activeIndex: number | null | undefined,
    optionsLength: number,
}) {
    if (up) {
        if (highlightedIndex == null && activeIndex === -1) {
            return 0
        }

        if (highlightedIndex == null && activeIndex !== -1) {
            const index = (activeIndex || 0) - 1
            return index < 0 ? optionsLength - 1 : index
        }

        const index = (highlightedIndex || 0) - 1
        return index < 0 ? optionsLength - 1 : index
    }

    if (!up) {
        if (highlightedIndex == null && activeIndex === -1) {
            return 0
        }

        if (highlightedIndex == null && activeIndex !== -1) {
            const index = (activeIndex || 0) + 1
            return index > optionsLength - 1 ? 0 : index
        }

        const index = (highlightedIndex || 0) + 1
        return index > optionsLength - 1 ? 0 : index
    }
}

const NUMERIC_OPERATORS = [
    { name: "Equals", id: "eq" },
    { name: "Not equal", id: "ne" },
    { name: "Greater than", id: "gt" },
    { name: "Greater than or equal", id: "gte" },
    { name: "Less than", id: "lt" },
    { name: "Less than or equal", id: "lte" },
]

const STRING_OPERATORS = [
    { name: "Is", id: "eq" },
    { name: "Is not", id: "ne" },
]

const BOOL_OPERATORS = [
    { name: "Is", id: "eq" },
    { name: "Is not", id: "ne" },
]

export function getOperatorsFromDataType(dataType: string) {
    switch (dataType) {
        case "numeric":
            return NUMERIC_OPERATORS
        case "string":
            return STRING_OPERATORS
        case "bool":
            return BOOL_OPERATORS
        default:
            return []
    }
}



export function mergeTexts() {
    const texts = Array.from(arguments)
    const filtered = texts.filter((x) => x != null && x !== "")
    return filtered.join(" ")
}

export function formatNumberAuto(num: number) {
    // if(num > 1e9) return numeral(num).format("0.0a")
    // if(num > 1e6) return numeral(num).format("0.0a")
    if (num > 1e3) return numeral(num).format("0.0a")
    return numeral(num).format("0a")
}

export function boolClass(bool: boolean, className: string) {
    return bool ? className : ""
}

function compareArr(arr1: Array<any>, arr2: Array<any>) {
    if (arr1.length !== arr2.length) return false
    for (const item of arr1) {
        if (!arr2.includes(item)) return false
    }
    return true
}

export function compareArrayRegardlessOfOrder(arr1: Array<any> | null | undefined, arr2: Array<any> | null | undefined) {
    if (arr1 == null && arr2 == null) return true
    if (arr1 == null || arr2 == null) return false
    if (arr1.length !== arr2.length) return false
    for (const item of arr1) {
        if (!arr2.includes(item)) return false
    }
    return true
}

export function compareObjectsRegardlessOfKeyOrder(obj1: any, obj2: any) {
    if (obj1 == null || obj2 == null) return false
    const obj1Keys = Object.keys(obj1)
    const obj2Keys = Object.keys(obj2)
    if (obj1Keys.length !== obj2Keys.length) return false
    for (const key of obj1Keys) {
        if (typeof obj1[key] === "object" && typeof obj2[key] === "object" && obj1[key] !== null && obj2[key] !== null) {
            const result = compareObjectsRegardlessOfKeyOrder(obj1[key], obj2[key])
            if (!result) return false
            continue
        }
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
            const result = compareArr(obj1[key], obj2[key])
            if (!result) return false
            continue
        }
        if (obj1[key] !== obj2[key]) return false
        continue
    }
    return true
}

export function isObjectEmpty(obj: object) {
    return Object.keys(obj).length === 0
}

export function bclass(bool: boolean, className: string) {
    return bool ? className : ""
}