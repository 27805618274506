import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { signInWithCustomToken } from 'firebase/auth';
import { auth, db } from 'firebase-config'
import { CustomLoadingButton } from 'components/LoadingButton/LoadingButton';
import NavLimited from 'components/NavLimited/NavLimited';
import { useGlobal } from 'hooks/global.js';
import moment from 'moment';
import { postEndpoint } from 'ApiConnector';
import { Collapse, LinearProgress } from '@mui/material';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import { UilAngleLeftB } from '@iconscout/react-unicons'
import styles from "./Join.module.css"
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageLoading from 'components/PageLoading/PageLoading';
import { toast } from 'react-hot-toast';
import { postEndpointWithErrorListening } from 'ApiConnector';
import styled from '@emotion/styled';
import { GraidentCircle } from 'components/GradientCircle/GraidentCircle';

export default function Join({ theme, user }) {
    const { register, handleSubmit, formState: { errors, isValid }, setError, setValue } = useForm({ mode: 'onChange', delayError: 2000 });
    const [loading, setLoading] = useState(false)
    const [pendingEmail, setPendingEmail] = useState(null);
    const [pendingChallenge, setPendingChallenge] = useState(null);
    const [authorizedWorkspaces, setAuthorizedWorkspaces] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [inviteCodes, setInviteCodes] = useState(null);

    const spInvite = searchParams.get("invite")
    const spToken = searchParams.get("token")

    useEffect(() => {
        if (spInvite == null || spToken == null) return
        setInviteCodes({
            invite: spInvite,
            token: spToken
        })
        // remove from search params
        setTimeout(() => {
            searchParams.delete('invite')
            searchParams.delete('token')
            setSearchParams(searchParams)
        }, 100);
    }, [spToken, spInvite])

    async function doChallenge(data) {
        setLoading(true)
        const { email } = data

        const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        if (!emailValid) {
            setError("email", { type: "manual", message: "Please enter a valid email" })
            setLoading(false)
            return
        }

        const { data: res, error } = await postEndpointWithErrorListening("user/auth/join/challenge", { email })

        if (error) {
            const message = error.message === "Challenge already sent" ? error.message : "We couldn't find any workspaces associated with this email"

            setError("email", { type: "manual", message: message })
            setLoading(false)
            return
        }

        setTimeout(() => {
            setPendingEmail(email)
            setLoading(false)
        }, 500);
        // signInWithEmailAndPassword(auth, email, password)
    }

    // if search params has query params 'invite' or 'token' return joinfrominvite
    if ((searchParams.get("invite") && searchParams.get("token")) || inviteCodes != null) {
        return <JoinFromInvite inviteCodes={inviteCodes} />
    }

    if (authorizedWorkspaces != null) return <PickWorkspace workspaces={authorizedWorkspaces} email={pendingEmail} challenge={pendingChallenge} />


    if (pendingEmail != null) {
        return <JoinCode email={pendingEmail} onJoinComplete={(authorizedWorkspaces, challenge) => {
            setPendingChallenge(challenge)
            setAuthorizedWorkspaces(authorizedWorkspaces)
        }} />
    }

    return (<>
        {/* <NavLimited /> */}
        <div className={styles.pageWrap}>
            <div className={styles.top}>
                <div className={styles.right}>
                    Don't belong to a workspace yet? <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.link}>Request access</a>
                </div>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.formWrapper}>
                    <div className={styles.formWrap}>
                        <div className={styles.title}>Join your team</div>
                        {/* write a cheeky subtitle */}
                        <div className={styles.subtitle}>Log in to an existing workspace</div>
                        {/* <img src={logo} alt="logo" className={styles.logo} /> */}
                        <form onSubmit={handleSubmit(doChallenge)} noValidate>
                            <div className={styles.inputTitle}>Email</div>
                            <input placeholder={"Email"} type="email" {...register('email', {
                                required: {
                                    value: true,
                                    message: "Email is required"
                                }, maxLength: {
                                    value: 255,
                                    message: "Please enter a valid email"
                                }, pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
                                    message: 'Please enter a valid email',
                                }
                            })} className={`${styles.normal} ${errors.email ? styles.error : ""}`} />
                            <Collapse in={Boolean(errors.email)} timeout={100}><div className={styles.errorTxt}>{errors?.email?.message}</div></Collapse>

                            <CustomLoadingButton
                                buttonType={"submit"}
                                sx={{ color: "var(--alpha-text)" }}
                                loading={loading}
                                className={styles.loginBtn}
                            >
                                Continue
                            </CustomLoadingButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

function JoinCode({ email, onJoinComplete }) {

    const { register, handleSubmit, formState: { errors, isValid }, setError, setValue } = useForm({ mode: 'onChange', delayError: 2000 });

    const [loading, setLoading] = useState(false)

    async function doChallenge(data) {
        setLoading(true)
        const { code } = data

        const codeValid = code.match(/^[0-9]{6}$/)
        if (!codeValid) {
            setError("code", { type: "manual", message: "Please enter a valid code" })
            setLoading(false)
            return
        }

        const res = await postEndpoint("user/auth/join/authorizedWorkspaces", { email, challenge: code })

        setTimeout(() => {
            onJoinComplete(res.data, code)
            setLoading(false)
        }, 500);
        return

    }



    return (<>
        {/* <NavLimited /> */}
        <div className={styles.pageWrap}>
            <div className={styles.top}>
                <div className={styles.right}>
                    Don't belong to a workspace yet? <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.link}>Request access</a>
                </div>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.formWrapper}>
                    <div className={styles.formWrap}>
                        <div className={styles.title}>Verify</div>
                        {/* write a cheeky subtitle */}
                        <div className={styles.subtitle}>Please enter the code sent to your email</div>
                        {/* <img src={logo} alt="logo" className={styles.logo} /> */}
                        <form onSubmit={handleSubmit(doChallenge)} noValidate>
                            {/* <div className={styles.inputTitle}>Code</div> */}
                            <input placeholder={"Code"} type="text" {...register('code', {
                                required: {
                                    value: true,
                                    message: "Code is required"
                                }, maxLength: {
                                    value: 6,
                                    message: "Please enter a valid code"
                                }, pattern: {
                                    value: /^[0-9]{6}$/, //eslint-disable-line
                                    message: 'Please enter a valid code',
                                }
                            })} className={`${styles.normal} ${errors.code ? styles.error : ""}`} />
                            {/* {<span className={styles.errorTxt} style={errors.email ? { maxHeight: "1.2em" } : { maxHeight: "0" }}>{errors.email?.message}</span>} */}
                            <Collapse in={Boolean(errors.code)} timeout={100}><div className={styles.errorTxt}>{errors?.code?.message}</div></Collapse>
                            {/* <div className={styles.bottomWrap}>
                                <Link className={styles.other} to='/reset'>Forgot password?</Link>
                            </div> */}

                            <CustomLoadingButton
                                buttonType={"submit"}
                                sx={{ color: "var(--alpha-text)" }}
                                loading={loading}
                                // disabled={!errors.password?.type === "manual"}
                                className={styles.loginBtn}
                            >
                                Continue
                            </CustomLoadingButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

function PickWorkspace({ workspaces: workspacesInput, email, challenge }) {

    const [workspaces, setWorkspaces] = useState(workspacesInput)
    const { register, handleSubmit, formState: { errors, isValid }, setError, watch, setValue, getValues } = useForm({ mode: 'onChange', delayError: 2000 });
    const [workspace, setWorkspace] = useState(null)
    const [accepted, setAccepted] = useState(false)
    const [accepting, setAccepting] = useState(false)
    const [declining, setDeclining] = useState(false)
    const [progress, setProgress] = useState(null)
    const { user, pingSession, getSessionToken } = useGlobal()

    useEffect(() => {
        if (workspacesInput == null) return
        setWorkspaces(workspacesInput)
    }, [workspacesInput])

    useEffect(() => {
        if (email == null) return
        setValue("email", email)
    }, [email])

    const navigate = useNavigate()

    useEffect(() => {
        if (workspaces?.length === 1 && workspacesInput.length === 1) {
            setWorkspace(workspaces[0])
        }
    }, [workspaces, workspacesInput])

    async function onDecline() {
        setDeclining(true)
        const res = await postEndpoint("user/auth/join/select/decline", { email, challenge, workspace: workspace?.id })
        setDeclining(false)

        const nw = workspaces.filter(w => w.id !== workspace.id)

        if (nw.length === 0) {
            navigate("/login")
            return
        }

        setWorkspaces(nw)
        setWorkspace(null)

    }

    async function onAccept() {
        setAccepting(true)
        const existRes = await postEndpoint("user/auth/join/select/check", { email, challenge, workspace: workspace?.id })
        const { exists } = existRes?.data || {}

        if (exists) {
            toast.success("Added to workspace. Please sign in.")
            navigate("/login")
            return
        }

        setAccepting(false)
        setAccepted(true)
    }

    async function doSignup(data) {

        setProgress({
            value: 1,
            message: "Accepting invite..."
        })

        const { name, email, password } = data;
        const { data: res, error } = await postEndpointWithErrorListening("user/auth/join/select/accept", {
            email,
            name,
            password,
            challenge,
            workspace: workspace?.id,
        })

        const { token: responseToken } = res || {}

        if (error != null) {
            setError("password", {
                type: "manual",
                message: error?.message || "There was a problem accepting the invitation. Please try again."
            })
            setProgress(null)
            return
        }

        setProgress({
            value: 35,
            message: "Loading workspace..."
        })
        await signInWithCustomToken(auth, responseToken)
        setProgress({
            value: 70,
            message: "Loading workspace..."
        })
        await getSessionToken()

        await new Promise(r => setTimeout(r, 1000))
        setProgress({
            value: 90,
            message: "Signing in..."
        })

    }

    if (progress != null) {
        return <div className={styles.pageWrap}>
            <div className={styles.top}>
                <div className={styles.right}>
                    Don't belong to a workspace yet? <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.link}>Request access</a>
                </div>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.loadingWrapLinear}>
                    <div className={styles.loadingIconWrap}>
                        {workspace.icon == null && <div className={styles.inviteIconDefault}>{workspace.name[0].toUpperCase()}</div>}
                        {workspace.icon != null && <img src={workspace.icon} className={styles.inviteIcon} />}
                    </div>
                    <div className={styles.linearLabel}>{progress.message}</div>
                    <div className={styles.linearSublabel}>This may take a few seconds</div>
                    <CustomLoader
                        variant="determinate"
                        value={progress.value}
                    />
                </div>
            </div>
        </div>
    }

    if (workspace != null) {

        if (!accepted) {

            // const inviteBox = <>
            //     <div className={styles.inviteTop}>You've been invited to join Multibase</div>
            //     <div className={styles.inviteBox}>
            //         <div className={styles.inviteIconWrap}>
            //             {workspace.icon == null && <div className={styles.inviteIconDefault}>{workspace.name[0].toUpperCase()}</div>}
            //             {workspace.icon != null && <img src={workspace.icon} className={styles.inviteIcon} />}
            //         </div>
            //         <div className={styles.inviteBoxTitle}>You've been invited to join the {workspace.name} workspace</div>
            //         <div className={styles.inviteBoxSubtitle}>You're ready to start analyzing your on-chain users. To accept this invitation, click "Join {workspace.name}" below.</div>
            //         <div className={styles.buttonWrap}>
            //             <CustomLoadingButton
            //                 loading={declining}
            //                 onClick={onDecline}
            //                 colorVariant={"outline"}
            //                 className={styles.widthHalf}
            //             >Decline</CustomLoadingButton>
            //             <CustomLoadingButton
            //                 loading={accepting}
            //                 onClick={onAccept}
            //                 className={styles.widthHalf}
            //             >Join {workspace.name}</CustomLoadingButton>
            //         </div>
            //     </div>
            // </>

            return <div className={styles.pageWrap}>
                <div className={styles.top}>
                    <div className={styles.right}>
                        Don't belong to a workspace yet? <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.link}>Request access</a>
                    </div>
                </div>
                <div className={styles.wrapper}>

                    <div className={styles.inviteTop}>You've been invited to join Multibase</div>
                    <div className={styles.inviteBox}>
                        {workspaces?.length > 1 && <div className={styles.inviteTopWrap}>
                            <button className={styles.inviteIconTopBtn} onClick={() => setWorkspace(null)}>
                                <div className={styles.inviteIconTop}><UilAngleLeftB /></div>
                                Back
                            </button>
                        </div>}
                        <div className={styles.inviteIconWrap}>
                            {workspace.icon == null && <GraidentCircle text={workspace.name[0].toUpperCase()} size={100} fontSize={48} />}
                            {workspace.icon != null && <img src={workspace.icon} className={styles.inviteIcon} />}
                        </div>
                        <div className={styles.inviteBoxTitle}>You've been invited to join the {workspace.name} workspace</div>
                        <div className={styles.inviteBoxSubtitle}>You're ready to start analyzing your on-chain users. To accept this invitation, click "Join {workspace.name}" below.</div>
                        <div className={styles.buttonWrap}>
                            <CustomLoadingButton
                                loading={declining}
                                onClick={onDecline}
                                colorVariant={"outline"}
                                className={styles.widthHalf}
                            >Decline</CustomLoadingButton>
                            <CustomLoadingButton
                                loading={accepting}
                                onClick={onAccept}
                                className={styles.widthHalf}
                            >Join {workspace.name}</CustomLoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        }

        const formEl = <div className={styles.formWrapper}>
            <div className={styles.formWrap}>
                <div className={styles.title}>Finish setup</div>
                {/* <img src={logo} alt="logo" className={styles.logo} /> */}
                <form onSubmit={handleSubmit(doSignup)} noValidate>
                    <div className={styles.inputTitle}>Name</div>
                    <input placeholder={"Satoshi Nakamoto"} type="text" {...register('name', {
                        required: {
                            value: true,
                            message: "Name is required"
                        }, maxLength: {
                            value: 255,
                            message: "Please enter a name email"
                        }
                    })} className={`${styles.normal} ${errors.name ? styles.error : ""}`} />
                    <Collapse in={Boolean(errors.name)} timeout={100}><div className={styles.errorTxt}>{errors?.name?.message}</div></Collapse>
                    <div className={styles.inputTitle}>Email</div>
                    <input placeholder={"satoshi@multibase.co"}
                        disabled={true}
                        type="email" {...register('email', {
                            required: {
                                value: true,
                                message: "Email is required"
                            }, maxLength: {
                                value: 255,
                                message: "Please enter a valid email"
                            }, pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
                                message: 'Please enter a valid email',
                            }
                        })} className={`${styles.normal} ${errors.email ? styles.error : ""}`} />
                    <Collapse in={Boolean(errors.email)} timeout={100}><div className={styles.errorTxt}>{errors?.email?.message}</div></Collapse>
                    <div className={styles.inputTitle}>Password</div>
                    <input placeholder={"Password"} type="password" {...register('password', {
                        required: {
                            value: true,
                            message: "Password is required"
                        },
                        minLength: {
                            value: 6,
                            message: "Your password must be at least 6 characters"
                        },
                        maxLength: {
                            value: 255,
                            message: "Max password length is 255 characters"
                        }
                    })} className={`${styles.normal} ${errors.password ? styles.error : ""}`} />
                    <Collapse in={Boolean(errors.password)} timeout={100}><div className={styles.errorTxt}>{errors?.password?.message}</div></Collapse>

                    <div className={styles.inputTitle}>Confirm Password</div>
                    <input placeholder={"Confirm Password"} type="password" {...register('cpassword', {
                        required: {
                            value: true,
                            message: "You must confirm your password"
                        },
                        validate: {
                            matchesPreviousPassword: (value) => {
                                const { password } = getValues();
                                return password === value || "Passwords should match!";
                            }
                        }
                    })} className={`${styles.normal} ${errors.password ? styles.error : ""}`} />
                    <Collapse in={Boolean(errors.password)} timeout={100}><div className={styles.errorTxt}>{errors?.password?.message}</div></Collapse>


                    <CustomLoadingButton
                        buttonType={"submit"}
                        sx={{ color: "var(--alpha-text)" }}
                        loading={progress != null}
                        disabled={!(isValid || errors.password?.type === "manual")}
                        className={`${styles.loginBtn} ${(isValid || errors.password?.type === "manual") ? styles.active : styles.inactive}`}
                    >
                        Continue
                    </CustomLoadingButton>
                </form>
            </div>
        </div>

        return <div className={styles.pageWrap}>
            <div className={styles.top}>
                <div className={styles.right}>
                    Don't belong to a workspace yet? <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.link}>Request access</a>
                </div>
            </div>
            <div className={styles.wrapper}>
                {formEl}
            </div>
        </div>

    }

    if (workspaces?.length === 0 || workspaces == null) {
        return (<>
            {/* <NavLimited /> */}
            <div className={styles.pageWrap}>
                <div className={styles.top}>
                    <div className={styles.right}>
                        Don't belong to a workspace yet? <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.link}>Request access</a>
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.formWrapper}>
                        <div className={styles.formWrap}>
                            <div className={styles.formIconWrap}>
                                <CorporateFareRoundedIcon />
                            </div>
                            <div className={styles.title}>No workspaces</div>
                            <div className={styles.subtitle} style={{ textAlign: "center", marginTop: "0px", marginBottom: "12px" }}>You don't belong to any workspaces yet.</div>
                            <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
                                <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.filledLink}>Request access</a>
                                <a href={"https://multibase.co?support=true"} target="_blank" rel="noreferrer" className={styles.filledLinkWhite}>Contact support</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }

    return <div className={styles.pageWrap}>
        <div className={styles.top}>
            <div className={styles.right}>
                Don't belong to a workspace yet? <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.link}>Request access</a>
            </div>
        </div>
        <div className={styles.wrapper}>
            <div className={styles.selectTitle}>
                Select a workspace to join
            </div>
            <div className={styles.workspaceSelectWrap}>
                {workspaces?.map((workspace, i) => {

                    const { icon, name } = workspace || {}

                    const imgEl = icon == null ? <GraidentCircle text={name[0].toUpperCase()} size={100} fontSize={48} /> : <img className={styles.iconImg} src={icon} />

                    return <button key={i} onClick={() => {
                        setWorkspace(workspace)
                    }} className={styles.workspaceSelectBtn}>
                        <div className={styles.workspaceSelect}>
                            <div className={styles.workspaceSelectIcon}>
                                {imgEl}
                            </div>
                            <div className={styles.workspaceSelectName}>
                                {workspace?.name}
                            </div>
                        </div>
                    </button>
                })}
            </div>
        </div>
    </div>

}

function JoinFromInvite({ inviteCodes }) {

    const { invite, token } = inviteCodes || {}
    const { register, handleSubmit, formState: { errors, isValid }, setError, watch, setValue, getValues } = useForm({ mode: 'onChange', delayError: 2000 });
    const [progress, setProgress] = useState(null)
    const [inviteData, setInviteData] = useState(undefined)
    const [accepted, setAccepted] = useState(false)
    const [declining, setDeclining] = useState(false)
    const [accepting, setAccepting] = useState(false)
    const navigate = useNavigate();
    const { user, pingSession, getSessionToken } = useGlobal()

    const { email: inviteDataEmail } = inviteData || {}

    async function doSignup(data) {

        setProgress({
            value: 1,
            message: "Accepting invite..."
        })

        const {
            name,
            password
        } = data

        const { data: res, error } = await postEndpointWithErrorListening("user/auth/join/invite/accept", { code: invite, token: token, name: name, password: password })
        const { token: responseToken } = res || {}

        if (error != null) {
            setError("password", {
                type: "manual",
                message: error?.message || "There was a problem accepting the invitation. Please try again."
            })
            setProgress(null)
            return
        }

        setProgress({
            value: 35,
            message: "Loading workspace..."
        })
        await signInWithCustomToken(auth, responseToken)
        await getSessionToken()
        // setProgress({
        //     value: 35,
        //     message: "Loading workspace..."
        // })
        // await pingSession()

        await new Promise(r => setTimeout(r, 1000))
        setProgress({
            value: 90,
            message: "Signing in..."
        })
    }

    async function onDecline() {
        setDeclining(true)
        const res = await postEndpoint("user/auth/join/invite/decline", { code: invite, token: token })
        setTimeout(() => {
            setDeclining(false)
            navigate("/login")
        }, 500);
    }

    async function onAccept() {
        setAccepting(true)

        const existRes = await postEndpoint("user/auth/join/invite/check", { code: invite, token: token })
        const { exists } = existRes?.data || {}

        if (exists) {
            toast.success("Added to workspace. Please sign in.")
            navigate("/login")
            return
        }

        setAccepting(false)
        setAccepted(true)
    }

    useEffect(() => {

        if (invite == null || token == null) return

        setInviteData(undefined)

        async function getInviteData() {
            try {
                const res = await postEndpoint("user/auth/join/invite/validate", { code: invite, token: token })
                const data = res.data;
                setInviteData(data)
            } catch {
                navigate("/login")
                toast.error("Invite link is invalid or expired")
                setInviteData(null)
            }
        }

        getInviteData()

    }, [invite, token])

    useEffect(() => {
        if (inviteDataEmail == null) return
        setValue("email", inviteDataEmail)
    }, [inviteDataEmail])

    if (inviteData === undefined || inviteCodes == null) {
        return <PageLoading />
    }

    if (inviteData === null) {
        return null
    }

    const { workspace } = inviteData

    if (!accepted) {

        return <div className={styles.pageWrap}>
            <div className={styles.top}>
                <div className={styles.right}>
                    Don't belong to a workspace yet? <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.link}>Request access</a>
                </div>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.inviteTop}>You've been invited to join Multibase</div>
                <div className={styles.inviteBox}>
                    <div className={styles.inviteIconWrap}>
                        {workspace.icon == null && <div className={styles.inviteIconDefault}>{workspace.name[0].toUpperCase()}</div>}
                        {workspace.icon != null && <img src={workspace.icon} className={styles.inviteIcon} />}
                    </div>
                    <div className={styles.inviteBoxTitle}>You've been invited to join the {workspace.name} workspace</div>
                    <div className={styles.inviteBoxSubtitle}>You're ready to start analyzing your on-chain users. To accept this invitation, click "Join {workspace.name}" below.</div>
                    <div className={styles.buttonWrap}>
                        <CustomLoadingButton
                            loading={declining}
                            onClick={onDecline}
                            colorVariant={"outline"}
                            className={styles.widthHalf}
                        >Decline</CustomLoadingButton>
                        <CustomLoadingButton
                            loading={accepting}
                            onClick={onAccept}
                            className={styles.widthHalf}
                        >Join {workspace.name}</CustomLoadingButton>
                    </div>
                </div>
            </div>
        </div>
    }

    if (progress != null) {
        return <div className={styles.pageWrap}>
            <div className={styles.top}>
                <div className={styles.right}>
                    Don't belong to a workspace yet? <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.link}>Request access</a>
                </div>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.loadingWrapLinear}>
                    <div className={styles.loadingIconWrap}>
                        {workspace.icon == null && <div className={styles.inviteIconDefault}>{workspace.name[0].toUpperCase()}</div>}
                        {workspace.icon != null && <img src={workspace.icon} className={styles.inviteIcon} />}
                    </div>
                    <div className={styles.linearLabel}>{progress.message}</div>
                    <div className={styles.linearSublabel}>This may take a few seconds</div>
                    <CustomLoader
                        variant="determinate"
                        value={progress.value}
                    />
                </div>
            </div>
        </div>
    }

    return <div className={styles.pageWrap}>
        <div className={styles.top}>
            <div className={styles.right}>
                Don't belong to a workspace yet? <a href={"https://multibase.co?request=true"} target="_blank" rel="noreferrer" className={styles.link}>Request access</a>
            </div>
        </div>
        <div className={styles.formWrapper}>
            <div className={styles.formWrap}>
                <div className={styles.title}>Finish setup</div>
                {/* <img src={logo} alt="logo" className={styles.logo} /> */}
                <form onSubmit={handleSubmit(doSignup)} noValidate>
                    <div className={styles.inputTitle}>Name</div>
                    <input placeholder={"Satoshi Nakamoto"} type="text" {...register('name', {
                        required: {
                            value: true,
                            message: "Name is required"
                        }, maxLength: {
                            value: 255,
                            message: "Please enter a name email"
                        }
                    })} className={`${styles.normal} ${errors.name ? styles.error : ""}`} />
                    <Collapse in={Boolean(errors.name)} timeout={100}><div className={styles.errorTxt}>{errors?.name?.message}</div></Collapse>
                    <div className={styles.inputTitle}>Email</div>
                    <input placeholder={"satoshi@multibase.co"}
                        disabled={true}
                        type="email" {...register('email', {
                            required: {
                                value: true,
                                message: "Email is required"
                            }, maxLength: {
                                value: 255,
                                message: "Please enter a valid email"
                            }, pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
                                message: 'Please enter a valid email',
                            }
                        })} className={`${styles.normal} ${errors.email ? styles.error : ""}`} />
                    <Collapse in={Boolean(errors.email)} timeout={100}><div className={styles.errorTxt}>{errors?.email?.message}</div></Collapse>
                    <div className={styles.inputTitle}>Password</div>
                    <input placeholder={"Password"} type="password" {...register('password', {
                        required: {
                            value: true,
                            message: "Password is required"
                        },
                        minLength: {
                            value: 6,
                            message: "Your password must be at least 6 characters"
                        },
                        maxLength: {
                            value: 255,
                            message: "Max password length is 255 characters"
                        }
                    })} className={`${styles.normal} ${errors.password ? styles.error : ""}`} />
                    <Collapse in={Boolean(errors.password)} timeout={100}><div className={styles.errorTxt}>{errors?.password?.message}</div></Collapse>

                    <div className={styles.inputTitle}>Confirm Password</div>
                    <input placeholder={"Confirm Password"} type="password" {...register('cpassword', {
                        required: {
                            value: true,
                            message: "You must confirm your password"
                        },
                        validate: {
                            matchesPreviousPassword: (value) => {
                                const { password } = getValues();
                                return password === value || "Passwords should match!";
                            }
                        }
                    })} className={`${styles.normal} ${errors.password ? styles.error : ""}`} />
                    <Collapse in={Boolean(errors.password)} timeout={100}><div className={styles.errorTxt}>{errors?.password?.message}</div></Collapse>


                    <CustomLoadingButton
                        buttonType={"submit"}
                        sx={{ color: "var(--alpha-text)" }}
                        loading={progress != null}
                        disabled={!(isValid || errors.password?.type === "manual")}
                        className={`${styles.loginBtn} ${(isValid || errors.password?.type === "manual") ? styles.active : styles.inactive}`}
                    >
                        Continue
                    </CustomLoadingButton>
                </form>
            </div>
        </div>
    </div>
}

const CustomLoader = styled(LinearProgress)(({ theme }) => {
    return {
        color: "rgb(var(--primary))",
        minHeight: 4,
        width: "100%",
        backgroundColor: "rgb(var(--primary) / 20%)",
        '& .MuiLinearProgress-bar': {
            borderRadius: 4,
            backgroundColor: "rgb(var(--primary))"
        }
    }
})