import { Card } from "components/Card/Card";
import { CardTitle } from "components/CardTitle/CardTitle";
import { Content } from "components/Content/Content";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Events.module.css";
import { useEffect, useRef, useState } from "react";
import { compareObjectsRegardlessOfKeyOrder, convertToNumberingScheme, getMinFromInterval, getValueString } from "helpers";
import { UilCalender } from '@iconscout/react-unicons'
import { CardV2 } from "components/CardV2/CardV2";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { SelectV2 } from "components/SelectV2/SelectV2";
import { TopBar } from "components/TopBar/TopBar";
import { Button } from "components/Button/Button";
import { Menu } from "components/Menu/Menu";
import { deleteQuery, getQuery, saveQuery } from "helpers/query";
import { useGlobal } from "hooks/global";
import { CustomLoadingButton } from "components/LoadingButton/LoadingButton";
import { EVENT_CONDITION_OPTIONS_V2, QUERY_TYPES, standardSections, validGranularities } from "Constants";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { TimeseriesTable } from "components/Table/Table";
import moment from "moment";
import { IntegratedDateSelector } from "components/IntegratedDateFormatter/IntegratedDateSelector";
import { CircularProgress } from "@mui/material";
import { TimeseriesChart } from "components/Chart/Chart";
import { useGetEndpoint, usePostEndpoint, useQueryFunctions } from "ApiConnector";
import { UilFilterSlash } from '@iconscout/react-unicons'
import PageLoading from "components/PageLoading/PageLoading";
import { toast } from "react-hot-toast";
import { Dropdown } from "components/Dropdown/Dropdown";
import { InputButton } from "components/InputButton/InputButton";
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import ElectricBoltRoundedIcon from '@mui/icons-material/ElectricBoltRounded';
import { Filters } from "components/Filters/Filters";
import { ReactComponent as Empty } from 'assets/empty/bar.svg';
import { ClassicSaveModal } from "components/ClassicSave/ClassicSaveModal";
import { DeleteModal } from "components/DeleteModal/DeleteModal";
import { getRequestFilters, getStoredFilters } from "utils/filter";
import { AutoAwesome } from "@mui/icons-material";
import { exportCSV } from "utils/export";
import { DateSelector } from "components/DateSelector/DateSelector";
import { FilterDate } from "models/date";

export function Events() {
    const { action, query } = useParams();
    const { invalidate } = useQueryFunctions()
    const [queryData, setQueryData] = useState(undefined)
    const [loadingSave, setLoadingSave] = useState(action === "edit")
    const [originalRequestOptions, setOriginalRequestOptions] = useState(undefined)
    const [saving, setSaving] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [saveModal, setSaveModal] = useState(false)

    const [granularity, setGranularity] = useState(FALLBACK_GRANULARITY)
    const [date, setDate] = useState({ mode: "period", period: FALLBACK_PERIOD })
    const navigate = useNavigate()
    const [events, setEvents] = useState([])
    const [filters, setFilters] = useState([])

    async function fetchQueryData() {
        setLoadingSave(true)
        const queryData = await getQuery(query)
        const queryInnerData = queryData.data
        const { date: d, granularity: g, events: e, filters: f } = queryInnerData

        setDate(d)
        setGranularity(g)
        setEvents(e)
        setFilters(f)

        const oro = getRequestOptions(e, f, d, g)
        setQueryData(queryData)
        setOriginalRequestOptions(oro)
        setLoadingSave(false)
    }

    useEffect(() => {
        // purpose: allow for switch to create from edit
        if (action !== "create") return
        if (queryData == null) return
        setQueryData(undefined)
        setOriginalRequestOptions(undefined)
        setEvents([])
        setFilters([])
        setDate({ mode: "period", period: FALLBACK_PERIOD, min: null, max: null })
        setGranularity(FALLBACK_GRANULARITY)
    }, [action])

    useEffect(() => {
        // purpose: allow for navigating to a query from the home page
        if (queryData == null) return
        if (!query) return
        setQueryData(undefined)
        fetchQueryData()
    }, [query])

    useEffect(() => {
        // purpose: allow for loading a query after click
        if (queryData != null) return
        if (action !== "edit") return
        if (events.length !== 0 || filters.length !== 0) return
        fetchQueryData()
    }, [action, query])

    async function save({ name, description }) {
        if (name === "") return
        const store = {
            events,
            filters: getStoredFilters(filters),
            date,
            granularity,
        }
        if (action === "create") {
            setSaveModal(false)
            setSaving(true)
            const id = await saveQuery({
                name,
                description,
                type: "event",
                created: new Date(),
                updated: new Date(),
                data: store,
            })
            invalidate("general/home")
            navigate(`/query/event/edit/${id}`)
            setSaving(false)
            setQueryData({ id, name, description, data: store })
        }
        if (action === "edit") {
            const { id } = queryData
            if (id == null) return
            setSaveModal(false)
            setSaving(true)
            await saveQuery({
                id,
                updated: new Date(),
                name,
                description,
                data: store,
            })
            invalidate("general/home")
            setSaving(false)
            setQueryData({ ...queryData, name, description, data: store })
        }
        const oro = getRequestOptions(events, filters, date, granularity)
        setOriginalRequestOptions(oro)
    }

    async function deleteChart() {
        const promise = new Promise(async (resolve, reject) => {
            try {
                const { id } = queryData
                await deleteQuery(id)
                invalidate("general/home")
                navigate("/")
                resolve()
            } catch (e) {
                reject()
            }
        })

        toast.promise(promise, {
            loading: "Deleting chart...",
            error: "There was an error deleting this chart",
            success: "Successfully deleted chart"
        })

    }

    function onGranularityChange(ng) {
        setGranularity(ng)
        const g = validGranularities.find((x) => x.id === ng)
        setDate({ mode: "period", period: g.periods[1].id })
    }

    function onPeriodSelect(period) {
        setDate({ mode: "period", period: period.id })
    }

    const { requiredFilters, singleFilters } = getEventFilterLimits(events)

    const isValid = isQueryValid(events, filters, granularity)
    const requestOptions = getRequestOptions(events, filters, date, granularity)
    const { data, error, refetch } = usePostEndpoint({ endpoint: "query/event", body: requestOptions, overrideQueryKeyObj: requestOptions, minKey: "minimum", maxKey: "maximum", skip: !isValid || requestOptions == null })

    const dirty = action === "create" ? true : !compareObjectsRegardlessOfKeyOrder(originalRequestOptions, requestOptions)
    // const dirty = isDirty(action, originalRequestOptions, requestOptions)

    if (action === "edit" && loadingSave) {
        return <PageLoading />
    }

    const { name, description } = queryData || {}

    return <>
        <ClassicSaveModal
            title={action === "create" ? "Save Chart" : "Edit Chart"}
            isOpen={saveModal}
            onClose={() => setSaveModal(false)}
            onSave={save}
            existing={{ name, description }}
        />
        <DeleteModal
            isOpen={deleteModal}
            onClose={() => setDeleteModal(false)}
            onDelete={deleteChart}
        />
        <Content flex>
            <TopBarWrap
                type={"event"}
                action={action}
                dirty={dirty}
                saving={saving}
                onSaveClicked={() => {
                    if (action === "create") {
                        setSaveModal(true)
                        return
                    }
                    save({ name, description })
                }}
                onDeleteClicked={() => setDeleteModal(true)}
                onEditClicked={() => setSaveModal(true)}
            />
            <CardV2>
                <div className={styles.queryContainer}>
                    <MetricsV2
                        events={events}
                        setEvents={setEvents}
                        filters={filters}
                        setFilters={setFilters}
                    />
                    <div className={styles.section}>
                        <div className={styles.title}>Filters</div>
                        <Filters
                            filters={filters}
                            setFilters={setFilters}
                            requiredFilters={requiredFilters}
                            singleFilters={singleFilters}
                        />
                    </div>
                </div>
            </CardV2>
            <Results
                events={events}
                setEvents={setEvents}
                data={data}
                error={error}
                refetch={refetch}
                granularity={granularity}
                onGranularityChange={onGranularityChange}
                onPeriodSelect={onPeriodSelect}
                isValid={isValid}
                date={date}
                setDate={setDate}
            />
        </Content>
    </>
}

function verifyEventsHaveFilters(events, filters, setFilters) {
    const { requiredFilters = [] } = getEventFilterLimits(events)
    let filtersToAdd = new Set()
    requiredFilters.forEach((c) => {
        if (filters.some(f => f.filterKey === c.filterKey)) return
        filtersToAdd.add(c)
    })
    const fullFilters = [...filters, ...filtersToAdd]
    setFilters(fullFilters)
}

function MetricsV2({ events, setEvents, filters, setFilters }) {

    const { workspace } = useGlobal()
    const { configuration } = workspace || {}
    const { dataOrigins = {} } = configuration || {}

    const [activeEvent, setActiveEvent] = useState({ isActive: false, index: null, value: null, el: null })
    const { data: eventData } = useGetEndpoint("general/singleResource", { resource: "events" })
    let allEvents = [...eventData?.map(e => ({
        name: e.event_text,
        id: e.event,
        type: "custom",
    })) ?? [], ...ONCHAIN_TEMPLATES.map(e => ({
        name: e.name,
        id: e.id,
        type: "template",
    }))]

    if (dataOrigins.offchain) {
        allEvents = [...AUTO_TEMPLATES.map(e => ({
            name: e.name,
            id: e.id,
            type: "auto",
        })), ...allEvents]
    }

    // modes
    const [activeMode, setActiveMode] = useState({ isActive: false, index: null, value: null, el: null, parent: null })
    const modes = getValidModes(activeMode.parent)

    function onEventChanged({ index, update }) {
        if (events.length === 0) {
            const blankMetric = { ...DEFAULT_CUSTOM_METRIC, ...update }
            const validModes = getValidModes(blankMetric)
            if (!validModes.includes(blankMetric.mode)) blankMetric.mode = validModes[0]
            setEvents([blankMetric])
            verifyEventsHaveFilters([blankMetric], filters, setFilters)
            return
        }
        const newEvents = [...events]
        newEvents[index] = { ...newEvents[index], ...update }
        if (update.id != null) {
            const validModes = getValidModes(newEvents[index])
            if (!validModes.includes(newEvents[index].mode)) {
                newEvents[index].mode = validModes[0]
            }
        }
        setEvents(newEvents)
        verifyEventsHaveFilters(newEvents, filters, setFilters)
    }

    function onEventDelete(index) {
        const newEvents = events.filter((_, idx) => idx !== index)
        setEvents(newEvents)
    }

    function addBlankMetric() {
        if (events.length === 0) {
            setEvents([{ ...DEFAULT_CUSTOM_METRIC }, {}])
            return
        }
        setEvents([...events, { ...DEFAULT_CUSTOM_METRIC }])
    }

    function duplicateMetric(index) {
        const newEvents = [...events, { ...events[index] }]
        setEvents(newEvents)
    }

    return <>
        <Dropdown
            anchorEl={activeEvent.el}
            isLoadingAsyncOptions={eventData == null}
            isOpen={activeEvent.isActive}
            onClose={() => setActiveEvent({ isActive: false, index: null, value: null, el: null })}
            sections={standardSections}
            options={allEvents.map((e) => {
                const { id, name, type } = e
                let icon = LayersRoundedIcon
                if (type === "template") icon = ElectricBoltRoundedIcon
                if (type === "auto") icon = AutoAwesome
                return { id, name, icon, section: type }
            })}
            onChange={(obj) => {
                const { id, name, section: type } = obj
                onEventChanged({
                    index: activeEvent.index, update: {
                        id,
                        name,
                        type,
                    }
                })
            }}
        />
        <Dropdown
            anchorEl={activeMode.el}
            search={false}
            fullWidth={false}
            isLoadingAsyncOptions={false}
            isOpen={activeMode.isActive}
            onClose={() => setActiveMode({ isActive: false, index: null, value: null, el: null, parent: null })}
            options={modes}
            activeValue={activeMode?.value?.id}
            onChange={(id) => {
                onEventChanged({ index: activeMode.index, update: { mode: id } })
            }}
        />
        <div className={styles.section}>
            <div className={styles.sectionInner}>
                {events.map((event, idx) => <MetricV2
                    key={idx}
                    index={idx}
                    event={event}
                    activeEvent={activeEvent}
                    setActiveEvent={setActiveEvent}
                    activeMode={activeMode}
                    setActiveMode={setActiveMode}
                    onEventDelete={() => onEventDelete(idx)}
                    duplicateMetric={() => duplicateMetric(idx)}
                    deletable={events.length > 1 || (events.length === 1 && event.id != null && event.id !== "")}

                />)}
                {events.length === 0 && <MetricV2
                    index={0}
                    activeEvent={activeEvent}
                    setActiveEvent={setActiveEvent}
                    activeMode={activeMode}
                    setActiveMode={setActiveMode}
                    onEventDelete={() => onEventDelete(0)}
                    duplicateMetric={() => duplicateMetric(0)}
                    deletable={false}
                />}
                <button className={styles.add} onClick={addBlankMetric}>
                    <div className={styles.plus}>+</div>
                    <div className={styles.text}>Add graph series</div>
                </button>
            </div>
        </div>
    </>
}

function MetricV2({
    index,
    event,
    activeEvent,
    setActiveEvent,
    activeMode,
    setActiveMode,
    deletable,
    duplicateMetric,
    onEventDelete
}) {
    const eventEl = useRef(null)
    const modeEl = useRef(null)

    const eventEmpty = event == null || event?.id == null || event?.id === ""
    const eventText = eventEmpty ? "Add a metric..." : event.name

    const modeEmpty = event == null || event?.mode == null || event?.mode === ""
    const modeText = modeEmpty ? "Mode..." : event.mode.name

    return <div className={styles.metric}>
        <div className={styles.letter}>
            {convertToNumberingScheme(index)}
        </div>
        <InputButton
            pref={eventEl}
            onOpen={() => setActiveEvent({ isActive: true, index, el: eventEl, value: event?.id })}
            isOpen={activeEvent.isActive && index === activeEvent.index}
            empty={eventEmpty}
            buttonText={eventText}
            fill
        />
        <InputButton
            pref={modeEl}
            className={styles.right}
            onOpen={() => {
                setActiveMode({ isActive: true, index, el: modeEl, value: event?.mode, parent: event })
            }}
            isOpen={activeMode.isActive && index === activeMode.index}
            empty={modeEmpty}
            buttonText={modeText}
            fill
            sx={{ maxWidth: 150 }}
        />
        <div className={styles.actions}>
            <button onClick={duplicateMetric} style={{ width: 24, height: 24 }}><ContentCopyRoundedIcon /></button>
            {deletable && <button onClick={onEventDelete} style={{ width: 26, height: 26 }}><DeleteOutlineRoundedIcon /></button>}
        </div>
    </div>
}

const DEFAULT_MODES = [
    {
        name: "Uniques",
        id: "uniques"
    },
    {
        name: "Total",
        id: "total"
    },
    {
        name: "Average",
        id: "average"
    },
]

function getValidModes(event) {
    if (event == null) return DEFAULT_MODES
    const { id, type } = event || {}
    if (type === "custom") { return DEFAULT_MODES }
    if (type === "auto") {
        const { modeOptions } = AUTO_TEMPLATES.find((x) => x.id === id) || {}
        return modeOptions
    }
    if (type === "template") {
        const { modeOptions } = ONCHAIN_TEMPLATES.find((x) => x.id === id) || {}
        return modeOptions
    }
}

function TopBarWrap({ type, action, dirty, saving, onSaveClicked, onDeleteClicked, onEditClicked }) {

    const [optionsOpen, setOptionsOpen] = useState(false)
    const openOptions = () => setOptionsOpen(true)
    const closeOptions = () => setOptionsOpen(false)
    const optionsEl = useRef(null)

    return <>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            {action === "edit" && <>
                <Button buttonRef={optionsEl} onClick={openOptions} className={styles.outlineBtn} colorVariant="outline">Options</Button>
                <Menu
                    open={optionsOpen}
                    onClose={closeOptions}
                    anchorEl={optionsEl?.current}
                    options={[
                        { onClick: () => onEditClicked(), text: "Edit" },
                        { onClick: () => onDeleteClicked(), text: "Delete" }
                    ]}
                />
            </>}
            <CustomLoadingButton
                disabled={!dirty}
                loading={saving}
                className={styles.addBtn}
                onClick={onSaveClicked}
            >
                Save
            </CustomLoadingButton>
        </div>
    </>
}

function Results({
    events,
    setEvents,
    data,
    error,
    refetch,
    isValid: isValidOverride,
    granularity,
    onGranularityChange,
    onPeriodSelect,
    date,
    setDate
}) {

    function exportCSVClicked() {
        if (!data) { return }
        const times = ["Date", ...data[0].values.map((x) => {
            return moment(x[0]).format("YYYY-MM-DDTHH:mm:ss[Z]")
        })]
        const dtf = data?.map((group, idx) => {
            const { id } = group
            const { name } = constants[id]
            return [name, ...group.values.map((v) => v[1])]
        })
        const fmt = [times, ...dtf]
        const trsp = fmt[0].map((_, colIndex) => fmt.map(row => row[colIndex]));
        exportCSV(trsp, "Event Series")
    }

    function onActiveChange(index) {
        const newEvents = [...events]
        const { active } = newEvents[index]
        newEvents[index].active = !active
        setEvents(newEvents)
    }

    const constants = useConstants({ events })

    const fullGranularity = validGranularities.find((x) => x.id === granularity)
    const validPeriods = fullGranularity.periods
    const isValid = isValidOverride
    const noResults = data != null && data.length === 0
    const loading = data === undefined && isValid && !error
    const noQuery = data === undefined && !isValid && !error

    let top = <div className={styles.top}>
        <div />
        <div className={styles.right}>
            <SelectV2
                options={validGranularities}
                option={granularity}
                onChange={(x) => {
                    onGranularityChange(x)
                }}
                className={styles.periodSelect}
            />
            <div>
                {validPeriods.map((x, idx) => {
                    const selected = date.mode === "period" && date.period === x.id
                    return <button key={idx} className={`${styles.period} ${selected && styles.selected}`} onClick={() => onPeriodSelect(x)}>{x.name}</button>
                })}
            </div>
            <DateSelector
                date={date}
                onChange={(val) => setDate(val)}
                icon={UilCalender}
                arrow={false}
                placeholder={"Select Date"}
                dimPlaceholder={false}
            />
            {/* <IntegratedDateSelector
                mode={dateMode}
                rawDate={date}
                date={dateValue}
                onChange={onDateChangeFreeform}
                classes={{ wrap: styles.integrated }}
                limitInSeconds={validGranularities.find((x) => x.id === granularity).min}
                periods={validGranularities.find((x) => x.id === granularity).periods.map((x) => ({ value: x.days, name: x.days.toString() }))}
            /> */}
        </div>
    </div>


    if (loading) {
        return <CardV2 noPadding>
            {top}
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <CircularProgress sx={{ color: "var(--alpha-text)" }} />
                </div>
            </div>
        </CardV2>
    }

    if (noQuery) {
        return <Card noPadding>
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <div className={styles.emptyIcon}>
                        <Empty />
                    </div>

                    <div className={styles.title}>Select a metric to get started</div>
                    <div className={styles.description}>In order to see results, you must select at least one metric from the left panel</div>
                </div>
            </div>
        </Card>
    }

    if (noResults) {
        return <CardV2 noPadding>
            {top}
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <div className={styles.iconWrap}>
                        <UilFilterSlash />
                    </div>
                    <div className={styles.title}>No events found</div>
                    <div className={styles.description}>Please change the parameters to see results</div>
                </div>
            </div>
        </CardV2>
    }

    if (error) {
        return <CardV2 noPadding>
            {top}
            <div className={styles.noResultsWrap}>
                <div className={styles.noResultsWrapInner}>
                    <div className={styles.title}>Error</div>
                    <div className={styles.description}>There was an error loading this query. Please try again or email <a className={styles.supportEmail} href="mailto: support@multibase.co">support@multibase.co</a></div>
                    <Button onClick={refetch} className={styles.errorBtn}>Reload</Button>
                </div>
            </div>
        </CardV2>
    }

    const active = events?.map((x, idx) => { return { idx: idx, active: x.active } }).filter((x) => x.active).map((x) => x.idx)
    const dataSorted = data?.sort((a, b) => a.id)

    return <>
        <CardV2 noPadding>
            {top}
            <div className={styles.resultsWrap}>
                <TimeseriesChart
                    data={dataSorted}
                    active={active}
                    onActiveChange={onActiveChange}
                    constants={constants}
                />
            </div>
        </CardV2>
        <CardV2 noPadding>
            <div className={styles.top}>
                <div>
                    <CardTitle>Table</CardTitle>
                </div>
                <div>
                    <Button colorVariant="outline" onClick={exportCSVClicked}>Export to CSV</Button>
                </div>
            </div>
            <div className={styles.resultsWrap}>
                <TimeseriesTable
                    data={data}
                    active={active}
                    onActiveChange={(params) => {
                        onActiveChange(params)
                    }}
                    constants={constants}
                />
            </div>
        </CardV2>
    </>
}

function isQueryValid(events, filters, granularity) {
    const missing = isMissingRequired(events, filters)
    if (missing) return false
    const areAnyMetricsValid = events?.some((x) => x.id !== "" && x.id != null)
    if (!areAnyMetricsValid) return false
    return true
}

function isEventFilterValid(filter) {
    if (filter == null) return false
    const { filterKey, filterOperator, filterValue } = filter
    if (filterKey == null || filterKey === "") return false
    if (filterOperator == null || filterOperator === "") return false
    if (filterValue == null || filterValue === "") return false
    if (Array.isArray(filterValue) && filterValue.length === 0) return false
    return true
}

function isMissingRequired(events, filters) {
    if (events == null) return true
    if (events.length === 0) return true
    const { requiredFilters = [], singleConditionsOnly = [] } = getEventFilterLimits(events)
    if (requiredFilters.length === 0) return false
    const validFilters = filters?.filter((x) => isEventFilterValid(x))
    return requiredFilters.some((x) => {
        const filter = validFilters?.find((y) => y.filterKey === x.filterKey)
        if (filter == null) return true
        return false
    })
}

function getRequestOptions(events, filters, date, granularity) {
    if (date == null || granularity == null) { return }
    // const reqCon = getRequestConditions(conditionLines)
    return {
        events: getRequestMetrics(events),
        filters: getRequestFilters(filters),
        date: getDateParams(date),
        interval: granularity
    }
}

function isMetricValid(metric) {
    if (metric == null) return false
    const { id, mode, value } = metric
    if (id == null || id === "") return false
    if (mode == null || mode === "") return false
    return true
}

function getRequestMetrics(events) {
    const filtered = events.filter((x) => isMetricValid(x))
    return filtered.map(({ id, type, mode }) => ({ id, type, mode: mode.id }));
}

function getDateParams(date) {

    let dateObj = null

    const {mode} = date || {}

    if(mode === "period"){
        const { period } = date
        const minFromIntervalUnroundedISO = getMinFromInterval(period)
        const minFromIntervalISO = moment(minFromIntervalUnroundedISO).startOf("hour").toISOString()
        dateObj = new FilterDate({
            mode: "since",
            min: minFromIntervalISO
        })
        return dateObj.toJSON()
    }

    dateObj = new FilterDate(date)
    return dateObj.toJSON()

    // const { mode, period, min, max } = date

    // if (mode === "period") {
    //     const minFromIntervalUnroundedISO = getMinFromInterval(period)
    //     const minFromIntervalISO = moment(minFromIntervalUnroundedISO).startOf("hour").toISOString()
    //     return {
    //         minimum: minFromIntervalISO
    //     }
    // }
    
    // return {
    //     minimum: min,
    //     maximum: max
    // }

}

const FALLBACK_GRANULARITY = "d"
const FALLBACK_PERIOD = validGranularities.find((x) => x.id === FALLBACK_GRANULARITY).periods[1].id

function useConstants({ events = [] }) {
    const { data: resourceEvents = [] } = useGetEndpoint("general/singleResource", { resource: "events" })

    return events.reduce((acc, metric, idx) => {
        const { id, type } = metric;
        let name = undefined
        if (type === "custom") {
            const found = resourceEvents.find((x) => x.event === id);
            acc[idx] = {
                name: found?.event_text,
                formatOptions: {
                    numberFormat: "integer",
                    decimals: 2,
                    abbreviate: true
                }
            }
        }

        if (type === "auto") {
            const found = AUTO_TEMPLATES.find((x) => x.id === id);
            acc[idx] = {
                name: found?.name,
                formatOptions: found?.formatOptions
            }
        }

        if (type === "template") {
            const found = ONCHAIN_TEMPLATES.find((x) => x.id === id);
            acc[idx] = {
                name: found?.name,
                formatOptions: found?.formatOptions
            }
        }
        return acc;
    }, {});


    // return events.reduce((acc, event) => {
    //     const { event: id, event_text } = event;
    //     acc[id] = {
    //         name: event_text,
    //     };
    //     return acc; // Add this line to return the accumulator
    // }, {}); // Specify an empty object as the initial value
}

function getEventFilterLimits(events) {
    const eventIds = events?.filter((x) => x.id != null && x.type === "template").map((x) => x.id)
    if (eventIds == null) { return {} }

    let requiredFilters = new Set()
    let conditionsWithMultipleEqualFalse = new Set()
    for (let i = 0; i < eventIds.length; i++) {
        const fullMetric = ONCHAIN_TEMPLATES.find((y) => y.id === eventIds[i])
        const { conditionOptions } = fullMetric || {}
        if (conditionOptions == null) { continue }
        const required = conditionOptions.filter((y) => y.required).map((y) => y.id)
        const multiple = conditionOptions.filter((y) => y.multiple === false).map((y) => y.id)


        requiredFilters = new Set([...requiredFilters, ...required])
        conditionsWithMultipleEqualFalse = new Set([...conditionsWithMultipleEqualFalse, ...multiple])
    }

    // convert to array
    requiredFilters = [...requiredFilters]
    conditionsWithMultipleEqualFalse = [...conditionsWithMultipleEqualFalse]

    // get full objs
    const requiredFiltersFull = requiredFilters.map((x) => {
        const { id, datatype, defaultOperator, name } = EVENT_CONDITION_OPTIONS_V2.find((y) => y.id === x)
        const fil = { filterResourceType: "template", filterObject: null, filterKey: id, filterDatatype: datatype, filterOperator: defaultOperator, name }
        return fil
    })
    const singleFull = conditionsWithMultipleEqualFalse.map((x) => {
        const { id, datatype, defaultOperator, name } = EVENT_CONDITION_OPTIONS_V2.find((y) => y.id === x)
        const fil = { filterResourceType: "template", filterObject: null, filterKey: id, filterDatatype: datatype, filterOperator: defaultOperator, name }
        return fil
    })

    return { requiredFilters: requiredFiltersFull, singleConditionsOnly: singleFull }
}

const DEFAULT_CUSTOM_METRIC = { active: true }

const AUTO_TEMPLATES = [
    {
        id: "unique_users",
        name: "Active Users",
        modeOptions: [
            { id: "uniques", name: "Uniques" }
        ],
        formatOptions: {
            numberFormat: "integer",
            decimals: 2,
            abbreviate: true
        }
    },
    {
        id: "new_users",
        name: "New Users",
        modeOptions: [
            { id: "uniques", name: "Uniques" }
        ],
        formatOptions: {
            numberFormat: "integer",
            decimals: 2,
            abbreviate: true
        }
    },
    {
        id: "new_onchain_users",
        name: "New Onchain Users",
        modeOptions: [
            { id: "uniques", name: "Uniques" }
        ],
        formatOptions: {
            numberFormat: "integer",
            decimals: 2,
            abbreviate: true
        }
    },
    {
        id: "returning_users",
        name: "Returning Users",
        modeOptions: [
            { id: "24h", name: "24h" },
            { id: "7d", name: "7d" },
            { id: "30d", name: "30d" }
        ],
        formatOptions: {
            numberFormat: "integer",
            decimals: 2,
            abbreviate: true
        }
    },
]

const ONCHAIN_TEMPLATES = [
    // {
    //     id: "balance_native",
    //     name: "Token balance",
    //     stackable: true,
    //     modes: ["average", "total"],
    //     conditionOptions: [
    //         {
    //             id: "token_address",
    //             multiple: false,
    //             required: true
    //         }
    //     ],
    //     formatOptions: {
    //         numberFormat: "integer",
    //         decimals: 2
    //     },
    //     graphOptions: {
    //         yAxisLabel: "Tokens",
    //     },
    //     excludeMetrics: [],
    //     excludeConditions: ["contract_address", "contract_method", "transaction_role", "event_type"]
    // },
    // {
    //     id: "balance_usd",
    //     name: "Balance USD",
    //     modes: ["average", "total"],
    //     conditionOptions: [
    //         {
    //             id: "token_address",
    //             multiple: true,
    //             required: false
    //         }
    //     ],
    //     formatOptions: {
    //         numberFormat: "currency",
    //         decimals: 2
    //     },
    //     graphOptions: {
    //         yAxisLabel: "USD",
    //     },
    //     excludeConditions: ["contract_interactions", "contract_address", "contract_method", "transaction_role", "event_type"]
    // },
    {
        id: "transaction_count",
        name: "Transactions",
        // modes: ["average", "total"],
        modeOptions: [
            { id: "total", name: "Total" },
            { id: "average", name: "Average" },
        ],
        formatOptions: {
            numberFormat: "integer",
            abbreviate: true,
            decimals: 2,
        },
        graphOptions: {
            yAxisLabel: "Transactions",
        },
        excludeConditions: ["token_address"]
    },
    {
        id: "token_transfers",
        name: "Token transfers",
        // modes: ["average", "total"],
        modeOptions: [
            { id: "total", name: "Total" },
            { id: "average", name: "Average" },
        ],
        formatOptions: {
            numberFormat: "integer",
            abbreviate: true,
            decimals: 2,
        },
        graphOptions: {
            yAxisLabel: "Transfers",
        },
        excludeMetrics: [],
        excludeConditions: ["contract_method", "event_type"]
    },
    {
        id: "nft_transfers",
        name: "NFT transfers",
        // modes: ["average", "total"],
        modeOptions: [
            { id: "total", name: "Total" },
            { id: "average", name: "Average" },
        ],
        formatOptions: {
            numberFormat: "integer",
            abbreviate: true,
            decimals: 2,
        },
        graphOptions: {
            yAxisLabel: "Transfers",
        },
        excludeMetrics: [],
        excludeConditions: ["contract_method", "event_type"]
    },
    // {
    //     id: "transaction_value_usd",
    //     name: "Transaction volume USD",
    //     // modes: ["average", "total"],
    //     modeOptions: [
    //         { id: "total", name: "Total" },
    //         { id: "average", name: "Average" },
    //     ],
    //     formatOptions: {
    //         numberFormat: "currency",
    //         decimals: 2
    //     },
    //     graphOptions: {
    //         yAxisLabel: "USD",
    //     },
    // },
    // {
    //     id: "transaction_value_native",
    //     name: "Token transaction volume",
    //     // modes: ["average", "total"],
    //     modeOptions: [
    //         { id: "total", name: "Total" },
    //         { id: "average", name: "Average" },
    //     ],
    //     conditionOptions: [
    //         {
    //             id: "token_address",
    //             multiple: false,
    //             required: true
    //         }
    //     ],
    //     formatOptions: {
    //         numberFormat: "integer",
    //         decimals: 2
    //     },
    //     graphOptions: {
    //         yAxisLabel: "USD",
    //     },
    // },
    {
        id: "transactions_per_hour",
        name: "Transactions per hour",
        // modes: ["total"],
        modeOptions: [
            { id: "total", name: "Total" },
        ],
        formatOptions: {
            numberFormat: "decimal",
        },
        graphOptions: {
            yAxisLabel: "Transactions Per Hour",
        }
    },
    // {
    //     id: "gas_fees_usd",
    //     name: "Gas fees USD",
    //     // modes: ["average", "total"],
    //     modeOptions: [
    //         { id: "total", name: "Total" },
    //         { id: "average", name: "Average" },
    //     ],
    //     formatOptions: {
    //         numberFormat: "currency",
    //         decimals: 2
    //     },
    //     graphOptions: {
    //         yAxisLabel: "USD",
    //     },
    //     excludeConditions: ["token_address"]
    // }
]