import { formatNumber, formatNumberAuto, formatSecondsDelta } from "helpers";
import Highcharts from "highcharts"
import HighchartSankey from "highcharts/modules/sankey";
import HighchartsReact from "highcharts-react-official";
import HC_patternFill from "highcharts-pattern-fill";
import moment from "moment";
import styles from "./Chart.module.css";
import { DIVERGENT_COLORS } from "Constants";
import { useEffect, useRef } from "react";
import numeral from "numeral";

HC_patternFill(Highcharts);
HighchartSankey(Highcharts);

export function TimeseriesChart({ data, constants, active, onActiveChange, mode, incomplete }) {
    const chartRef = useRef(null)

    useEffect(() => {
        const { chart } = chartRef?.current
        if (chart == null) { return }
        const allGroups = data.map((group, idx) => idx)
        const deactivatedGroups = allGroups.filter((idx) => !active.includes(idx))
        deactivatedGroups.forEach(id => {
            const series = chart.get(id)
            if (series == null) { return }
            if (series.visible) {
                series.setVisible(false)
            }
        })
        active.forEach((id, idx) => {
            const series = chart.get(idx)
            if (series == null) { return }
            if (!series.visible) {
                series.setVisible(true)
            }
        })
    }, [active])

    function getSeries() {
        const gwt = data.map((group, idx) => { //mapping groups
            const { values, id } = group;
            const { name } = constants[id] || {}
            const color = DIVERGENT_COLORS[idx % DIVERGENT_COLORS.length]

            return {
                data: values.map((v) => [moment(v[0]).unix() * 1000, v[1]]),
                zoneAxis: 'x',
                zones: [
                    {
                        value: moment(values.slice(-2)[0][0]).unix() * 1000,
                    },
                    {
                        value: moment(values.slice(-1)[0][0]).unix() * 1000,
                        dashStyle: 'Dash',
                    }
                ],
                id: idx,
                name: name,
                color: color,
                type: "area",
                lineColor: color,
                fillOpacity: 0.1,
                lineWidth: 1.5,
                // yAxis: idx,
                states: {
                    hover: {
                        lineWidth: 1.5,
                    },
                },
                borderWidth: 0,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 0.8
                    },
                    stops: [
                        [0, Highcharts.Color(color).setOpacity(0.1).get('rgba')],
                        [1, Highcharts.Color(color).setOpacity(0.0).get('rgba')],
                    ]
                }

            }
        })
        // gwt.reverse()
        return gwt;
    }

    function getDataMin(values, padding = 0.05) {
        if (!values) { return 0 }
        const min = Math.min(...values)
        return min * (1.0 - padding)
    }

    function getDataMax(values, padding = 0.05) {
        if (!values) { return 0 }
        const max = Math.max(...values)
        return max * (1.0 + padding)
    }


    function getYAxis() {
        return {
            labels: {
                enabled: true,
                formatter: function () {
                    return numeral(this.value).format("0.[00]a");
                },
                style: {
                    color: "rgb(var(--alpha) / 60%)",
                    fontSize: "11px",
                    fontFamily: "var(--mono-font)"
                },
            },
            title: {
                enabled: false,
            },
            gridLineColor: "var(--grid-line-color)",
            gridLineWidth: 1,
            offset: 0,
            minPadding: 0.1,
            maxPadding: 0.1,
            endOnTick: false,
            startOnTick: false,
        }
    }

    function getCurrentOptions() {
        const series = getSeries();
        const yAxis = getYAxis()

        const options = {
            chart: {
                animation: false,
                backgroundColor: "rgb(255,255,255,0)",
                panning: false,
                // marginLeft: 75,
                style: {
                    fontFamily: 'var(--main-font)'
                },
            },
            title: {
                text: null
            },
            plotOptions: {
                area: {
                    fillOpacity: 0.75
                },
                series: {
                    animation: false,
                    marker: {
                        enabled: false,
                    },
                    events: {
                        legendItemClick: function () {
                            const id = this.options.id;
                            onActiveChange(id)
                            return false
                        }
                    }
                }

            },
            exporting: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            rangeSelector: {
                enabled: false,
            },
            series: series,
            scrollbar: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    let html = "";

                    let timeText = ""
                    const timestampRaw = this.points[0].x;
                    const date = new Date(timestampRaw);
                    const fmtDate = moment(date).format("MMM D, h:mm A");
                    timeText = fmtDate

                    const fmttedPoints = this.points.map((point, idx) => {
                        const id = parseInt(point.series.options.id)
                        const isLast = point.point.index === point.series.points.length - 1
                        const { name, formatOptions } = constants[id] || {}
                        const { numberFormat, decimals, abbreviate } = formatOptions || {}
                        const pointOne = point.y;
                        // const decimals = pointOne > 1000 ? 2 : undefined
                        const pointOneFormatted = formatNumber({
                            number: pointOne,
                            type: numberFormat,
                            abbreviate: abbreviate,
                            decimals: decimals
                        });
                        const color = DIVERGENT_COLORS[idx % DIVERGENT_COLORS.length]
                        return `<div class="${styles.normalInfoWrap}"><div class="${styles.dot}" style="background-color: ${color}"></div><div class="${styles.tooltipText}">${mode === "average" ? "Avg " : ""}${name}: <span class="${styles.tooltipValue}">${pointOneFormatted}${isLast ? "*" : ""}</span></div></div>`
                    })
                    html = `<div class="${styles.normalTooltipWrap}"><div class="${styles.tooltipValueWrap}">${timeText}</div>${fmttedPoints.join("")}</div>`;
                    return html;
                },
                shared: true,
                crosshairs: true,
                split: false,
                followPointer: true,
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                borderWidth: 0,
                shadow: false
            },
            xAxis: {
                type: "datetime",
                labels: {
                    style: {
                        color: "rgb(var(--alpha) / 60%)",
                        fontSize: "10px",
                        fontFamily: "var(--mono-font)"
                    }
                },
                crosshair: {
                    color: "rgb(var(--alpha) / 25%)",
                },
                gridLineWidth: 1,
                gridLineColor: "var(--grid-line-color)",
                lineColor: "var(--grid-line-color)",
                zoomEnabled: false,
                panningEnabled: false,
            },
            yAxis: yAxis,
            legend: {
                enabled: true,
                align: 'center',
                verticalAlign: 'bottom',
                itemDistance: 16,
                itemMarginTop: 0,
                margin: 16,
                symbolHeight: 10,
                itemStyle: {
                    maxWidth: 100
                },
                labelFormatter: function () {
                    return `<div class="${styles.legendItemWrap} ${this.visible ? "" : styles.invisible}">${this.name}</div>`
                },
            },
        };
        return options;
    }

    const options = getCurrentOptions();

    return (
        <>
            {/* <div style={{ height: "100%", backgroundImage: `url(${theme === "light" ? watermarkLight : watermarkDark})`, padding: "0 16px" }} className={styles.watermark}> */}
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartRef}
                    containerProps={{ style: { height: "100%" } }}
                    constructorType={"chart"}
                />
            </div>
        </>
    );

}

export function NormalChart({ data, constants }) {
    const chartRef = useRef(null)

    function getSeries() {
        const gwt = data.map((group, idx) => { //mapping groups
            const { values, id } = group;
            const { name } = constants[id] || {}
            const color = DIVERGENT_COLORS[idx % DIVERGENT_COLORS.length]
            return {
                data: values,
                id: id,
                name: name,
                color: color,
                type: "area",
                lineColor: color,
                fillOpacity: 0.1,
                lineWidth: 1.5,
                yAxis: idx,
                states: {
                    hover: {
                        lineWidth: 1.5,
                    },
                },
                borderWidth: 0,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 0.8
                    },
                    stops: [
                        [0, Highcharts.Color(color).setOpacity(0.1).get('rgba')],
                        [1, Highcharts.Color(color).setOpacity(0.0).get('rgba')],
                    ]
                }

            }
        })
        // gwt.reverse()
        return gwt;
    }

    function getDataMin(values, padding = 0.05) {
        if (!values) { return 0 }
        const min = Math.min(...values)
        return min * (1.0 - padding)
    }

    function getDataMax(values, padding = 0.05) {
        if (!values) { return 0 }
        const max = Math.max(...values)
        return max * (1.0 + padding)
    }


    function getYAxis() {
        return data.map((group, idx) => {
            const { id, values } = group;
            const { name, formatOptions, overrideMinimum } = constants[id] || {}
            const min = overrideMinimum == null ? getDataMin(values.map((v) => v[1])) : overrideMinimum
            const max = getDataMax(values.map((v) => v[1]))
            const { numberFormat } = formatOptions || {}

            return {
                labels: {
                    enabled: true,
                    formatter: function () {
                        return formatNumber({ number: this.value, type: numberFormat });
                    },
                    style: {
                        color: "rgb(var(--alpha) / 60%)",
                        fontSize: "11px",
                        fontFamily: "var(--mono-font)"
                    },
                },
                title: {
                    enabled: false,
                    // enabled: name != null,
                    // text: name,
                    x: idx % 2 === 1 ? 4 : -4,
                    style: {
                        color: "rgb(var(--alpha) / 60%)",
                        fontSize: "11px",
                        fontFamily: "var(--mono-font)"
                    },
                },
                gridLineColor: idx === 0 ? "var(--grid-line-color)" : "transparent",
                gridLineWidth: idx === 0 ? 1 : 0,
                offset: 0,
                minPadding: 0.1,
                opposite: idx % 2 == 1,
                maxPadding: 0.1,
                endOnTick: false,
                startOnTick: false,
                min: min,
                max: max,

            }
        })
    }

    function getCurrentOptions() {
        const series = getSeries();
        const yAxis = getYAxis()

        const options = {
            chart: {
                animation: false,
                backgroundColor: "rgb(255,255,255,0)",
                panning: false,
                // marginLeft: 75,
                style: {
                    fontFamily: 'var(--main-font)'
                },
            },
            title: {
                text: null
            },
            plotOptions: {
                area: {
                    fillOpacity: 0.75
                },
                series: {
                    animation: false,
                    marker: {
                        enabled: false,
                    },
                    events: {
                        legendItemClick: function () {
                            return false
                        }
                    }
                }

            },
            exporting: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            rangeSelector: {
                enabled: false,
            },
            series: series,
            scrollbar: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    let html = "";

                    let timeText = ""
                    const timestampRaw = this.points[0].x;
                    const date = new Date(timestampRaw);
                    const fmtDate = moment(date).format("MMM D YYYY, h:mm A");
                    timeText = fmtDate

                    const fmttedPoints = this.points.map((point, idx) => {
                        const id = point.series.options.id
                        const { name, formatOptions } = constants[id] || {}
                        const { numberFormat, decimals } = formatOptions || {}
                        const pointOne = point.y;
                        const abbreviate = pointOne > 1000
                        // const decimals = pointOne > 1000 ? 2 : undefined
                        const pointOneFormatted = formatNumber({
                            number: pointOne,
                            type: numberFormat,
                            abbreviate: abbreviate,
                            decimals: decimals
                        });
                        const color = DIVERGENT_COLORS[idx % DIVERGENT_COLORS.length]
                        return `<div class="${styles.normalInfoWrap}"><div class="${styles.dot}" style="background-color: ${color}"></div><div class="${styles.tooltipText}">${name}: <span class="${styles.tooltipValue}">${pointOneFormatted}</span></div></div>`
                    })
                    html = `<div class="${styles.normalTooltipWrap}"><div class="${styles.tooltipValueWrap}">${timeText}</div>${fmttedPoints.join("")}</div>`;
                    return html;
                },
                shared: true,
                crosshairs: true,
                split: false,
                followPointer: true,
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                borderWidth: 0,
                shadow: false
            },
            xAxis: {
                type: "datetime",
                labels: {
                    style: {
                        color: "rgb(var(--alpha) / 60%)",
                        fontSize: "10px",
                        fontFamily: "var(--mono-font)"
                    }
                },
                crosshair: {
                    color: "rgb(var(--alpha) / 25%)",
                },
                gridLineWidth: 1,
                gridLineColor: "var(--grid-line-color)",
                lineColor: "var(--grid-line-color)",
                zoomEnabled: false,
                panningEnabled: false,
            },
            yAxis: yAxis,
            legend: {
                enabled: true,
                align: 'center',
                verticalAlign: 'bottom',
                itemDistance: 16,
                itemMarginTop: 0,
                margin: 16,
                symbolHeight: 10,
                itemStyle: {
                    maxWidth: 100
                },
                labelFormatter: function () {
                    return `<div class="${styles.legendItemWrap} ${this.visible ? "" : styles.invisible}">${this.name}</div>`
                },
            },
        };
        return options;
    }

    const options = getCurrentOptions();

    return (
        <>
            {/* <div style={{ height: "100%", backgroundImage: `url(${theme === "light" ? watermarkLight : watermarkDark})`, padding: "0 16px" }} className={styles.watermark}> */}
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartRef}
                    containerProps={{ style: { height: "100%" } }}
                    constructorType={"chart"}
                />
            </div>
        </>
    );

}

export function PieChart({ data, constants }) {
    const chartRef = useRef(null)

    function getSeries() {
        // data looks like ["United States", 10, 0.1]
        // we must classify everything pct as < OTHER_PERCENT as other

        // const otherData = data.filter(d => d[2] < other)
        // if(otherData.length === 0) return [{ data: data }]
        // const otherSum = otherData.reduce((acc, curr) => acc + curr[1], 0)
        // const otherPct = otherData.reduce((acc, curr) => acc + curr[2], 0)
        // const otherName = "Other"
        // const dataNotOther = data.filter(d => d[2] >= other)
        // const series = [...dataNotOther, [otherName, otherSum, otherPct]]

        const topN = 25;
        const otherThreshold = 0.02;

        // data is already in descending order

        let otherizedData = data.slice(0, topN);
        const topNPlus = data.slice(topN);

        // Calculate the count and percentage for the "Other" category
        let otherCount = 0;
        let otherPercentage = 0;
        for (let obj of topNPlus) {
            const [, count, pct] = obj;
            if (pct < otherThreshold) {
                otherCount += count;
                otherPercentage += pct;
                continue;
            }
            otherizedData.push(obj);
        }

        if (otherCount > 0) {
            otherizedData.push(["Other", otherCount, otherPercentage]);
        }

        return [{ data: otherizedData }]
    }

    function getCurrentOptions() {
        const series = getSeries();

        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                animation: false,
                backgroundColor: "rgb(255,255,255,0)",
                panning: false,
                type: 'pie',
                style: {
                    fontFamily: 'var(--main-font)'
                },
            },
            exporting: {
                enabled: false
            },
            title: {
                text: null
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    innerSize: '70%',
                    cursor: 'pointer',
                    colors: DIVERGENT_COLORS,
                    borderWidth: 0,
                    showInLegend: true,
                    dataLabels: {
                        enabled: true,
                        useHTML: true,
                        format: `<span class="${styles.datalabel}" style="font-family: inherit; font-weight: 600">{point.name}:</span> {point.percentage:.1f} %`,
                        color: "var(--alpha-text)",
                        width: 50,
                        style: {
                            textOutline: false,
                            // fontFamily: "var(--mono-font)",
                            fontWeight: "400",
                        }
                    }
                },
                series: {
                    animation: false,
                    marker: {
                        enabled: false,
                    }
                }
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    const { y, point } = this || {}
                    const { index, name } = point || {}
                    // const isLast = point.point.index === point.series.points.length - 1
                    // const { name, formatOptions } = constants[id] || {}
                    // const { numberFormat, decimals, abbreviate } = formatOptions || {}
                    // const pointOne = point.y;
                    // const decimals = pointOne > 1000 ? 2 : undefined
                    // const pointOneFormatted = formatNumber({
                    //     number: pointOne,
                    //     type: numberFormat,
                    //     abbreviate: abbreviate,
                    //     decimals: decimals
                    // });
                    const color = DIVERGENT_COLORS[index % DIVERGENT_COLORS.length]
                    return `<div class="${styles.piechartTooltipWrap}"><div class="${styles.dot}" style="background-color: ${color}"></div><div class="${styles.tooltipText}"><span class="${styles.tooltipTitle}">${name}</span>:&nbsp;<span class="${styles.tooltipValue}">${y}</span></div></div>`
                },
                shared: true,
                crosshairs: true,
                split: false,
                followPointer: true,
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                borderWidth: 0,
                shadow: false,
                width: 200,
            },
            legend: {
                enabled: false,
                // align: 'left',
                // verticalAlign: 'bottom',
                // itemDistance: 16,
                // width: "100%",
                // itemMarginTop: 8,
                // margin: 16,
                // symbolHeight: 12,
                // symbolRadius: 0,
                // itemStyle: {
                //     maxWidth: 100
                // },
                // labelFormatter: function () {
                //     return `<div class="legendItemWrap ${this.visible ? "" : "invisible"}" style="font-size: 12px">${this.name}</div>`
                // },
            },
            series,
        };
        return options;
    }

    const options = getCurrentOptions();

    return (
        <>
            {/* <div style={{ height: "100%", backgroundImage: `url(${theme === "light" ? watermarkLight : watermarkDark})`, padding: "0 16px" }} className={styles.watermark}> */}
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartRef}
                    containerProps={{ style: { height: "100%" } }}
                    constructorType={"chart"}
                />
            </div>
        </>
    );

}

export function FunnelChart({ categories, data, constants, onBarClick }) {

    const { formatOptions, formatGroup, getConversionTimeFmt } = constants
    const { numberFormat } = formatOptions || {}

    const chartRef = useRef(null)
    
    const mainSeries = [{
        key: "dropoff",
        name: "dropoff",
    }, {
        key: "count",
        name: "full",
    }]

    function getSeries() {
        return mainSeries.map((series, idx) => ({
            name: series.name,
            data: data.map((d) => d[series.key]),
            cursor: 'pointer',
            color: series.key === "count" ? DIVERGENT_COLORS[0] : "url(#custom-pattern)",
            opacity: series.key === "count" ? 1 : 0.7,
            states: {
                inactive: {
                    enabled: false,
                },
            },
            borderWidth: 0,
            borderRadius: 5,
            point: {
                events: {
                    // temporarily removed clicking
                    // click: function () {
                    //     const { x, series, graphic, y, point, total } = this
                    //     const { key } = series
                    //     let conversion = null
                    //     if (key === "full" && x > 0) {
                    //         conversion = getConversionTimeFmt(x)
                    //     }

                    //     // onBarClick({element: graphic.element, index: x, type, total, conversion, value: y})
                    // }
                }
            }
        }))
    }

    function getCurrentOptions() {
        const series = getSeries();

        const options = {
            defs: {
                patterns: [
                    {
                        id: "custom-pattern",
                        path: {
                            d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
                            stroke: DIVERGENT_COLORS[0],
                            strokeWidth: 3
                        }
                    }
                ]
            },
            chart: {
                animation: false,
                backgroundColor: "rgb(255,255,255,0)",
                panning: false,
                type: 'column',
                style: {
                    fontFamily: 'var(--main-font)'
                },
            },
            title: {
                text: null
            },
            xAxis: {
                categories,
                labels: {
                    style: {
                        color: "rgb(var(--alpha) / 60%)",
                        fontSize: "12px",
                        fontFamily: "var(--main-font)"
                    },
                    formatter: function (x) {
                        return formatGroup(x.value)
                    }
                },
                crosshair: {
                    color: "rgb(var(--alpha) / 25%)",
                },
                categories,
                gridLineWidth: 1,
                gridLineColor: "var(--grid-line-color)",
                lineColor: "var(--grid-line-color)",
                zoomEnabled: false,
                panningEnabled: false,
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Values'
                },
                stackLabels: {
                    enabled: false,
                },
                labels: {
                    enabled: true,
                    formatter: function () {
                        return formatNumber({ number: this.value, type: numberFormat });
                    },
                    style: {
                        color: "rgb(var(--alpha) / 60%)",
                        fontSize: "11px",
                        fontFamily: "var(--mono-font)"
                    },
                },
                title: {
                    enabled: false,
                    // enabled: name != null,
                    // text: name,
                    x: -4,
                    style: {
                        color: "rgb(var(--alpha) / 60%)",
                        fontSize: "11px",
                        fontFamily: "var(--mono-font)"
                    },
                },
                gridLineColor: "var(--grid-line-color)",
                gridLineWidth: 1,
                offset: 0,
                minPadding: 0.1,
                opposite: false,
                maxPadding: 0.1,
                endOnTick: false,
                startOnTick: false,
            },
            legend: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                shape: "callout",
                borderWidth: 0,
                // borderRadius: 10,
                outside: true,
                useHTML: true,
                hideDelay: 0,
                borderColor: 'transparent',
                backgroundColor: 'var(--sdp02)',
                borderRadius: 8,
                padding: 8,
                borderWidth: 0,
                shadow: false,
                formatter: function () {
                    const { x, y, total, series, point } = this
                    const { name } = x
                    
                    const { index } = point
                    const { index: sIndex } = series
                    const pointData = data[sIndex]
                    const { average_conversion_time } = pointData || {}
                    const preText = sIndex === 0 ? "dropoffs" : "conversions"
                    let extraLine = ""
                    if (sIndex === 1 && index > 0) {
                        const conversionTimeFmt = formatSecondsDelta(average_conversion_time, "m")
                        extraLine = `<div class="${styles.tooltipLine} ${styles.gray}">
                            <div class="${styles.valueTag}">${conversionTimeFmt}</div>time to convert
                        </div>`
                    }
                    const valueNum = formatNumberAuto(y)
                    return `<div class="${styles.tooltipWrapV2}">
                        <div class="${styles.tooltipText} ${styles.title}">${name}</div>
                        <div class="${styles.tooltipLine} ${styles.gray}">
                            <div class="${styles.valueTag}">${numeral(y / total).format("0.0%")}</div> ${preText}
                        </div>
                        <div class="${styles.tooltipLine} ${styles.gray}">
                            <div class="${styles.valueTag}">${valueNum}</div> unique users
                        </div>
                        ${extraLine}
                    </div>`
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                },
                series: {
                    animation: false,
                    marker: {
                        enabled: false,
                    },
                    events: {
                        legendItemClick: function () {
                            return false
                        }
                    }
                }
            },
            credits: {
                enabled: false,
            },
            series: series,
        }

        return options;
    }

    const options = getCurrentOptions();

    return (
        <>
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartRef}
                    containerProps={{ style: { height: "100%" } }}
                    constructorType={"chart"}
                />
            </div>
        </>
    );

}