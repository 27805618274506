
import { Outlet } from "react-router-dom";
import styles from "./Layout.module.css"
import { Header } from 'components/Header/Header';


export default function Layout() {

    return <>
        <div className={styles.outer}>
            <Header />
            <Outlet />
        </div>
    </>
}