import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SyncIcon from '@mui/icons-material/Sync';
import TableChartIcon from '@mui/icons-material/TableChart';
import { UilFilter } from "@iconscout/react-unicons"
import RouteIcon from '@mui/icons-material/Route';

export function getDecorator(rowType, subtype) {
    if (rowType === "label") {
        return [<PeopleAltIcon />, "var(--icon-color-two)"]
    }
    if (rowType === "query") {
        if (subtype === "time" || subtype === "event") {
            return [<BarChartIcon />, "var(--icon-color-one)"]
        }
        if (subtype === "retention") {
            return [<SyncIcon />, "var(--icon-color-one)"]
        }
        // composition
        if (subtype === "composition") {
            return [<TableChartIcon />, "var(--icon-color-one)"]
        }
        if (subtype === "distribution") {
            return [<TableChartIcon />, "var(--icon-color-one)"]
        }
        if(subtype === "funnel"){
            return [<UilFilter />, "var(--icon-color-one)"]
        }
        if(subtype === "pathfinder"){
            return [<RouteIcon />, "var(--icon-color-one)"]
        }
    }
}