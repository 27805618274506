import styled from "@emotion/styled";
import { MenuItem, Menu as MuiMenu } from "@mui/material";
import { Link } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";

export function Menu({ anchorEl, open, onClose, options, align = "center" }) {

    function handleItemClick(fn) {
        onClose()
        fn()
    }

    return <CustomMenu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{
            sx: {
                // background: "var(--ndp02)",
                transition: "none",
                minWidth: anchorEl?.offsetWidth
            }
        }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: align,
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: align,
        }}
        MenuListProps={{
            sx: {
                background: "var(--ndp01)",
            }
        }}
        transitionDuration={0}
    >
        {/* <MenuInner> */}
        {options?.map((x, idx) => {
            let extraProps = {}
            if (x.href != null) {
                extraProps = {
                    component: Link,
                    to: x.href,
                    target: x.target,
                    rel: x.rel,
                    onClick: undefined
                }
            }

            if (isExternal(x.href)) {
                const old = extraProps["to"]
                extraProps["href"] = old
                delete extraProps["to"]
                extraProps["component"] = MuiLink
            }

            return <CustomMenuItem
                key={idx}
                value={x.id}
                disableRipple={true}
                onClick={() => handleItemClick(x.onClick)}
                {...extraProps}
            // component={Link}
            // to={"/user"}
            >
                {x.text}
            </CustomMenuItem>
        })}
        {/* </MenuInner> */}
    </CustomMenu>
}

const CustomMenu = styled(MuiMenu)(({ theme }) => {
    return {
        // 'label + &': {
        //     marginTop: theme.spacing(3),
        // },
        "&& .Mui-selected": {
            backgroundColor: "var(--ndp02)"
        },
        '& .MuiInputBase-input': {
            borderRadius: 5,
            position: 'relative',
            border: '1px solid var(--border-01)',
            fontSize: 13,
            paddingLeft: "8px",
            display: "flex",
            alignItems: "center",
            boxSizing: 'border-box',
            // backgroundColor: backgroundColor ?? "var(--ndp02)",
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            '&:focus': {
            },
        },

        icon: {
            fill: "var(--light)",
        },
    }
})

const MenuInner = styled.div(() => ({
    background: "var(--ndp02)",
}))

const CustomMenuItem = styled(MenuItem)(({ theme }) => {
    return {
        fontSize: 13,
        fontFamily: "Inter",
        ":hover": {
            background: "var(--ndp02)"
        },
    }
})



function isExternal(href) {
    var re = new RegExp("^(http|https)://", "i");
    return re.test(href);
}