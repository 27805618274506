import { ClickAwayListener } from "@mui/material"
import styles from './RightSidebar.module.css'
import { useEffect, useState } from "react"
import { ClearOutlined } from "@mui/icons-material"

export function RightSidebar({
    title,
    children,
    isOpen,
    onClose,
    // sidebarData,
    // setSidebarData,
}){

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                onClose()
            }
        }
        window.addEventListener('keydown', handleEscape)
        return () => window.removeEventListener('keydown', handleEscape)
    })

    if(!isOpen) return null

    return <ClickAwayListener onClickAway={onClose}>
        <div className={styles.sidebarWrap}>
            <div className={styles.sidebarInner}>
                <div className={styles.sidebarTop}>
                    <div className={styles.sidebarTitle}>{title}</div>
                    <button onClick={onClose} className={styles.sidebarClose}>
                        <ClearOutlined />
                    </button>
                </div>
                <div className={styles.sidebarContent}>
                    {children}
                </div>
            </div>
        </div>
    </ClickAwayListener>

}