import { getExplorerLink, smartTrim } from "helpers";
import { useGlobal } from "hooks/global";
import { Link } from "react-router-dom";
import styles from "./Home.module.css";
import {
  UilChartLine,
  UilChartPie,
  UilFilter,
  UilDatabase,
} from "@iconscout/react-unicons";
import { Content } from "components/Content/Content";
import { getDecorator } from "helpers/icons";
import { useGetEndpoint, usePostEndpoint } from "ApiConnector";
import moment from "moment";
import { WidgetBarChart } from "components/MiniChart/MiniChart";
import ContentLoader from "react-content-loader";
import { MoreHoriz, Route, Sync } from "@mui/icons-material";
import { EmptyDropdown } from "components/EmptyDropdown/EmptyDropdown";
import { useEffect, useRef, useState } from "react";
import { PLATFORMS } from "Constants";
import { Progress } from "components/Progress/Progress";
import { MiniTable } from "components/MiniTable/MiniTable";
import throttle from "lodash-es/throttle";
import { Modal } from "components/Modal/Modal";
import emptyHome from "assets/empty/home.png";
import { Banner } from "components/Banner/Banner";

export function Home() {
  const { workspace } = useGlobal();
  const [createPanel, setCreatePanel] = useState(false);
  if (workspace.configuration == null)
    return (
      <PageLoading
        createPanel={createPanel}
        setCreatePanel={setCreatePanel}
      />
    );

  if (!workspace.configuration.exists) {
    return (
      <Content noPadding>
        <Banner absolute />
        <div className={styles.emptyWrap}>
          <div className={styles.emptyContent}>
            <img src={emptyHome} className={styles.emptyHome} />
            <div
              style={{
                marginTop: 24,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className={styles.emptyTitle}>
                No data connected
              </div>
              <div className={styles.emptySubtitle}>
                There isn't any data is connected to this
                dashboard, yet. Connect your sources to start
                unveiling valuable&nbsp;insights.
              </div>
              <Link
                to={`/sources`}
                className={styles.emptyBtn}
                onClick={e => {
                  setCreatePanel(true);
                }}
              >
                Add a data source
              </Link>
            </div>
          </div>
        </div>
      </Content>
    );
  }

  return (
    <>
      <CreatePanel
        isOpen={createPanel}
        onClose={() => setCreatePanel(false)}
      />
      <Content>
        <Banner />
        <div className={styles.infoWidgetRow}>
          <InfoWidget
            title={"📊 Create a report"}
            description={
              "Get started by creating a report. Reports are the building blocks of your dashboard."
            }
            onClick={e => {
              setCreatePanel(true);
            }}
          />
          <InfoWidget
            title={"📖 See documentation"}
            description={
              "Tutorials and guides to help you get started with Multibase."
            }
            href={"https://docs.multibase.co"}
            target={"_blank"}
          />
          {/* <InfoWidget
                    title={"📈 Load a sample dataset"}
                    description={"Load a sample dataset load a sample dataset  load a sample dataset load a sample dataset "}
                    onClick={(e) => {
                        loadSampleProject("defi")
                    }}
                /> */}
        </div>

        <Overview setCreatePanel={setCreatePanel} />
      </Content>
    </>
  );
}

function Overview({ setCreatePanel }) {
  return (
    <div className={styles.overviewWrap}>
      <div className={styles.widgetRow}>
        <Insights setCreatePanel={setCreatePanel} />
        <DAUs />
      </div>
      <div className={styles.widgetRow}>
        <RecentUsers />
        <RecentEvents />
      </div>
      <div className={styles.widgetRow}>
        <Transactions />
        <TopContracts />
      </div>
    </div>
  );
}

function BarChartLoader({ barSize = 50, padding = 24, viewBox }) {
  const barPositionOne = `calc(50% - ${barSize / 2
    }px - ${padding}px - ${barSize}px)`;
  const barPositionTwo = `calc(50% - ${barSize / 2}px)`;
  const barPositionThree = `calc(50% + ${barSize / 2}px + ${padding}px)`;

  const margin = 16;

  return (
    <div className={styles.widgetLoadingOuter}>
      <ContentLoader
        backgroundColor="rgb(255 255 255 / 5%)"
        foregroundColor="rgb(255 255 255 / 10%)"
        style={{ height: "100%", width: "100%" }}
        viewBox={viewBox || `0 0 400 400`}
      >
        <rect x="0" y={margin} rx="4" ry="4" width="30%" height="16" />
        <rect
          x="0"
          y={margin + 24}
          rx="4"
          ry="4"
          width="60%"
          height="16"
        />
        <rect
          x={barPositionOne}
          y={`calc(100% - 350px - ${margin}px`}
          rx="4"
          ry="4"
          width={barSize}
          height="350"
        />
        <rect
          x={barPositionTwo}
          y={`calc(100% - 300px - ${margin}px)`}
          rx="4"
          ry="4"
          width={barSize}
          height="300"
        />
        <rect
          x={barPositionThree}
          y={`calc(100% - 220px - ${margin}px)`}
          rx="4"
          ry="4"
          width={barSize}
          height="220"
        />
      </ContentLoader>
    </div>
  );
}

function TableLoader({ viewBox }) {
  return (
    <div className={styles.widgetLoadingOuter}>
      <ContentLoader
        style={{ height: "100%", width: "100%" }}
        backgroundColor="rgb(255 255 255 / 5%)"
        foregroundColor="rgb(255 255 255 / 10%)"
        viewBox={viewBox || `0 0 400 400`}
      >
        <rect x="16" y="16" rx="4" ry="4" width="30%" height="16" />
        <rect x="16" y="42" rx="4" ry="4" width="60%" height="16" />

        {Array.from({ length: 8 }, (_, i) => 8 - i).map(i => {
          const y = `calc(100% - (36px * ${i}))`;
          return (
            <rect
              key={i}
              x="16"
              y={y}
              rx="4"
              ry="4"
              width="calc(100% - 32px)"
              height="20"
            />
          );
        })}
      </ContentLoader>
    </div>
  );
}

function Insights({ forceLoading, setCreatePanel }) {
  const { workspace } = useGlobal();
  const { data: homeData } = useGetEndpoint(
    "general/home",
    undefined,
    forceLoading
  );
  const color = "rgb(255 255 255 / 50%)";

  if (homeData == null || forceLoading) {
    return (
      <div className={styles.widget}>
        <TableLoader viewBox={"0 0 341 498"} />
      </div>
    );
  }

  if (homeData.length === 0) {
    return (
      <div className={styles.widget}>
        <div className={styles.widgetHeader}>
          <div
            className={styles.widgetLine}
            style={{ backgroundColor: color }}
          />
          <div className={styles.widgetHeaderTextWrap}>
            <div className={styles.widgetHeaderDate}>Newest</div>
            <div className={styles.widgetHeaderTitle}>Reports</div>
            <div className={styles.widgetHeaderSubtitle}>
              A quick overview of your reports
            </div>
          </div>
        </div>
        <div className={styles.insightBodyEmpty}>
          <div className={styles.insightEmptyIcon}>
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="none" viewBox="0 0 20 18">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M1 7h18M4 4h.01M7 4h.01M10 4h.01M3 17h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2Z" />
            </svg>
          </div>
          <div className={styles.insightEmptyTitle}>
            No reports yet
          </div>
          <div className={styles.insightEmptySubtitle}>
            To get started,&nbsp;
            <button
              onClick={() => setCreatePanel(true)}
              className={styles.insightEmptyBtnInline}
            >
              create a report
            </button>
          </div>
          {/* <button onClick={() => setCreatePanel(true)} className={styles.insightEmptyBtn}>
						Create a report
					</button> */}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.widget}>
      <div className={styles.widgetHeader}>
        <div
          className={styles.widgetLine}
          style={{ backgroundColor: color }}
        />
        <div className={styles.widgetHeaderTextWrap}>
          <div className={styles.widgetHeaderDate}>Newest</div>
          <div className={styles.widgetHeaderTitle}>Reports</div>
          <div className={styles.widgetHeaderSubtitle}>
            A quick overview of your reports
          </div>
        </div>
      </div>
      <div className={styles.insightBody}>
        {homeData?.map((report, idx) => {
          const { id, name, itemType, type, updated, author } =
            report;
          const updatedFmt = moment(updated).format("MMM D, YYYY");
          const user = workspace.users.find(x => x.id === author);
          const userTxt = user != null ? `by ${user.name}` : null;
          const [decorator] = getDecorator(itemType, type);

          if (itemType === "label") {
            return (
              <Link
                key={idx}
                className={styles.insight}
                to={"/users"}
                state={{
                  type: "label",
                  name,
                  id
                }}
              >
                <div className={styles.insightIcon}>
                  {decorator}
                </div>
                <div className={styles.insightText}>
                  <div className={styles.insightTitle}>
                    {name}
                  </div>
                  <div className={styles.insightSubtitle}>
                    Updated {updatedFmt} {userTxt}
                  </div>
                </div>
              </Link>
            );
          }

          if (itemType === "query") {
            return (
              <Link
                key={idx}
                className={styles.insight}
                to={`/query/${type}/edit/${id}`}
              >
                <div className={styles.insightIcon}>
                  {decorator}
                </div>
                <div className={styles.insightText}>
                  <div className={styles.insightTitle}>
                    {name}
                  </div>
                  <div className={styles.insightSubtitle}>
                    Updated {updatedFmt} {userTxt}
                  </div>
                </div>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
}

function DAUs({ forceLoading }) {
  const { workspace } = useGlobal();
  const [type, setType] = useState("onchain");
  const [typeOptions, setTypeOptions] = useState(["onchain"]);
  const { data } = usePostEndpoint({
    endpoint: "widgets/active-users",
    body: { type },
    skip: forceLoading,
  });
  const color = "var(--primary-hex)";
  const [optionsOpen, setOptionsOpen] = useState(false);
  const optionsEl = useRef(null);

  useEffect(() => {
    if (workspace.configuration == null) return;
    if (workspace.configuration.dataOrigins == null) return;
    const dataOrigins = workspace.configuration.dataOrigins;
    if (dataOrigins.offchain) {
      setTypeOptions(["onchain", "offchain"]);
      setType("offchain");
    }
  }, [workspace?.configuration?.dataOrigins]);

  function typeClick(type) {
    setType(type);
    setOptionsOpen(false);
  }

  if (data == null) {
    return (
      <div className={`${styles.widget} ${styles["w-2-3"]}`}>
        <BarChartLoader viewBox={"0 0 691 500"} />
      </div>
    );
  }

  return (
    <>
      <EmptyDropdown
        isOpen={optionsOpen}
        onClose={() => setOptionsOpen(false)}
        anchorEl={optionsEl}
        style={{ border: "1px solid rgb(var(--primary) / 80%)" }}
        align="right"
      >
        <div className={styles.widgetOptions}>
          {typeOptions.map((typeOption, idx) => {
            return (
              <button
                key={idx}
                className={styles.widgetOption}
                onClick={() => typeClick(typeOption)}
              >
                {typeOption === "onchain"
                  ? "On-chain"
                  : "Off-chain"}
              </button>
            );
          })}
          {/* {type === "offchain" && <button className={styles.widgetOption} onClick={() => typeClick("onchain")}>On-chain</button>}
                {type === "onchain" && <button className={styles.widgetOption} onClick={() => typeClick("offchain")}>Off-chain</button>} */}
        </div>
      </EmptyDropdown>
      <div className={`${styles.widget} ${styles["w-2-3"]}`}>
        <div className={styles.widgetHeader}>
          <div
            className={styles.widgetLine}
            style={{ backgroundColor: color }}
          />
          <div className={styles.widgetHeaderTextWrap}>
            <div className={styles.widgetHeaderDate}>
              Last 30 days
            </div>
            <div className={styles.widgetHeaderTitle}>
              Daily active users
            </div>
            <div className={styles.widgetHeaderSubtitle}>
              Unique users who have{" "}
              {type === "offchain"
                ? "interacted with your product"
                : "executed a transaction"}
            </div>
          </div>
          {typeOptions.length > 1 && (
            <button
              className={styles.widgetOptionsBtn}
              onClick={() => setOptionsOpen(true)}
              ref={optionsEl}
            >
              <MoreHoriz />
            </button>
          )}
        </div>
        <div className={styles.widgetBodyStatic}>
          <WidgetBarChart data={data} color={color} name={"DAUs"} />
        </div>
      </div>
    </>
  );
}

const userColumns = [
  {
    key: "ts",
    title: "Imported",
    minWidth: 75,
    renderCell: ({ value }) => {
      // date and time, small
      const timestamp = moment(value).format("MMM D, YYYY [at] h:mm A");
      return <div>{timestamp}</div>;
    },
  },
  {
    key: "uid",
    title: "User",
    minWidth: 125,
    renderCell: ({ value, data }) => {
      const { user_alias } = data || {};
      if (user_alias == null) return <Link className={styles.lightUserHover} to={`/users/${value}`}>{smartTrim(value, 15)}</Link>;
      return <Link className={styles.lightUserHover} to={`/users/${value}`}>{user_alias}</Link>;
    },
  },
  {
    key: "chains",
    title: "Chains",
    minWidth: 100,
    renderCell: ({ value, data }) => {
      if (value == null) return <div>–</div>;
      const { chains } = data;
      return (
        <div className={styles.chains}>
          {chains.map((chain, idx) => {
            const pObj = PLATFORMS.find(x => x.id === chain);
            if (pObj == null)
              return <span key={idx} className={`normalCell`}>–</span>;
            return (
              <img
                key={idx}
                src={pObj?.imgURL}
                className={styles.platformIcon}
              />
            );
          })}
        </div>
      );
    },
  },
];

const eventColumns = [
  {
    key: "ts",
    title: "Time",
    minWidth: 75,
    renderCell: ({ value }) => {
      // date and time, small
      const timestamp = moment(value).format("MMM D, YY [at] h:mm A");
      return <div>{timestamp}</div>;
    },
  },
  {
    key: "event", title: "Event", renderCell: ({ value }) => {
      if (value === "") return "[no method]"
      return value
    }
  },
  {
    key: "uid",
    title: "User",
    renderCell: ({ value, data }) => {
      const { user_alias } = data;
      const userText =
        user_alias != null && user_alias !== ""
          ? user_alias
          : smartTrim(value, 15);
      return (
        <Link to={`/users/${value}`} className={styles.userLink}>
          {userText}
        </Link>
      );
    },
  },
];

function RecentUsers({ forceLoading }) {
  const [offset, setOffset] = useState(0);
  const [users, setUsers] = useState(undefined);
  const requestOptions = { offset };
  const [loading, setLoading] = useState(false);
  const { data: addressData } = usePostEndpoint({
    endpoint: "users/light",
    body: requestOptions,
    skip: forceLoading,
  });
  const color = "var(--graph-color-library-divergent-two)";
  const scrollRef = useRef(null);

  useEffect(() => {
    if (addressData == null) return;
    if (offset === 0) {
      setUsers(addressData);
      return;
    }
    if (users == null) {
      setUsers(addressData);
      return;
    }

    const newUsersExist = addressData.some(
      newUser => !users.some(oldUser => oldUser.uid === newUser.uid)
    );
    if (!newUsersExist) return;
    setUsers([...users, ...addressData]);
    setLoading(false);
  }, [addressData, users]);

  useEffect(() => {
    const el = scrollRef.current;

    if (!el) return;

    const handleScroll = throttle(() => {
      if (addressData == null || loading) return;

      const { scrollTop, scrollHeight, clientHeight } = el;

      if (scrollTop + clientHeight >= scrollHeight) {
        setLoading(true);
        setOffset(prevOffset => prevOffset + 100);
      }
    }, 200);

    el.addEventListener("scroll", handleScroll);

    return () => {
      el.removeEventListener("scroll", handleScroll);
    };
  }, [addressData, loading, users]);

  const hardLoading = users == null;
  const lightLoading = addressData == null;

  if (hardLoading) {
    return (
      <div className={`${styles.widget} ${styles["w-1-2"]}`}>
        <TableLoader viewBox={"0 0 515 498"} />
      </div>
    );
  }

  return (
    <>
      <div className={`${styles.widget} ${styles["w-1-2"]}`}>
        <div className={styles.widgetHeader}>
          <div
            className={styles.widgetLine}
            style={{ backgroundColor: color }}
          />
          <div className={styles.widgetHeaderTextWrap}>
            <div className={styles.widgetHeaderDate}>All time</div>
            <div className={styles.widgetHeaderTitle}>
              Recent users
            </div>
            <div className={styles.widgetHeaderSubtitle}>
              The most recent users synchronized
            </div>
          </div>
          <Link to={`/users`} className={styles.widgetViewMoreBtn}>
            <div className={styles.widgetViewMoreBtnIcon}>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4.828 10h6.239m-6.239 4h6.239M6 1v4a1 1 0 0 1-1 1H1m14-4v16a.97.97 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2Z"
                />
              </svg>
            </div>
            <div className={styles.widgetViewMoreBtnText}>
              View more
            </div>
          </Link>
        </div>
        <div className={styles.widgetBody} ref={scrollRef}>
          {lightLoading && (
            <Progress
              loading={true}
              className={styles.scrollLoading}
              size={2}
            />
          )}
          <MiniTable
            data={users}
            columns={userColumns}
            classes={{
              table: styles.widgetTable,
              thead: styles.widgetTableHeaderWrap,
              theadCell: styles.tableHeader,
              tbodyRow: styles.tableRow,
              tbodyCell: styles.tableCell,
            }}
          />
        </div>
      </div>
    </>
  );
}

function RecentEvents({ forceLoading }) {
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState(undefined);
  const requestOptions = { offset };
  const { data: eventResponse } = usePostEndpoint({
    endpoint: "events/light",
    body: requestOptions,
    skip: forceLoading,
  });
  const color = "var(--graph-color-library-divergent-three)";
  const scrollRef = useRef(null);

  useEffect(() => {
    if (eventResponse == null) return;
    if (offset === 0) {
      setEvents(eventResponse);
      return;
    }
    if (events == null) {
      setEvents(eventResponse);
      return;
    }

    const newEventsExist = eventResponse.some(
      newEvent => !events.some(oldEvent => oldEvent.id === newEvent.id)
    );
    if (!newEventsExist) return;
    setEvents([...events, ...eventResponse]);
    setLoading(false);
  }, [eventResponse, events]);

  useEffect(() => {
    const el = scrollRef.current;

    if (!el) return;

    const handleScroll = throttle(() => {
      if (eventResponse == null || loading) return;

      const { scrollTop, scrollHeight, clientHeight } = el;

      if (scrollTop + clientHeight >= scrollHeight) {
        setLoading(true);
        setOffset(prevOffset => prevOffset + 100);
      }
    }, 200);

    el.addEventListener("scroll", handleScroll);

    return () => {
      el.removeEventListener("scroll", handleScroll);
    };
  }, [eventResponse, loading, events]);

  // useEffect(() => {
  //     const el = scrollRef.current
  //     if (el == null) return
  //     el.addEventListener("scroll", () => {
  //         if (eventResponse == null) return
  //         const { scrollTop, scrollHeight, clientHeight } = el
  //         if (scrollTop + clientHeight >= scrollHeight) {
  //             setOffset(offset + 100)
  //         }
  //     })
  // })

  const hardLoading = events == null;
  const lightLoading = eventResponse == null;

  if (hardLoading) {
    return (
      <div className={`${styles.widget} ${styles["w-1-2"]}`}>
        <TableLoader viewBox={"0 0 515 498"} />
      </div>
    );
  }

  return (
    <>
      <div className={`${styles.widget} ${styles["w-1-2"]}`}>
        <div className={styles.widgetHeader}>
          <div
            className={styles.widgetLine}
            style={{ backgroundColor: color }}
          />
          <div className={styles.widgetHeaderTextWrap}>
            <div className={styles.widgetHeaderDate}>All time</div>
            <div className={styles.widgetHeaderTitle}>
              Recent events
            </div>
            <div className={styles.widgetHeaderSubtitle}>
              The most recent events that have been executed
            </div>
          </div>
          <Link to={`/events`} className={styles.widgetViewMoreBtn}>
            <div className={styles.widgetViewMoreBtnIcon}>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4.828 10h6.239m-6.239 4h6.239M6 1v4a1 1 0 0 1-1 1H1m14-4v16a.97.97 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2Z"
                />
              </svg>
            </div>
            <div className={styles.widgetViewMoreBtnText}>
              View more
            </div>
          </Link>
        </div>
        <div className={styles.widgetBody} ref={scrollRef}>
          {lightLoading && (
            <Progress
              loading={true}
              className={styles.scrollLoading}
              size={2}
            />
          )}
          <MiniTable
            data={events}
            columns={eventColumns}
            classes={{
              table: styles.widgetTable,
              thead: styles.widgetTableHeaderWrap,
              theadCell: styles.tableHeader,
              tbodyRow: styles.tableRow,
              tbodyCell: styles.tableCell,
            }}
          />
        </div>
      </div>
    </>
  );
}

function Transactions({ forceLoading }) {
  const [type, setType] = useState("offchain");
  const { data } = usePostEndpoint({
    endpoint: "widgets/transaction-volume",
    body: { type },
    skip: forceLoading,
  });
  const color = "var(--graph-color-library-divergent-four)";

  if (data == null) {
    return (
      <div className={`${styles.widget} ${styles["w-2-3"]}`}>
        <BarChartLoader
          barSize={100}
          padding={32}
          viewBox={"0 0 691 500"}
        />
      </div>
    );
  }

  return (
    <>
      <div className={`${styles.widget} ${styles["w-2-3"]}`}>
        <div className={styles.widgetHeader}>
          <div
            className={styles.widgetLine}
            style={{ backgroundColor: color }}
          />
          <div className={styles.widgetHeaderTextWrap}>
            <div className={styles.widgetHeaderDate}>
              Last 30 days
            </div>
            <div className={styles.widgetHeaderTitle}>
              Daily transaction volume
            </div>
            <div className={styles.widgetHeaderSubtitle}>
              The total number of transactions executed
            </div>
          </div>
        </div>
        <div className={styles.widgetBody}>
          <WidgetBarChart
            data={data}
            color={color}
            name={"Transactions"}
          />
        </div>
      </div>
    </>
  );
}

const contractColumns = [
  {
    key: "to_address",
    title: "Contract",
    renderCell: ({ value, data }) => {
      const explorerLink = getExplorerLink(data.chain, "address", value)
      return (
        <a
          href={explorerLink}
          target="_blank"
          className={styles.contractLink}
        >
          {smartTrim(value, 15)}
        </a>
      );
    },
  },
  {
    key: "count",
    title: "Unique Users",
  },
];

function TopContracts({ forceLoading }) {
  const { data } = usePostEndpoint({
    endpoint: "widgets/top-contracts",
    skip: forceLoading,
  });
  const color = "var(--graph-color-library-divergent-three)";
  const scrollRef = useRef(null);

  const hardLoading = data == null;

  if (hardLoading) {
    return (
      <div className={styles.widget}>
        <TableLoader viewBox={"0 0 341 498"} />
      </div>
    );
  }

  return (
    <>
      <div className={`${styles.widget} ${styles.w12}`}>
        <div className={styles.widgetHeader}>
          <div
            className={styles.widgetLine}
            style={{ backgroundColor: color }}
          />
          <div className={styles.widgetHeaderTextWrap}>
            <div className={styles.widgetHeaderDate}>
              Last 30 days
            </div>
            <div className={styles.widgetHeaderTitle}>
              Top contracts
            </div>
            <div className={styles.widgetHeaderSubtitle}>
              The top contracts that have been interacted with the
              most
            </div>
          </div>
        </div>
        <div className={styles.widgetBody} ref={scrollRef}>
          <MiniTable
            data={data}
            columns={contractColumns}
            classes={{
              table: styles.widgetTable,
              thead: styles.widgetTableHeaderWrap,
              theadCell: styles.tableHeader,
              tbodyRow: styles.tableRow,
              tbodyCell: styles.tableCell,
            }}
          />
        </div>
      </div>
    </>
  );
}
// function TopContracts() {
//     // const [type, setType] = useState("offchain")
//     // const { data } = usePostEndpoint({ endpoint: "widgets/active-users", body: { type } })
//     const color = "var(--graph-color-library-divergent-five)"
//     // const [optionsOpen, setOptionsOpen] = useState(false)
//     // const optionsEl = useRef(null)

//     // function typeClick(type) {
//     //     setType(type)
//     //     setOptionsOpen(false)
//     // }

//     // if (data == null) {
//     //     return <div className={styles.widget}>
//     //         <BarChartLoader />
//     //     </div>
//     // }

//     return <>
//         <div className={`${styles.widget} ${styles.w12}`}>
//             <div className={styles.widgetHeader}>
//                 <div className={styles.widgetLine} style={{ backgroundColor: color }} />
//                 <div className={styles.widgetHeaderTextWrap}>
//                     <div className={styles.widgetHeaderDate}>Last 30 days</div>
//                     <div className={styles.widgetHeaderTitle}>Top contracts</div>
//                     <div className={styles.widgetHeaderSubtitle}>The top contracts that have been interacted with the most</div>
//                 </div>
//             </div>
//             {/* <div className={styles.widgetBody}>
//                 <WidgetBarChart data={data} color={color} name={"DAUs"} />
//             </div> */}
//         </div>
//     </>
// }

function InfoWidget({ title, description, href, target, onClick }) {
  let props = {};
  if (href != null) {
    props = {
      href,
      target,
    };
  }

  if (onClick != null) {
    props = {
      ...props,
      onClick: e => {
        e.preventDefault();
        onClick(e);
      },
    };
  }

  return (
    <a className={styles.infoWidget} {...props}>
      <div className={styles.infoWidgetTitle}>{title}</div>
      <div className={styles.infoWidgetSubtitle}>{description}</div>
      {/* {href != null && <a href={href} target={target} className={styles.infoWidgetCTA}>
            {ctaText}
        </a>}
        {onClick != null && <button className={styles.infoWidgetCTA} onClick={onClick}>
            {ctaText}
        </button>} */}
    </a>
  );
}

function CreateItem({ icon, title, description, href, onClose, isNew }) {
  return (
    <Link
      className={styles.createItemWrap}
      to={href}
      onClick={e => {
        onClose();
      }}
    >
      <div className={styles.createItemIcon}>{icon}</div>
      <div className={styles.createItemTextWrap}>
        <div className={styles.createItemTitle}>
          {title}
          {isNew && <div className={styles.itemTagNew}>New</div>}
        </div>
        <div className={styles.createItemDescription}>
          {description}
        </div>
      </div>
    </Link>
  );
}

function PageLoading({ createPanel, setCreatePanel }) {
  return (
    <>
      <CreatePanel
        isOpen={createPanel}
        onClose={() => setCreatePanel(false)}
      />
      <Content>
        <div className={styles.infoWidgetRow}>
          <InfoWidget
            title={"📊 Create a report"}
            description={
              "Create a report create a report create a report create a "
            }
            onClick={e => {
              setCreatePanel(true);
            }}
          />
          <InfoWidget
            title={"📖 See documentation"}
            description={
              "See documentation see documentation see documentation see"
            }
            href={"https://docs.multibase.co"}
            target={"_blank"}
          />
        </div>
        <div className={styles.overviewWrap}>
          <div className={styles.widgetRow}>
            <Insights
              forceLoading={true}
              setCreatePanel={setCreatePanel}
            />
            <DAUs forceLoading={true} />
          </div>
          <div className={styles.widgetRow}>
            <RecentUsers forceLoading={true} />
            <RecentEvents forceLoading={true} />
          </div>
          <div className={styles.widgetRow}>
            <Transactions forceLoading={true} />
            <TopContracts forceLoading={true} />
          </div>
        </div>
      </Content>
    </>
  );
}

function CreatePanel({ isOpen, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Create new"}
      width={700}
    >
      <div className={styles.newPopoverWrap}>
        <div className={styles.popoverTitle}>Reports</div>
        <div className={styles.popperItemWrap}>
          <CreateItem
            icon={<UilChartLine />}
            title={"Events"}
            description={
              "Visualize events, balance, transactions, and activity over time."
            }
            href={`/query/event/create`}
            onClose={onClose}
          />
          <CreateItem
            icon={<UilChartPie />}
            title={"Composition"}
            description={
              "Overlaps among users including NFTs, tokens, and contracts."
            }
            href={`/query/composition/create`}
            onClose={onClose}
          />
          <CreateItem
            icon={<Sync />}
            title={"Retention"}
            description={
              "Explore likelihood of returning addresses to contract methods."
            }
            href={`/query/retention/create`}
            onClose={onClose}
          />
          <CreateItem
            icon={<UilFilter />}
            title={"Funnel"}
            description={
              "Visualize smart contract method conversion rates."
            }
            href={`/query/funnel/create`}
            onClose={onClose}
          />
          <CreateItem
            icon={<Route />}
            title={"Pathfinder"}
            description={
              "Map user behavior with paths of on-chain actions."
            }
            href={`/query/pathfinder/create`}
            onClose={onClose}
          />
        </div>
        <div className={styles.popoverTitle}>Users</div>
        <div className={styles.popperItemWrap}>
          <CreateItem
            icon={<UilDatabase />}
            title={"Labels"}
            description={
              "Cohort your audience by their on-chain trends."
            }
            href={`/users`}
            onClose={onClose}
          />
        </div>
      </div>
    </Modal>
  );
}
