import { createElement, useEffect, useRef, useState } from "react"
import * as Sankey from 'd3-sankey'
import styles from './PathfinderChart.module.css'
import { formatSecondsDelta, getExplorerLink, leftTrim, smartTrim } from "helpers";
import numeral from "numeral";
import { UilBoltAlt, UilUser, UilApps, UilExternalLinkAlt, UilSnapchatAlt } from '@iconscout/react-unicons'
import { CircularProgress, ClickAwayListener, Collapse, Drawer, Popper } from "@mui/material";
import { UilMultiply, UilFileExport, UilFileContract } from '@iconscout/react-unicons'
import { usePostEndpoint } from "ApiConnector";
import Blockies from 'react-blockies';
import { Link } from "react-router-dom";
import { Tooltip } from "components/Tooltip/Tooltip";
import moment from "moment";
import useResizeObserver from "use-resize-observer";
import { Apps, ElectricBoltRounded, GridView, HourglassDisabled, HourglassEmpty, HourglassEmptyRounded, LayersRounded } from "@mui/icons-material";
import { exportCSV } from "utils/export";

const BASE_NODE_HEIGHT = 200

function find(nodeById, id) {
    const node = nodeById.get(id);
    if (!node) throw new Error("missing: " + id);
    return node;
}


function computeNodeLinks({ nodes, links, index = false, extraNodes = [], extraLinks = [] }) {
    const nodesWithExtra = structuredClone([...nodes, ...extraNodes])
    const linksWithExtra = structuredClone([...links, ...extraLinks])

    for (const [i, node] of nodesWithExtra.entries()) {
        node.index = i;
        node.sourceLinks = [];
        node.targetLinks = [];
    }

    let nodeById = new Map(nodesWithExtra.map((d, i) => [d.name, d]));
    for (const [i, link] of linksWithExtra.entries()) {
        link.index = i;
        let { source, target } = link;
        source = link.source = find(nodeById, source);
        target = link.target = find(nodeById, target);
        source.sourceLinks.push(link);
        target.targetLinks.push(link);
    }

    return { nodes: nodesWithExtra, links: linksWithExtra }
}

function computeNodeValues({ nodes }) {
    for (const node of nodes) {
        const sumSource = node.sourceLinks.reduce((acc, curr) => {
            const { height, value } = curr
            return {
                height: acc.height + height,
                value: acc.value + value
            }
        }, { height: 0, value: 0 })
        const sumTarget = node.targetLinks.reduce((acc, curr) => {
            const { height, value } = curr
            return {
                height: acc.height + height,
                value: acc.value + value
            }
        }, { height: 0, value: 0 })

        const height = Math.max(sumSource.height, sumTarget.height)
        const val = Math.max(sumSource.value, sumTarget.value)
        node.value = val
        node.height = height
    }
}

function getOffsetFromLinks(linkGroup, link) {
    // sort linkGroup by value
    linkGroup.sort((a, b) => {
        if (a.target.isDropoff) return 1
        if (b.target.isDropoff) return -1

        if (a.target.isOther) return 1
        if (b.target.isOther) return -1

        return b.value - a.value
    })

    const index = linkGroup.findIndex((x) => x.index === link.index)
    const sum = linkGroup.slice(0, index).reduce((acc, curr) => {
        return acc + curr.height
    }, 0)
    return sum
}

function computeLinkPositions({ links }) {
    for (const link of links) {
        const { source, target } = link
        const yOffsetOfSource = getOffsetFromLinks(source.sourceLinks, link)
        const yOffsetOfTarget = getOffsetFromLinks(target.targetLinks, link)
        link.width = link.height
        link.sourceOffset = yOffsetOfSource
        link.targetOffset = yOffsetOfTarget
    }
}

function computeLinkHeights({ links, total }) {
    for (const link of links) {
        const { value } = link
        const pct = value / total
        const height = Math.max(BASE_NODE_HEIGHT * pct, 1)
        link.height = height
    }
}

function multibaseSankey({ nodes: initialNodes, links: initialLinks, total }) {
    const { nodes, links } = computeNodeLinks({ nodes: initialNodes, links: initialLinks })
    computeLinkHeights({ links, total })
    computeNodeValues({ nodes })
    computeLinkPositions({ links, total })
    return { nodes, links }
}

export function PathfinderChart({ nodeData, linkData, total, data, steps }) {
    const { nodes = [], links = [] } = multibaseSankey({ nodes: nodeData, links: linkData, total })

    nodes.sort((a, b) => {
        if (a.step === b.step) {
            if (a.isDropoff) return 1
            if (b.isDropoff) return -1

            if (a.isOther) return 1
            if (b.isOther) return -1

            return b.value - a.value
        }
        return a.step - b.step
    })

    return <PathfinderChartInner
        nodes={nodes}
        links={links}
        data={data}
        steps={steps}
    />
}

export function PathfinderChartInner({ nodes, links, steps }) {
    const [targetData, setTargetData] = useState(null)
    const [highlighted, setHighlighted] = useState({
        nodes: null,
        links: null
    })
    const [bookmarked, setBookmarked] = useState({
        nodes: null,
        links: null,
    })
    const [lastClicked, setLastClicked] = useState(null);
    const [sidebarData, setSidebarData] = useState(null)
    const timerRef = useRef(null);

    // useresizeobserver hook
    const { ref, width = 1, height = 1 } = useResizeObserver();

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                setLastClicked(null)
                // setBookmarked({ nodes: null, links: null })
                unhighlight(true)
            }
        }
        window.addEventListener('keydown', handleEscape)
        return () => window.removeEventListener('keydown', handleEscape)
    })

    function resetAllToOriginalValues(hard) {

        if ((bookmarked.nodes != null || bookmarked.links != null) && !hard) {
            return
        }

        setTargetData(null)
        setLastClicked(null)
        setBookmarked({ nodes: null, links: null })
        setHighlighted({
            nodes: null,
            links: null
        })
    }

    function bookmarkLink(link) {

        if (lastClicked === link.target.index) {
            setLastClicked(null)
            unhighlight(true)
            return
        }

        setLastClicked(link.target.index)

        let highlightLinkIndeces = [link.index]
        let highlightNodeIndeces = [link.target.index]
        const nodesToColor = [link.source]
        while (nodesToColor.length > 0) {
            const _node = nodesToColor.pop()
            highlightNodeIndeces.push(_node.index)
            _node?.targetLinks.forEach((_link) => {
                highlightLinkIndeces.push(_link.index)
                nodesToColor.push(_link.source)
            })
        }

        setHighlighted({ nodes: null, links: null })
        setBookmarked({
            nodes: highlightNodeIndeces,
            links: highlightLinkIndeces
        })
    }

    function bookmarkNode(node, ref) {

        if (lastClicked === node.index) {
            setLastClicked(null)
            unhighlight(true)
            return
        }

        setTargetData({
            ref: ref,
            node: node
        })
        setLastClicked(node.index)

        let highlightNodeIndeces = [node.index]
        let highlightLinkIndeces = []
        const nodesToColor = [node]
        while (nodesToColor.length > 0) {
            const _node = nodesToColor.pop()
            highlightNodeIndeces.push(_node.index)
            _node?.targetLinks.forEach((_link) => {
                highlightLinkIndeces.push(_link.index)
                nodesToColor.push(_link.source)
            })
        }

        setHighlighted({ nodes: null, links: null })
        setBookmarked({
            nodes: highlightNodeIndeces,
            links: highlightLinkIndeces
        })
    }

    function highlightNode(node) {

        if (bookmarked.nodes != null || bookmarked.links != null) {
            return
        }

        const updateTimer = () => {
            const nodesToColor = [node]
            let linksToHighlight = []
            let highlightNodeIndeces = [node.index]
            while (nodesToColor.length > 0) {
                const _node = nodesToColor.pop()
                highlightNodeIndeces.push(_node.index)
                _node?.targetLinks.forEach((_link) => {
                    linksToHighlight.push(_link.index)
                    nodesToColor.push(_link.source)
                })
            }

            setHighlighted({
                nodes: highlightNodeIndeces,
                links: linksToHighlight
            })
        };

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(updateTimer, 100);
    }

    function highlightLinks(link) {
        if (bookmarked.nodes != null || bookmarked.links != null) {
            return
        }

        const updateTimer = () => {
            const nodesToColor = [link.source]
            let highlightNodeIndeces = [link.target.index]
            let highlightedLinkIndeces = [link.index]
            while (nodesToColor.length > 0) {
                const _node = nodesToColor.pop()
                highlightNodeIndeces.push(_node.index)
                _node?.targetLinks.forEach((_link) => {
                    highlightedLinkIndeces.push(_link.index)
                    nodesToColor.push(_link.source)
                })
            }

            setHighlighted({
                nodes: highlightNodeIndeces,
                links: highlightedLinkIndeces
            })


        };

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(updateTimer, 100);
    }

    function unhighlight(hard = false) {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        resetAllToOriginalValues(hard)
    }

    const sumByStep = nodes.reduce((acc, curr) => {
        const { step, value } = curr
        if (acc[step] == null) {
            acc[step] = 0
        }
        acc[step] += value
        return acc
    }, {})

    const stepArray = Array(steps).fill(0).map((_, idx) => idx + 1)
    const isHighlightingActive = highlighted.nodes != null || highlighted.links != null || bookmarked.nodes != null || bookmarked.links != null

    return <>
        {/* <PathfinderSidebar
            sidebarData={sidebarData}
            setSidebarData={setSidebarData}
            unhighlight={unhighlight}
        /> */}
        {/* <div className={"Paths"} style={{ position: "relative", zIndex: 0, width: "100%", height: "100%", marginRight: sidebarData != null ? "300px" : "0" }} id={'Canvas'} ref={ref}> */}
        <div className={"Paths"} style={{ position: "relative", zIndex: 0, width: "100%", height: "100%" }} id={'Canvas'} ref={ref}>
            <NodeMenu
                targetData={targetData}
                setTargetData={setTargetData}
                unhighlight={unhighlight}
                setSidebarData={setSidebarData}
                sumByStep={sumByStep}
            />
            <svg className={styles.sankeyLinks}>
                {links.map((link, idx) => {
                    const linkIsHighlighted = highlighted.links?.includes(link.index) || bookmarked.links?.includes(link.index)
                    return <NodePath
                        link={link}
                        isHighlighted={linkIsHighlighted}
                        isHighlightingActive={isHighlightingActive}
                        highlightLinks={highlightLinks}
                        bookmarkLink={bookmarkLink}
                        unhighlight={unhighlight}
                        key={idx}
                    />
                })}
            </svg>
            <div className={styles.pathsWrap}>
                {stepArray.map((step, idx) => {
                    return <div
                        className={styles.stepColumn}
                        key={idx}
                        style={{
                            width: `calc(100% / ${steps})`
                        }}
                    >
                        {nodes.filter((node) => node.step === step).map((node, idx) => {
                            return <NodeCardV3
                                key={idx}
                                node={node}
                                isHighlighted={highlighted.nodes?.includes(node.index) || bookmarked.nodes?.includes(node.index)}
                                isHighlightingActive={isHighlightingActive}
                                highlightNode={highlightNode}
                                unhighlight={unhighlight}
                                bookmarkNode={bookmarkNode}
                                total={sumByStep[step]}
                                targetData={targetData}
                            />
                        })}
                    </div>
                })}
            </div>
        </div>
    </>
}

// const menuOptions = [
//     {
//         label: "View users",
//         icon: <UilUser />,
//         mode: "users",
//         title: "View users",
//         content: UserSidebarContent
//     },
//     {
//         label: "List transactions",
//         icon: <UilApps />,
//         mode: "transactions",
//         title: "Transactions",
//         content: TransactionSidebarContent
//     }
// ]

function NodeMenu({
    targetData,
    setTargetData,
    unhighlight,
    setSidebarData,
    nodeData,
    sumByStep
}) {
    const [selectedIndex, setSelectedIndex] = useState(0)

    // useEffect(() => {
    //     setSelectedIndex(0)
    // }, [targetData])

    // useEffect(() => {
    //     const handleArrow = (e) => {
    //         if (e.key === 'ArrowDown') {
    //             e.preventDefault()
    //             setSelectedIndex((prev) => {
    //                 return Math.min(prev + 1, menuOptions.length - 1)
    //             })
    //         } else if (e.key === 'ArrowUp') {
    //             e.preventDefault()
    //             setSelectedIndex((prev) => {
    //                 return Math.max(prev - 1, 0)
    //             })
    //         } else if (e.key === 'Enter') {
    //             const selectedOption = menuOptions[selectedIndex]
    //             setTargetData(null)
    //             setSidebarData({
    //                 title: selectedOption.title,
    //                 renderedData: createElement(selectedOption.content, {
    //                     node: targetData.node
    //                 })
    //             })

    //             // const selectedOption = menuOptions[selectedIndex]
    //             // if (selectedOption.mode === "users") {
    //             //     renderUserData()
    //             // } else if (selectedOption.mode === "transactions") {
    //             //     renderTransactionData()
    //             // }
    //         }
    //     }
    //     window.addEventListener('keydown', handleArrow)
    //     return () => window.removeEventListener('keydown', handleArrow)
    // })

    return <Popper
        open={targetData != null}
        anchorEl={targetData?.ref?.current}
        placement={"bottom-start"}
        sx={{
            background: "rgb(var(--background))",
        }}
        modifiers={[
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
        ]}
    >
        <ClickAwayListener
            onClickAway={() => {
                setSelectedIndex(0)
                unhighlight(true)
            }}
        >

            <div></div>
        </ClickAwayListener>
    </Popper>

    // return <Popper
    //     open={targetData != null}
    //     anchorEl={targetData?.ref?.current}
    //     placement={"bottom-start"}
    //     sx={{
    //         background: "rgb(var(--background))",
    //     }}
    //     modifiers={[
    //         {
    //             name: 'offset',
    //             options: {
    //                 offset: [0, 8],
    //             },
    //         },
    //     ]}
    // >
    //     <ClickAwayListener
    //         onClickAway={() => {
    //             setSelectedIndex(0)
    //             unhighlight(true)
    //         }}
    //     >

    //         <div className={styles.bookmarkMenu}>
    //             <div className={styles.bookmarkMenuForeground} />
    //             {/* <button 
    //             className={`${styles.bookmarkMenuOption} ${selectedIndex === 0 ? styles.selected : ""}`} onClick={}
    //                 <div className={styles.bookmarkMenuIconWrap}>
    //                     <UilUser />
    //                 </div>
    //                 View users
    //             </button> */}
    //             {menuOptionsForType.map((option, idx) => {

    //                 const { mode, title, content } = option

    //                 return <button
    //                     key={idx}
    //                     className={`${styles.bookmarkMenuOption} ${selectedIndex === idx ? styles.selected : ""}`}
    //                     onClick={() => {
    //                         setSelectedIndex(idx)
    //                         setTargetData(null)
    //                         setSidebarData({
    //                             title: title,
    //                             renderedData: createElement(content, {
    //                                 node: targetData.node,
    //                                 total: sumByStep[targetData.node.step]
    //                             })
    //                         })
    //                     }}
    //                     onMouseEnter={() => {
    //                         setSelectedIndex(idx)
    //                     }}
    //                 >
    //                     <div className={styles.bookmarkMenuIconWrap}>
    //                         {option.icon}
    //                     </div>
    //                     {option.label}
    //                 </button>
    //             })}
    //         </div>
    //     </ClickAwayListener>
    // </Popper>
}

function NodeCardV3({
    node,
    isHighlighted,
    isHighlightingActive,
    highlightNode,
    unhighlight,
    bookmarkNode,
    total,
    targetData
}) {

    const { isDropoff, isOther, conversion, metadata, type, height } = node || {}
    const nodeRef = useRef(null)

    let color = "rgb(var(--primary))"
    if (isDropoff) {
        color = "rgb(255 255 255 / 50%)"
    }

    const value = node.value
    const valueFmt = numeral(value).format('0,0')

    const pct = (value / total)
    const pctFmt = numeral(pct).format('0.00%')

    const { text, subtext } = metadata || {}
    let name = null
    if (isDropoff) {
        name = "Dropoff"
    } else if (isOther) {
        name = "Other events"
    } else if (text != null) {
        name = text
    }

    const dimmed = !isHighlighted && isHighlightingActive
    const highlighted = isHighlighted && isHighlightingActive
    const isTarget = targetData?.node?.name === node.name

    let contractEl = <></>
    if (subtext != null) {
        contractEl = <div className={styles.nodeSub}>{subtext}</div>
    }

    let iconstyles = {}
    if (isDropoff) {
        iconstyles = { width: 14, minWidth: 14, height: 14, minHeight: 14, marginRight: 2 }
    }

    return <div className={`${styles.nodeCardV3} ${highlighted ? styles.highlighted : ""} ${dimmed ? styles.dimmed : ""}`}>
        <div className={styles.nodeCardInner}>
            <div className={styles.nodeCardIconWrap} style={iconstyles}>
                {/* {<UilBoltAlt />} */}
                {type === "onchain" && <ElectricBoltRounded />}
                {type === "offchain" && <LayersRounded />}
                {isOther && <Apps />}
                {isDropoff && <HourglassDisabled />}
            </div>
            <div className={styles.nodeTextWrap}>
                <div className={styles.nodeLabel}>{name}</div>
                {contractEl}
                <div className={styles.nodeValsWrap}>
                    <span className={styles.nodePct}>{pctFmt}</span>
                    <span className={styles.nodeValue}>{valueFmt}</span>
                </div>
            </div>
        </div>
        <div
            className={`${styles.nodeRect} ${isTarget ? styles.target : ""} ${isDropoff ? styles.dropoff : ""}`}
            ref={nodeRef}
            id={`node-${node.index}`}
            onMouseEnter={() => {
                highlightNode(node)
            }}
            onMouseLeave={() => unhighlight()}
            onClick={() => {
                bookmarkNode(node, nodeRef)
            }}
            style={{
                height: height,
                background: color
            }}
        />
    </div>
}

function getRelativeBoundingRect(childElement, parentElement) {
    const childRect = childElement.getBoundingClientRect();
    const parentRect = parentElement.getBoundingClientRect();

    return {
        top: childRect.top - parentRect.top,
        left: childRect.left - parentRect.left,
        width: childRect.width,
        height: childRect.height
    };
}

function NodePath({
    link,
    isHighlighted,
    isHighlightingActive,
    highlightLinks,
    unhighlight,
    bookmarkLink,
}) {

    const [sourceDOM, setSourceDOM] = useState(null);
    const [targetDOM, setTargetDOM] = useState(null);
    const [parentDOM, setParentDOM] = useState(null);

    useEffect(() => {
        const updateValues = () => {
            const sourceDOMId = `node-${link.source.index}`;
            const targetDOMId = `node-${link.target.index}`;

            const sourceElement = document.getElementById(sourceDOMId);
            const targetElement = document.getElementById(targetDOMId);
            const parentElement = document.getElementById("Canvas");

            setSourceDOM(sourceElement);
            setTargetDOM(targetElement);
            setParentDOM(parentElement);

        };

        updateValues()
    });


    const { width, sourceOffset, targetOffset } = link

    const isDropoff = link.target.isDropoff

    if (!sourceDOM || !targetDOM || !parentDOM) {
        return null; // or return a loading indicator
    }

    const sourceRect = getRelativeBoundingRect(sourceDOM, parentDOM)
    const targetRect = getRelativeBoundingRect(targetDOM, parentDOM)

    let color = "rgb(var(--primary) / 20%)"
    if (isDropoff) {
        color = "rgb(255 255 255 / 5%)"
    }

    const sourceX0 = sourceRect.left
    const sourceX1 = sourceRect.left + sourceRect.width
    const sourceY0 = sourceRect.top
    // const sourceY1 = sourceRect.top + sourceRect.height

    const targetX0 = targetRect.left
    const targetX1 = targetRect.left + targetRect.width
    const targetY0 = targetRect.top
    // const targetY1 = targetRect.top + targetRect.height

    const shouldDim = isHighlightingActive && !isHighlighted
    if (shouldDim && !isDropoff) {
        color = "rgb(var(--primary) / 5%)"
    } else if (shouldDim && isDropoff) {
        color = "rgb(255 255 255 / 1%)"
    }

    return <g>
        <path
            d={Sankey.sankeyLinkHorizontal()({
                ...link,
                y0: sourceY0 + sourceOffset + (width / 2),
                y1: targetY0 + targetOffset + (width / 2),
                source: {
                    ...link.source,
                    x0: sourceX0,
                    x1: sourceX1,
                },
                target: {
                    ...link.target,
                    x0: targetX0,
                    x1: targetX1,
                },
            })}
            id={`path-${link.index}`}
            stroke={color}
            strokeWidth={width}
            fill="none"
            onMouseOver={() => {
                highlightLinks(link)
            }}
            onMouseLeave={() => unhighlight()}
            onClick={() => {
                bookmarkLink(link)
            }}
            className={`${styles.linkPath} ${isDropoff ? styles.linkPathDropoff : ""}`}
        />
    </g>
}

// function PathfinderSidebar({
//     sidebarData,
//     setSidebarData,
//     unhighlight
// }) {

//     // on escape, set sidebar data to null
//     function onClose() {
//         setSidebarData(null)
//         unhighlight(true)
//     }

//     useEffect(() => {
//         const handleEscape = (e) => {
//             if (e.key === 'Escape') {
//                 setSidebarData(null)
//             }
//         }
//         window.addEventListener('keydown', handleEscape)
//         return () => window.removeEventListener('keydown', handleEscape)
//     })


//     if (sidebarData == null) {
//         return
//     }

//     const { title, renderedData } = sidebarData || {}

//     // show left
//     return <ClickAwayListener onClickAway={onClose}>
//         <div className={styles.sidebarWrap}>
//             <div className={styles.sidebarInner}>
//                 <div className={styles.sidebarTop}>
//                     <div className={styles.sidebarTitle}>{title}</div>
//                     <button onClick={onClose} className={styles.sidebarClose}>
//                         <UilMultiply />
//                     </button>
//                 </div>
//                 <div className={styles.sidebarContent}>
//                     {renderedData}
//                 </div>
//             </div>
//         </div>
//     </ClickAwayListener>
// }

// function UserSidebarContent({ node, total }) {
//     const { type } = node;
//     if (type === "custom") {
//         return <UserSidebarContentCustom node={node} total={total} />
//     }
//     if (type === "onchain" || type == null) {
//         return <UserSidebarContentChain node={node} total={total} />
//     }
// }

// function UserSidebarContentCustom({ node, total }) {

//     const { step, uids, isOther, isDropoff, currentEvent, conversion, name, value } = node || {}

//     function exportCSVClicked() {
//         const headers = ["User", "Chain"]
//         const rows = uids.map((uid) => {
//             const [part0, part1] = uid.split(".")
//             return [part0, part1]
//         })
//         const csvData = [headers, ...rows]
//         exportCSV(csvData, "Pathfinder Users")
//     }

//     const valueFmt = numeral(value).format('0,0')
//     const pct = (value / total)
//     const pctFmt = numeral(pct).format('0.00%')

//     // const isDropoff = currentEvent === "DROPOFF"
//     // const isOther = currentEvent === "OTHER"

//     let title = leftTrim(name, 25)
//     if (isDropoff) {
//         title = "Dropoff"
//     } else if (isOther) {
//         title = "Other methods"
//     }

//     return <div className={styles.userSidebar}>
//         <div className={styles.userTop}>
//             <div className={styles.userTitle}>
//                 <div className={styles.stepsIcon} />
//                 {step === 1 && <div className={styles.userText}>{title}</div>}
//                 {step > 1 && <div className={styles.userText}>{title} - N+{step - 1}</div>}
//             </div>
//             <div className={styles.userValsWrap}>
//                 <div className={styles.userSub}>{valueFmt} users ({pctFmt})</div>
//                 {/* <div className={styles.userSub}>{formatSecondsDelta(conversion)} conversion</div> */}
//             </div>
//             <div className={styles.userButtons}>
//                 <button className={styles.userButton} onClick={exportCSVClicked}>
//                     <div className={styles.userIcon}>
//                         <UilFileExport />
//                     </div>
//                     Export CSV
//                 </button>
//             </div>
//         </div>
//         <div className={styles.generalSidebar}>
//             {uids?.map((uid, idx) => {

//                 const [part0, part1] = uid.split(".")

//                 if (part1 == null) {
//                     return <div className={styles.sidebarRow} key={idx}>
//                         <div className={styles.sidebarRowInner}>
//                             <div className={styles.sidebarRowLabel}>{leftTrim(uid, 25)}</div>
//                             <div className={styles.sidebarRowRight}>
//                                 <div className={styles.userTag}>Anonymous</div>
//                             </div>
//                         </div>
//                     </div>
//                 }

//                 if (part0 != null && part1 != null) {
//                     const wallet_address = part0
//                     const chain = part1
//                     let display = smartTrim(wallet_address, 20)

//                     const explorerLink = getExplorerLink(chain, "address", wallet_address)

//                     return <div className={styles.sidebarRow} key={idx}>
//                         <Link className={`${styles.sidebarRowLink} ${styles.identiconWrap}`} to={`/users/${wallet_address}`} target="_blank" rel="noreferrer" key={idx}>
//                             <Blockies
//                                 seed={wallet_address}
//                                 size={10}
//                                 scale={10}
//                                 bgColor={"transparent"}
//                                 className={styles.identicon}
//                             />
//                             <div className={styles.addressesRowText}>
//                                 <div className={styles.sidebarRowLabel}>{display}</div>
//                             </div>
//                         </Link>
//                         <a className={styles.explorerAbsWrap} href={explorerLink} target="_blank" rel="noreferrer">
//                             <Tooltip text={"Open in explorer"}>
//                                 <div className={styles.explorerAbs}>
//                                     <UilExternalLinkAlt />
//                                 </div>
//                             </Tooltip>
//                         </a>
//                     </div>
//                 }

//             })}
//         </div>
//     </div>
// }

// function UserSidebarContentChain({ node, total }) {
//     const { step, uids, currentEvent, conversion, name, value } = node || {}

//     function exportCSVClicked() {
//         const headers = ["User", "Chain"]
//         const rows = uids.map((uid) => {
//             const [part0, part1] = uid.split(".")
//             return [part0, part1]
//         })
//         const csvData = [headers, ...rows]
//         exportCSV(csvData, "Pathfinder Users")
//     }

//     const valueFmt = numeral(value).format('0,0')
//     const pct = (value / total)
//     const pctFmt = numeral(pct).format('0.00%')

//     const isDropoff = currentEvent === "DROPOFF"
//     const isOther = currentEvent === "OTHER"

//     let title = leftTrim(name, 25)
//     if (isDropoff) {
//         title = "Dropoff"
//     } else if (isOther) {
//         title = "Other methods"
//     }

//     return <div className={styles.userSidebar}>
//         <div className={styles.userTop}>
//             <div className={styles.userTitle}>
//                 <div className={styles.stepsIcon} />
//                 {step === 1 && <div className={styles.userText}>{title}</div>}
//                 {step > 1 && <div className={styles.userText}>{title} - N+{step - 1}</div>}
//             </div>
//             <div className={styles.userValsWrap}>
//                 <div className={styles.userSub}>{valueFmt} users ({pctFmt})</div>
//                 {/* <div className={styles.userSub}>{formatSecondsDelta(conversion)} conversion</div> */}
//             </div>
//             <div className={styles.userButtons}>
//                 <button className={styles.userButton} onClick={exportCSVClicked}>
//                     <div className={styles.userIcon}>
//                         <UilFileExport />
//                     </div>
//                     Export CSV
//                 </button>
//             </div>
//         </div>
//         <div className={styles.generalSidebar}>
//             {uids?.map((uid, idx) => {

//                 const [part0, part1] = uid.split(".")


//                 if (part0 != null && part1 != null) {
//                     const wallet_address = part0
//                     const chain = part1
//                     let display = smartTrim(wallet_address, 20)

//                     const explorerLink = getExplorerLink(chain, "address", wallet_address)

//                     return <div className={styles.sidebarRow} key={idx}>
//                         <Link className={`${styles.sidebarRowLink} ${styles.identiconWrap}`} to={`/users/${wallet_address}`} target="_blank" rel="noreferrer" key={idx}>
//                             <Blockies
//                                 seed={wallet_address}
//                                 size={10}
//                                 scale={10}
//                                 bgColor={"transparent"}
//                                 className={styles.identicon}
//                             />
//                             <div className={styles.addressesRowText}>
//                                 <div className={styles.sidebarRowLabel}>{display}</div>
//                             </div>
//                         </Link>
//                         <a className={styles.explorerAbsWrap} href={explorerLink} target="_blank" rel="noreferrer">
//                             <Tooltip text={"Open in explorer"}>
//                                 <div className={styles.explorerAbs}>
//                                     <UilExternalLinkAlt />
//                                 </div>
//                             </Tooltip>
//                         </a>
//                     </div>
//                 }

//             })}
//         </div>
//     </div>
// }

// function TransactionSidebarContent({ node }) {
//     const { instances, name, currentEvent, value, step } = node || {}
//     const { data: transactionData } = usePostEndpoint({ endpoint: "transactions/metadata", body: { hashes: instances }, skip: instances == null })


//     const isDropoff = currentEvent === "DROPOFF"
//     const isOther = currentEvent === "OTHER"
//     const valueFmt = numeral(value).format('0,0')

//     let title = leftTrim(name, 12)
//     if (isDropoff) {
//         title = "Dropoff"
//     } else if (isOther) {
//         title = "Other methods"
//     }

//     const [method, contract, chain] = currentEvent.split(".")

//     if (transactionData == null) {
//         return <div className={styles.sidebarLoading}>
//             <CircularProgress sx={{ color: "var(--alpha-text)" }} size={40} />
//         </div>
//     }

//     return <div className={styles.userSidebar}>
//         <div className={styles.userTop}>
//             <div className={styles.userTitle}>
//                 <div className={styles.stepsIcon} />
//                 {step === 1 && <div className={styles.userText}>{title}</div>}
//                 {step > 1 && <div className={styles.userText}>{title} - N+{step - 1}</div>}
//             </div>
//             <div className={styles.userValsWrap}>
//                 <div className={styles.userSub}>{valueFmt} transactions</div>
//             </div>
//             <div className={styles.userButtons}>
//                 {(contract != null && contract !== "") && <a className={styles.userButton} href={getExplorerLink(chain, "address", contract)} target="_blank" rel="noreferrer">
//                     <div className={styles.userIcon}>
//                         <UilFileContract />
//                     </div>
//                     Contract
//                 </a>}
//             </div>
//         </div>
//         <div className={styles.generalSidebar}>
//             {transactionData?.map((tx, idx) => {
//                 const { ts, hash, wallet_address, wallet_name } = tx || {}
//                 const momentObj = moment(ts)
//                 const hashTrimmed = smartTrim(hash, 15)
//                 const explorerLink = getExplorerLink(chain, "tx", hash)

//                 let display = smartTrim(wallet_address, 20)
//                 if (wallet_name != null) {
//                     display = leftTrim(wallet_name, 20)
//                 }

//                 return <div className={styles.sidebarRow} key={idx}>
//                     <a href={explorerLink} target="_blank" rel="noreferrer" className={styles.sidebarRowLink}>
//                         {/* <div className={styles.addressesRowText}>
//                             <div className={styles.sidebarRowLabel}>{display}</div>
//                         </div> */}
//                         <div className={styles.addressesRowText}>
//                             <div className={styles.sidebarRowLabel}>Transaction hash {hashTrimmed}</div>
//                             <div className={styles.inlineUserRowFlex} style={{ margin: "4px 0" }}>
//                                 <Blockies
//                                     seed={wallet_address}
//                                     size={10}
//                                     scale={10}
//                                     bgColor={"transparent"}
//                                     className={styles.identiconSmall}
//                                 />
//                                 {display}
//                             </div>
//                             {/* readable date */}
//                             <div className={styles.sidebarRowSub}>{momentObj.format("MMM D, YYYY h:mm A")}</div>
//                         </div>
//                     </a>
//                 </div>
//             })}
//             {/* {addressData?.map((x, idx) => {

//                 const { wallet_address, chain } = x || {}

//                 let display = smartTrim(wallet_address, 20)
//                 const { name } = x || {}
//                 if (name != null) {
//                     display = leftTrim(name, 20)
//                 }

//                 const explorerLink = getExplorerLink(chain, "address", wallet_address)

//                 return <div className={styles.sidebarRow} key={idx}>
//                     <Link className={`${styles.sidebarRowLink} ${styles.identiconWrap}`} to={`/users/${wallet_address}`} target="_blank" rel="noreferrer" key={idx}>
//                         <Blockies
//                             seed={wallet_address}
//                             size={10}
//                             scale={10}
//                             bgColor={"transparent"}
//                             className={styles.identicon}
//                         />
//                         <div className={styles.addressesRowText}>
//                             <div className={styles.sidebarRowLabel}>{display}</div>
//                         </div>
//                     </Link>
//                     <a className={styles.explorerAbsWrap} href={explorerLink} target="_blank" rel="noreferrer">
//                         <Tooltip text={"Open in explorer"}>
//                             <div className={styles.explorerAbs}>
//                                 <UilExternalLinkAlt />
//                             </div>
//                         </Tooltip>
//                     </a>
//                 </div>
//             })} */}
//         </div>
//     </div>
// }